import React from 'react'
import PropTypes from 'prop-types'

import { FORM_ERROR } from 'final-form'

import root from 'utils/windowOrGlobal'
import logger from 'utils/logger'

import StateMachineProvider, { StateMachineContainer } from 'utils/StateMachine'

import * as api from './api'

const statechart = {
  initial: 'idle',
  states: {
    idle: {
      on: {
        CHANGE_PASSWORD: 'loading'
      }
    },
    loading: {
      onEntry: 'submitPasswordChange',
      on: {
        CHANGE_SUCCESS: 'complete',
        CHANGE_FAILURE: 'error'
      }
    },
    error: {
      on: {
        CHANGE_PASSWORD: 'loading'
      },
      onEntry: 'showError'
    },
    complete: {
      on: {
        RESET: 'idle'
      }
    }
  }
}

class PasswordChangeContainer extends StateMachineContainer {
  componentDidTransition (prevStateMachine, event) {
    if (event && event.type) {
      switch (event.type) {
        case 'CHANGE_FAILURE':
          this.setError(event)
          break
        case 'CHANGE_SUCCESS':
          this.setSuccess(event)
          break
        case 'RESET':
          this.resetForm(event)
          break
      }
    }
  }

  setSuccess = async event => {
    const { callback, resetForm } = event

    root.setTimeout(() => {
      this.transition({ type: 'RESET', resetForm })
    }, 3000)

    callback()
  }

  setError = event => {
    let error = event.error

    if (Array.isArray(error)) {
      error = error[0]
    }

    event.callback({ [FORM_ERROR]: error })
  }

  resetForm = event => event.resetForm()

  submitPasswordChange = async ({ values, callback, ...event }) => {
    logger.captureBreadcrumb({
      message: 'PasswordChangeScreen.submit',
      category: 'auth'
    })

    try {
      const [error, data] = await api.changePassword(values)

      if (error) {
        let errorMsg = Array.isArray(error) ? error[0] : error

        if (
          errorMsg.indexOf('Invalid') > -1 &&
          errorMsg.indexOf('current') > -1
        ) {
          errorMsg = 'Current password is incorrect.'
        }

        this.transition({ type: 'CHANGE_FAILURE', error: errorMsg, callback })
      } else {
        logger.captureBreadcrumb({
          message: 'PasswordChangeScreen.success',
          category: 'auth'
        })

        this.transition({
          ...event,
          type: 'CHANGE_SUCCESS',
          data,
          callback
        })
      }
    } catch (error) {
      logger.captureException(error)
    }
  }
}

export default class PasswordChangeStoreProvider extends React.Component {
  static propTypes = {
    children: PropTypes.func.isRequired
  }

  constructor (props) {
    super(props)
    this.container = new PasswordChangeContainer({ statechart })
  }

  render () {
    return (
      <StateMachineProvider container={this.container}>
        {machineStore => this.props.children(machineStore)}
      </StateMachineProvider>
    )
  }
}
