import { addDays, format } from 'date-fns'
import _, { get, isNil } from 'lodash'

import { withStateMachine, StateMachineContainer } from 'utils/StateMachine'
import logger from 'utils/logger'
import {
  getStayPalRequestStatus,
  isFriend,
  getFullName
} from 'utils/profile-info'

import { getReadableError } from 'utils/api'
import * as api from './api'
import formatDate from 'utils/formatDate'

const DATE_FORMAT = 'yyyy/MM/dd'

const ALL_ACTIONS = {
  SUBMIT_SEARCH: { loading: { actions: ['submitSearch'] } },
  SET_PARAMS: { idle: { actions: ['setParams', 'debouncedTriggerSearch'] } },
  SET_LOCATION: { idle: { actions: ['setLocation'] } },
  SET_ACTIVE_ITEM: { idle: { actions: ['setActiveItem'] } },
  SET_ACTIVE_PANEL: { idle: { actions: ['setActivePanel'] } },
  SET_ACTIVE_DETAILS: { idle: { actions: ['setActiveDetails'] } },
  SET_INVITATION_STATUS: { idle: { actions: ['setInvitationStatus'] } },
  SET_MOBILE_SIZE: { idle: { actions: ['setMobileSize'] } },
  SEARCH_RESET: { idle: { actions: ['searchReset'] } },
  TRIGGER_SEARCH: { idle: { actions: ['debouncedTriggerSearch'] } },
  FETCH_COMMUNITY_NUMBERS: { idle: { actions: ['fetchCommunityNumbers'] } },
  FETCH_OWN_PLACES: { idle: { actions: ['fetchOwnPlaces'] } },
  SEND_MESSAGE: { idle: { actions: ['sendMessage'] } },
  SET_SP_REQUEST_SUCCESS: { idle: { actions: ['setSpRequestSuccess'] } },
  FETCH_DETAILS: { idle: { actions: ['fetchDetails'] } },
  SET_ACTIVE_PIN: {
    idle: { actions: ['setLocation', 'setActiveItem', 'fetchDetails'] }
  }
}

const statechart = {
  initial: 'idle',
  states: {
    idle: {
      on: {
        ...ALL_ACTIONS
      },
      onEntry: 'hideLoading'
    },
    loading: {
      onEntry: 'showLoading',
      on: {
        SUCCESS: 'idle',
        FAILURE: 'idle',
        ...ALL_ACTIONS
      }
    }
  }
}

const defaultState = {
  isFirstSearch: false,
  result: {
    search: {},
    myCommunity: { totalStayPalsCount: 0, totalStayPalsOfStayPalsCount: 0 }
  },
  params: {
    searchFor: 'business', // 'all' | 'business' | 'fun
    location: {},
    dates: {
      checkIn: format(new Date(), DATE_FORMAT),
      checkOut: format(addDays(new Date(), 1), DATE_FORMAT)
    },
    guests: 1
  },
  location: {
    center: null,
    zoom: null,
    bounds: null
  },
  activeItem: null,
  activeUserID: null,
  activeDetails: {},
  activePanel: 'myCommunity',
  isMobile: false,
  errors: null,
  ownPlacesFetched: false,
  ownPlaces: []
}

// Avoid querying the backend when the parameters don't change and
// results are a cached/subset
let prevSearchDetails = ''

function shouldSubmitSearch ({ path, data, dates, boundsAlreadyCached }) {
  dates.checkIn = formatDate(dates.checkIn, DATE_FORMAT)
  dates.checkOut = formatDate(dates.checkOut, DATE_FORMAT)
  const newSearchDetails = JSON.stringify({ path, data, dates })

  if (boundsAlreadyCached && prevSearchDetails === newSearchDetails) {
    return null
  }

  return newSearchDetails
}

const withSPDetails = sp => ({
  ...sp,
  friend: isFriend(sp),
  name: getFullName(sp),
  stayPalRequestStatus: getStayPalRequestStatus(sp)
})

function hotfixSearchResults (data) {
  const { results } = data

  if (results[0] && results[0].owner) {
    let spMap = {}
    let currentSpCount = 0
    let currentSpOfSpCount = 0

    const countSps = ({ id, connection }) => {
      if (spMap[id]) return

      if (connection === 1) {
        currentSpCount++
      } else if (connection === 2) {
        currentSpOfSpCount++
      }

      spMap[id] = true
    }

    let resultsInOldFormat = results.map(r => {
      countSps(r.owner)
      r.housemates.forEach(countSps)
      const owner = withSPDetails(r.owner)

      return {
        ...r,
        owner,
        userID: r.owner.id,
        pid: r.owner.pid,
        houseMembers: [owner, ...r.housemates.map(withSPDetails)]
      }
    })

    return hotfixSameCoordResults({
      ...data,
      currentSpCount,
      currentSpOfSpCount,
      results: resultsInOldFormat
    })
  }

  return hotfixSameCoordResults(data)
}

function hotfixSameCoordResults (data) {
  const newData = _.cloneDeep(data)

  // Guto: to overcome a bug on the backend where it returns
  // duplicated places on SPs search, we get only the place that
  // belongs to the place's owner
  const sameIDGroupsWithDuplicates = _.toArray(
    _.groupBy(newData.results, result => result.id)
  ).filter(group => group.length > 1)

  newData.results = newData.results
    .map(item => {
      const isDuplicate = sameIDGroupsWithDuplicates.find(
        group => !!group.find(result => result.id === item.id)
      )

      if (isDuplicate && item.houseMembers && item.houseMembers.length > 1) {
        const owner = item.houseMembers.find(sp => sp.isOwner)
        if (item.pid !== owner.pid) return
      }

      return _.merge({}, item)
    })
    .filter(item => typeof item !== 'undefined')

  newData.results = _.uniqBy(newData.results, result => result.id)

  const sameCoordGroups = _.groupBy(
    newData.results,
    result => `${result.lat}-${result.lng}`
  )

  const groupsWithDups = _.toArray(sameCoordGroups).filter(
    group => group.length > 1
  )

  groupsWithDups.forEach(group => {
    group.forEach((result, i) => {
      result.lat += i * 0.0005
      result.lng += i * 0.0005
    })
  })

  return newData
}

class MapContainer extends StateMachineContainer {
  constructor (props) {
    super(props)

    this.state = {
      ...this.state,
      ...defaultState
    }
  }

  requestQueue = []

  request = async (apiName, data, event = {}) => {
    logger.captureBreadcrumb({
      message: 'MapContainer.' + apiName,
      category: 'map',
      data
    })

    try {
      const [error, response] = await api[apiName](data)

      if (error) {
        throw new Error(error)
      } else {
        return response
      }
    } catch (error) {
      logger.captureException(error)

      let err = getReadableError(error)

      this.transition({
        ...event,
        type: 'FAILURE',
        error: {
          [apiName]: err
        }
      })
      return false
    }
  }

  triggerSearch = async ({ location, form, field, fitMarkers = false }) => {
    const { state } = this
    const boundsAlreadyCached = get(location, 'boundsAlreadyCached')
    if (form === 'search-inline') {
      let { activePanel, params } = state

      let origin = 'form'
      if (field === 'location') {
        this.transition({
          type: 'SET_LOCATION',
          location,
          origin
        })
      } else if (location) {
        origin = 'map'
        if (fitMarkers) {
          fitMarkers = 'later' // actions.setLocation(payload.location, origin)
        }
      }

      location =
        field === 'location' && location
          ? location
          : params.location && { ...params.location }

      let { location: paramLocation, dates: paramDates, ...data } = params
      let dates = { ...paramDates }
      location = location || paramLocation

      if (!location.address) {
        location = { ...location, address: params.location.address }
      }

      let path = 'map_search/stay'
      let method = 'post'
      let apiTransform // Usig this variable to denote new api

      if (activePanel === 'myCommunity') {
        if (!Array.isArray(location.bounds)) {
          return
        }

        path = 'map_search/sp'
        method = 'get'
        apiTransform = true
        data = { includeOnly: data.includeOnly }
        location = {
          bounds: location.bounds.join(','),
          lat: location.lat,
          lng: location.lng
        }
        dates = {}
      }

      this.transition({
        type: 'SET_PARAMS',
        dates,
        ...data
      })

      const newSearchDetails = shouldSubmitSearch({
        path,
        data,
        dates,
        boundsAlreadyCached
      })

      if (!newSearchDetails) {
        return
      }

      this.transition({
        type: 'SUBMIT_SEARCH',
        data: {
          ...data,
          ...dates,
          ...location
        },
        path,
        method,
        apiTransform,
        origin,
        fitMarkers,
        activePanel,
        newSearchDetails
      })

      return true
    }
  }

  debouncedTriggerSearch = _.debounce(this.triggerSearch, 300, {
    leading: false,
    trailing: true
  })

  submitSearch = async ({
    data,
    path = 'search',
    method = 'post',
    apiTransform,
    cbAction,
    resolveFn,
    rejectFn,
    originalData,
    origin = 'form',
    fitMarkers,
    activePanel,
    newSearchDetails,
    isFirstSearch
  }) => {
    if (this.searching === newSearchDetails) return

    this.searching = newSearchDetails

    if (typeof originalData !== 'undefined') {
      this.transition({
        type: 'SET_PARAMS',
        ...originalData
      })

      if (!fitMarkers) {
        this.transition({
          type: 'SET_LOCATION',
          location: originalData.location,
          origin
        })
      } else {
        // Use this later
        fitMarkers = 'later'
        // fitMarkers = actions.setLocation(originalData.location, origin)
      }
    }

    if (typeof cbAction !== 'undefined') {
      cbAction()
    }

    // retain 'includeOnly' parameter 'sp' & 'sp_of_sp' for v1 requests
    if (!apiTransform && data.includeOnly) {
      data.includeOnly = data.includeOnly === 1 ? 'sp' : 'sp_of_sp'
    }

    let response

    if (
      activePanel === 'search' &&
      data.searchFor &&
      data.searchFor === 'all'
    ) {
      data.includeOnly = data.includeOnly === 1 ? 'sp' : 'sp_of_sp'

      const [businessResult, funResult] = await Promise.all([
        this.request('categorySearch', {
          path,
          data: {
            ...data,
            searchFor: 'business'
          }
        }),
        this.request('categorySearch', {
          path,
          data: {
            ...data,
            searchFor: 'fun'
          }
        })
      ])

      if (businessResult && funResult) {
        response = _.merge({}, businessResult, funResult)
      }
    } else {
      const apiName = activePanel === 'search' ? 'categorySearch' : 'plainApi'
      response = await this.request(apiName, { path, method, data })
    }

    if (!response) {
      this.searching = null

      return
    }

    if (get(response, 'results.length', 0) > 0) {
      response = hotfixSearchResults(response)
    }

    const { state } = this

    response.results = [...response.results, ...state.ownPlaces]

    this.setState({
      result: {
        ...state.result,
        [activePanel]: response
      },
      errors: null,
      isFirstSearch:
        typeof isFirstSearch !== 'undefined'
          ? isFirstSearch
          : state.isFirstSearch
    })

    prevSearchDetails = newSearchDetails

    if (fitMarkers === 'later') {
      this.transition({
        type: 'SET_LOCATION',
        location: originalData.location,
        origin
      })
    }

    this.transition({
      type: 'SUCCESS'
    })

    this.searching = null

    return true
  }

  setParams = ({ params }) => {
    this.setState({
      params: {
        ...this.state.params,
        ...params
      }
    })

    return true
  }

  setLocation = ({ location, origin }) => {
    let { lat, lng, center } = location

    if (!center || isNil(center.lat) || isNil(center.lng)) {
      if (!isNil(lat) && !isNil(lng)) {
        center = { lat, lng }
      } else {
        center = this.state.location.center
      }
    }

    this.setState({
      location: {
        ...location,
        center,
        origin
      },
      params: {
        ...this.state.params,
        location: {
          ...center,
          bounds: location.bounds
        }
      }
    })

    return true
  }

  setActiveItem = async ({ itemID = null, userID = null }) => {
    await this.setState({
      activeItem: itemID,
      activeUserID: userID
    })

    return true
  }

  setActivePanel = async ({ panel, isFirstSearch = false }) => {
    await this.setState({
      activePanel: panel,
      isFirstSearch
    })

    return true
  }

  fetchCommunityNumbers = async () => {
    const myCommunity = await this.request('fetchCommunityNumbers', {
      bounds: [
        -59.44507509904665,
        -47.98828125,
        -86.18616428439896,
        -167.87109375
      ]
    })

    if (myCommunity) {
      this.setState({
        result: {
          ...this.state.result,
          myCommunity
        }
      })

      this.transition({
        type: 'SUCCESS'
      })

      return true
    }
  }

  fetchOwnPlaces = async () => {
    // Prevent multiple calls
    if (this.fetchingOwnPlaces) {
      return
    }

    this.fetchingOwnPlaces = true
    const ownPlaces = await this.request('fetchOwnPlaces')

    if (ownPlaces) {
      this.setState({
        ownPlaces: get(hotfixSearchResults(ownPlaces), 'results', []),
        ownPlacesFetched: true
      })

      this.transition({ type: 'SUCCESS' })

      this.fetchingOwnPlaces = false
      return true
    }
  }

  setSpRequestSuccess = ({ id, stayPalRequestStatus }) => {
    const { activeDetails, activeUserID } = this.state

    if (id === activeUserID) {
      if (stayPalRequestStatus === 'received') {
        // Find and update housemates/members. to friends

        this.setState({
          activeDetails: _.set(activeDetails, 'user', {
            ...activeDetails.user,
            friend: true
          })
        })
      } else {
        this.setState({
          activeDetails: _.set(
            activeDetails,
            'stayPalRequestStatus',
            'pending'
          )
        })
      }
    }

    return true
  }

  sendMessage = async ({ data, cb }) => {
    const result = await this.request('sendMessage', data)

    if (result) {
      logger.captureBreadcrumb({
        message: 'MapContainer sendMessage SUCCESS',
        category: 'map',
        data: result
      })
      // const conversation = data.conversation
      // yield put(setCurrentConversation(conversation))
      // yield fork(fetchConversation, {
      //   payload: conversation.id,
      //   meta: { page: 1 }
      // })

      if (typeof cb === 'function') {
        cb()
      }

      this.transition({
        type: 'SUCCESS'
      })

      return true
    } else {
      if (typeof cb === 'function') {
        cb()
      }
    }
  }

  setMobileSize = ({ isMobile }) => {
    this.setState({
      isMobile
    })

    return true
  }

  searchReset = () => {
    this.setState({
      ...defaultState,
      ownPlacesFetched: true,
      ownPlaces: this.state.ownPlaces
    })

    return true
  }

  fetchDetails = async ({ subject, itemID, userID }) => {
    // TODO: find out why this is triggered thrice even when the
    // transition fn. is called only one
    const requestID = subject + itemID + userID

    if (this.fetchingDetails === requestID) {
      return
    }

    if (!itemID || !userID) {
      return
    }

    this.fetchingDetails = requestID

    this.setState({
      activeDetails: {
        ...this.state.activeDetails,
        loading: true
      }
    })

    const [details, connection] = await Promise.all([
      this.request('fetchDetails', {
        id: itemID,
        userID
      }),
      this.request('fetchInvitationStatus', userID)
    ])

    this.fetchingDetails = null

    if (!this.state.activeUserID || !details || !connection) {
      this.setState({
        activeDetails: {
          ...this.state.activeDetails,
          loading: false
        }
      })

      return
    }

    let { place, mutualStaypals } = details

    if (_.isArray(place.housemates)) {
      place.houseMembers = [place.owner, ...place.housemates].map(withSPDetails)
    } else {
      place.houseMembers = [place.owner].map(withSPDetails)
    }

    place.housemates = place.houseMembers.slice(1)

    if (place.owner) {
      place.owner = withSPDetails(place.owner)
    }

    if (_.isArray(mutualStaypals)) {
      mutualStaypals = mutualStaypals.map(withSPDetails)
    }

    const user = place.houseMembers.find(({ id }) => id === userID)

    this.setState({
      activeDetails: details
        ? {
          ...this.state.activeDetails,
          loading: false,
          place,
          user,
          mutualStayPals: mutualStaypals,
          stayPalRequestStatus: getStayPalRequestStatus(
            connection.connectionType
          )
        }
        : {}
    })

    // this.transition({ type: 'SUCCESS' })
    return true
  }
}

const withStore = withStateMachine(null, new MapContainer({ statechart }))

export default withStore
