import React from 'react'
import PropTypes from 'prop-types'

import uniqBy from 'lodash/uniqBy'
import debounce from 'lodash/debounce'

import { Container, Subscribe } from 'unstated'

import logger from 'utils/logger'

import * as api from '../api/staypals'

export class StayPalsContainer extends Container {
  constructor (props) {
    super(props)

    this.debouncedLoad = debounce(this.load, 500, {
      trailing: true,
      leading: false
    })

    this.state = {
      list: [],
      loading: false,
      search: '',
      page: 0,
      totalPages: 0,
      filter: ['stayPal', 'stayPal2']
    }
  }

  load = async ({ search, page = 1, filter } = {}) => {
    this.setState({
      page,
      filter,
      search,
      loading: true
    })

    let filterType

    if (filter && filter.length === 1) {
      filterType = filter[0] === 'stayPal' ? 1 : 2
    }

    logger.captureBreadcrumb({
      message: 'StayPalsContainer.load',
      category: 'staypals',
      data: { search, page, filter: filterType }
    })

    const [error, data] = await api.loadIntegratedStayPals({
      search,
      page,
      filter: filterType
    })

    if (error) {
      logger.captureException(new Error(error))

      this.setState({ loading: false })
      return
    }

    const total = data.totalResults
    const perPage = data.rpp
    const totalPages = Math.ceil(Math.max(total, perPage) / perPage)

    let list = data.results.map(item => ({
      ...item.connection,
      mutual: item.mutualConnections
    }))

    if (page > 1) {
      list = this.state.list.concat(list)
    }

    list = uniqBy(list, item => item.id)

    this.setState({
      totalPages,
      list,
      loading: false
    })
  }

  loadNextPage = () => {
    const { search, page, filter, totalPages } = this.state

    if (totalPages <= page) return

    this.load({ page: page + 1, search, filter })
  }

  search = term => {
    this.debouncedLoad({ search: term, filter: this.state.filter })
  }

  filter = filter => {
    this.debouncedLoad({ filter, search: this.state.search })
  }
}

const container = new StayPalsContainer()

export default class ProvideStayPals extends React.PureComponent {
  static propTypes = {
    children: PropTypes.func.isRequired
  }

  componentDidMount () {
    container.load()
  }

  render () {
    return (
      <Subscribe to={[container]}>
        {store => this.props.children(store)}
      </Subscribe>
    )
  }
}
