import api from 'utils/api'

export const sendSPRequest = emails => {
  if (!Array.isArray(emails)) {
    emails = [emails]
  }

  return api.post('/connections', { emails })
}

export const getGmailContacts = params => {
  return api.post('/referrals/get_contacts', params)
}
