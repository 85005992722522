import React from 'react'

import get from 'lodash/get'

import { Form } from 'react-final-form'

import styled from 'styled-components'

import root from 'utils/windowOrGlobal'
import { getCloudinaryPicture } from 'utils/profile-info'

import { AuthProvider } from 'stores/auth'

import GMap from 'app/screens/explore/components/Map/GMap'

import Button from '../../../components/SmallButton'

const getPinImage = user => {
  const size = 48
  const margin = 4

  const pinConfig = {
    radius: 'max',
    width: size - margin,
    height: size - margin,
    crop: 'scale',
    angle: 'ignore',
    flags: 'preserve_transparency'
  }

  const overlayConfig = {
    overlay: `assets:pins:pin-bottom-tail--red`,
    crop: 'scale',
    width: size,
    gravity: 'north',
    y: '-2'
  }

  const clConfig = {
    transform: [pinConfig, overlayConfig]
  }

  const cloudinaryPublicId =
    get(user, 'cloudinaryPublicId') || 'assets/default-avatar.png'

  return getCloudinaryPicture({ cloudinaryPublicId }, clConfig)
}

class UserPinMap extends React.Component {
  state = {
    originalCenter: { lat: null, lng: null },
    center: { lat: null, lng: null },
    active: false
  }

  componentDidMount () {
    this.setOriginalCenter()
  }

  componentDidUpdate (prevProps) {
    if (prevProps.place !== this.props.place) {
      this.setOriginalCenter()
    }
  }

  setOriginalCenter = () => {
    const { place } = this.props

    const center = {
      lat: get(place, 'latitude'),
      lng: get(place, 'longitude')
    }

    this.setState({
      originalCenter: center,
      center
    })
  }

  setActive = (value = true) => {
    if (value && !this.state.active) {
      this.setState({ active: true })
    } else if (!value && this.state.active) {
      this.setState({ active: false })
    }
  }

  onMapLocationChange = (values, form) => {
    this.setState({ center: get(values, 'center') })

    if (this.state.active) {
      form.batch(() => {
        const fields = form.getRegisteredFields()
        if (!get(fields, '[0]')) {
          form.registerField('lat', () => {}, {})
          form.registerField('lng', () => {}, {})
        }

        form.change('lat', get(values, 'center.lat'))
        form.change('lng', get(values, 'center.lng'))
      })
    }
  }

  onSubmit = (values, _, callback) => {
    this.setState({ originalCenter: values, center: values })

    const center = {
      latitude: get(values, 'lat'),
      longitude: get(values, 'lng')
    }

    this.props.onSubmitData(center, callback)
  }

  render () {
    const { place } = this.props
    const { active, originalCenter, center } = this.state

    if (!place) {
      return null
    }

    return (
      <AuthProvider>
        {authStore => {
          const user = authStore.getCurrentUser()
          const userPinImage = getPinImage(user)

          return (
            <Form
              initialValues={originalCenter}
              onSubmit={this.onSubmit}
              render={({ form, handleSubmit, submitting, dirty }) => {
                return (
                  <MapWrapper active={active} onClick={this.setActive}>
                    <GMap
                      onChange={values => {
                        if (!submitting) {
                          this.onMapLocationChange(values, form)
                        }
                      }}
                      defaultZoom={18}
                      center={center}
                      options={{
                        disableDefaultUI: true,
                        clickableIcons: false,
                        zoomControl: active,
                        zoomControlOptions: {
                          position: get(
                            root,
                            'google.maps.ControlPosition.LEFT_CENTER'
                          )
                        }
                      }}
                    />

                    <UserPinImage src={userPinImage} alt='Your pin' />

                    {!active && (
                      <Button
                        onClick={this.setActive}
                        className='f7 absolute bottom-0 right-0 mr1 mb1'
                      >
                        Adjust
                      </Button>
                    )}

                    {active &&
                      !dirty &&
                      !submitting && (
                      <Button
                        onClick={() => this.setActive(false)}
                        className='f7 absolute bottom-1 right-1'
                      >
                          Close
                      </Button>
                    )}

                    {active &&
                      (dirty || submitting) && (
                      <div className='absolute bottom-1 right-1 flex flex-row'>
                        <Button
                          onClick={() => {
                            form.reset()
                            this.setOriginalCenter()
                          }}
                          className='f6 mr2'
                          disabled={submitting}
                        >
                            Reset
                        </Button>

                        <Button
                          blueBackground
                          onClick={handleSubmit}
                          className='f6'
                          disabled={submitting}
                        >
                          {submitting ? 'Saving...' : 'Save'}
                        </Button>
                      </div>
                    )}

                    {submitting && (
                      <div className='absolute--fill absolute bg-white-30' />
                    )}
                  </MapWrapper>
                )
              }}
            />
          )
        }}
      </AuthProvider>
    )
  }
}

const MapWrapper = styled.div`
  position: relative;
  margin-left: 1rem;

  &,
  .map-container {
    width: ${props => (props.active ? '100%' : '200px')};
    height: ${props => (props.active ? '320px' : '120px')};
  }
`

const UserPinImage = styled.img`
  width: 48px;
  height: 68px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -44px;
  margin-left: -24px;
  pointer-events: none;
`

export default UserPinMap
