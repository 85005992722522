import React from 'react'

import { Form } from 'react-final-form'

import navigateBack from 'utils/navigateBack'

import colors from 'styles/colors'

import Modal from 'components/Modal'
import { ErrorText } from 'components/Input'
import ButtonPill from 'components/ButtonPill'

const PromptModal = ({
  data,
  onSubmit,
  title,
  getInitialValues,
  formatValues = v => v,
  validateValues,
  children
}) => (
  <Modal isOpen onRequestClose={navigateBack} contentLabel={title}>
    <Form
      initialValues={getInitialValues(data)}
      validate={validateValues}
      onSubmit={(values, _, callback) => {
        onSubmit(formatValues(values), () => {
          navigateBack()
          callback()
        })
      }}
      render={formProps => {
        const { handleSubmit, submitting, invalid, submitError } = formProps

        return (
          <form
            onSubmit={handleSubmit}
            className='center flex flex-column items-center w-100 w-90-ns mv4'
          >
            <h3 className='f3 bb b--black-05 mb4 pb4 w-100'>{title}</h3>

            {children(formProps)}

            {invalid &&
              submitError && (
              <ErrorText center style={{ marginBottom: '2rem' }}>
                {submitError}
              </ErrorText>
            )}

            <div className='w-100 flex flex-row justify-between items-center bt b--black-05 mt3 pt4'>
              <CancelButton disabled={submitting} />
              <SubmitButton disabled={submitting}>
                {submitting ? 'Saving...' : 'Save'}
              </SubmitButton>
            </div>
          </form>
        )
      }}
    />
  </Modal>
)

export const CancelButton = props => (
  <ButtonPill onClick={navigateBack} children='Cancel' {...props} />
)

export const SubmitButton = props => (
  <ButtonPill
    type='submit'
    children='Save'
    background={colors.blue}
    hoverBackground={colors.darkBlue}
    color={colors.white}
    border={colors.whiteRGBA(0.1)}
    {...props}
  />
)

export default PromptModal
