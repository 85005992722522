import React from 'react'

import { Emoji, Picker } from 'emoji-mart'

export const EmojiComponent = props => (
  <Emoji
    {...props}
    set='emojione'
    size={22}
    fallback={(em, props) => {
      return em ? `:${em.short_names[0]}:` : props.emoji
    }}
  />
)

export const parseEmoji = (currentMessage, replaceWith) => {
  let matchArr
  let lastOffset = 0
  const regex = new RegExp('(:[a-zA-Z0-9-_+]+:(:skin-tone-[2-6]:)?)', 'g')
  const partsOfTheMessageText = []

  while ((matchArr = regex.exec(currentMessage)) !== null) {
    const previousText = currentMessage.substring(lastOffset, matchArr.index)

    if (previousText.length) partsOfTheMessageText.push(previousText)

    lastOffset = matchArr.index + matchArr[0].length

    const emojiExists = <EmojiComponent emoji={matchArr[0]} />

    if (emojiExists) {
      const emoji = replaceWith || `<Emoji emoji={${matchArr[0]}} />`
      partsOfTheMessageText.push(emoji)
    } else {
      partsOfTheMessageText.push(matchArr[0])
    }
  }

  const finalPartOfTheText = currentMessage.substring(
    lastOffset,
    currentMessage.length
  )

  if (finalPartOfTheText.length) partsOfTheMessageText.push(finalPartOfTheText)

  // return partsOfTheMessageText.map((t, i) => <span key={i}>{t}</span>)
  return partsOfTheMessageText.join('')
}

export const EmojiPicker = ({ onSelect }) => (
  <Picker
    set='emojione'
    color='#53a0ff'
    style={{ position: 'absolute', bottom: '60px', right: '30px' }}
    onSelect={onSelect}
    title='Pick your emoji…'
    emoji='point_up'
    autoFocus
  />
)
