import React from 'react'

import logger from 'utils/logger'

import uniqBy from 'lodash/uniqBy'
import get from 'lodash/get'

import * as api from '../api/messages'
import * as stayApi from '../api/stayProposal'

import StateMachineProvider, { StateMachineContainer } from 'utils/StateMachine'
import camelize from 'utils/camelize'

import { getCurrentUser } from 'stores/auth'

const statechart = {
  key: 'messages',
  initial: 'start',
  states: {
    start: {
      on: {
        LOAD: { loading: { actions: ['authenticatePusher'] } }
      }
    },
    loading: {
      onEntry: ['loadMessages', 'showLoading'],
      on: {
        LOAD_SUCCESS: 'list',
        LOAD_FAILURE: 'error'
      }
    },
    error: {
      onEntry: 'showError',
      on: { RETRY: 'loading' }
    },
    list: {
      onEntry: 'showList',
      on: {
        NEW_MESSAGE: 'list',
        SEND_MESSAGE: { list: { actions: ['sendMessage'] } },
        NEXT: { list: { actions: ['loadNext'] } },
        NEXT_CANCEL: 'list',
        LOAD_SUCCESS: 'list',
        LOAD_FAILURE: 'list',
        RELOAD: 'loading',
        EXIT: { start: { actions: ['unauthenticatePusher'] } }
      }
    }
  }
}

class MessagesContainer extends StateMachineContainer {
  constructor (props) {
    super(props)

    // Make sure to keep the state machine engine
    const stateMachine = this.state

    this.state = {
      ...stateMachine,
      listRef: null,
      currentUser: getCurrentUser(),
      data: { messages: [] },
      hasMore: true,
      params: {
        id: null,
        page: 1
      }
    }
  }

  componentDidTransition (prevStateMachine, event) {
    if (event && event.type) {
      switch (event.type) {
        case 'LOAD_SUCCESS':
          this.setMessages(event)
          break
        case 'NEW_MESSAGE':
          this.setNewMessage(event)
          break
      }
    }
  }

  setListRef = listRef => {
    this.setState({ listRef })
  }

  setCurrentUserToMessages = data => {
    const list = data.messages
    const friend = data.recipientUser.isFriend

    const isCurrentUser = user => user.id === this.state.currentUser.pid

    return list
      .map(message => {
        if (!message.user) return message

        const current = isCurrentUser(message.user)

        const user = {
          ...message.user,
          current,
          friend: !current && friend
        }

        return { ...message, user }
      })
      .reverse()
  }

  setMessages = ({ data: { messages, ...data } }) => {
    messages = this.setCurrentUserToMessages({ messages, ...data })

    if (data.currentPage > 1) {
      messages = uniqBy(messages.concat(this.state.data.messages), 'id')
    }

    const totalPages = Math.ceil(data.totalMessages / data.messagesPerPage)

    const hasMore = data.page < totalPages
    const isViewerHost =
      get(data, 'stayDetails.hostId') === this.state.currentUser.pid

    data = {
      ...data,
      messages: [...messages],
      isViewerHost,
      lastUpdated: Date.now()
    }

    this.setState({ data, hasMore })
  }

  setNewMessage = async ({ message }) => {
    message = camelize(message)

    if (message.conversationId !== this.state.params.id) {
      return
    }

    const data = {
      messages: [message],
      recipientUser: this.state.data.recipientUser
    }
    let messages = this.setCurrentUserToMessages(data)

    messages = uniqBy(this.state.data.messages.concat(messages), 'id')

    await this.setState({ data: { ...this.state.data, messages } })
  }

  loadMessages = async (event = {}) => {
    logger.captureBreadcrumb({
      message: 'MessagesScreen.loadMessages',
      category: 'messages',
      data: event
    })

    let params = {
      id: event.id,
      page: event.page || 1
    }

    if (['RETRY'].includes(event.type)) {
      params = this.state.params
      params.page = 1
    }

    try {
      await this.setState({ params })

      const [error, data] = await api.getConversationMessages(params)

      if (error) {
        throw new Error(error)
      } else {
        await this.transition({ type: 'LOAD_SUCCESS', data })
      }
    } catch (error) {
      console.log('[MessagesScreen.loadMessages] Error: ', error)
      logger.captureException(error)
      await this.transition({ type: 'LOAD_FAILURE', error })
    }
  }

  loadNext = async () => {
    // We probably need to fix our statecharts instead of
    // using a stupid instance variable 😅
    if (this.loadingNext) return
    const { hasMore, params } = this.state
    const nextPage = Number.isNaN(params.page) ? 1 : params.page + 1

    if (!hasMore) {
      this.transition('NEXT_CANCEL')
      return
    }

    this.loadingNext = true

    await this.loadMessages({
      ...params,
      page: nextPage
    })

    this.loadingNext = false
  }

  authenticatePusher = () => {
    const { currentUser } = this.state

    try {
      this.newMessageTransition = message => {
        this.transition({ type: 'NEW_MESSAGE', message })
      }

      this.channel = api.authenticatePusher({
        userPID: currentUser.pid,
        token: currentUser.jwtToken,
        newMessageHandler: this.newMessageTransition
      })
    } catch (error) {
      logger.captureException(error)
    }
  }

  unauthenticatePusher = () => {
    try {
      if (this.channel) {
        this.channel.close()
      }
    } catch (error) {
      logger.captureException(error)
    }
  }

  sendMessage = async ({ message }) => {
    const { data, params } = this.state

    const conversationID = params.id || data.id

    logger.captureBreadcrumb({
      message: 'MessagesScreen.sendMessage',
      category: 'messages',
      data: { conversationID, message }
    })

    try {
      const [error] = await api.postMessage({ id: conversationID, message })

      if (error) {
        throw new Error(error)
      }
    } catch (error) {
      console.log('[MessagesScreen.sendMessage] Error: ', error)
      logger.captureException(error)
    }
  }

  handleStayProposal = async action => {
    const { data } = this.state

    const stayID = data.stayDetails.id

    logger.captureBreadcrumb({
      message: 'MessagesScreen.handleStayProposal',
      category: 'stays',
      data: { action, stayID }
    })

    try {
      const [error] = await stayApi.handleProposal({ id: stayID, action })

      if (error) {
        throw new Error(error)
      } else {
        this.transition({ type: 'RELOAD' })
      }
    } catch (error) {
      console.log('[MessagesScreen.handleStayProposal] Error: ', error)
      logger.captureException(error)
    }
  }
}

export default class MessagesStoreProvider extends React.Component {
  constructor (props) {
    super(props)
    this.container = new MessagesContainer({
      statechart,
      currentUser: props.currentUser
    })
  }

  render () {
    return (
      <StateMachineProvider container={this.container}>
        {machineStore => this.props.children(machineStore)}
      </StateMachineProvider>
    )
  }
}
