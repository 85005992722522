import React from 'react'
import get from 'lodash/get'

import { Form } from 'react-final-form'

import { required } from 'utils/field-validators'
import navigateBack from 'utils/navigateBack'

import colors from 'styles/colors'

import Modal from 'components/Modal'
import Input, { ErrorText } from 'components/Input'
import ButtonPill from 'components/ButtonPill'

import ListItems from './ListItems'

import ProfileStoreProvider from '../store'

const EditPhonesModal = () => (
  <ProfileStoreProvider>
    {({ store, user, reload }) => {
      const phones = get(user, 'phoneNumbers', [])
      const loading = store.matchesActions('showLoading')

      const commonProps = { store, loading, phones, reload }

      return (
        <Modal
          isOpen
          onRequestClose={navigateBack}
          contentLabel='Edit phone numbers'
        >
          <div className='center flex flex-column items-center w-100 w-90-ns mt4'>
            <h3 className='f3 bb b--black-05 mb4 pb4 w-100'>
              Edit phone numbers
            </h3>

            <AllPhonesList {...commonProps} />

            <AddPhoneInput {...commonProps} />

            <div className='w-100 flex flex-row justify-center bt b--black-05 mt3 pt4'>
              <ButtonPill onClick={navigateBack}>Close</ButtonPill>
            </div>
          </div>
        </Modal>
      )
    }}
  </ProfileStoreProvider>
)

const AllPhonesList = ({ store, loading, phones, reload }) => {
  if (phones.length === 0) return null

  const items = phones.map(phone => ({
    value: phone.number,
    actionText: 'Remove',
    actionClick: () => {
      store.transition({
        type: 'REMOVE_PHONE',
        phone: phone.number,
        callback: reload
      })
    }
  }))

  return <ListItems label='All phone numbers' loading={loading} items={items} />
}

const AddPhoneInput = ({ store, reload }) => (
  <Form
    onSubmit={(values, form, callback) => {
      store.transition({
        type: 'ADD_PHONE',
        phone: values.phone,
        callback: () => {
          form.reset()
          reload()
          callback()
        }
      })
    }}
    render={({ handleSubmit, submitting, invalid, submitError }) => (
      <form
        onSubmit={handleSubmit}
        className={`w-100 flex flex-column mt3 ${submitting ? '0-60' : ''}`}
      >
        <label htmlFor='phone' className='f5 black-50 pl2 mb2'>
          Add another phone number
        </label>

        <div className='flex flex-column flex-row-l justify-between-l items-center-l mb3'>
          <div style={{ flex: 1 }} className='pr3-l mb3 mb0-l'>
            <Input
              id='phone'
              name='phone'
              placeholder='Enter new phone number'
              validate={required}
              disabled={submitting}
              style={{ marginBottom: 0 }}
              floatingLabel={false}
            />
          </div>

          <div>
            <ButtonPill
              type='submit'
              children={submitting ? 'Adding...' : 'Add'}
              background={colors.blue}
              hoverBackground={colors.darkBlue}
              color={colors.white}
              border={colors.whiteRGBA(0.1)}
              disabled={submitting}
            />
          </div>
        </div>

        {invalid && submitError && <ErrorText>{submitError}</ErrorText>}
      </form>
    )}
  />
)

export default EditPhonesModal
