import React from 'react'

import root from 'utils/windowOrGlobal'

import { DateRangePicker } from 'react-date-range'
import MediaQuery from 'react-responsive'

import {
  format,
  parse,
  isSameDay,
  eachDayOfInterval,
  startOfMonth,
  addDays,
  endOfDay,
  startOfDay,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
  compareAsc,
  isBefore
} from 'date-fns'

import { isEqual, uniqWith } from 'lodash'

import styled from 'styled-components'

import colors from 'styles/colors'

import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'

import Button from '../../../components/SmallButton'

const TODAY = new Date()

class Calendar extends React.Component {
  static defaultProps = {
    defaultMarkedDates: [],
    onSubmit: () => {}
  }

  constructor (props) {
    super(props)

    this.state = {
      markedDates: this.defaultMarkedDates,
      hasChanged: false,
      submitting: false
    }
  }

  componentDidUpdate (prevProps) {
    if (!isEqual(prevProps.defaultMarkedDates, this.props.defaultMarkedDates)) {
      this.setState({ markedDates: this.defaultMarkedDates })
    }
  }

  get defaultMarkedDates () {
    const { defaultMarkedDates } = this.props

    // "blocked" dates means the user manually blocked them
    let blockedDates = defaultMarkedDates.filter(
      date => date.dueTo === 'blocked'
    )

    const datesParsed = blockedDates.map(date =>
      parse(date.date, 'yyyy-MM-dd', new Date())
    )

    const datesAfterToday = datesParsed.filter(date => date >= TODAY)

    return datesAfterToday
  }

  get disabledDates () {
    const { defaultMarkedDates } = this.props

    // non-blocked dates means they're unavailable for other reasons (ie. booked, etc)
    // that can't be manually made available again from here
    const disabledDates = defaultMarkedDates.filter(
      date => date.dueTo !== 'blocked'
    )

    return disabledDates.map(date => {
      const day = parse(date.date, 'yyyy-MM-dd', new Date())

      return formatDateRange(day, colors.blackRGBA(0.3), {
        dueTo: date.dueTo,
        disabled: true
      })
    })
  }

  get markedDates () {
    const { markedDates } = this.state

    if (!markedDates[0]) {
      return [getPlaceholderRange()]
    }

    const dates = markedDates.map(date => formatDateRange(date))
    return dates.concat(this.disabledDates)
  }

  get staticRanges () {
    return getStaticRanges(this.state.markedDates)
  }

  get inputRanges () {
    return getInputRanges(this.state.markedDates)
  }

  get focusedRange () {
    return [this.markedDates.length - 1, 0]
  }

  clearMarkedDates = () => {
    const hasChanged = !!this.defaultMarkedDates[0]

    const clear = () => {
      this.setState({ markedDates: [], hasChanged })
    }

    if (hasChanged) {
      const confirmed = root.confirm(
        `Are you sure? This will make this room available on all dates (except for dates that are already booked).`
      )

      if (confirmed) {
        clear()
      }
    } else {
      clear()
    }
  }

  cancelChanges = () => {
    this.setState({
      markedDates: this.defaultMarkedDates,
      hasChanged: false
    })
  }

  handleSubmit = () => {
    const { markedDates } = this.state

    const formattedDates = markedDates.map(date => format(date, 'yyyy-MM-dd'))

    this.setState({ submitting: true })
    this.props.onSubmit(formattedDates, () => {
      this.setState({ submitting: false, hasChanged: false })
    })
  }

  handleSelect = ranges => {
    const { startDate, endDate } = getFirstObjItem(ranges)

    const { markedDates } = this.state

    const selectedDays = eachDayOfInterval({
      start: startDate,
      end: endDate
    })

    // Remove newly selected dates if each day between
    // startDate and endDate was already selected before
    const isDuplicate = includesDays(markedDates, selectedDays)

    if (isDuplicate) {
      let cleanDates = []

      if (this.disabledDates[0]) {
        const includesDisabled = this.disabledDates.find(date =>
          includesDay(selectedDays, date.startDate)
        )

        if (includesDisabled) {
          const disabled = includesDisabled
          root.alert(
            `Cannot make ${format(
              disabled.startDate,
              'MM/DD/yyyy'
            )} available due to: ${disabled.dueTo}`
          )

          const disabledList = this.disabledDates.map(date => date.startDate)
          const cleanSelectedDays = filterOutDays(selectedDays, disabledList)
          cleanDates = filterOutDays(markedDates, cleanSelectedDays)
        }
      } else {
        cleanDates = filterOutDays(markedDates, selectedDays)
      }

      this.setState({ markedDates: cleanDates, hasChanged: true })
      return
    }

    // Make sure to save only unique dates
    const newMarkedDates = [...markedDates, ...selectedDays]
    const uniqMarkedDates = uniqWith(newMarkedDates, isSameDay)

    this.setState({ markedDates: uniqMarkedDates, hasChanged: true })
  }

  render () {
    const { submitting, loading } = this.state

    return (
      <div>
        {/* TODO: use hooks instead of component for media query */}
        <MediaQuery maxWidth={991}>
          <DateRangePickerWrapper
            minDate={TODAY}
            ranges={this.markedDates}
            onChange={this.handleSelect}
            months={2}
            direction='vertical'
            focusedRange={this.focusedRange}
            showDateDisplay={false}
            // showMonthAndYearPickers={false}
            staticRanges={[]} // {this.staticRanges}
            inputRanges={[]} // {this.inputRanges}
          />
        </MediaQuery>
        <MediaQuery minWidth={992}>
          <DateRangePickerWrapper
            minDate={TODAY}
            ranges={this.markedDates}
            onChange={this.handleSelect}
            months={2}
            direction='horizontal'
            focusedRange={this.focusedRange}
            showDateDisplay={false}
            // showMonthAndYearPickers={false}
            staticRanges={[]} // {this.staticRanges}
            inputRanges={[]} // {this.inputRanges}
          />
        </MediaQuery>

        {!submitting && (
          <div className='mt3 flex flex-row justify-start items-center'>
            {this.state.markedDates[0] && (
              <Button onClick={this.clearMarkedDates}>Clear all</Button>
            )}

            {this.state.hasChanged && (
              <div className='ml5-l flex-grow-1 flex flex-row justify-center items-center'>
                <Button className='mr3' onClick={this.cancelChanges}>
                  Cancel
                </Button>
                <Button
                  blueBackground
                  onClick={this.handleSubmit}
                  disabled={loading}
                >
                  Save changes
                </Button>
              </div>
            )}
          </div>
        )}

        {submitting && (
          <div className='mt3 flex flex-row justify-center items-center'>
            <Button blueBackground disabled>
              Saving...
            </Button>
          </div>
        )}
      </div>
    )
  }
}

const DateRangePickerWrapper = styled(DateRangePicker)`
  .rdrDayNumber,
  .rdrWeekDay {
    font-weight: 500;
  }

  .rdrDayDisabled {
    .rdrStartEdge,
    .rdrEndEdge {
      color: rgb(248, 248, 248) !important;
    }

    .rdrDayNumber span {
      color: #aeb9bf !important;
    }
  }

  .rdrDefinedRangesWrapper {
    display: none;
  }
`

/**
 * Helpers
 */

// date = addDays(today, -1)
function getPlaceholderRange (date = TODAY) {
  return formatDateRange(date, colors.blackRGBA(0.2), { key: 'selection' })
}

function formatDateRange (date, color = colors.red, ...options) {
  return {
    startDate: date,
    endDate: date,
    key: format(date, 'yyyy-MM-dd'),
    color,
    ...options
  }
}

function includesDay (list, day) {
  return list.some(dayB => isSameDay(dayB, day))
}

function includesDays (listA, listB) {
  return listB.every(day => includesDay(listA, day))
}

function filterOutDays (listA, listB) {
  return listA.filter(dayB => !includesDay(listB, dayB))
}

function getFirstObjItem (obj) {
  const key = Object.keys(obj)[0]
  return obj[key]
}

const defineds = {
  startOfWeek: startOfWeek(new Date()),
  endOfWeek: endOfWeek(new Date()),
  startOfNextWeek: startOfWeek(addDays(new Date(), 7)),
  endOfNextWeek: endOfWeek(addDays(new Date(), 7)),
  startOfNextWeekend: addDays(endOfWeek(new Date(), { weekStartsOn: 1 }), -1),
  endOfNextWeekend: endOfWeek(new Date(), { weekStartsOn: 1 }),
  startOfToday: startOfDay(new Date()),
  endOfToday: endOfDay(new Date()),
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
  startOfNextMonth: startOfMonth(addMonths(new Date(), 1)),
  endOfNextMonth: endOfMonth(addMonths(new Date(), 1))
}

function staticRangeHandler (markedDates) {
  return {
    range: {},
    isSelected () {
      const range = this.range()
      const rangeDates = eachDayOfInterval({
        start: range.startDate,
        end: range.endDate
      })

      return includesDays(markedDates, rangeDates)
    }
  }
}

function createStaticRanges (ranges) {
  return markedDates =>
    ranges.map(range => ({ ...staticRangeHandler(markedDates), ...range }))
}

const getStaticRanges = createStaticRanges([
  {
    label: 'Today',
    range: () => ({
      startDate: defineds.startOfToday,
      endDate: defineds.endOfToday
    })
  },
  // {
  //   label: 'This Week',
  //   range: () => ({
  //     startDate: defineds.startOfWeek,
  //     endDate: defineds.endOfWeek
  //   })
  // },
  {
    label: 'Next Weekend',
    range: () => ({
      startDate: defineds.startOfNextWeekend,
      endDate: defineds.endOfNextWeekend
    })
  },
  {
    label: 'Next Week',
    range: () => ({
      startDate: defineds.startOfNextWeek,
      endDate: defineds.endOfNextWeek
    })
  },
  {
    label: 'This Month',
    range: () => ({
      startDate: defineds.startOfMonth,
      endDate: defineds.endOfMonth
    })
  },
  {
    label: 'Next Month',
    range: () => ({
      startDate: defineds.startOfNextMonth,
      endDate: defineds.endOfNextMonth
    })
  }
])

const getInputRanges = markedDates => [
  {
    label: 'days starting today',
    range (value) {
      const today = new Date()
      return {
        startDate: today,
        endDate: addDays(today, Math.max(Number(value), 1) - 1)
      }
    },
    getCurrentValue () {
      if (!includesDay(markedDates, defineds.startOfToday)) return '-'

      return countDaysAfterDate(markedDates, defineds.startOfToday)
    }
  }
]

function countDaysAfterDate (list, date) {
  const sortedList = list.sort(compareAsc)

  let count = 0
  for (let i = 0; i < sortedList.length; i++) {
    const prevDay = startOfDay(sortedList[i - 1] || date)
    const nextDay = startOfDay(sortedList[i])

    if (isBefore(nextDay, date)) {
      continue
    }

    if (
      isSameDay(date, prevDay) ||
      isSameDay(date, nextDay) ||
      isSameDay(addDays(prevDay, 1), nextDay)
    ) {
      count++
      continue
    }

    break
  }

  return count
}

export default Calendar
