import api from 'utils/api'

export const loadIntegratedStayPals = async ({ search, page, filter } = {}) => {
  const params = { term: search || '', page: page || 1, rpp: 40 }

  if (filter) {
    params.includeOnly = filter
  }

  return api.get('/stay_pals/sp_and_sp2', params)
}
