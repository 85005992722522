import React from 'react'
import styled from 'styled-components'
import { Router } from '@reach/router'
import DealsList from './screens/DealsList'
import DealInformation from './screens/DealInformation'
import LearnMore from './screens/LearnMore'

const DealsContainer = styled(Router)`
  display: flex;
  flex: 1;
  justify-content: center;
  align-item: center;
  align-content: center;
  width: 100%;
  background-color: #f7fbfc;
`

const DealsIndex = () => {
  return (
    <DealsContainer>
      <DealsList default path='deals' />
      <DealInformation path={`:dealId/*`} />
      <LearnMore path='learnmore' />
    </DealsContainer>
  )
}

export default DealsIndex
