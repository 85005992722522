import React from 'react'

import PlacesAutocomplete from 'react-places-autocomplete'

import styled from 'styled-components'

import Icon from 'components/Icon'

import { geocodeAddress } from 'utils/location'
import logger from 'utils/logger'

import colors from 'styles/colors'

class LocationSearchInput extends React.Component {
  state = {
    address: ''
  }

  handleError = error => {
    logger.captureException(error)

    this.props.handlePlaceSelect({
      failed: true
    })
  }

  handleChange = address => this.setState({ address })

  handleSelect = (address, placeID) => {
    logger.captureBreadcrumb({
      message: 'LocationSearchInput',
      category: 'map',
      data: { address, placeID }
    })

    this.setState({ address })

    geocodeAddress(address, placeID)
      .then(this.handleGeocodeResult)
      .catch(this.handleError)
  }

  handleGeocodeResult = results => {
    let place = results[0]

    if (!place.geometry) {
      place = { ...place, failed: true }
    } else if (!place.name) {
      place = {
        ...place,
        name: place.formattedAddress
      }
    }

    this.props.handlePlaceSelect(place)
  }

  render () {
    return (
      <PlacesAutocomplete
        value={this.state.address}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
        onError={this.handleError}
        highlightFirstSuggestion
      >
        {({ getInputProps, suggestions, getSuggestionItemProps }) => (
          <div className='relative'>
            <div className='relative'>
              <SearchInput
                {...getInputProps({
                  placeholder: 'Where to? (optional)',
                  className: 'location-search-input'
                })}
              />
              <InputIcon name='pin' align='left' />
              <InputIcon name='search' align='right' />
            </div>
            {suggestions.length > 0 && (
              <SuggestionsContainer>
                {suggestions.map(suggestion => {
                  const {
                    mainText,
                    secondaryText
                  } = suggestion.formattedSuggestion

                  return (
                    <Suggestion
                      {...getSuggestionItemProps(suggestion, {
                        active: suggestion.active
                      })}
                    >
                      <PlaceIcon />
                      <span className='b dib mr2'>{mainText}</span>
                      <span className='f6 black-60'>{secondaryText}</span>
                    </Suggestion>
                  )
                })}
              </SuggestionsContainer>
            )}
          </div>
        )}
      </PlacesAutocomplete>
    )
  }
}

const SearchInput = styled.input`
  font-size: 1rem;

  width: 100%;
  padding: 1rem 3rem;
  border: none;
  border-radius: 2px;
  background-color: ${colors.ice};

  :placeholder {
    color: rgba(0, 0, 0, 0.4);
  }
`

const InputIcon = styled(Icon)`
  height: 1.125rem;
  width: 1.125rem;
  color: rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 1rem;
  ${props => (props.align === 'right' ? 'right: 1rem' : 'left: 1rem')};
`

const SuggestionsContainer = styled.div`
  z-index: 1000000;
  position: absolute;
  top: 3rem;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.25);
  width: 100%;

  @media screen and (max-width: 1024px) {
    bottom: 100%;
    top: initial;
  }
`

const PlaceIcon = styled(Icon).attrs({
  height: '1rem',
  name: 'pin'
})`
  color: #555555;
  display: inline-block;
  margin: -0.35rem 0.5rem 0;
`

const Suggestion = styled.div`
  background-color: ${props => (props.active ? '#f0f0f0' : '#ffffff')};
  padding: 10px;
  color: #555555;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export default LocationSearchInput
