import React from 'react'

import formatDate from 'utils/formatDate'

import Modal from 'components/Modal'

import InfiniteList from 'components/InfiniteList'
import AddStayPalButton from '../AddStayPalButton'
import { Avatar } from '../IntegratedStayPalsList'

import { getDefaultPicture } from 'utils/profile-info'

import config from 'config'

const defaultPicture = getDefaultPicture()
const format = 'h:mm a eee, MMM dd'

class PendingRequestsModal extends React.Component {
  constructor (props) {
    super(props)

    this.listHeight = 0.45 * config.WINDOW_HEIGHT
  }

  renderItem = ({ item }) => {
    return (
      <div className='pa2 flex flex-row items-center w-100' key={item.email}>
        <div
          className='flex flex-row items-center pr3 w-60'
          style={{ flex: 1 }}
        >
          <Avatar
            src={item.cloudinaryImgUrl || defaultPicture}
            alt={`${item.name}'s picture`}
          />

          <div className='ph2 h-100 w-90' style={{ flex: 1 }}>
            <p className='mb0 truncate'>{item.name ? item.name : item.email}</p>
            <p className='mt1 mb0 f7 truncate'>
              {formatDate(item.date, format)}
            </p>
          </div>
        </div>

        <AddStayPalButton stayPalRequestStatus='pending' />
      </div>
    )
  }

  render () {
    const { props } = this
    return (
      <React.Fragment>
        <Modal
          isOpen={props.show}
          onRequestClose={() => props.toggle(false)}
          contentLabel='PendingRequestsModal'
        >
          <div className='center flex flex-column mb0'>
            <h3 className='ma0 pb4 tc fw3'>Invites you've sent:</h3>

            <InfiniteList
              height={this.listHeight}
              width='100%'
              itemHeight={60}
              renderItem={this.renderItem}
              data={props.contacts}
              loading={props.loading}
              extraData={props}
              loadMore={props.loadMore}
              hasMore={!props.loading && props.hasMore}
            />
          </div>
        </Modal>
      </React.Fragment>
    )
  }
}

export default PendingRequestsModal
