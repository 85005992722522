import React from 'react'
import { Subscribe, Container } from 'unstated'

import logger from 'utils/logger'

import * as api from 'app/screens/settings/screens/prime/_api'

export default class PaymentMethods extends Container {
  constructor (props) {
    super(props)

    this.state = {
      methods: [],
      error: '',
      loading: false
    }

    this.getMethods()
  }

  _setLoading = (loading = true) =>
    this.setState({
      error: '',
      loading
    })

  _setError = error => {
    logger.captureException(new Error(error))

    this.setState({ loading: false, error })
  }

  _setMethods = methods => this.setState({ methods, loading: false })

  getMethods = async () => {
    logger.captureBreadcrumb({
      message: 'PaymentMethods.getMethods',
      category: 'stay-proposal'
    })
    this._setLoading()

    const [error, data] = await api.getPaymentMethods()

    if (error) {
      this._setError(error)

      return
    }

    this._setMethods(data.paymentMethods)

    return data.paymentMethods
  }

  submitMethod = async method => {
    logger.captureBreadcrumb({
      message: 'PaymentMethods.submitMethod',
      category: 'stay-proposal',
      data: { method }
    })
    this._setLoading()

    const [error, data] = await api.createPaymentMethod(method)

    if (error) {
      this._setError(error)

      return
    }

    this._setMethods(this.state.methods.concat([data.paymentMethod]))

    return data.paymentMethod
  }

  setMethodDefault = async method => {
    logger.captureBreadcrumb({
      message: 'PaymentMethods.setMethodDefault',
      category: 'stay-proposal',
      data: { method }
    })

    let { methods } = this.state

    if (method.isDefault) {
      return methods
    }

    this._setLoading()

    const [error] = await api.setDefaultMethod(method.id)

    if (error) {
      this._setError(error)

      return methods
    }

    let updatedMethods = methods.map(m => ({
      ...m,
      isDefault: m.id === method.id
    }))

    this._setMethods(updatedMethods)

    return updatedMethods
  }
}

export const withPaymentsStore = Child => props => (
  <Subscribe to={[PaymentMethods]}>
    {store => {
      const { state, ...rest } = store

      return (
        <Child
          {...{
            ...state,
            ...rest,
            ...props
          }}
        />
      )
    }}
  </Subscribe>
)
