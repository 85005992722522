import React from 'react'

import styled from 'styled-components'

import colors from 'styles/colors'

import { Form } from 'react-final-form'

import {
  composeValidators,
  required,
  minLength,
  maxLength
} from 'utils/field-validators'

import PasswordChangeStoreProvider from './store'

import Icon from 'components/Icon'
import Input, { ErrorText } from 'components/Input'
import Button from 'components/Button'

const validatePassword = composeValidators(
  required,
  minLength(8),
  maxLength(50)
)

class ChangePassword extends React.PureComponent {
  render () {
    return (
      <PasswordChangeStoreProvider>
        {store => (
          <Form
            onSubmit={(values, form, callback) => {
              store.transition({
                type: 'CHANGE_PASSWORD',
                values,
                callback,
                resetForm: form.reset
              })
            }}
            validate={values => ({
              currentPassword: validatePassword(values.currentPassword),
              newPassword: validatePassword(values.newPassword),
              confirmPassword:
                values.newPassword !== values.confirmPassword
                  ? "Passwords don't match"
                  : undefined
            })}
            render={({
              handleSubmit,
              submitting,
              invalid,
              submitError,
              submitSucceeded
            }) => {
              if (submitSucceeded) {
                return (
                  <div className='flex flex-column items-center justify-center center w-100 mw7'>
                    <CheckMark className='mb2' />

                    <h1 className='mb3'>Password changed successfully.</h1>
                  </div>
                )
              }

              return (
                <form
                  onSubmit={handleSubmit}
                  className='flex flex-column items-center justify-center center w-100 ph4 mb0'
                >
                  <h1 className='mb5'>Change Password</h1>

                  <Input
                    name='currentPassword'
                    label='Current Password'
                    autoComplete='current-password'
                    type='password'
                    disabled={submitting}
                    style={{ marginBottom: '2rem' }}
                  />

                  <Input
                    name='newPassword'
                    label='New Password'
                    type='password'
                    autoComplete='new-password'
                    disabled={submitting}
                    style={{ marginBottom: '2rem' }}
                    helperText='At least 8 characters'
                  />

                  <Input
                    name='confirmPassword'
                    label='Confirm Password'
                    type='password'
                    autoComplete='new-password'
                    disabled={submitting}
                    style={{ marginBottom: '2rem' }}
                  />

                  {invalid &&
                    submitError && (
                    <ErrorText center style={{ marginBottom: '2rem' }}>
                      {submitError}
                    </ErrorText>
                  )}

                  <SubmitButton
                    className='mb3'
                    type='submit'
                    color={colors.white}
                    colorHover={colors.offwhite}
                    bgColor={colors.blue}
                    bgColorHover={colors.darkBlue}
                    disabled={submitting}
                    expand
                    expandWidth='100%'
                  >
                    {submitting ? 'SAVING...' : 'SAVE'}
                  </SubmitButton>
                </form>
              )
            }}
          />
        )}
      </PasswordChangeStoreProvider>
    )
  }
}

export const SubmitButton = styled(Button)`
  padding-left: 32px;
  padding-right: 32px;
  letter-spacing: 2px;

  @media (max-width: 480px) {
    width: 100%;
  }
`

const CheckMark = styled(Icon).attrs({ name: 'checkmark' })`
  color: ${colors.pigmentGreen};
  height: 48px;
`

export default ChangePassword
