import React from 'react'

import get from 'lodash/get'

import styled from 'styled-components'

import colors from 'styles/colors'

import getAssetUrl from 'utils/getAssetUrl'
import { push } from 'utils/parse-querystring'

import ButtonPill from 'components/ButtonPill'
import { View, Text } from 'components/Native'
import Icon from 'components/Icon'
import Modal from 'components/Modal'

import * as routes from 'config/routes'

const coverUri = getAssetUrl('assets/hangout_i3gbth.png', {
  height: 600,
  opacity: 90,
  effect: 'make_transparent:10'
})

class Success extends React.Component {
  get hostFirstName () {
    return get(this.props, 'proposalStore.state.data.host.name', 'host').split(
      ' '
    )[0]
  }

  navigateMessages = () => {
    const conversationId = get(
      this.props,
      'proposalStore.state.stayData.conversation_id'
    )

    push({
      pathname: routes.MESSAGES,
      query: { id: conversationId }
    })
  }

  navigateHome = async () => {
    const { proposalStore } = this.props

    await proposalStore.transition('RESET')

    push({
      pathname: routes.APP
    })
  }

  render () {
    const { show } = this.props

    return (
      <Modal
        isOpen={show}
        onRequestClose={this.navigateHome}
        contentLabel='New Stay Proposed!'
      >
        <Container>
          <img src={coverUri} />

          <IconsRow>
            <CheckmarkIcon name='checkmark' />
          </IconsRow>

          <Title>Congratulations!</Title>
          <Copy>Stay proposal sent to {this.hostFirstName}</Copy>

          <ButtonPill
            background={colors.blue}
            hoverBackground={colors.darkBlue}
            border={colors.whiteRGBA(0.1)}
            color={colors.white}
            onClick={this.navigateMessages}
          >
            Say hi 👋 to {this.hostFirstName}
          </ButtonPill>

          <ButtonPill className='mt3' onClick={this.navigateHome}>
            Close
          </ButtonPill>
        </Container>
      </Modal>
    )
  }
}

const Container = styled(View)`
  flex: 1;
  padding: 16px;
  align-items: center;
`

const IconsRow = styled(View)`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
`

const CheckmarkIcon = styled(Icon)`
  width: 3.5em;
  height: 3.5em;
  border-radius: 1.75em;
  background-color: ${colors.lightGreen};
  box-shadow: 0 4px 4px ${colors.blackRGBA(0.1)};
  color: white;
`

const Title = styled(Text)`
  font-weight: bold;
  font-size: 24px;
  color: ${colors.blackRGBA(0.8)};
  margin-bottom: 8px;
`

const Copy = styled(Text)`
  font-size: 18px;
  color: ${colors.blackRGBA(0.6)};
  margin-bottom: 24px;
`

export default Success
