import React, { PureComponent } from 'react'

import styled from 'styled-components'

import config from 'config'

import GoogleMap from 'google-map-react'

import mapStyles from './mapStyles'

class GoogleMapWrapper extends PureComponent {
  constructor (props) {
    super(props)

    this.state = { mounted: false }
  }

  componentDidMount () {
    this.setState({ mounted: true })
  }

  render () {
    // Hack to fix 504 error problem
    if (!this.state.mounted) {
      return <div />
    }

    const { options, ...rest } = this.props

    return (
      <MapWrapper className='map-container'>
        <GoogleMap
          bootstrapURLKeys={{
            key: config.GOOGLE_API_KEY + '&v=3.31'
          }}
          options={{ styles: mapStyles, ...options }}
          {...rest}
        />
      </MapWrapper>
    )
  }
}

const MapWrapper = styled.div`
  height: 93vh;
  width: 100%;
  overflow-y: hidden;
  z-index: 0;

  > div {
    height: calc(100% + 30px) !important;
  }
`

export default GoogleMapWrapper
