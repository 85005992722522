import { withStateMachine, StateMachineContainer } from 'utils/StateMachine'
import logger from 'utils/logger'

import { getReadableError } from 'utils/api'
import * as api from './api'

const ALL_ACTIONS = {
  LOAD_SENT: { loading: { actions: ['getSent'] } },
  LOAD_IGNORED: { loading: { actions: ['getIgnored'] } },
  TOGGLE_MODAL: { idle: { actions: ['toggleModal', 'resetState'] } },
  SEND_REQUEST: { loading: { actions: ['sendRequest'] } }
}

const statechart = {
  initial: 'idle',
  states: {
    idle: {
      on: {
        ...ALL_ACTIONS
      },
      onEntry: 'hideLoading'
    },
    loading: {
      onEntry: 'showLoading',
      on: {
        SUCCESS: 'idle',
        FAILURE: 'idle',
        ...ALL_ACTIONS
      }
    }
  }
}

const defaultState = {
  ignored: [],
  sent: [],
  modalToShow: null,
  inProgress: [],
  page: 0,
  hasMore: true
}

class RequestsSentIgnoredContainer extends StateMachineContainer {
  constructor (props) {
    super(props)

    this.state = {
      ...this.state,
      ...defaultState
    }
  }

  request = async (apiName, data, event = {}) => {
    logger.captureBreadcrumb({
      message: 'RequestsSentIgnoredContainer.' + apiName,
      category: 'stayPals',
      data
    })

    try {
      const [error, response] = await api[apiName](data)

      if (error) {
        throw new Error(error)
      } else {
        this.transition({
          type: 'SUCCESS'
        })
        return response
      }
    } catch (error) {
      logger.captureException(error)

      let err = getReadableError(error)

      this.transition({
        ...event,
        type: 'FAILURE',
        error: {
          [apiName]: err
        }
      })
      return false
    }
  }

  getSent = async () => {
    const page = this.state.page + 1
    const data = await this.request('getSent', page)

    if (!data) {
      return
    }

    const total = data.outgoingConnectionsCount
    const perPage = data.resultsPerPage
    const totalPages = Math.ceil(Math.max(total, perPage) / perPage)

    let sent = data.results.map(c => ({
      ...c,
      stayPalRequestStatus: c.relationship
    }))

    if (page > 1) {
      sent = this.state.sent.concat(sent)
    }

    console.log({
      sent,
      page,
      hasMore: page < totalPages
    })

    this.setState({
      sent,
      page,
      hasMore: page < totalPages
    })
  }

  getIgnored = async () => {
    const page = this.state.page + 1
    const data = await this.request('getIgnored', page)

    if (!data) {
      return
    }

    const total = data.totalResults
    const perPage = data.rpp
    const totalPages = Math.ceil(Math.max(total, perPage) / perPage)

    let ignored = data.results

    if (page > 1) {
      ignored = this.state.ignored.concat(ignored)
    }

    this.setState({
      ignored,
      page,
      hasMore: page < totalPages
    })
  }

  toggleModal = ({ modalToShow }) => {
    this.setState({ modalToShow })
  }

  resetState = ({ modalToShow }) => {
    if (!modalToShow) {
      this.setState(defaultState)
    }
  }

  resetError = () => {
    this.transition({
      error: {}
    })
  }

  addProgress = id => {
    this.setState({
      inProgress: this.state.inProgress.concat([id])
    })
  }

  removeProgress = id => {
    this.setState({
      inProgress: this.state.inProgress.filter(i => i !== id)
    })
  }

  sendRequest = async ({ email, cb }) => {
    this.addProgress(email)

    const ok = await this.request('sendRequest', email)

    this.removeProgress(email)

    if (ok) {
      const ignored = this.state.ignored.map(c => {
        if (c.email !== email) return c

        return {
          ...c,
          stayPalRequestStatus: 'pending'
        }
      })

      this.setState({
        ignored
      })

      this.transition({
        type: 'SUCCESS'
      })

      if (typeof cb === 'function') {
        cb()
      }

      return true
    }
  }
}

const withStore = withStateMachine(
  null,
  new RequestsSentIgnoredContainer({ statechart })
)

export default withStore
