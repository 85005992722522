import logger from 'utils/logger'

import get from 'lodash/get'
import camelize from 'utils/camelize'
import root from 'utils/windowOrGlobal'

import * as api from '../api/pricing'

import { withStateMachine, StateMachineContainer } from 'utils/StateMachine'

const statechart = {
  key: 'stay-proposal-pricing',
  initial: 'idle',
  states: {
    idle: {
      onEntry: 'hideTotals',
      on: { LOAD: 'loading' }
    },
    loading: {
      onEntry: ['showLoading', 'loadEstimates'],
      on: {
        LOAD_SUCCESS: 'success',
        LOAD_FAILURE: 'error'
      }
    },
    success: {
      onEntry: 'showTotals',
      on: { LOAD: 'loading' }
    },
    error: {
      onEntry: 'showError',
      on: { RECOVER: 'idle' }
    }
  }
}

class PricingContainer extends StateMachineContainer {
  constructor (props) {
    super(props)

    // Make sure to keep the state machine engine
    const stateMachine = this.state

    this.state = {
      ...stateMachine,
      data: {
        total: '0.00',
        savingsToBusiness: '0.00',
        earningsToTraveler: '0.00',
        earningsToHost: '0.00'
      }
    }
  }

  componentDidTransition (prevStateMachine, event) {
    if (event && event.type) {
      switch (event.type) {
        case 'LOAD_SUCCESS':
          this.setEstimates(event.data)
          break
        case 'LOAD_FAILURE':
          this.alertError(event.error)
          break
      }
    }
  }

  setEstimates = data => {
    this.setState({
      data: camelize({
        total: get(data, 'scTotalPrice', 0),
        ...data
      })
    })
  }

  alertError = async error => {
    const errorStr = Array.isArray(error) ? error[0] : error

    root.alert(
      `Uh oh! An error was encountered while trying to process.
      (${errorStr})`
    )

    await this.transition('RECOVER')
  }

  loadEstimates = async ({ params }) => {
    logger.captureBreadcrumb({
      message: 'PricingContainer.loadEstimates',
      category: 'stay-proposal',
      data: params
    })

    try {
      const [error, data] = await api.getEstimation(params)

      if (error) {
        throw new Error(error)
      } else {
        await this.transition({
          type: 'LOAD_SUCCESS',
          data: get(data, 'comparablePricing', {})
        })
        return true
      }
    } catch (error) {
      console.log('[PricingContainer.loadEstimates] Error: ', error)
      logger.captureException(error)

      await this.transition({ type: 'LOAD_FAILURE', error })
      return false
    }
  }
}

const withPricingStore = withStateMachine(
  null,
  new PricingContainer({ statechart })
)

export default withPricingStore
