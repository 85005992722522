import config from 'config'

import api from 'utils/api'

import get from 'lodash/get'

import logger from 'utils/logger'
import stringifyCyclic from 'utils/stringify-cyclic'

import Pusher from 'pusher-js'

export const getConversationMessages = ({ id, page }) => {
  const params = { page }

  return api.get(`/conversations/${id}`, params)
}

export const postMessage = ({ id, message }) => {
  const params = { body: message }

  return api.post(`/conversations/${id}/messages`, params)
}

export const authenticatePusher = ({ userPID, token, newMessageHandler }) => {
  const pusherConfig = {
    encrypted: true,
    authEndpoint: config.API_URL + '/pusher/auth',
    activityTimeout: 60000,
    auth: {
      headers: {
        'API-SECRET': config.API_SECRET,
        'AUTH-TOKEN': token
      }
    }
  }

  if (['development', 'staging'].includes(config.ENV)) {
    pusherConfig.cluster = 'ap2'

    // Pusher.logToConsole = true
  }

  const socket = new Pusher(config.PUSHER_APP_KEY, pusherConfig)

  socket.connection.bind('error', err => {
    // Pusher errors here seen to often have cyclic references.
    // That doesn't play well with default JSON.stringify from Sentry.
    const error = { error: stringifyCyclic(err) }
    console.log('[AuthenticatePusher connection] Error: ', error)
    if (get(error, 'error.data.code') === 4004) {
      logger.captureException(
        'AuthenticatePusher connection: OVER QUOTA LIMIT',
        error
      )
    } else {
      logger.captureException('AuthenticatePusher connection', error)
    }
  })

  let channel

  try {
    channel = socket.subscribe(`private-user-${userPID}`)

    if (newMessageHandler) {
      bindPusherChannel(channel, newMessageHandler)
      channel.close = () => unbindPusherChannel(channel, newMessageHandler)
    }
  } catch (error) {
    console.log('[AuthenticatePusher] Error: ', error)
    logger.captureException(error)
  }

  return channel
}

export const bindPusherChannel = (channel, handler) => {
  try {
    channel.bind('new_message', handler)
  } catch (error) {
    console.log('[AuthenticatePusher bindPusherChannel] Error: ', error)
    logger.captureException(error)
  }
}

export const unbindPusherChannel = (channel, handler) => {
  try {
    channel.unbind('new_message', handler)
  } catch (error) {
    console.log('[AuthenticatePusher unbindPusherChannel] Error: ', error)
    logger.captureException(error)
  }
}
