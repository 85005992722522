import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { getUserPicture } from 'utils/profile-info'

import { NavigateButton } from '../components/CommonStyles'
// const DEFAULT_HOST_IMAGE =
//   'https://res.cloudinary.com/staycircles/image/upload/f_auto,q_auto:best,w_60,c_scale/v1507614625/assets/default-avatar.png'

const PotentialHosts = ({
  potentialHosts = [],
  type,
  destination,
  onSelectFriends
}) => {
  const _renderEmptyPotential = () => {
    return (
      <Fragment>
        <p className='message--empty'>
          You might have friends-of-friends on this destination that could host
          you for free!
        </p>
        <a className='link'>Learn more</a>
      </Fragment>
    )
  }

  const _renderPotentialList = () => {
    const mockPotential = [...potentialHosts]
    const totalPotentialHost = mockPotential.length
    const offScreenCount = totalPotentialHost - 12

    const listMessage = ` friends and friends-of friends in ${destination}`
    const firstTwentyHost = mockPotential.slice(0, 12)

    return (
      <Fragment>
        <p className='message--list'>
          {type === 'deals' ? (
            <>
              <span>{totalPotentialHost}</span>
              {listMessage}
            </>
          ) : (
            <>
              Share flight destination with <span>{totalPotentialHost}</span>{' '}
              friends to {destination}
            </>
          )}
        </p>
        <ul className='list'>
          {firstTwentyHost.map((host, index) => {
            // const hostImg =
            //   host.cloudinaryImgUrl &&
            //   host.cloudinaryImgUrl.includes('avatar-person')
            //     ? DEFAULT_HOST_IMAGE
            //     : host.cloudinaryImgUrl
            const hostImg = getUserPicture(host)
            return (
              <ListItem zIndex={totalPotentialHost - index}>
                <img src={hostImg} alt={host.lastName} />
              </ListItem>
            )
          })}
          {offScreenCount > 0 && (
            <li className='list__remain'>+{offScreenCount}</li>
          )}
        </ul>
      </Fragment>
    )
  }
  return (
    <Wrapper>
      {potentialHosts && potentialHosts.length > 0
        ? _renderPotentialList()
        : _renderEmptyPotential()}
      {potentialHosts && potentialHosts.length > 0 && type === 'shared' && (
        <NavigateButton mgTop={20} onClick={onSelectFriends}>
          Select Friends
        </NavigateButton>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  font-weight: 700;
  .message--empty {
    margin-bottom: 10px;
    color: #1a365e;
  }
  .message--list {
    color: #6c7982;
    & > span {
      color: #1a365e;
    }
  }
  .link {
    color: #76abe2;
    text-decoration-line: underline;
    cursor: pointer;
  }
  .list {
    display: flex;
    margin: 8px auto 0 0;
    position: relative;
    & > li {
      width: 43px;
      height: 43px;
      border-radius: 50%;
    }
    &__remain {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-left: auto;
      border: 2px solid #f2f2f2;
    }
  }
`

const ListItem = styled.li`
  display: inherit;
  border: 2px solid #ffffff;
  background-color: #f2f2f2;
  overflow: hidden;
  margin: 0 -10px 0 0;
  z-index: ${({ zIndex }) => zIndex};
  & > img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
`

PotentialHosts.propTypes = {
  potentialHosts: PropTypes.array,
  destination: PropTypes.object,
  type: PropTypes.string,
  onSelectFriends: PropTypes.func
}

export default PotentialHosts
