import React from 'react'
import PropTypes from 'prop-types'

import { FORM_ERROR } from 'final-form'

import logger from 'utils/logger'

import { AuthProvider } from 'stores/auth'

import * as api from './api'

import StateMachineProvider, { StateMachineContainer } from 'utils/StateMachine'

const statechart = {
  key: 'account',
  initial: 'idle',
  states: {
    idle: {
      on: {
        UPDATE: { loading: { actions: ['update'] } },
        UPLOAD_PHOTO: {
          loading: { actions: ['uploadPhoto', 'showUploadProgress'] }
        },
        EDIT_PRIMARY_EMAIL: { loading: { actions: ['setPrimaryEmail'] } },
        ADD_EMAIL: { loading: { actions: ['addEmail'] } },
        REMOVE_EMAIL: { loading: { actions: ['removeEmail'] } },
        RESEND_EMAIL: { loading: { actions: ['resendEmail'] } },
        ADD_PHONE: { loading: { actions: ['addPhone'] } },
        REMOVE_PHONE: { loading: { actions: ['removePhone'] } },
        CONFIRM_EMAIL: { loading: { actions: ['confirmEmail'] } }
      }
    },
    loading: {
      onEntry: 'showLoading',
      on: {
        LOAD_SUCCESS: 'idle',
        LOAD_FAILURE: 'error'
      }
    },
    error: {
      on: { RECOVER: 'idle' }
    }
  }
}

class ProfileContainer extends StateMachineContainer {
  constructor (props) {
    super(props)

    // Make sure to keep the state machine engine
    const stateMachine = this.state

    this.state = {
      ...stateMachine,
      emailsResent: [],
      pictureFile: null,
      uploadProgress: null
    }
  }

  componentDidTransition (prevStateMachine, event) {
    if (event && event.type) {
      switch (event.type) {
        case 'LOAD_SUCCESS':
          this.setSuccess(event)
          break
        case 'LOAD_FAILURE':
          this.setError(event)
          break
      }
    }
  }

  setSuccess = async ({ data, callback }) => {
    callback && callback(data)
  }

  setError = event => {
    let error = event.error

    if (Array.isArray(error)) {
      error = error[0]
    }

    event.callback && event.callback({ [FORM_ERROR]: error })
    this.transition('RECOVER')
  }

  request = async (apiName, requestData, callback) => {
    logger.captureBreadcrumb({
      message: 'ProfileContainer.' + apiName,
      category: 'account',
      data: requestData
    })

    try {
      const [error, data] = await api[apiName](requestData)

      if (error) {
        this.transition({ type: 'LOAD_FAILURE', error, callback })
      } else {
        this.transition({ type: 'LOAD_SUCCESS', data, callback })
        return data
      }
    } catch (error) {
      logger.captureException(error)
    }
  }

  update = async ({ data: submitData, callback }) => {
    await this.request('updateProfileDetails', submitData, callback)
  }

  setPictureFile = file => {
    this.setState({ pictureFile: file })
  }

  uploadPhoto = async ({ user, file, callback }) => {
    if (!file) return

    const onUploadProgress = progress => {
      this.setState({ uploadProgress: progress })
    }

    const params = {
      file,
      userID: user.pid || user.id,
      onUploadProgress
    }

    const data = await this.request('uploadPhoto', params)

    if (data) {
      await this.transition({
        type: 'UPDATE',
        callback,
        data: {
          cloudinaryImgUrl: data.secure_url,
          cloudinaryPublicId: data.public_id
        }
      })
    }

    this.setState({ uploadProgress: null })
  }

  setPrimaryEmail = async ({ email, callback }) => {
    await this.request('setPrimaryEmail', email, callback)
  }

  addEmail = async ({ email, callback }) => {
    await this.request('addNewEmail', email, callback)
  }

  removeEmail = async ({ email, callback }) => {
    await this.request('deleteEmail', email, callback)
  }

  resendEmail = async ({ email, callback }) => {
    const data = await this.request('resendEmailConfirmation', email, callback)
    if (data) {
      this.setState({ emailsResent: this.state.emailsResent.concat(email) })
    }
  }

  addPhone = async ({ phone, callback }) => {
    await this.request('addNewPhone', phone, callback)
  }

  removePhone = async ({ phone, callback }) => {
    await this.request('deletePhone', phone, callback)
  }

  confirmEmail = async ({ code, callback }) => {
    await this.request('confirmEmail', code, callback)
  }
}

const container = new ProfileContainer({ statechart })

export default class ProfileStoreProvider extends React.Component {
  static propTypes = {
    children: PropTypes.func.isRequired
  }

  render () {
    return (
      <AuthProvider>
        {store => {
          const user = store.getCurrentUser()

          return (
            <StateMachineProvider container={container}>
              {machineStore =>
                this.props.children({
                  store: machineStore,
                  reload: () => store.loadDetails(),
                  user
                })
              }
            </StateMachineProvider>
          )
        }}
      </AuthProvider>
    )
  }
}
