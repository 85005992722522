import styled from 'styled-components'

import colors from 'styles/colors'

export const FieldWrapper = styled.div`
  padding: 0 16px;
  position: relative;
`

export const Input = styled.input.attrs({
  placeholder: 'Enter email(s) here, separate by comma'
})`
  width: 100%;
  height: 40px;
  line-height: 40px;
  padding: 2px 70px 2px 12px;
  outline: none;

  border: 1px solid ${colors.blackRGBA(0.1)};
  border-radius: 8px;

  font-size: ${props => props.fontSize || '16px'};

  &:focus {
    border-color: ${colors.darkerBlue};
  }

  &::placeholder {
    font-size: ${props => props.fontSize || '14px'};
    font-style: italic;
  }
`

export const Button = styled.button.attrs({
  children: 'Send'
})`
  position: absolute;
  top: 0;
  right: ${props => props.right || '16px'};
  bottom: 0;

  cursor: pointer;

  height: 40px;
  padding: 0 12px;

  font-size: 18px;
  color: #fff;

  background-color: ${colors.darkRed};
  border: 1px solid ${colors.red};
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
  border-left: none;

  &:hover {
    background-color: ${colors.darkerRed};
  }

  ${props =>
    props.disabled &&
    `
    cursor: not-allowed;
    opacity: 0.8;

    &:hover {
      background-color: ${colors.darkerRed} !important;
    }
  `};
`

const Field = {
  Wrapper: FieldWrapper,
  Input,
  Button
}

export default Field
