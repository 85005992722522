import api from 'utils/api'

export const destroyLookup = () => {
  return api.get('/account/security/destroy_lookup')
}

export const destroyAccount = async ({ email, password, passwordSet }) => {
  if (passwordSet) {
    const loginResponse = await api.post('/auth/sign_in/', { email, password })

    if (loginResponse[0]) return loginResponse
  }

  // return api.post('/auth/delete_user_data')
  return api.delete('/account/security/destroy')
}
