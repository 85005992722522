import React from 'react'
import PropTypes from 'prop-types'

import { get, uniqBy } from 'lodash'
import * as emailValidator from 'email-validator'

import logger from 'utils/logger'

import * as api from '../api'

import StateMachineProvider, { StateMachineContainer } from 'utils/StateMachine'

const statechart = {
  key: 'hosting-housemates',
  initial: 'idle',
  states: {
    idle: {
      onEntry: ['showLoading'],
      on: { LOAD: { loading: { actions: ['showLoading'] } } }
    },
    loading: {
      onEntry: ['load'],
      on: {
        SUCCESS: 'list',
        ERROR: 'error'
      }
    },
    list: {
      onEntry: 'showList',
      on: {
        LOAD: 'loading',
        REMOVE: { list: { actions: ['showLoading', 'remove'] } },
        INVITE_OWNER: { list: { actions: ['showLoading', 'inviteOwner'] } },
        CANCEL_INVITE: { list: { actions: ['showLoading', 'cancelInvite'] } },
        CANCEL_OWNER: { list: { actions: ['showLoading', 'cancelOwner'] } },
        INVITE: { list: { actions: ['invite'] } },
        SEND_INVITES: { list: { actions: ['showLoading', 'inviteBatch'] } },
        SUCCESS: 'list',
        ERROR: 'error'
      }
    },
    error: {
      onEntry: 'showError',
      on: { RECOVER: 'loading' }
    }
  }
}

class HousematesContainer extends StateMachineContainer {
  constructor (props) {
    super(props)

    // Make sure to keep the state machine engine
    const stateMachine = this.state

    this.state = {
      ...stateMachine,
      data: {
        placeId: null,
        owner: {},
        housemates: [],
        pendingHousemates: [],
        pendingOwner: []
      },
      error: undefined
    }
  }

  componentDidTransition (prevStateMachine, event) {
    if (event && event.type) {
      switch (event.type) {
        case 'SUCCESS':
          this.setData(event.data)
          break
        case 'ERROR':
          this.setError(event.error)
          break
      }
    }
  }

  setData = data => {
    if (!data) return
    this.setState({ data })
  }

  setError = async error => {
    const errorStr = Array.isArray(error) ? error[0] : error
    await this.setState({ error: errorStr })
  }

  getHousemates = () => {
    const {
      owner,
      housemates,
      pendingHousemates,
      pendingOwner
    } = this.state.data

    let list = [{ ...owner, status: 'owner' }].concat(
      addStatus(pendingOwner, 'pendingOwner'),
      housemates,
      addStatus(pendingHousemates, 'pending')
    )

    return uniqBy(list, 'id')
  }

  request = async (name, apiCall, data) => {
    logger.captureBreadcrumb({
      message: 'HousematesContainer.' + name,
      category: 'hosting',
      data
    })

    try {
      const [error, response] = await apiCall(data)

      if (error) {
        throw new Error(error)
      } else {
        return response
      }
    } catch (error) {
      logger.captureException(error)

      this.transition({ type: 'ERROR', error })
      return false
    }
  }

  load = async ({ place, callback = () => {} }) => {
    let placeId = get(place, 'id', this.state.data.placeId)
    let owner = get(place, 'owner', this.state.data.owner)

    const loadRequest = req => this.request(req, api[req], placeId)

    let housemates = await loadRequest('getHousemates')
    let pendingHousemates = await loadRequest('getPendingHousemates')
    let pendingOwner = await loadRequest('getPendingOwner')

    housemates = get(housemates, 'housemates', [])
    pendingHousemates = get(pendingHousemates, 'housemateRequests', [])
    pendingOwner = get(pendingOwner, 'ownershipRequests', [])

    this.transition({
      type: 'SUCCESS',
      data: { placeId, owner, housemates, pendingHousemates, pendingOwner }
    })

    callback()
  }

  remove = async ({ id }) => {
    const placeId = this.state.data.placeId

    const data = { placeId, housemateId: id }

    const res = await this.request('remove', api.removeHousemate, data)

    if (res) {
      this.transition({ type: 'LOAD' })
    }
  }

  inviteOwner = async ({ email }) => {
    const placeId = this.state.data.placeId

    const data = { placeId, email }

    const res = await this.request('inviteOwner', api.inviteOwner, data)

    if (res) {
      this.transition({ type: 'LOAD' })
    }
  }

  cancelInvite = async ({ id }) => {
    const placeId = this.state.data.placeId

    const data = { placeId, housemateId: id }

    const res = await this.request(
      'cancelInvite',
      api.cancelHousemateInvite,
      data
    )

    if (res) {
      this.transition({ type: 'LOAD' })
    }
  }

  cancelOwner = async ({ id }) => {
    const placeId = this.state.data.placeId

    const data = { placeId, housemateId: id }

    const res = await this.request('cancelOwner', api.cancelOwnerInvite, data)

    if (res) {
      this.transition({ type: 'LOAD' })
    }
  }

  invite = async ({ email, placeId, multiple = false, callback }) => {
    placeId = placeId || this.state.data.placeId

    email = email.trim()

    if (!emailValidator.validate(email)) return

    const data = { email, placeId }

    logger.captureBreadcrumb({
      message: 'AddHousemateContainer.invite',
      category: 'hosting',
      data: { email }
    })

    const response = await this.request('invite', api.inviteHousemate, data)

    if (response) {
      if (multiple) {
        return response
      } else {
        this.transition({ type: 'LOAD', callback })
      }
    } else {
      const error = this.state.error

      if (typeof callback === 'function') {
        callback(error)
      }
    }

    return response
  }

  inviteBatch = async ({ placeId, emails, callback }) => {
    if (!emails) return

    placeId = placeId || this.state.data.placeId

    const validEmails = emails
      .split(',')
      .map(e => e.trim())
      .filter(e => emailValidator.validate(e))

    if (emails.length > 0 && validEmails.length === 0) {
      this.transition('SUCCESS')

      const error = 'Emails are invalid'

      if (typeof callback === 'function') {
        callback(error)
      }

      return error
    }

    let invitedEmails = []

    for (let email of validEmails) {
      const res = await this.invite({ email, placeId, multiple: true })

      if (!res) break

      invitedEmails.push(email)
    }

    let error

    if (invitedEmails.length > 0) {
      this.transition('SUCCESS')
    } else {
      error = this.state.error
    }

    if (typeof callback === 'function') {
      callback(error)
    }
  }
}

const container = new HousematesContainer({ statechart })

export default class HousematesStoreProvider extends React.PureComponent {
  static propTypes = {
    children: PropTypes.func.isRequired
  }

  componentDidMount () {
    this.loadData()
  }

  loadData = async () => {
    const { place } = this.props
    if (place) {
      await container.transition({ type: 'LOAD', place })
    }
  }

  render () {
    return (
      <StateMachineProvider container={container}>
        {machineStore => this.props.children(machineStore)}
      </StateMachineProvider>
    )
  }
}

/**
 * Helpers
 */

function addStatus (list, status) {
  return list.map(i => ({ ...i, status }))
}
