import get from 'lodash/get'

import { cloudinaryUpload } from 'config/api'
import { getDefaultResponse } from 'utils/api'
import root from 'utils/windowOrGlobal'

async function uploadImage (formData, onUploadProgress) {
  function progressEvt (e) {
    const completed = Math.round((e.loaded * 100.0) / e.total)
    onUploadProgress && onUploadProgress(completed)
  }

  const config = {
    'content-type': 'multipart/form-data',
    'X-Requested-With': 'XMLHttpRequest',
    onUploadProgress: progressEvt
  }

  return getDefaultResponse(cloudinaryUpload.post, '/', formData, config)
}

export default uploadImage

/**
 * Helpers
 */

export function cropImage (file, region, callback) {
  return modifyCanvasOnImageLoad(
    file,
    (canvas, image) => {
      const ctx = canvas.getContext('2d')
      ctx.clearRect(0, 0, canvas.width, canvas.height)
      canvas.width = region.width
      canvas.height = region.height

      ctx.drawImage(
        image,
        region.left,
        region.top,
        region.width,
        region.height,
        0,
        0,
        region.width,
        region.height
      )
    },
    callback
  )
}

export function resizeImage (file, size, callback) {
  return modifyCanvasOnImageLoad(
    file,
    (canvas, image) => {
      if (image.width > size) {
        image.height *= size / image.width
        image.width = size
      }

      const ctx = canvas.getContext('2d')
      ctx.clearRect(0, 0, canvas.width, canvas.height)
      canvas.width = image.width
      canvas.height = image.height
      ctx.drawImage(image, 0, 0, image.width, image.height)
    },
    callback
  )
}

export function rotateImage (file, degrees, callback) {
  return modifyCanvasOnImageLoad(
    file,
    (canvas, image) => {
      const ctx = canvas.getContext('2d')
      ctx.clearRect(0, 0, canvas.width, canvas.height)
      canvas.width = image.width
      canvas.height = image.height

      ctx.save()
      ctx.translate(canvas.width / 2, canvas.height / 2)
      ctx.rotate((degrees * Math.PI) / 180)

      ctx.drawImage(
        image,
        -image.width / 2,
        -image.width / 2,
        image.width,
        image.height
      )

      ctx.restore()
    },
    callback
  )
}

function modifyCanvasOnImageLoad (file, onLoad, callback) {
  return new Promise(resolve => {
    const { url, clearURL } = createBlobURL(file)

    const image = new root.Image()

    image.onload = async function () {
      // Create temp canvas
      const canvas = document.createElement('canvas')
      canvas.id = 'resizeCanvas'
      canvas.style.display = 'none'
      document.body.appendChild(canvas)

      onLoad(canvas, image)

      const resizedImg = canvas.toDataURL()
      const blobImage = dataURLtoBlob(resizedImg)

      clearURL()
      canvas.remove()

      callback && callback(blobImage)
      resolve(blobImage)
    }

    image.src = url
  })
}

export function createBlobURL (file) {
  const URL = root.URL || root.webkitURL

  if (!get(URL, 'createObjectURL')) {
    console.warn('window.URL was not supported')
    return
  }

  const url = URL.createObjectURL(file)

  const clearURL = () => {
    URL.revokeObjectURL(url)
  }

  return { url, clearURL }
}

// Snippet forked from https://github.com/bubkoo/dataurl-to-blob
export function dataURLtoBlob (dataURL) {
  if (!window || window.window !== window) {
    console.warn('This module is only available in browser')
    return
  }

  const Blob = root.Blob || root.MozBlob || root.WebKitBlob
  if (!Blob) {
    console.warn('Blob was not supported')
    return
  }

  // parse the dataURL components as per RFC 2397
  const matches = isDataURL(dataURL)
  if (!matches) {
    console.warn('invalid dataURI')
    return
  }

  // default to text/plain;charset=utf-8
  const mediaType = matches[2]
    ? matches[1]
    : 'text/plain' + (matches[3] || ';charset=utf-8')

  const isBase64 = !!matches[4]
  const dataString = dataURL.slice(matches[0].length)
  const byteString = isBase64
    ? root.atob(dataString) // convert base64 to raw binary data held in a string
    : decodeURIComponent(dataString) // convert base64/URLEncoded data component to raw binary

  const array = []
  for (let i = 0; i < byteString.length; i++) {
    array.push(byteString.charCodeAt(i))
  }

  const blobFile = new Blob([new Uint8Array(array)], { type: mediaType })

  return blobFile
}

export function isDataURL (dataURL) {
  // parse the dataURL components as per RFC 2397
  const dataURLPattern = /^data:((.*?)(;charset=.*?)?)(;base64)?,/
  return typeof dataURL === 'string' && dataURL.match(dataURLPattern)
}
