import React from 'react'
import styled from 'styled-components'
import colors from 'styles/colors'
import Icon from 'components/Icon'
// import ButtonPill from 'components/ButtonPill'
import { Container } from '../../components/CommonStyles'

import mapIconSrc from '../../assets/map-icon.png'
import googlePlaySrc from '../../assets/googleplay.png'
import appSoreSrc from '../../assets/appstore.png'

const LearnMoreContainer = styled(Container)`
  height: auto;
  margin-top: 40px;
  width: 750px;
  background-color: ${colors.white};
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1,
  width: 100%;
  bottom: 0px;
  right 0px;
  border-top-right-radius: 30px;
  border-top-left-radius: 30px;
`

const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
`

const Text = styled.p`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-horizontal: 35px;
  color: ${colors.darkestBlue};
  font-size: ${props => props.size || 16}px;
  text-align: ${props =>
    props.bold ? 'center' : props.medium ? 'center' : 'left'};
  font-weight: ${props => (props.bold ? 700 : props.medium ? 500 : 400)};
  padding-vertical: ${props =>
    props.paddingVertical ? props.paddingVertical : 0};
  margin-vertical: ${props =>
    props.marginVertical ? props.marginVertical : 0};
`

const TopIconContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  padding: 20px 10px;
  justify-content: space-between;
`

const OptionContainerWrapper = styled.div`
  width: 282px;
  margin: 0 auto;
`

const OptionContainer = styled.div`
display: flex;
  flex: 1,
  margin-top: 12px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-horizontal: 35px;
  margin-bottom: 10px;
`
const OptionText = styled.p`
  display: flex;
  flex-direction: column;
  flex: 1;
  font-size: 14px;
  padding-left: 10px;
  color: ${colors.black};
  line-height: 19.6px;
  font-weight: 500;
  margin-bottom: 0px;
`

const ButtonContainer = styled.div`
  width: 282px;
  margin: 0 auto;
  padding: 24px 0px;
`

const PlayStoreContainer = styled.div`
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const ContentText = {
  heading: 'Get your friends on your map',
  desc1:
    'Adventure Machine is built on the StayCircles platform to easily connect you with friends and friends-of-friends in across the world.',
  desc2:
    'On the StayCircles app (iOS/Android) you can explore your world map of your friends and friends-of-friends',
  options: [
    'See your friends who are on StayCircles',
    'Invite friends to Adventure Machine',
    'Build your world map of friends and friends-of-friends!'
  ]
}

const LearnMore = () => {
  const { heading, desc1, desc2, options } = ContentText
  return (
    <LearnMoreContainer>
      <MapImageContainer />
      <ContentContainer>
        <TopIconContainer>
          <Icon name='sc-logo' height={40} />
          <Icon name='am-logo' height={40} />
        </TopIconContainer>
        <Text bold size={33} style={{ lineHeight: '39.6px' }}>
          {heading}
        </Text>
        <DescriptionContainer>
          <Text
            paddingVertical={20}
            medium
            size={14}
            style={{
              width: '245px',
              color: colors.black,
              lineHeight: '19.6px'
            }}
          >
            {desc1}
          </Text>
          <Text
            paddingVertical={20}
            medium
            size={14}
            style={{
              width: '245px',
              color: colors.black,
              lineHeight: '19.6px'
            }}
          >
            {desc2}
          </Text>
        </DescriptionContainer>
        <OptionContainerWrapper>
          {options.map(option => {
            return (
              <OptionContainer key={option}>
                <Icon name='checkmark-solid' width={28} />
                <OptionText>{option}</OptionText>
              </OptionContainer>
            )
          })}
        </OptionContainerWrapper>
        <ButtonContainer>
          {/* <ButtonPill
            background={colors.darkBlue}
            hoverBackground={colors.blue}
            color={colors.white}
            border={colors.whiteRGBA(0.1)}
            style={{ width: '100%' }}
          >
            CONNECT
          </ButtonPill> */}
          <PlayStoreContainer>
            <div>
              <a
                href='https://apps.apple.com/us/app/adventure-machine/id1412862317'
                target='_blank'
              >
                <StoreImage src={appSoreSrc} />
              </a>
            </div>
            <div>
              <a
                href='https://play.google.com/store/apps/details?id=com.staycircles.am.android&hl=en&gl=US'
                target='_blank'
              >
                <StoreImage src={googlePlaySrc} />
              </a>
            </div>
          </PlayStoreContainer>
        </ButtonContainer>
      </ContentContainer>
    </LearnMoreContainer>
  )
}

export default LearnMore

const MapImageContainer = styled.div`
  width: 100%;
  height: 265px;
  background-image: url(${mapIconSrc});
  background-size: cover;
`
const StoreImage = styled.img`
  height: 60px;
  width: auto;
`
