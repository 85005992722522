import React from 'react'

import logger from 'utils/logger'

import { get, uniqBy } from 'lodash'

import { getTransactions } from './api'

import StateMachineProvider, { StateMachineContainer } from 'utils/StateMachine'

const statechart = {
  key: 'transactions',
  initial: 'idle',
  states: {
    idle: {
      on: { LOAD: 'loading' }
    },
    loading: {
      onEntry: ['showLoading', 'load'],
      on: {
        LOAD_SUCCESS: [
          {
            target: 'list',
            cond: (extState, event) => {
              return get(event, 'data.logs.length', 0) > 0
            }
          },
          { target: 'empty' }
        ],
        LOAD_FAILURE: 'error'
      }
    },
    list: {
      onEntry: 'showList',
      on: {
        LOAD: 'loading',
        REFRESH: 'loading',
        RELOAD: { list: { actions: ['load'] } },
        NEXT: { list: { actions: ['loadNext'] } },
        NEXT_CANCEL: 'list',
        LOAD_SUCCESS: 'list',
        LOAD_FAILURE: 'list'
      }
    },
    empty: {
      onEntry: 'showEmpty',
      on: { REFRESH: 'loading' }
    },
    error: {
      onEntry: 'showError',
      on: { RECOVER: 'loading' }
    }
  }
}

class TransactionsContainer extends StateMachineContainer {
  constructor (props) {
    super(props)

    // Make sure to keep the state machine engine
    const stateMachine = this.state

    this.state = {
      ...stateMachine,
      data: { logs: [] },
      params: {
        page: 1,
        transactionsType: undefined,
        hasMore: true
      }
    }
  }

  componentDidTransition (prevStateMachine, event) {
    if (event && event.type) {
      switch (event.type) {
        case 'LOAD_SUCCESS':
          this.setData(event.data)
          break
      }
    }
  }

  setData = data => {
    let logs = get(data, 'logs', [])

    if (data.page > 1) {
      logs = uniqBy(this.state.data.logs.concat(logs), 'id')
    }

    const newData = { ...data, logs }

    this.setState({ data: newData })
  }

  load = async (event = {}) => {
    logger.captureBreadcrumb({
      message: 'TransactionsContainer.load',
      category: 'transactions',
      data: { transactionsType: event.transactionsType }
    })

    let params = {
      page: event.page || 1,
      transactionsType: event.transactionsType,
      hasMore: get(event, 'hasMore', true)
    }

    if (
      ['RECOVER', 'RELOAD'].includes(event.type) &&
      !event.page &&
      this.state.params.transactionsType
    ) {
      params = this.state.params
    }

    try {
      await this.setState({ params })

      const [error, data] = await getTransactions(params)

      if (error) {
        throw new Error(error)
      } else {
        await this.transition({ type: 'LOAD_SUCCESS', data })
        return data
      }
    } catch (error) {
      logger.captureException(error)

      await this.transition({ type: 'LOAD_FAILURE', error })
      return false
    }
  }

  loadNext = async () => {
    const { params, data } = this.state
    const nextPage = Number.isNaN(params.page) ? 1 : params.page + 1

    const totalPages = Math.ceil(data.totalLogsCount / data.perPageCount)
    const hasMore = nextPage < totalPages

    await this.setState({
      params: {
        ...params,
        page: nextPage,
        hasMore
      }
    })

    if (hasMore || isNaN(totalPages)) {
      await this.load({
        ...params,
        page: nextPage,
        hasMore: true
      })
    } else {
      await this.transition('NEXT_CANCEL')
    }
  }
}

export default class TransactionsStoreProvider extends React.Component {
  constructor (props) {
    super(props)
    this.container = new TransactionsContainer({
      statechart
    })
  }

  render () {
    return (
      <StateMachineProvider container={this.container}>
        {machineStore => this.props.children(machineStore)}
      </StateMachineProvider>
    )
  }
}
