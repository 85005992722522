import React, { PureComponent } from 'react'

import styled from 'styled-components'

import LocationSearchInput from './LocationSearchInput'
import { TouchableOpacity } from 'components/Native'

import colors from 'styles/colors'

export const FILTER = {
  SP: 1,
  SP2: 2
}

const STAY_PREFERENCES = [
  { key: 'no_children', label: 'Children', on: 'Hide', off: 'Show' },
  { key: 'no_dogs', label: 'Dogs', on: 'Hide', off: 'Show' },
  { key: 'no_cats', label: 'Cats', on: 'Hide', off: 'Show' },
  {
    key: 'private_room',
    label: 'Room',
    on: 'Private Only',
    off: 'Private or Shared'
  },
  {
    key: 'private_bathroom',
    label: 'Bathroom',
    on: 'Private Only',
    off: 'Private or Shared'
  }
]

class ControlPanel extends PureComponent {
  static defaultProps = {
    searchPreferences: []
  }

  state = {
    showPreferences: false
  }

  toggleShowPreferences = () =>
    this.setState(({ showPreferences }) => ({
      showPreferences: !showPreferences
    }))

  isSelected = type => {
    const { includeOnly } = this.props

    return includeOnly === undefined || includeOnly === type
  }

  toggleSP = () => this.props.handleFilterToggle(FILTER.SP)

  toggleSP2 = () => this.props.handleFilterToggle(FILTER.SP2)

  renderToggles = () => {
    const { toggleSearchPreference, ...props } = this.props
    const { showPreferences } = this.state

    if (!toggleSearchPreference) {
      return null
    }

    return (
      <div className='ph3 mb2 flex flex-row items-center justify-around'>
        <TouchableOpacity
          className='black-60 underline f5 mb2'
          onClick={this.toggleShowPreferences}
        >
          {showPreferences ? 'Hide' : 'Show'} Filters
        </TouchableOpacity>
        {showPreferences && (
          <div>
            {STAY_PREFERENCES.map(pref => (
              <p key={pref.key} className='f6 mb2'>
                <span className='black-60'>{pref.label}:</span>{' '}
                <TouchableOpacity
                  onPress={() => toggleSearchPreference(pref.key)}
                >
                  {props[pref.key] ? pref.on : pref.off}
                </TouchableOpacity>
              </p>
            ))}
          </div>
        )}
      </div>
    )
  }

  render () {
    const { address, setAddress, handlePlaceSelect } = this.props

    return (
      <Container>
        <LocationSearchInput {...{ address, setAddress, handlePlaceSelect }} />
        <div className='pa3 mt2 flex flex-row items-center justify-around'>
          <p className='f6 black-60 ma0 '>Search on map</p>
          <Button
            color={colors.blue}
            active={this.isSelected(FILTER.SP2)}
            onClick={this.toggleSP2}
          >
            Friends-of-Friends
          </Button>
          <Button
            color={colors.red}
            active={this.isSelected(FILTER.SP)}
            onClick={this.toggleSP}
          >
            Friends
          </Button>
        </div>
        {this.renderToggles()}
      </Container>
    )
  }
}

const Container = styled.div`
  z-index: 1;
  position: absolute;
  top: 1rem;
  right: 1rem;
  // width: 22rem;
  border-radius: 0.5rem;
  background: white;
  box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.05);
`

const Button = styled.button.attrs({
  type: 'button'
})`
  font-size: 1.125rem;
  color: ${props => (props.active ? props.color : colors.silver)};

  padding: 0.5rem 1rem;
  margin-left: 0.5rem;

  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 100px;
  outline: none;

  background: ${props => (props.active ? '#ffffff' : colors.offwhite)};
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);

  cursor: pointer;

  :active {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
  }
`

export default ControlPanel
