import React from 'react'

import get from 'lodash/get'

import colors from 'styles/colors'

import Button from 'components/ButtonPill'
import { FullPageLoader } from 'components/Loader'

class PlacesEmpty extends React.Component {
  state = { ready: false }

  componentDidMount () {
    this.navigatePlaceId()
  }

  componentDidUpdate (prevProps) {
    if (prevProps.places.length === 0 && this.props.places.length > 0) {
      this.navigatePlaceId()
    }
  }

  navigatePlaceId () {
    const { places, uri, navigate } = this.props

    if (get(places, '[0]')) {
      let place = places.find(place => place.primary)

      place = place || places[0]

      const somePlaceIsOpen = places.some(place => uri.includes(place.id))

      if (get(place, 'id') && !somePlaceIsOpen) {
        navigate(place.id, { replace: true })
        return
      }
    }

    this.setState({ ready: true })
  }

  render () {
    if (!this.state.ready || this.props.loading) {
      return <FullPageLoader />
    }

    return (
      <div className='w-100 flex flex-column flex-grow-1 justify-center items-center'>
        <h3 className='black-70 f3 fw5 lh-copy tc'>
          To get started on hosting <br /> let’s add your place
        </h3>

        <Button
          background={colors.darkBlue}
          hoverBackground={colors.darkerBlue}
          color={colors.white}
          border={colors.whiteRGBA(0.1)}
          onClick={() => this.props.navigate('new_place')}
        >
          Add Place
        </Button>

        <p className='black-40 lh-copy mv3 tc'>
          Don’t worry - your address is only <br /> shown to your Friends
        </p>
      </div>
    )
  }
}

export default PlacesEmpty
