import cloudinaryCore from 'cloudinary-tiny-js'

const getAssetUrl = cloudinaryCore({
  cloudName: 'staycircles',
  secure: true,
  defaults: {
    fetchFormat: 'auto',
    quality: 'auto'
  }
})

export default getAssetUrl
