import { get, findKey, omit } from 'lodash'

import { navigate } from 'gatsby'

import { getCurrentUser } from 'stores/auth'

import * as routes from 'config/routes'
import { withStateMachine, StateMachineContainer } from 'utils/StateMachine'
import logger from 'utils/logger'

import { getReadableError } from 'utils/api'
import * as api from './api'

const statechart = {
  initial: 'idle',
  states: {
    idle: {
      on: {
        LOAD: { loading: { actions: ['getStayPals'] } },
        UN_STAYPAL: { loading: { actions: ['unStayPal'] } },
        DESTROY_LOOKUP: { loading: { actions: ['destroyLookup'] } },
        DESTROY_ACCOUNT: { loading: { actions: ['destroyAccount'] } }
      },
      onEntry: 'hideLoading'
    },
    loading: {
      onEntry: 'showLoading',
      on: {
        SUCCESS: 'idle',
        FAILURE: 'idle',
        LOAD: { loading: { actions: ['getStayPals'] } }
      }
    }
  }
}

export const defaultStayPals = {
  page: 0,
  term: '',
  hasMore: true,
  list: []
}

export const defaultDestroyModal = {
  show: false,
  reason: ''
}

class MaintenanceContainer extends StateMachineContainer {
  requestQueue = []

  request = async (apiName, data, event = {}) => {
    logger.captureBreadcrumb({
      message: 'MaintenanceContainer.' + apiName,
      category: 'stayPals',
      data
    })

    try {
      const [error, response] = await api[apiName](data)

      if (error) {
        throw new Error(error)
      } else {
        return response
      }
    } catch (error) {
      logger.captureException(error)

      let err = getReadableError(error)

      if (apiName === 'destroyAccount' && /invalid/i.test(err)) {
        err = 'Invalid Password'
      }

      this.transition({
        ...event,
        type: 'FAILURE',
        error: {
          [apiName]: err
        }
      })
      return false
    }
  }

  getStayPals = async ({ clearOld = false, stayPals, ...event }) => {
    const { page = 1, term } = stayPals
    const requestID = JSON.stringify({ page, term })

    this.requestQueue.push(requestID)

    const data = await this.request('getStayPals', { page, term })
    this.requestQueue = this.requestQueue.filter(id => id !== requestID)

    if (this.requestQueue.length > 0) {
      // abort if there is another request in the queue
      return
    }

    if (data) {
      let list = get(data, 'results', stayPals.list)

      if (!clearOld) {
        list = stayPals.list.concat(list)
      }

      const hasMore = list.length < data.totalSp

      this.transition({
        ...event,
        type: 'SUCCESS',
        stayPals: {
          ...stayPals,
          hasMore,
          list
        }
      })

      return true
    }
  }

  unStayPal = async ({ stayPalID, stayPals, ...event }) => {
    const ok = await this.request('unStayPal', stayPalID)

    if (ok) {
      const list = stayPals.list.filter(({ id }) => id !== stayPalID)

      this.transition({
        ...event,
        type: 'SUCCESS',
        stayPals: {
          ...stayPals,
          list
        }
      })

      return true
    }
  }

  destroyLookup = async event => {
    const data = await this.request('destroyLookup')

    if (data) {
      const lookup = omit(get(data, 'count', {}), ['ownedPlaces'])
      const reason = findKey(lookup, v => v > 0)

      this.transition({
        ...event,
        type: 'SUCCESS',
        destroyModal: {
          show: true,
          canDelete: !!reason,
          reason
        }
      })

      return true
    }
  }

  destroyAccount = async ({ password, ...event }) => {
    const ok = await this.request(
      'destroyAccount',
      {
        email: getCurrentUser().email,
        password
      },
      event
    )

    if (ok) {
      this.transition({
        ...event,
        type: 'SUCCESS',
        destroyModal: {
          destroyed: true
        }
      })

      setTimeout(() => {
        navigate(`/${routes.LOGOUT}`)
      }, 3000)

      return true
    }
  }
}

const withMaintenanceStore = withStateMachine(
  null,
  new MaintenanceContainer({ statechart })
)

export default withMaintenanceStore
