import axios from 'axios'

import logger from 'utils/logger'

import { get, snakeCase, mapKeys } from 'lodash'
import { stringify } from 'qs'

import api from 'utils/api'
import config from 'config'

const baseURLv2 = config.API_URL.replace('v1', 'v2')

export const getAllSubscriptionPlans = async () => {
  return api.get('/subscription_plans', '', {
    baseURL: baseURLv2
  })
}

export const getSubscriptionPlan = async () => {
  return api.get('/subscriptions/current_subscription')
}

export const cancelSubscriptionPlan = async id => {
  return api.patch(`/subscriptions/${id}/cancel_subscription`)
}

// id = 1 is Prime by default
export const activateSubscriptionPlan = async (id = 1) => {
  return api.post('/subscriptions', { subscription_plan_id: id })
}

export const reactivateSubscriptionPlan = async id => {
  return api.patch(`/subscriptions/${id}/reactivate_subscription`)
}

export const getPaymentMethods = async () => {
  return api.get('/payment_methods')
}

export const setDefaultMethod = async id => {
  return api.put('/payment_methods/' + id + '/set_default')
}

export const deletePaymentMethod = async id => {
  return api.delete('/payment_methods/' + id)
}

export const upgradeSubscriptionPlan = async ({ currentPlan, id }) => {
  return api.put('/subscriptions/' + currentPlan, {
    subscription_plan_id: id
  })
}

/**
 * card = {
 *  number,
 *  expMonth,
 *  expYear,
 *  cvc,
 *  addressZip
 * }
 */

export const createPaymentMethod = async card => {
  const [error, response] = await getStripeToken(card)

  const errorMsg =
    'Something went wrong. Please check your card details and try again.'

  if (error) {
    return [errorMsg, response]
  }

  const cardToken = get(response, 'data.id')

  if (!cardToken) {
    return [errorMsg]
  }

  const data = {
    payment_method: {
      card_token: cardToken
    }
  }

  return api.post('/payment_methods', data)
}

export const getStripePublishableKey = async () => {
  return api.get('/stripe/publishable_key')
}

export const getStripeToken = async card => {
  const [err, data] = await getStripePublishableKey()

  if (err) {
    console.log('[getStripePublishableKey]', err)
    logger.captureException(
      new Error('Could not retrieve Stripe Publishable Key: ' + err)
    )
    return [err, data]
  }

  const stripeKey = data.key

  if (!stripeKey) {
    console.log('[getStripePublishableKey] No Stripe Publishable Key found')
    // ERROR SPK00
    return ['ERROR SPK00']
  }

  let response, error

  try {
    response = await axios({
      url: `https://api.stripe.com/v1/tokens`,
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${stripeKey}`
      },
      data: stringify(mapKeys(card, (_, key) => `card[${snakeCase(key)}]`))
    })
  } catch (e) {
    error = get(e, 'response.data.message', `${err.name}: ${err.message}`)
    console.log('[getStripeToken]', error)
    logger.captureException(new Error(error))
  }

  return [error, response]
}

export const getCheckoutSessionId = async planId => {
  return api.get(`/subscriptions/stripe_checkout_session?plan_id=${planId}`)
}

export const getManageStripeSubscriptionUrl = async planId => {
  return api.get(`/subscriptions/manage_stripe_subscription_url`)
}
