import styled, { css } from 'styled-components'
import colors from 'styles/colors'

export default styled.button.attrs({ type: 'button' })`
  padding: 0.25rem 0.75rem;
  border-radius: 1rem;
  font-weight: 600;
  height: 2rem;
  transition: all 0.25s ease;
  background-color: white;
  cursor: pointer;
  color: ${colors.blackRGBA(0.6)};
  border: 1px solid ${colors.blackRGBA(0.3)};

  &:hover,
  &:focus {
    opacity: 0.9;
  }

  &:active {
    opacity: 0.7;
  }

  ${props =>
    props.blueBorder &&
    css`
      color: ${colors.blue};
      border: 2px solid ${colors.blue};

      &:hover,
      &:focus {
        opacity: 0.7;
        border-color: ${colors.blue};
      }

      &:active {
        opacity: 0.5;
      }
    `} ${props =>
  props.blueBackground &&
    css`
      color: ${colors.white};
      background-color: ${colors.blue};
      border: 2px solid ${colors.blue};

      &:hover,
      &:focus {
        opacity: 0.7;
        border-color: ${colors.blue};
      }

      &:active {
        opacity: 0.5;
      }
    `};

  ${props =>
    props.disabled &&
    css`
      opacity: 0.5;
    `};
`
