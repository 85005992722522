import styled from 'styled-components'

const Button = styled.div`
  background-color: rgb(45, 136, 248);
  color: #FFFFFF;
  width: ${({ width, fluid }) => (fluid ? '100%' : `${width || 100}px`)};
  padding: 18px 0;
  text-align: center;
  ${({ circleBorder }) => (circleBorder ? 'border-radius: 30px;' : '')}
  ${({ bottomBorder }) =>
    bottomBorder
      ? 'border-bottom-right-radius: 12px;border-bottom-left-radius: 12px;'
      : ''}
  font-weight: ${({ bold }) => (bold ? 700 : 400)};
  cursor: pointer;
  &:hover {
    background-color: rgba(45, 136, 248, .9);
  }
`
export default Button
