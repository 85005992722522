import React from 'react'

import debounce from 'lodash/debounce'
import round from 'lodash/round'
import get from 'lodash/get'
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays'

import styled from 'styled-components'

import colors from 'styles/colors'

import { TextInput, ErrorText } from 'components/Input'

import withPricingStore from '../store/pricing'

import { RenderOnActions } from 'utils/StateMachine'

import { getUserPicture, getDefaultPicture } from 'utils/profile-info'

class Price extends React.Component {
  constructor (props) {
    super(props)

    this.loadEstimates = debounce(this.loadEstimates, 1000)

    const { data, activeState } = props

    this.state = {
      price: activeState === 'inputPrice' ? '' : get(data, 'price.night', 0),
      error: false
    }

    this.inputRef = React.createRef()
  }

  get guest () {
    const guest = get(this.props, 'user') || {}
    return {
      ...guest,
      picture: getUserPicture(guest),
      firstName: guest.firstName || (guest.name || '').split(' ')[0]
    }
  }

  get host () {
    const host = get(this.props, 'data.host') || {}

    return {
      ...host,
      picture: host.picture || getDefaultPicture(),
      firstName: host.firstName || (host.name || '').split(' ')[0]
    }
  }

  get roomsLength () {
    return get(this.props, 'data.rooms.length', 0)
  }

  get nights () {
    const { startDate, endDate } = get(this.props, 'data', {})

    return differenceInCalendarDays(endDate, startDate) || 0
  }

  loadEstimates = async price => {
    const { startDate, endDate, guests, rooms } = get(this.props, 'data', {})

    const params = {
      startDate,
      endDate,
      roomIds: rooms.map(r => r.id),
      guests,
      proposedPrice: price
    }

    await this.props.machineStore.transition({ type: 'LOAD', params })
  }

  onPriceChange = e => {
    let { value } = e.target

    if (!value) {
      this.setState({ price: '' })
      return
    }

    value = (value || 0).toString()

    if (value && !value.match(/\./g)) {
      value = parseFloat(value)
      value = Math.abs(value)
    } else if (
      value &&
      value.match(/\./g) &&
      !['.', '0'].includes(value[value.length - 1])
    ) {
      value = round(value, 2)
    }

    value = value.toString()

    if (
      !isNaN(value) &&
      value.length <= 6 &&
      (!value.match(/\./g) || value.match(/\./g).length < 2)
    ) {
      this.setState({ price: value })

      if (value >= 40) {
        this.loadEstimates(value)
      }
    }

    if (value || this.state.error) {
      this.checkError(value)
    }
  }

  checkError = value => {
    value = Number(value)

    if (value && !isNaN(value) && value < 40) {
      this.setState({ error: 'Enter 40 or more' })
    } else {
      this.setState({ error: false })
    }
  }

  resetState = async () => {
    const activeState = get(this.props, 'activeState')

    if (activeState !== 'inputPrice') {
      await this.props.proposalStore.transition({
        type: 'RESET',
        from: 'inputPrice'
      })
    }
  }

  handleNext = async price => {
    const total = get(this.props, 'machineStore.state.data.total', 0)

    await this.resetState()

    if (price >= 40) {
      const params = {
        night: price,
        total
      }

      await this.props.proposalStore.transition({
        type: 'NEXT',
        data: { price: params }
      })
    }
  }

  componentDidMount () {
    if (this.inputRef && this.props.activeState === 'inputPrice') {
      this.inputRef.current.focus()
    }
  }

  static getDerivedStateFromProps (props, state) {
    return {
      total: get(props, 'machineStore.state.data.total', 0)
    }
  }

  componentDidUpdate (prevProps, prevState) {
    const prevTotal = prevState.total
    const total = get(this.props, 'machineStore.state.data.total')

    if (prevTotal !== total && total > 0) {
      this.handleNext(this.state.price)
    }
  }

  render () {
    const data = get(this.props, 'machineStore.state.data', {})
    const { price, error } = this.state

    return (
      <Wrapper onClick={this.resetState}>
        <Container>
          <InputContainer>
            <TextInput
              placeholder='40.00'
              name='stayproposal-night-price'
              className='pointer'
              value={price}
              onChange={this.onPriceChange}
              ref={this.inputRef}
              disabled={this.props.activeState !== 'inputPrice'}
            />
            {!!error && <ErrorText children={error} />}
            <DollarText>$</DollarText>
          </InputContainer>

          <RenderOnActions values={['hideTotals', 'showLoading']}>
            {(hidden, loading) => (
              <ComparisonChart disabled={hidden || loading}>
                <PieChartWrapper>
                  <PieChart />

                  <CompanyText>company</CompanyText>
                  <PicturesContainer>
                    <GuestPicture src={this.guest.picture} />

                    <HostPicture src={this.host.picture} />
                  </PicturesContainer>

                  <PieLeftLine />
                  <PieRightLine />
                  <PieBottomLine />
                </PieChartWrapper>

                <InfoColumn>
                  <InfoText>Rooms: </InfoText>
                  <InfoText>Nights: </InfoText>
                  <InfoText bottom>Total Cost: </InfoText>
                  <InfoText>Company saves: </InfoText>
                  <InfoText>{this.guest.firstName} earns: </InfoText>
                  <InfoText>{this.host.firstName} earns: </InfoText>
                </InfoColumn>

                <ValuesColumn>
                  <ValueText>{this.roomsLength}</ValueText>
                  <ValueText>{this.nights}</ValueText>
                  <ValueText bottom>${data.total}</ValueText>
                  <ValueText>${data.savingsToBusiness}</ValueText>
                  <ValueText>${data.earningsToTraveler}</ValueText>
                  <ValueText>${data.earningsToHost}</ValueText>
                </ValuesColumn>
              </ComparisonChart>
            )}
          </RenderOnActions>
        </Container>
      </Wrapper>
    )
  }
}

const View = styled.div`
  display: flex;
  flex-direction: column;
`

const Wrapper = styled(View)`
  flex: 1;
`

const Container = styled(View)`
  flex: 1;
  padding: 16px;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
`

const InputContainer = styled(View)`
  align-items: center;
  position: relative;
`

// const Input = styled.input.attrs({
//   type: 'number',
// })`
//   font-size: 32px;
//   color: ${colors.blackRGBA(0.8)};
//   width: 148px;
//   height: 56px;
//   padding: 8px 8px 8px 32px;
//   background-color: #fff;
//   border-color: ${props => (props.error ? colors.red : colors.blackRGBA(0.1))};
//   border-width: 1px;
//   border-radius: 6px;
// `

const DollarText = styled.p`
  font-size: 32px;
  color: ${colors.blackRGBA(0.6)};
  position: absolute;
  left: -28px;
  top: 15px;
`

// const HelperLink = styled.TouchableOpacity`
//   margin: 32px 0;
// `

// const HelperLinkText = styled.p`
//   font-family: ${font.Regular};
//   font-size: 14px;
//   color: ${colors.blue};
//   opacity: 0.7;
//   text-align: center;
// `

const ComparisonChart = styled(View)`
  flex-direction: row;
  align-items: center;
  opacity: ${props => (props.disabled ? 0.3 : 1)};
`

const PieChartWrapper = styled(View)`
  width: 130px;
  height: 130px;
  transform: rotate(-15deg);
`

const PieChart = styled(View)`
  width: 130px;
  height: 130px;
  border-radius: 65px;
  border: 1px solid ${colors.blue};
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(15deg);
`

const CompanyText = styled.p`
  font-size: 15px;
  color: ${colors.blue};
  text-align: center;
  margin: 20px 0;
  transform: rotate(15deg);
`

const PicturesContainer = styled(View)`
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
`
const Picture = styled.img`
  width: 42px;
  height: 42px;
  border-radius: 21px;
  margin-top: 5px;
  transform: rotate(15deg);
`
const GuestPicture = styled(Picture)``
const HostPicture = styled(Picture)``

const PieLine = styled(View)`
  width: 1px;
  height: 65px;
  background: ${colors.blue};
`

const PieLeftLine = styled(PieLine)`
  position: absolute;
  top: 17px;
  left: 36px;
  transform: rotate(-60deg);
`

const PieRightLine = styled(PieLine)`
  position: absolute;
  top: 17px;
  right: 36px;
  transform: rotate(60deg);
`

const PieBottomLine = styled(PieLine)`
  position: absolute;
  left: 64px;
  top: 65px;
`

const InfoColumn = styled(View)`
  flex-direction: column;
  margin-left: 16px;
  margin-right: 4px;
`

const InfoText = styled.p`
  font-size: 14px;
  color: ${colors.blackRGBA(0.6)};
  margin-bottom: ${props => (props.bottom ? '16px' : 0)};
`

const ValuesColumn = styled(View)`
  flex-direction: column;
  align-items: center;
`

const ValueText = styled.p`
  font-weight: bold;
  font-size: 14px;
  color: ${colors.blackRGBA(0.8)};
  text-align: center;
  margin-bottom: ${props => (props.bottom ? '16px' : 0)};
`

export default withPricingStore(Price)
