import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import cn from 'classnames'

import StayPalPicture from './StayPalPicture'

import { isYourself } from './utils'
import { getFullName } from 'utils/profile-info'

class MultiPin extends PureComponent {
  constructor (props) {
    super(props)

    this.onLoadImage = this.onLoadImage.bind(this)
    this.onErrorLoadImage = this.onErrorLoadImage.bind(this)

    this.isDragging = false
    this.isMoving = false
    this.isCurrentUser = isYourself(props.stayPal, props.currentUser)

    this.state = {
      pictureLoaded: false,
      pictureInvalid: false
    }
  }

  componentDidMount () {
    this._isMounted = true
    this.checkPictureLoaded()
  }

  componentWillUnmount () {
    this._isMounted = false
    if (this.picture) {
      this.picture.removeEventListener('load', this.onLoadImage)
      this.picture.removeEventListener('error', this.onErrorLoadImage)
    }
  }

  checkPictureLoaded () {
    let pictureLoaded = this.picture ? this.picture.complete : true
    if (this._isMounted) {
      this.setState({ pictureLoaded })
    }

    if (!pictureLoaded && this.picture) {
      this.picture.addEventListener('load', this.onLoadImage)
      this.picture.addEventListener('error', this.onErrorLoadImage)
    }
  }

  onLoadImage () {
    if (this._isMounted) {
      this.setState({
        pictureLoaded: true
      })
    }
  }

  onErrorLoadImage () {
    if (this._isMounted) {
      this.setState({
        pictureLoaded: true,
        pictureInvalid: true
      })
    }
  }

  _onClick = (e, zoomIn = false) => {
    e.stopPropagation()
    // Prevent selecting pin when map is being dragged
    if (this.isDragging) return

    const { active, activeUserID, item, stayPal, onClick } = this.props

    if (
      (!active || activeUserID !== stayPal.id) &&
      !stayPal.is3rdDegreeSp &&
      !this.isCurrentUser
    ) {
      onClick(item, stayPal.id, 15, zoomIn)
    }
  }

  _onMouseEnter = () => {
    this.props.onMouseEnter && this.props.onMouseEnter()
  }

  __onMouseLeave = () => {
    this.props.onMouseLeave && this.props.onMouseLeave()
  }

  __onMouseDown = () => {
    this.isMoving = false
    this.props.onMouseDown && this.props.onMouseDown()
  }

  __onMouseMove = () => {
    this.isMoving = true
    this.props.onMouseMove && this.props.onMouseMove()
  }

  __onMouseUp = () => {
    this.isDragging = this.isMoving
    this.props.onMouseUp && this.props.onMouseUp()
  }

  render () {
    const { stayPal, activeUserID, ...props } = this.props

    const name = getFullName(stayPal)

    return (
      <div
        className={cn('sc-pin SC-ExploreMap-Pin', {
          'SC-ExploreMap-Pin--active':
            props.active && activeUserID === stayPal.id,
          'SC-ExploreMap-Pin--second': !stayPal.friend && !this.isCurrentUser
        })}
        onClick={this._onClick}
        onDoubleClick={e => this._onClick(e, true)}
        onMouseEnter={this._onMouseEnter}
        onMouseLeave={this._onMouseLeave}
        onMouseDown={this._onMouseDown}
        onMouseMove={this._onMouseMove}
        onMouseUp={this._onMouseUp}
      >
        <StayPalPicture
          className='SC-ExploreMap-Pin__avatar'
          stayPal={stayPal}
          alt={name}
          getImgRef={r => {
            this.picture = r
          }}
          isCurrentUser={this.isCurrentUser}
          isPictureInvalid={this.state.pictureInvalid}
          width='61'
          height='61'
        >
          <div className='overlay flex items-center justify-center tc'>
            <span className='overlay-scrolling-text nowrap absolute dib f4 b'>
              {name}
            </span>
          </div>
        </StayPalPicture>
      </div>
    )
  }
}

MultiPin.propTypes = {
  activeHousemateIndex: PropTypes.number,
  unfocused: PropTypes.bool,
  isCommunity: PropTypes.bool,
  onClick: PropTypes.func,
  handleSendMessage: PropTypes.func,
  item: PropTypes.object,
  user: PropTypes.object,
  stayPal: PropTypes.object,
  params: PropTypes.object,
  paymentMethods: PropTypes.array
}

export default MultiPin
