import formatDate from 'date-fns/format'
import { getDefaultPicture } from './profile-info'
import getAssetUrl from './getAssetUrl'
import { isBefore, parseISO } from 'date-fns'
import { get, uniqBy } from 'lodash'
const defaultPictureUri = getAssetUrl(
  'AM/city_picture/1/cjbawzz9k3qcb9iy7qqt',
  { height: 600 }
)
export function formatFlightDeal (deal) {
  return {
    ...deal,
    key: String(deal.id),
    updatedAt: formatDate(new Date(deal.updatedAt), 'MM/dd/yy hh:mm a'),
    firstOutLastBack: getFirstOutLastBack(deal),
    from: getAirportName(deal.fromAirport),
    fromShort: getAirportName(deal.fromAirport, false),
    to: getAirportName(deal.toAirport),
    toShort: getAirportName(deal.toAirport, false),
    toAirportPicture: getToAirportPicture(deal.toAirportPictures),
    potentialHosts: fixPotentialHosts(deal.potentialHosts),
    alternativeDates: sortAltDates(deal.alternativeDates)
  }
}

export function getToAirportPicture (toAirportPictures) {
  if (!Array.isArray(toAirportPictures) || !toAirportPictures[0]) {
    return defaultPictureUri
  }

  if (typeof toAirportPictures[0] !== 'string') {
    return toAirportPictures
      .filter(i => i.url)
      .sort((a, b) => (a.isPrimary ? -1 : b.isPrimary ? 1 : 0))[0].url
  }

  return toAirportPictures[0]
}

function getFirstOutLastBack ({ whenFrom, whenTo }) {
  const fromDates = formatDate(new Date(whenFrom), 'MMM yyyy').split(' ')
  const toDates = formatDate(new Date(whenTo), 'MMM yyyy').split(' ')

  if (fromDates[1] !== toDates[1]) {
    return `${fromDates[0]} (${fromDates[1]}) - ${toDates[0]} (${toDates[1]})`
  }

  if (fromDates[0] === toDates[0]) {
    return `${fromDates[0]} (${fromDates[1]})`
  }

  return `${fromDates[0]} - ${toDates[0]} (${toDates[1]})`
}

const defaultAvatar = getDefaultPicture()

function sortStayPalsWithAvatars (a, b) {
  if (a.cloudinaryImgUrl) {
    if (b.cloudinaryImgUrl) {
      return 0
    }
    return -1
  }

  if (b.cloudinaryImgUrl) {
    return 1
  }

  return 0
}

function fixPotentialHosts (potentialHosts) {
  if (!potentialHosts) {
    return []
  }

  return potentialHosts.sort(sortStayPalsWithAvatars).map(h => ({
    ...h,
    key: String(h.id),
    cloudinaryImgUrl: h.cloudinaryImgUrl || defaultAvatar
  }))
}

function getAirportName (airport, includeIata = true) {
  let name = ''

  if (!get(airport, 'regionalCity')) {
    if (get(airport, 'country.name')) {
      name = airport.country.name
    } else {
      name = airport.name
    }
  }

  if (typeof airport.regionalCity === 'string') {
    name = airport.regionalCity
  }

  if (get(airport, 'regionalCity.name')) {
    name = airport.regionalCity.name
  }

  if (includeIata) {
    name = `${name} (${airport.iataCode})`
  }

  return name
}

function sortAltDates (alternativeDates) {
  if (!Array.isArray(alternativeDates)) {
    return
  }

  // Some alternative dates are coming without price for some reason. This is to remediate that.
  const withPriceOnly = alternativeDates
    .filter(ad => !!ad.price)
    .map(ad => ({
      ...ad,
      key: `${ad.departDate}.${ad.returnDate}.${ad.airline}.${ad.price}`
    }))

  return uniqBy(withPriceOnly, 'key').sort((a, b) =>
    a.departDate === b.departDate
      ? 0
      : isBefore(parseISO(a.departDate), parseISO(b.departDate))
        ? -1
        : 1
  )
}
