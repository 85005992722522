import React from 'react'

import styled from 'styled-components'
import formatDate from 'utils/formatDate'
import parseDate from 'date-fns/parse'
import addDays from 'date-fns/addDays'

import get from 'lodash/get'

import Icon from 'components/Icon'
import ButtonPill from 'components/ButtonPill'

import { DateRangePicker } from 'react-date-range'
import Counter from 'components/Counter'
import LocationSearch from '../components/LocationSearch'

import colors from 'styles/colors'

import MediaQuery from 'react-responsive'

const DATE_FORMAT = 'yyyy-MM-dd'

class Dates extends React.Component {
  today = new Date()

  state = {
    placeDetails: null,
    dates: { startDate: this.today, endDate: addDays(this.today, 1) },
    guests: 1
  }

  componentDidMount () {
    // Get default values from the store
    // If there are dates, also search for location from PlaceSearch store

    const { startDate, endDate, guests } = get(
      this.props,
      'proposalStore.state.data',
      {}
    )

    if (startDate && endDate) {
      console.log({
        from: 'componentDidMount',
        startDate,
        endDate
      })
      this.onSaveDates({ dates: { startDate, endDate } }, DATE_FORMAT)
    }

    if (guests) {
      this.setState({ guests })
    }
  }

  get hasDates () {
    const { startDate, endDate } = this.state.dates

    return !!(startDate && endDate)
  }

  onSaveDates = ({ dates: { startDate, endDate } }, format) => {
    if (format) {
      startDate = parseDate(startDate, format, this.today)
      endDate = parseDate(endDate, format, this.today)
    }

    this.setState({ dates: { startDate, endDate } })
  }

  addGuest = () => {
    this.setState(({ guests }) => ({
      guests: Math.min(guests + 1, 6)
    }))
  }

  removeGuest = () => {
    this.setState(({ guests }) => ({
      guests: Math.max(guests - 1, 1)
    }))
  }

  renderGuestsCounter () {
    const { guests } = this.state

    return (
      <Counter
        value={guests}
        minValue={1}
        maxValue={6}
        decrement={this.removeGuest}
        increment={this.addGuest}
      />
    )
  }

  onPressNext = () => {
    const { dates, guests, placeDetails } = this.state

    const data = {
      startDate: formatDate(dates.startDate, DATE_FORMAT),
      endDate: formatDate(dates.endDate, DATE_FORMAT),
      guests,
      placeDetails
    }

    this.props.proposalStore.transition({ type: 'NEXT', data })
  }

  get selectionRange () {
    const { startDate, endDate } = this.state.dates

    return {
      startDate,
      endDate,
      key: 'dates'
    }
  }

  setPlace = placeDetails => {
    this.setState({ placeDetails })
  }

  render () {
    return (
      <Container>
        <HeadContainer>
          <HeadIcon />

          <HeadTextWrapper>
            <HeadTextTop>Select</HeadTextTop>
            <HeadTextBottom>dates and filters</HeadTextBottom>
          </HeadTextWrapper>
        </HeadContainer>

        <LocationSearch handlePlaceSelect={this.setPlace} />

        <div className='flex mb3 mt4'>
          <label className='mr3'>Guests: </label>
          {this.renderGuestsCounter()}
        </div>

        <MediaQuery minWidth={992}>
          <DateRangePicker
            direction='horizontal'
            months={2}
            minDate={this.today}
            showDateDisplay={false}
            ranges={[this.selectionRange]}
            onChange={this.onSaveDates}
          />
        </MediaQuery>
        <MediaQuery maxWidth={991}>
          <DateRangePicker
            direction='vertical'
            months={2}
            minDate={this.today}
            showDateDisplay={false}
            ranges={[this.selectionRange]}
            onChange={this.onSaveDates}
          />
        </MediaQuery>

        <div>
          <ButtonPill
            children='Search Stay'
            className='mt3 mb3 w-100 w-auto-ns'
            onClick={this.onPressNext}
            disabled={!this.hasDates}
            background={colors.blue}
            hoverBackground={colors.darkBlue}
            border={colors.whiteRGBA(0.1)}
            color={!this.hasDates ? colors.whiteRGBA(0.5) : colors.white}
          />
        </div>
      </Container>
    )
  }
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  padding: 8px 1rem;

  @media (min-width: 992px) {
    padding: 16px 3rem;
  }

  .rdrDefinedRangesWrapper {
    display: none;
  }
`

const HeadContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-top: 32px;
  margin-bottom: 32px;
`

const HeadIcon = styled(Icon).attrs({
  name: 'calendar'
})`
  color: ${colors.blue};
  height: 3.5rem;
  width: 3.5rem;
  margin-right: 18px;
`

const HeadTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const HeadTextTop = styled.p`
  margin-bottom: 0;
  color: ${colors.blackRGBA(0.4)};
  font-size: 1.5rem;
`

const HeadTextBottom = styled.p`
  margin-bottom: 0;
  color: ${colors.blackRGBA(0.7)};
  font-size: 1.5rem;
`

export default Dates
