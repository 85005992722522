import React from 'react'
import PropTypes from 'prop-types'

import { createBlobURL, getUserPicture, cloudinary } from 'utils/profile-info'

class Avatar extends React.Component {
  getURL (originalURL) {
    if (originalURL && typeof originalURL !== 'string') {
      const { url, clearURL } = createBlobURL(originalURL)

      if (this.image && this.image.onload) {
        this.image.onload = () => clearURL()
      }

      return url
    }

    // For some reason some URLs endup not getting https
    // quick hotfix here for that.
    if (
      originalURL.includes('cloudinary') &&
      !originalURL.startsWith('https')
    ) {
      const url = originalURL.replace(/http:/, 'https:')

      return url
    }

    return originalURL
  }

  getDefaultPicture = () => {
    return 'https://res.cloudinary.com/staycircles/image/upload/f_auto,q_auto:best,w_60,c_scale/v1507614625/assets/default-avatar.png'
  }

  render () {
    const {
      user,
      url: imgUrl,
      circle,
      className,
      userCloudinary,
      publicID,
      options,
      getRef,
      ...rest
    } = this.props

    let url = imgUrl || (user && user.cloudinaryImgUrl)
    const cpid = publicID || (user && user.cloudinaryPublicID)

    if (!url) {
      if (userCloudinary && cpid) {
        url = cloudinary.url(cpid, {
          fetch_format: 'auto',
          quality: 'auto:best',
          ...options
        })
      } else {
        url = getUserPicture(user, options)
      }
    }

    const src = this.getURL(url)

    return (
      <img
        src={src || this.getDefaultPicture()}
        width={options.width || '40'}
        // height={options.height || '40'}
        alt='avatar'
        className={`${
          circle ? 'br-100' : ''
        } bg-white ba b--white ${className || ''}`}
        ref={r => {
          this.image = r
          typeof getRef === 'function' && getRef(r)
        }}
        {...rest}
      />
    )
  }
}

Avatar.propTypes = {
  circle: PropTypes.bool,
  user: PropTypes.object,
  url: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  className: PropTypes.string,
  userCloudinary: PropTypes.bool,
  publicID: PropTypes.string,
  options: PropTypes.object,
  getRef: PropTypes.func
}

Avatar.defaultProps = {
  circle: true,
  userCloudinary: true,
  options: {
    width: 60,
    height: 60,
    crop: 'scale'
  }
}

export default Avatar
