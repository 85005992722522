import React from 'react'
import { useNavigate } from '@reach/router'
import QueryString from 'qs'
import PropTypes from 'prop-types'
import Button from '../../components/Button'
import root from 'utils/windowOrGlobal'
import {
  Container,
  Text,
  DivineText,
  Image
} from '../../components/CommonStyles'
import ImageSrc from '../../assets/airport_select.png'

const SelectTypeScreen = ({ page, step }) => {
  const navigate = useNavigate()

  const _onClick = () => {
    navigate(
      `${window.location.pathname}?${QueryString.stringify({
        page,
        step: step + 1
      })}`,
      step,
      page
    )
  }

  const _onClickLocation = () => {
    if (!navigator.geolocation) {
      console.log('Geolocation is not supported by your browser')
      root.alert(
        `Uh oh! An error was encountered while trying to process.
      (Geolocation is not supported by your browser)`
      )
    } else {
      navigator.geolocation.getCurrentPosition(
        position => {
          const lat = position.coords.latitude
          const lng = position.coords.longitude
          navigate(
            `${window.location.pathname}?${QueryString.stringify({
              page,
              step: step + 1
            })}`,
            { state: { lat, lng } }
          )
        },
        () => {
          console.log('Unable to retrieve your location')
          root.alert(
            `Uh oh! An error was encountered while trying to process.
      (Unable to retrieve your location)`
          )
        }
      )
    }
  }

  return (
    <Container>
      <Image src={ImageSrc} />
      <Text>Add your airport(s)</Text>
      <Button width={280} circleBorder bold onClick={_onClickLocation}>
        by your location (GPS)
      </Button>
      <DivineText>or</DivineText>
      <Button width={280} circleBorder bold onClick={_onClick}>
        by address
      </Button>
    </Container>
  )
}

SelectTypeScreen.propTypes = {
  page: PropTypes.string,
  step: PropTypes.number
}

export default SelectTypeScreen
