import get from 'lodash/get'

import api, { getDefaultResponse as apiCall } from 'utils/api'
import apiConfig from 'config/api'
import camelize from 'utils/camelize'

export const getHostingInfo = async () => {
  return api.get('/hosts/hosting_info')
}

export const getHostingPreferences = async () => {
  return api.get('/preferences')
}

export const updateHostingPreferences = async data => {
  // We need to manually decamelize this because
  // camelize function sees businessStaypals2 as business_staypals_2
  // (we want business_staypals2)
  if (data.business) {
    data = {
      business_staypals: get(data, 'business.staypals'),
      business_staypals2: get(data, 'business.staypals2')
    }
  } else {
    data = {
      friendship_staypals: get(data, 'friendship.staypals'),
      friendship_staypals2: get(data, 'friendship.staypals2')
    }
  }

  data = {
    preferences: {
      hosting: data
    }
  }

  const response = await apiCall(apiConfig.post, '/preferences', data)

  return camelize(response)
}
