import React from 'react'

import { get, range } from 'lodash'

import {
  format as formatDate,
  getDaysInMonth,
  setMonth,
  parseISO
} from 'date-fns'

import { required } from 'utils/field-validators'

import Input from 'components/Input'
import Select from 'components/Select'
import PromptModal from './PromptModal'

const validateDay = ({ day, month, year = 2000 }) => {
  if (!day) return 'Required'
  if (month && day > getDaysInMonth(new Date(year, month))) {
    return 'Invalid'
  }
}

const validateYear = year => {
  if (!year) return 'Required'
  const currentYear = formatDate(new Date(), 'yyyy')
  // Can't be less than 13yo and older than 100yo xD
  if (year < 1900 || currentYear - year < 13) return 'Invalid'
}

const formatBirthday = ({ year, month, day }) => {
  const dob = formatDate(new Date(year, month, day), 'yyyy/MM/dd')
  return { dob }
}

const EditBirthdayModal = () => (
  <PromptModal
    title='Edit birthday'
    getInitialValues={user => {
      if (get(user, 'dob')) {
        const parsedDOB = parseISO(user.dob)
        const month = formatDate(parsedDOB, 'MM') - 1
        const day = formatDate(parsedDOB, 'dd')
        const year = formatDate(parsedDOB, 'yyyy')
        return { month, day, year }
      }
    }}
    validateValues={values => ({
      month: required(values.month),
      day: validateDay(values),
      year: validateYear(values.year)
    })}
    formatValues={formatBirthday}
  >
    {submitting => (
      <div className='w-100 flex flex-row justify-between'>
        <div className='w-50'>
          <Select
            id='month'
            name='month'
            placeholder='Month'
            autoComplete='bday-month'
            disabled={submitting}
            renderOptions={() => {
              const today = new Date()
              return range(0, 12).map(month => {
                const date = setMonth(today, month)
                const label = formatDate(date, 'MMMM')
                return (
                  <option key={month} value={month}>
                    {label}
                  </option>
                )
              })
            }}
          />
        </div>

        <div className='w-20'>
          <Input
            id='day'
            name='day'
            label='Day'
            autoComplete='bday-day'
            disabled={submitting}
          />
        </div>

        <div className='w-25'>
          <Input
            id='year'
            name='year'
            label='Year'
            autoComplete='bday-year'
            disabled={submitting}
          />
        </div>
      </div>
    )}
  </PromptModal>
)

export default EditBirthdayModal
