import React from 'react'

import get from 'lodash/get'

import { Form } from 'react-final-form'

import root from 'utils/windowOrGlobal'
import { required } from 'utils/field-validators'
import { RenderOnAction } from 'utils/StateMachine'

import styled from 'styled-components'

import colors from 'styles/colors'

import { AuthProvider } from 'stores/auth'
import CreatePlaceStoreProvider from './store'

import Input, { ErrorText } from 'components/Input'
import ButtonPill from 'components/ButtonPill'
import Icon from 'components/Icon'
import { FullPageLoader } from 'components/Loader'

import AddressSearchField from '../../components/AddressSearchField'

const FormContainer = ({ onSubmit, title, subtitle, children }) => (
  <Form
    onSubmit={(values, _, callback) => onSubmit(values, callback)}
    render={({ handleSubmit, ...formProps }) => (
      <form
        onSubmit={handleSubmit}
        className='center flex flex-column items-center w-100 w-90-ns mw6 mv4'
      >
        <h2 className='black-80 f3 fw6 lh-title tc w-100'>{title}</h2>
        <h3 className='black-60 f4 fw5 lh-copy tc bb b--black-05 mb4 pb4 w-100'>
          {subtitle}
        </h3>

        {children(formProps)}
      </form>
    )}
  />
)

const AddressForm = ({ onSubmit, navigate }) => (
  <FormContainer
    onSubmit={onSubmit}
    title='Add new place'
    subtitle={
      <span>
        Your friends are waiting...
        <br />
        Get on the map
      </span>
    }
  >
    {({ submitting, invalid, submitError, form }) => (
      <React.Fragment>
        <AddressSearchField
          name='address1'
          validate={required}
          disabled={submitting}
          form={form}
        />

        <Input
          name='city'
          label='City'
          validate={required}
          disabled={submitting}
        />

        <div className='w-100 flex flex-row justify-between'>
          <div style={{ width: '48%' }}>
            <Input
              name='state'
              label='State'
              validate={required}
              disabled={submitting}
            />
          </div>

          <div style={{ width: '48%' }}>
            <Input
              name='zipCode'
              label='Zip/Postal'
              validate={required}
              disabled={submitting}
            />
          </div>
        </div>

        <Input
          name='country'
          label='Country'
          validate={required}
          disabled={submitting}
        />

        {invalid && submitError && (
          <ErrorText center style={{ marginBottom: '2rem' }}>
            {submitError}
          </ErrorText>
        )}

        <div className='w-100 flex flex-row justify-between items-center bt b--black-05 mt3 pt4'>
          <CancelButton disabled={submitting} navigate={navigate} />
          <SubmitButton disabled={submitting}>
            {submitting ? 'Saving...' : 'Next'}
          </SubmitButton>
        </div>
      </React.Fragment>
    )}
  </FormContainer>
)

const AparmentNumber = ({ onSubmit, navigateBack }) => (
  <FormContainer
    title='Apartment number?'
    subtitle={
      <span>
        Enter the apartment number (eg. "Apt 12")
        <br />
        or leave the field empty
      </span>
    }
    onSubmit={onSubmit}
  >
    {({ submitting, invalid, submitError }) => (
      <React.Fragment>
        <div className='w-100 w-70-ns'>
          <Input
            name='address2'
            label='Apartment number / unit / suite'
            disabled={submitting}
          />
        </div>

        {invalid && submitError && (
          <ErrorText center style={{ marginBottom: '2rem' }}>
            {submitError}
          </ErrorText>
        )}

        <div className='w-100 flex flex-row justify-between items-center bt b--black-05 mt3 pt4'>
          <CancelButton disabled={submitting} onClick={navigateBack}>
            Back
          </CancelButton>

          <SubmitButton disabled={submitting}>
            {submitting ? 'Saving...' : 'Next'}
          </SubmitButton>
        </div>
      </React.Fragment>
    )}
  </FormContainer>
)

const Success = ({ navigate, placeId, roomId }) => {
  let nextLocation = '../'
  if (placeId) {
    nextLocation = '../' + placeId + '/rooms/' + roomId
  }

  return (
    <div className='center flex flex-column items-center w-100 w-90-ns mw6 mv4'>
      <p>
        <CheckmarkIcon />
      </p>
      <h3 className='black-70 f3 fw5 lh-title tc bb b--black-05 mb4 pb4 w-100'>
        Place added!
      </h3>
      <p className='black-60 f4 tc lh-copy ph4 mb4'>
        We have created a room for you automatically so you can start hosting
        right away!
      </p>

      <SubmitButton type='button' onClick={() => navigate(nextLocation)}>
        Click here to set it up
      </SubmitButton>
    </div>
  )
}

const ErrorMessage = ({ error, onRecover }) => (
  <div className='center flex flex-column items-center w-100 w-90-ns mw6 mv4'>
    <h3 className='black-70 f3 fw5 lh-title tc bb b--black-05 mb4 pb4 w-100'>
      Oops
    </h3>
    <p className='black-60 tc lh-copy mb2'>Something went wrong:</p>

    {error && (
      <ErrorText center style={{ marginBottom: '2rem' }}>
        {error}
      </ErrorText>
    )}

    <SubmitButton type='button' onClick={onRecover}>
      RETRY
    </SubmitButton>
  </div>
)

const AddPlace = ({ navigate, placesLength, onCreatePlace }) => (
  <AuthProvider>
    {authStore => {
      const user = authStore.getCurrentUser()
      return (
        <CreatePlaceStoreProvider>
          {store => {
            const setData = data => {
              const userName =
                get(user, 'firstName') || get(user, 'name', '').split(' ')[0]
              const name = `${userName}'s Place #${(placesLength || 0) + 1}`
              data = { ...data, name }

              store.transition({ type: 'SET_DATA', data })
            }

            const createPlace = data => {
              store.transition({
                type: 'CREATE_PLACE',
                data,
                callback: (_, cb) => onCreatePlace(cb)
              })
            }

            const navigateBack = () => store.transition('BACK')

            const onRecover = () => store.transition('RECOVER')

            const place = get(store, 'state.data')
            const placeId = get(place, 'id', '')
            const roomId = get(place, 'rooms[0].id', '')

            return (
              <div
                className='flex flex-column flex-grow-1 justify-center items-center ph2 ph0-ns mw-100
'
              >
                <RenderOnAction value='showAddressForm'>
                  <AddressForm onSubmit={setData} navigate={navigate} />
                </RenderOnAction>

                <RenderOnAction value='showApartmentForm'>
                  <AparmentNumber
                    onSubmit={createPlace}
                    navigateBack={navigateBack}
                  />
                </RenderOnAction>

                <RenderOnAction value='showLoading'>
                  <FullPageLoader />
                </RenderOnAction>

                <RenderOnAction value='showError'>
                  <ErrorMessage
                    error={get(store, 'state.error')}
                    onRecover={onRecover}
                  />
                </RenderOnAction>

                <RenderOnAction value='showSuccess'>
                  <Success
                    navigate={navigate}
                    placeId={placeId}
                    roomId={roomId}
                  />
                </RenderOnAction>
              </div>
            )
          }}
        </CreatePlaceStoreProvider>
      )
    }}
  </AuthProvider>
)

export const CancelButton = ({ navigate, ...props }) => {
  function navigateBack () {
    // Only go back if previous page won't go out of site
    // Otherwise go back to hosting home
    if (root.history.state) {
      root.history.back()
    } else {
      navigate('../')
    }
  }

  return <ButtonPill onClick={navigateBack} children='Cancel' {...props} />
}

export const SubmitButton = props => (
  <ButtonPill
    type='submit'
    background={colors.blue}
    hoverBackground={colors.darkBlue}
    color={colors.white}
    border={colors.whiteRGBA(0.1)}
    {...props}
  />
)

const CheckmarkIcon = styled(Icon).attrs({ name: 'checkmark' })`
  color: ${colors.blue};
  width: 3rem;
  height: 3rem;
`

export default AddPlace
