import React from 'react'

import Icon from 'components/Icon'

import styled from 'styled-components'

import colors from 'styles/colors'

const ZoomContainer = styled.div`
  position: absolute;
  z-index: 99;
  top: 50%;
  right: 10px;
  transform: (translateY(-50%));

  > a {
    color: black;
    display: block;
    font-size: 12px;
    font-weight: normal;
    text-align: center;
    cursor: pointer;
    width: 25px;
    height: 25px;
    line-height: 26px;
    background-color: white;
    border-radius: 25px;
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.3);

    &:hover {
      color: ${colors.red};
    }
  }

  > a:nth-child(2) {
    margin-top: 1rem;
  }
`

const SymbolIcons = styled(Icon)`
  width: 1rem;
  height: 1rem;
`

class ZoomRange extends React.PureComponent {
  render () {
    const { current, setZoom, wrapperClassName = '' } = this.props

    return (
      <ZoomContainer className={wrapperClassName}>
        <a
          href='#'
          title='Zoom in'
          onClick={() => setZoom(Math.min(current + 1, 18))}
        >
          <SymbolIcons name='plus' />
        </a>

        <a
          href='#'
          title='Zoom out'
          onClick={() => setZoom(Math.max(current - 1, 1))}
        >
          <SymbolIcons name='minus' />
        </a>
      </ZoomContainer>
    )
  }
}

export default ZoomRange
