import React from 'react'

import { snakeCase, get } from 'lodash'

import SPMap from 'app/screens/explore/components/Map'

import { getCurrentUser } from 'stores/auth'
import withStore from 'app/screens/explore/store'

import { RenderOnAction } from 'utils/StateMachine'
import { extractLocation } from 'utils/location'
import root from 'utils/windowOrGlobal'

import styled from 'styled-components'

const Container = styled.div`
  width: 100%;
  height: 100%;
  @media (min-width: 992px) {
    width: 100%;
    height: 100%;
  }
`

class Host extends React.Component {
  shouldComponentUpdate (nextProps) {
    if (
      nextProps !== this.props ||
      nextProps.machineStore.state !== this.props.machineStore.state
    ) {
      return true
    }

    return false
  }

  render () {
    return (
      <Container>
        <RenderOnAction value='showLoading'>
          {loading => (
            <MapWrapper
              loading={loading}
              {...this.props}
              {...this.props.machineStore.state}
            />
          )}
        </RenderOnAction>
      </Container>
    )
  }
}

const query = {}

class MapWrapper extends React.PureComponent {
  constructor (props) {
    super(props)

    this.currentUser = getCurrentUser()

    this.state = {
      zoom: 5
    }
  }

  run = (type, data = {}) => {
    this.props.machineStore.transition({
      type: snakeCase(type).toUpperCase(),
      ...data
    })
  }

  componentDidMount () {
    root.document.body.setAttribute('id', 'search-page')
    root.addEventListener('resize', this.setWindowSize)
    this.setWindowSize()

    const { activePanel } = this.props

    if (activePanel === 'search') {
      this.triggerSearch()
    } else {
      this.run('setActivePanel', {
        panel: 'search',
        isFirstSearch: true
      })
    }
  }

  componentDidUpdate (prevProps) {
    if (
      prevProps.activePanel === 'myCommunity' &&
      this.props.activePanel === 'search'
    ) {
      this.triggerSearch()
    }
  }

  triggerSearch = () => {
    const { placeDetails, guests, startDate, endDate } = get(
      this.props,
      'proposalStore.state.data',
      {}
    )
    const { bookingType } = this.props

    if (placeDetails) {
      root.setTimeout(() => {
        this.run('setLocation', {
          location: extractLocation(placeDetails),
          origin: 'form'
        })
      }, 100)
    }

    this.run('setParams', {
      params: {
        searchFor: bookingType === 'business' ? 'business' : 'fun',
        guests,
        dates: {
          checkIn: startDate,
          checkOut: endDate
        }
      }
    })
  }

  componentWillUnmount () {
    root.document.body.setAttribute('id', '')
    root.removeEventListener('resize', this.setWindowSize)
    root.clearTimeout(this.loadingTimeout)

    if (this.props.activeItem) {
      this.run('setActiveItem')
    }
  }

  setWindowSize = (saveState = true) => {
    const matches =
      root.matchMedia && root.matchMedia('(max-width: 767px)').matches

    if (saveState) {
      this.setState({ isMobile: matches })
    }

    this.run('setMobileSize', matches)

    return matches
  }

  setActivePin = (item, userID, zoom = 15, shouldZoomIn = false) => {
    const { location } = this.props

    if (location.zoom > zoom) {
      zoom = location.zoom
    }

    this.run('setActivePin', {
      itemID: item.id,
      userID,
      location: {
        ...location,
        zoom: shouldZoomIn ? zoom : location.zoom,
        center: {
          lat: item.lat || item.latitude || item.fakeLatitude,
          lng: item.lng || item.longitude || item.fakeLongitude
        }
      },
      subject: 'stay'
    })
  }

  selectHost = host => {
    this.props.proposalStore.transition({
      type: 'NEXT',
      data: { host }
    })
  }

  toggleSearchPreference = preference => {
    const { params, location } = this.props

    this.run('setParams', {
      params: {
        ...params,
        [preference]: !params[preference]
      },
      location,
      form: 'search-inline'
    })
  }

  render () {
    const { params } = this.props

    return (
      <SPMap
        setActivePin={this.setActivePin}
        setupMapInternals={({ map, maps }) => {
          this.map = map
          this.mapsApi = maps
        }}
        searchFor={params.searchFor}
        currentUser={this.currentUser}
        query={query}
        run={this.run}
        selectHost={this.selectHost}
        toggleSearchPreference={this.toggleSearchPreference}
        {...this.props}
      />
    )
  }
}

export default withStore(Host)
