import React from 'react'

import Button from './Button'

import styled from 'styled-components'

import colors from 'styles/colors'

const ErrorMessageView = ({ retry }) => {
  return (
    <ErrorMessageWrapper>
      <ErrorMessageText>
        An error has occured. Make sure your connection is working.
      </ErrorMessageText>
      <Button children='RETRY' color={colors.blue} onClick={retry} />
    </ErrorMessageWrapper>
  )
}

const ErrorMessageWrapper = styled.div`
  display: flex;
  fliex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
`

const ErrorMessageText = styled.p`
  font-size: 16px;
  font-weight: bold;
  color: ${colors.darkGray};
  margin-bottom: 16px;
`

export default ErrorMessageView
