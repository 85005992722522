import React, { Fragment } from 'react'
import get from 'lodash/get'

import { required } from 'utils/field-validators'

import Input from 'components/Input'
import PromptModal from './PromptModal'

const EditNameModal = () => (
  <PromptModal
    title='Edit name'
    getInitialValues={user => ({
      firstName: get(user, 'firstName'),
      lastName: get(user, 'lastName')
    })}
  >
    {submitting => (
      <Fragment>
        <Input
          id='firstName'
          name='firstName'
          label='First name'
          autoComplete='given-name'
          validate={required}
          disabled={submitting}
        />

        <Input
          id='lastName'
          name='lastName'
          label='Last name'
          autoComplete='family-name'
          validate={required}
          disabled={submitting}
        />
      </Fragment>
    )}
  </PromptModal>
)

export default EditNameModal
