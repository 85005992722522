import React from 'react'

import get from 'lodash/get'
import formatDate from 'utils/formatDate'

import { Link } from '@reach/router'

import { AuthProvider } from 'stores/auth'

import { getFullName } from 'utils/profile-info'

import colors from 'styles/colors'

import MatchQueryModal from 'components/MatchQueryModal'

import { UserPicture, EditUserPictureModal } from './components/EditUserPicture'
import EditNameModal from './components/EditNameModal'
import EditBirthdayModal from './components/EditBirthdayModal'
import EditEmailsModal from './components/EditEmailsModal'
import EditPhonesModal from './components/EditPhonesModal'
import EmailVerificationModal from './components/EmailVerificationModal'

const MODALS = [
  { test: /edit_picture/, Component: EditUserPictureModal },
  { test: /edit_name/, Component: EditNameModal },
  { test: /edit_birthday/, Component: EditBirthdayModal },
  { test: /edit_email/, Component: EditEmailsModal },
  { test: /edit_phone/, Component: EditPhonesModal },
  { query: 'confirmation_code', Component: EmailVerificationModal }
]

const ProfileIndex = () => (
  <div className='flex flex-column items-center justify-center center w-100 ph4 mb4'>
    <h1>Account & Profile</h1>

    <div className='mv3 w-100 tc'>
      <UserPicture />
      <ItemsList />
    </div>

    <MatchQueryModal modalsList={MODALS} />
  </div>
)

const ItemsList = () => (
  <AuthProvider>
    {store => {
      const user = store.getCurrentUser()
      const loading = store.state.loading

      const name = getFullName(user)
      const dob = get(user, 'dob') && formatDate(user.dob, 'MMMM dd, yyyy')
      const email = get(user, 'primaryEmail')
      const phone =
        get(user, 'primaryPhone') || get(user, 'phoneNumbers[0].number')

      const FIELDS = [
        { label: 'Name', value: name, link: 'edit_name' },
        { label: 'Birthday', value: dob, link: 'edit_birthday' },
        { label: 'Email', value: email, link: 'edit_email' },
        { label: 'Phone Number', value: phone, link: 'edit_phone' }
      ]

      return FIELDS.map(field => (
        <Link to={`./${field.link}`} className='link' key={field.link}>
          <div
            className='br3 b--black-10 ba pa3 flex flex-row justify-between items-center link black-70 bg-white-80 bg-animate hover-bg-near-white mb3'
            style={{
              cursor: loading ? 'progress' : 'pointer'
            }}
          >
            <div
              className={`flex flex-column items-start ${
                !field.value ? 'pv2' : ''
              }`}
              style={{ maxWidth: '90%' }}
            >
              {(loading || field.value) && (
                <span className='black-50 mb1'>{field.label}</span>
              )}

              <span className='f4 b truncate w-100'>
                {(loading && 'Loading...') ||
                  field.value ||
                  `Add ${field.label}`}
              </span>
            </div>

            <span style={{ color: colors.blue }}>Edit</span>
          </div>
        </Link>
      ))
    }}
  </AuthProvider>
)

export default ProfileIndex
