import React from 'react'

import { createMask } from 'recondition'
import get from 'lodash/get'
import styled from 'styled-components'

import OpenSidebar from 'app/components/OpenSidebar'

import ProposalStoreProvider from './store/proposal'

import Menu from './screens/Menu'
import Dates from './screens/Dates'
import Host from './screens/Host'
import FinalizeStay from './screens/FinalizeStay'

const STEPS = [
  {
    icon: 'calendar',
    labelTop: 'Select',
    labelBottom: 'Dates and filters',
    key: 'selectDates',
    route: 'Dates'
  },
  {
    icon: 'pin-map',
    labelTop: 'Select',
    labelBottom: 'Host on map',
    key: 'selectHost',
    route: 'Host'
  },
  {
    icon: 'better-bed',
    labelTop: 'Choose',
    labelBottom: 'Room',
    key: 'chooseRoom',
    route: 'Room'
  },
  {
    icon: 'money',
    labelTop: 'Input',
    labelBottom: 'Comparable rate',
    key: 'inputPrice',
    route: 'Price',
    business: true
  },
  {
    icon: 'card',
    labelTop: 'Select',
    labelBottom: 'Payment method',
    key: 'selectPayment',
    route: 'Payment',
    business: true
  },
  {
    icon: 'message',
    labelTop: 'Send',
    labelBottom: 'Stay Proposal',
    key: 'reviewProposal',
    route: 'Review'
  }
]

class BookingIndex extends React.PureComponent {
  get steps () {
    const { type } = this.props

    if (type !== 'business') {
      return STEPS.filter(s => !s.business)
    }

    return STEPS
  }

  renderContent = (store, steps) => {
    const { type, user } = this.props

    const Content = createMask({
      state: get(store, 'state.machineState.value')
    })

    return (
      <Content.Switch>
        <Content.Case state='selectDates'>
          <Dates proposalStore={store} />
        </Content.Case>
        <Content.Case state='selectHost'>
          <Host proposalStore={store} bookingType={type} />
        </Content.Case>
        <Content.Default>
          <FinalizeStay
            proposalStore={store}
            user={user}
            steps={steps}
            bookingType={type}
          />
        </Content.Default>
      </Content.Switch>
    )
  }

  render () {
    const { type } = this.props
    const { steps } = this
    const listKey = type === 'business' ? 'bookingBusiness' : 'bookingFriends'

    return (
      <Container>
        <ProposalStoreProvider>
          {store => (
            <div className='h-100-l flex-l db flex-row-l'>
              <OpenSidebar listKey={listKey}>
                <Menu bookingType={type} proposalStore={store} steps={steps} />
              </OpenSidebar>
              <section className='sc-search flex flex-auto h-100 scrollbar overflow-auto'>
                {this.renderContent(store, steps)}
              </section>
            </div>
          )}
        </ProposalStoreProvider>
      </Container>
    )
  }
}

export const Container = styled.div`
  width: 100%;
  height: 100%;
`

export default BookingIndex
