import camelize from './camelize'

/**
 * Parse Google Maps geocoding result into a proper address object
 *
 * @param {Object} place - Google Maps geocoding result
 * @returns {Object} { address, city, state, country, zip_code, latitude, longitude }
 */
function parseAddress (place) {
  if (!place) return {}

  place = camelize(place)

  let fullAddress = {}

  const fields = {
    address: ['street_number', 'route'],
    city: [
      'locality',
      'administrative_area_level_2',
      'postal_town',
      'sublocality_level_1'
    ],
    state: ['administrative_area_level_1'],
    country: ['country'],
    zipCode: ['postal_code']
  }

  const componentForm = {
    street_number: 'shortName',
    route: 'shortName',
    locality: 'longName',
    administrative_area_level_2: 'longName',
    postal_town: 'longName',
    sublocality_level_1: 'longName',
    administrative_area_level_1: 'shortName',
    country: 'longName',
    postal_code: 'shortName'
  }

  const addressComponents = place.addressComponents

  const hasType = type => addressComponents.some(c => c.types.includes(type))

  if (addressComponents && addressComponents[0]) {
    for (let i = 0; i < addressComponents.length; i++) {
      const component = addressComponents[i]
      const addressType = component.types[0]

      if (componentForm[addressType]) {
        const val = component[componentForm[addressType]]
        const field = Object.keys(fields).find(key =>
          fields[key].includes(addressType)
        )

        if (field === 'address') {
          if (addressType === 'route') {
            fullAddress[field] = [fullAddress[field], val].join(' ').trim()
          } else if (addressType === 'street_number') {
            fullAddress[field] = [val, fullAddress[field]].join(' ').trim()
          }
        } else if (field === 'city') {
          if (
            addressType === 'locality' ||
            (!hasType('locality') &&
              addressType === 'administrative_area_level_2') ||
            !fullAddress[field]
          ) {
            fullAddress[field] = val
          }
        } else {
          fullAddress[field] = val
        }
      }
    }

    // Handle `premise` addresses
    if (
      !fullAddress.address &&
      !place.types.includes('street_address') &&
      place.types.includes('premise')
    ) {
      fullAddress.address = handlePremiseAddress(addressComponents)
    }
  }

  const { location } = place.geometry

  if (location) {
    if (typeof location.lat === 'function') {
      fullAddress.latitude = location.lat()
      fullAddress.longitude = location.lng()
    } else {
      fullAddress.latitude = location.lat
      fullAddress.longitude = location.lng
    }
  }

  return fullAddress
}

function handlePremiseAddress (components) {
  const premise = components.find(component =>
    component.types.includes('premise')
  )
  const sublocalities = components.filter(component =>
    component.types.includes('sublocality')
  )

  const getSublocalityLevel = component => {
    return component.types.find(type => type.match(/sublocality_level/g))
  }

  return sublocalities
    .sort((a, b) => {
      // Sort sublocalities because we need then ordered from bigger level to smaller
      const levelA = getSublocalityLevel(a)
      const levelB = getSublocalityLevel(b)
      return levelB.localeCompare(levelA)
    })
    .reduce((prev, curr, i) => {
      return `${prev}${i === 0 ? ' ' : ', '}${curr.longName}`
    }, premise.longName)
}

export default parseAddress
