import logger from 'utils/logger'
import root from 'utils/windowOrGlobal'

export default id => {
  if (typeof id !== 'string') {
    logger.captureException(new Error('getHeight requires an id string'))

    return
  }

  const element = root.document.getElementById(id)

  return element ? element.offsetHeight : 0
}
