import React from 'react'

import { Link, navigate } from '@reach/router'

import * as routes from 'config/routes'

import styled from 'styled-components'

import colors from 'styles/colors'

import Icon from 'components/Icon'

const LIST = [
  { label: 'Account & Profile', link: routes.SETTINGS },
  { label: 'Change Password', link: routes.PASSWORD },
  { label: 'Address Visibility', link: routes.ADDRESS_VISIBILITY },
  { label: 'Notifications', link: routes.NOTIFICATIONS },
  { label: 'StayCircles Subscription', link: routes.PRIME_SUBSCRIPTION },
  { label: 'Transactions', link: routes.TRANSACTIONS },
  { label: 'Remove Friends', link: routes.MAINTENANCE },
  {
    label: 'Permanently Delete Account',
    link: routes.PERMANENTLY_DELETE_ACCOUNT
  }
]

const isActive = ({ isCurrent }) => {
  return isCurrent ? { active: 1 } : null
}

const Sidebar = ({ passwordSet = true }) => {
  const menuList = [...LIST]
  if (!passwordSet) {
    delete menuList[1]
  }
  return (
    <Container>
      <h4 className='f3 pl3'>Settings</h4>
      <PageSelector onChange={e => navigate(e.target.value)}>
        {menuList.map(s => (
          <option key={s.link} value={s.link} children={s.label} />
        ))}
      </PageSelector>
      <SidbarLarge className='br3 overflow-hidden b--black-10 ba sidebar-lg'>
        {menuList.map(item => (
          <Item
            to={item.link}
            key={item.link}
            className='b--black-10 bb pa3 flex flex-row justify-between items-center link black-70 bg-white-80 bg-animate hover-bg-near-white'
            getProps={isActive}
          >
            {item.label}
            <CaretIcon />
          </Item>
        ))}
      </SidbarLarge>
    </Container>
  )
}

const Container = styled.div`
  // margin-top: 2rem;
  margin-left: 2rem;
  margin-right: 2rem;
  // height: 0;
  @media screen and (min-width: 992px) {
    width: 20rem;
    margin-right: 0;
  }
`

const Item = styled(Link)`
  &[active] {
    background: ${colors.offwhite};
    border-right: 2px solid ${colors.blue};
  }

  &:active,
  &:focus {
    outline: 0;
  }
`

const CaretIcon = styled(Icon).attrs({ name: 'caret' })`
  width: 1rem;
  color: ${colors.blackRGBA(0.2)};
  transform: rotate(-90deg);
`

const SidbarLarge = styled.div`
  @media screen and (max-width: 991px) {
    display: none;
  }
`

const PageSelector = styled.select`
  width: 100%;
  padding: 16px;
  background: white;
  border: 1px solid #c9c9ca;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 14px;
  margin-bottom: 30px;

  @media screen and (min-width: 992px) {
    display: none;
  }
`

export default Sidebar
