import React from 'react'

import Modal from 'components/Modal'
import { TextInput } from 'components/Input'

import colors from 'styles/colors'

import ButtonPill from 'components/ButtonPill'

class ConfirmDeleteModal extends React.Component {
  state = {
    password: ''
  }

  setPassword = e => {
    this.setState({
      password: e.target.value
    })
  }

  render () {
    const { show, close, error, destroy, loading } = this.props
    const { password } = this.state

    return (
      <Modal isOpen={show} onRequestClose={close} contentLabel='Login'>
        <div className='center flex flex-column items-center w-100 w-90-ns mb0'>
          <h3 className='mt0 mb3'>Permanently Delete Account</h3>

          <div className='mv4'>
            <p className='tc mb4 lh-copy'>
              Deleting your account is irreversible.
              <br />
              If you sent any StayCircles invitations they will become invalid.
              <br />
              <br />
              Enter your password to confirm you want to permanently delete this
              account:
            </p>

            {!!error && (
              <p
                className='tc lh-copy b mt4 mb3'
                style={{ color: colors.darkRed }}
              >
                {error}
              </p>
            )}

            <div className='form-group flex justify-center'>
              <TextInput
                small
                type='password'
                onChange={this.setPassword}
                placeholder='Password'
                readOnly={loading}
              />
            </div>
          </div>

          <div className='tc'>
            <ButtonPill onClick={close} children='Cancel' className='mr3' />

            <ButtonPill
              onClick={() => {
                if (!password) return
                destroy(password)
              }}
              children='Delete'
              background={colors.darkRed}
              hoverBackground={colors.red}
              color={colors.white}
              border={colors.whiteRGBA(0.1)}
              style={{
                cursor: loading ? 'not-allowed' : 'pointer'
              }}
              disabled={loading}
            />
          </div>
        </div>
      </Modal>
    )
  }
}

export default ConfirmDeleteModal
