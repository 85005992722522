import React, { Fragment } from 'react'
import { Link } from '@reach/router'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { format } from 'date-fns'

import { NavigateButton } from '../../components/CommonStyles'
import TypeButton from './TripType'
import PotentialHosts from '../PotentialHosts'
import HiddenDeal from '../HiddenDeal'
import { PRIME_DEAL_TYPES, PRIME_LOGOS } from '../../config'
import ExpiredIconSrc from '../../assets/expired.png'

const normalizeCurrency = currencyString =>
  currencyString ? currencyString.replace(/\(USD\)/g, '') : ''
const formatTime = date => format(new Date(date), 'MMM (yyyy)')

const TripCard = ({
  type = 'deals',
  airlines,
  alternativeDates,
  dealImage,
  dealType,
  price,
  subjectPrice,
  normalPrice,
  fromAirport,
  toAirport,
  whenFrom,
  fromShort,
  toShort,
  whenTo,
  fromDest,
  toDest,
  potentialHosts = [],
  destinationName = '',
  destinationInfo = {},
  toAirportPictures = [],
  toAirportPicture,
  active,
  updatedAt,
  primeType,
  id,
  visibleOnAm,
  firstOutLastBack,
  // specific for destination with friends & recommend visit
  singleSharedDesId,
  onSelectFriends,
  onRecommend,
  potentialTraveller,
  visitId,
  creatingChat
}) => {
  // const primaryImage = toAirportPictures.find(apPic => apPic.isPrimary)
  const dealData = {
    type,
    airlines,
    alternativeDates,
    dealImage,
    dealType,
    price,
    subjectPrice,
    normalPrice,
    fromAirport,
    toAirport,
    whenFrom,
    fromShort,
    toShort,
    whenTo,
    fromDest,
    toDest,
    potentialHosts,
    destinationName,
    destinationInfo,
    toAirportPictures,
    active,
    updatedAt,
    primeType,
    id
  }
  const destinationNameText =
    destinationName || toAirport?.regionalCity?.name || 'New York'

  const formattedFromTime = formatTime(whenFrom)
  const formattedArriveTime = formatTime(whenTo)

  const isPFCDeal = primeType === 'first_class_deal'

  const _renderPriceBlock = () => {
    const priceValue = normalizeCurrency(price)
    const normalPriceValue = normalizeCurrency(normalPrice)

    return (
      <div className='card-price'>
        <span className='card-price--deal'>{priceValue}</span>
        {type === 'deals' && (
          <span className='card-price--normal'>{normalPriceValue}</span>
        )}
      </div>
    )
  }

  // const _renderExpiredDestinationBlock = () => {
  //   const destinationText = `${destinationNameText}${
  //     price && (type === 'deals' || type === 'recommend')
  //       ? ` - ${normalizeCurrency(price)}`
  //       : ''
  //   }`
  //   return <div className='card-destination'>{destinationText}</div>
  // }

  const fromDesText = fromDest || fromAirport?.iataCode
  const toDesText = toDest || toAirport?.iataCode

  const _renderCardContent = () => (
    <Fragment>
      <div className='card-content'>
        <div className='card-destination'>{destinationNameText}</div>
        {price &&
          (type === 'deals' || type === 'recommend') &&
          _renderPriceBlock()}
        <p className='card-info'>
          {fromDesText} - {toDesText} / {formattedFromTime} -{' '}
          {formattedArriveTime}
        </p>
        {type === 'deals' && <TypeButton dealType={dealType} />}
      </div>
      {type !== 'recommend' ? (
        <div className='potential-hosts-wrapper'>
          <PotentialHosts
            potentialHosts={potentialHosts}
            destination={destinationNameText}
            type={type}
            onSelectFriends={() =>
              onSelectFriends({
                destinationInfo,
                formattedFromTime,
                formattedArriveTime,
                potentialHosts,
                singleSharedDesId
              })
            }
          />
        </div>
      ) : (
        <NavigateButton
          margin='16px 0 30px 30px'
          onClick={() =>
            onRecommend({ flightDealID: id, potentialTraveller, visitId })
          }
        >
          {creatingChat === visitId
            ? 'Recommending Visit...'
            : 'Recommend Visit'}
        </NavigateButton>
      )}
    </Fragment>
  )
  const _renderHiddenCardContent = () => (
    <Fragment>
      <div className='card-content hidden'>
        <p className='card-info hidden'>Sent at {updatedAt}</p>
        <div className='card-destination'>(Hidden deal) for {subjectPrice}</div>
        <p className='card-info hidden'>
          {firstOutLastBack}
          <br />
          {fromDesText} - XX
        </p>
      </div>
    </Fragment>
  )
  return (
    <CardWrapper active={active}>
      {((primeType !== PRIME_DEAL_TYPES.FREE_DEAL && PRIME_LOGOS[primeType]) ||
        !active) && (
        <div className='prime-type'>
          {PRIME_LOGOS[primeType] && type === 'deals' && (
            <div>{isPFCDeal ? 'Jetsetter' : 'Adventurer'}</div>
          )}
          {/* {!active && <span>Expired</span>} */}
        </div>
      )}
      {(type === 'shared' || type === 'recommend') && active && (
        <div className='shared-destination-live'>Live</div>
      )}
      <CardImage
        src={toAirportPicture}
        // inActive={!active}
      />
      {active && type === 'deals' ? (
        <CardLink to={`/app/adventure/deals/${id}`} state={dealData}>
          {visibleOnAm ? _renderCardContent() : _renderHiddenCardContent()}
        </CardLink>
      ) : active ? (
        <Fragment>{_renderCardContent()}</Fragment>
      ) : (
        <>
          <ExpiredOverlay>
            <img src={ExpiredIconSrc} />
            <p className='title'>Expired</p>
            <p>
              The airlines increased prices.
              <br />
              This flight deal alert has expired.
            </p>
          </ExpiredOverlay>
          <Fragment>{_renderCardContent()}</Fragment>
        </>
        // <ExpireContent>
        //   {_renderExpiredDestinationBlock()}
        //   <div className='card-info'>
        //     {formattedArriveTime}{' '}
        //     {type !== 'deals' || type !== 'recommend'
        //       ? ` - ${formattedArriveTime} `
        //       : ''}
        //     | {fromDesText} - {toDesText}
        //   </div>
        // </ExpireContent>
      )}
      {type === 'deals' && visibleOnAm === false && active && <HiddenDeal />}
    </CardWrapper>
  )
}

const CardWrapper = styled.div`
  // width: 100%;
  height: auto;
  background: #ffffff;
  border: 1px solid #f2f2f2;
  border-radius: 12px;
  margin: 0 0.5rem 30px;
  position: relative;
  min-height: ${({ active }) => (active ? '' : '100px')};
  @media screen and (min-width: 992px) {
    width: 551px;
    margin: 0 auto 30px;
  }
  .prime-type {
    z-index: 12;
    background-color: #ffffff;
    position: absolute;
    top: 30px;
    right: 0;
    border-radius: 12px 0px 0px 12px;
    padding: 11px 11px 11px 10px;
    & > span {
      display: inline-block;
      background: rgba(0, 0, 0, 0.1);
      border-radius: 100px;
      padding: 3px 12px;
      margin-left: 5px;
    }
  }
  .shared-destination-live {
    z-index: 12;
    background-color: #ffffff;
    position: absolute;
    top: 30px;
    right: 0;
    text-transform: uppercase;
    color: #ff0000;
    font-style: italic;
    border-radius: 16px 0px 0px 16px;
    padding: 11px 14px 11px 14px;
  }
  .card-content {
    padding: 25px 0 0 30px;
    font-weight: 700;
    position: relative;
    .card-destination {
      font-size: 1.5rem;
      color: #1a365e;
    }
    .card-price {
      &--deal {
        font-size: 2rem;
        color: #1a365e;
      }
      &--normal {
        color: #999999;
        text-decoration: line-through;
        margin-left: 0.5rem;
      }
    }
    .card-info {
      margin-top: 5px;
      color: #6c7982;
    }
    .card-info.hidden {
      margin-bottom: 5px;
    }
    &.hidden {
      padding-bottom: 30px;
    }
  }
  .potential-hosts-wrapper {
    padding: 40px 30px 30px;
  }
`
const CardImage = styled.div`
  width: 100%;
  height: ${({ inActive }) => (inActive ? '100px' : '215px')};
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  background-image: url(${({ src }) => src});
  background-size: cover;
  background-position: center;
  ${({ inActive }) =>
    inActive ? 'border-radius: 12px; filter: brightness(0.8);' : ''}
`
// const ExpireContent = styled.div`
//   width: 100%;
//   height: 100%;
//   position: absolute;
//   top: 0;
//   left: 0;
//   z-index: 10;
//   background: linear-gradient(
//     270deg,
//     rgba(0, 0, 0, 0.67) 0%,
//     rgba(0, 0, 0, 0.38) 100%
//   );
//   border-radius: 12px;
//   padding: 32px 0 32px 30px;
//   .card-destination,
//   .card-info {
//     color: #ffffff;
//     text-decoration-line: line-through;
//   }
//   .card-destination {
//     font-size: 1.2em;
//   }
//   .card-info {
//     font-size: 10px;
//   }
// `

const ExpiredOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 14;
  background-color: rgba(255, 255, 255, 0.75);
  text-align: center;
  padding-top: 1.4rem;
  color: #000000;
  line-height: 20px;
  font-weight: 700;
  .title {
    font-size: 1.6em;
    margin-bottom: 0rem;
    line-height: 48px;
  }
`

const CardLink = styled(Link)`
  text-decoration: none;
`

TripCard.propTypes = {
  dealImage: PropTypes.string,
  dealType: PropTypes.string,
  destinationName: PropTypes.string,
  destinationInfo: PropTypes.object,
  primeType: PropTypes.string,
  price: PropTypes.string,
  subjectPrice: PropTypes.string,
  normalPrice: PropTypes.string,
  fromAirport: PropTypes.object,
  toAirport: PropTypes.object,
  whenFrom: PropTypes.string,
  fromShort: PropTypes.string,
  whenTo: PropTypes.string,
  toShort: PropTypes.string,
  fromDest: PropTypes.string,
  toDest: PropTypes.string,
  type: PropTypes.string,
  updatedAt: PropTypes.string,
  toAirportPictures: PropTypes.array,
  toAirportPicture: PropTypes.string,
  airlines: PropTypes.array,
  alternativeDates: PropTypes.array,
  potentialHosts: PropTypes.array,
  active: PropTypes.bool,
  id: PropTypes.number,
  singleSharedDesId: PropTypes.number,
  onSelectFriends: PropTypes.func,
  onRecommend: PropTypes.func,
  potentialTraveller: PropTypes.object,
  visitId: PropTypes.number,
  creatingChat: PropTypes.number,
  visibleOnAm: PropTypes.bool,
  firstOutLastBack: PropTypes.string
}

export default TripCard
