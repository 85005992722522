import React from 'react'

import { navigate } from 'gatsby'

import * as routes from 'config/routes'

import colors from 'styles/colors'

import Modal from 'components/Modal'
import ButtonPill from 'components/ButtonPill'

const CannotDeleteModal = ({ show, close, reason }) => {
  const ReasonContent = {
    Text: () => <span />,
    action: () => {},
    actionLabel: ''
  }

  switch (reason) {
    case 'ownedPlacesWithHousemates':
      ReasonContent.Text = () => (
        <span>
          You can't delete your account yet because you have places with
          housemates in it. You must first remove the place's housemates or
          transfer the ownership of them to one of your housemates.
        </span>
      )
      ReasonContent.action = () => navigate(`${routes.HOSTING_BUSINESS}`)
      ReasonContent.actionLabel = 'Go to Places & Rooms page'
      break

    case 'pendingTransactions':
      ReasonContent.Text = () => (
        <span>
          You can't delete your account yet because you have pending
          transactions. You must first add a payout method to your account to
          complete your pending payout transactions. <br />
          If you've already added an payout method, please wait a few minutes
          and try again. If it still doesn't work please get in touch with us
          via our chat at the bottom right corner of the screen.
        </span>
      )
      ReasonContent.action = () => navigate(`${routes.PAYOUT}`)
      ReasonContent.actionLabel = 'Go to Payout Methods page'
      break

    default:
      ReasonContent.Text = () => (
        <span>
          You can't delete your account yet because you have ongoing or upcoming
          stays. You must first either cancel or complete all your ongoing and
          upcoming stays.
        </span>
      )
      ReasonContent.action = () => navigate(`${routes.STAYS}`)
      ReasonContent.actionLabel = 'Go to Stays page'
      break
  }

  return (
    <Modal isOpen={show} onRequestClose={close} contentLabel='Login'>
      <div className='center flex flex-column items-center w-100 w-90-ns mb0'>
        <h3 className='mt0 mb3'>Could not delete account</h3>

        <div className='mv4'>
          <p className='tc lh-copy'>
            <ReasonContent.Text />
          </p>
        </div>

        <div className='tc'>
          <ButtonPill onClick={close} children='Cancel' className='mr3' />

          <ButtonPill
            onClick={() => {
              close()
              ReasonContent.action()
            }}
            children={ReasonContent.actionLabel}
            background={colors.blue}
            hoverBackground={colors.darkBlue}
            color={colors.white}
            border={colors.whiteRGBA(0.1)}
          />
        </div>
      </div>
    </Modal>
  )
}

export default CannotDeleteModal
