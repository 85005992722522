import React from 'react'

import get from 'lodash/get'

import Loader from 'components/Loader'

import StayItem from './StayItem'

import StaysProvider from '../store'

class StaysList extends React.Component {
  render () {
    return (
      <StaysProvider type={this.props.type}>
        {store => {
          let list = get(store, 'state.data.stays', [])

          const { filter } = this.props

          if (filter !== 'All') {
            list = list.filter(item => {
              if (filter === 'Hosting') {
                return item.type === 'Hosting'
              } else {
                return item.type === 'Staying'
              }
            })
          }

          if (store.matchesActions('showLoading')) {
            return (
              <div className='w-100 flex justify-center items-center mt4'>
                <Loader />
              </div>
            )
          }

          if (!list.length) {
            return (
              <div className='w-100 mt5'>
                <p className='tc f3'>No stays found.</p>
              </div>
            )
          }

          return (
            <div className='w-100'>
              {list.map(item => (
                <StayItem
                  key={item.id}
                  item={item}
                  getReceiptUrl={store.getReceiptUrl}
                  cancelStay={() => {
                    store.transition({
                      type: 'HANDLE_PROPOSAL',
                      id: item.id,
                      action: 'cancel'
                    })
                  }}
                />
              ))}
            </div>
          )
        }}
      </StaysProvider>
    )
  }
}

export default StaysList
