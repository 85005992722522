/* Forked from https://github.com/ngokevin/react-file-reader-input */
import * as React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

import root from 'utils/windowOrGlobal'

export default class FileInput extends React.Component {
  static propTypes = {
    as: PropTypes.oneOf(['binary', 'buffer', 'text', 'url']),
    children: PropTypes.any,
    onChange: PropTypes.func,
    accept: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object
  }

  static defaultProps = {
    accept: 'image/*',
    as: 'url'
  }

  constructor (props) {
    super(props)

    // FileReader compatibility warning.
    if (!root.File || !root.FileReader || !root.FileList || !root.Blob) {
      console.warn(
        '[components/FileInput.js] Some file APIs detected as not supported.' +
          ' File reader functionality may not fully work.'
      )
    }
  }

  handleChange = e => {
    const files = Array.prototype.slice.call(e.target.files) // Convert into Array
    const readAs = this.props.as.toLowerCase()

    // Build Promise List, each promise resolved by FileReader.onload.
    Promise.all(
      files.map(
        file =>
          new Promise((resolve, reject) => {
            let reader = new root.FileReader()

            reader.onload = result => {
              // Resolve both the FileReader result and its original file.
              resolve([result, file])
            }

            // Read the file with format based on this.props.as.
            switch (readAs) {
              case 'binary': {
                reader.readAsBinaryString(file)
                break
              }
              case 'buffer': {
                reader.readAsArrayBuffer(file)
                break
              }
              case 'text': {
                reader.readAsText(file)
                break
              }
              case 'url': {
                reader.readAsDataURL(file)
                break
              }
            }
          })
      )
    ).then(zippedResults => {
      // Run the callback after all files have been read.
      this.props.onChange(e, zippedResults)
    })
  }

  triggerInput = () => {
    const input = ReactDOM.findDOMNode(this._reactFileReaderInput)
    if (input) {
      input.click()
    }
  }

  render () {
    const { as, children, className = '', style, ...props } = this.props

    // If user passes in children, display children and hide input.
    const hiddenInputStyle = children
      ? { position: 'absolute', top: '-9999px' }
      : {}

    return (
      <div
        className={`file-input ${className}`}
        onClick={this.triggerInput}
        style={style}
      >
        <input
          {...props}
          type='file'
          ref={c => {
            this._reactFileReaderInput = c
          }}
          onChange={this.handleChange}
          onClick={() => {
            this._reactFileReaderInput.value = null
          }}
          style={hiddenInputStyle}
        />
        {children}
      </div>
    )
  }
}
