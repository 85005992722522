import React from 'react'

import get from 'lodash/get'

import formatDate from 'utils/formatDate'
import differenceInDays from 'date-fns/differenceInDays'
import pluralize from 'pluralize'

import { Link } from '@reach/router'
import ReactModal from 'react-modal'

import {
  getUserPicture,
  getFullName,
  getPlaceAddress
} from 'utils/profile-info'
import navigateBack from 'utils/navigateBack'

import styled from 'styled-components'

import colors from 'styles/colors'
import zIndex from 'styles/zIndex'

import Loader from 'components/Loader'
import Icon from 'components/Icon'

import StaysProvider from '../store'

import RoomItem from './RoomItem'
import ActionButtons from './ActionButtons'
import PaymentMethodsModal from './PaymentMethodsModal'

ReactModal.setAppElement('#___gatsby')

class StayDetails extends React.Component {
  state = {
    statusChanged: false
  }

  navigateBack = () => {
    const { location, navigate, skipReload } = this.props
    let path = location.pathname

    if (skipReload) {
      navigateBack()
      return
    }

    if (this.state.statusChanged) {
      // Hack to reload stays list
      if (path.indexOf('/index') > -1) {
        path = path.replace('/index', '')
      } else {
        path = path + '/index'
      }
    }

    navigate(path)
  }

  render () {
    return (
      <StaysProvider shouldLoadStays={false}>
        {store => {
          return (
            <ModalComponent
              style={{
                overlay: {
                  backgroundColor: colors.whiteRGBA(0.4),
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-end',
                  zIndex: zIndex.modal
                }
              }}
              isOpen
              contentLabel='Stay Details'
              onRequestClose={() => {
                this.navigateBack()
                store.transition('CLOSE_DETAILS')
              }}
            >
              <StayDetailsModal
                {...this.props}
                data={get(store, 'state.stayDetails', {})}
                loading={store.matchesActions('showLoadingStayDetails')}
                load={() => {
                  store.transition({
                    type: 'LOAD_STAY_DETAILS',
                    id: this.props.itemId,
                    convoId: this.props.convoId
                  })
                }}
                loadingStatus={store.matchesActions('loadingStatus')}
                handleProposal={(action, data = {}) => {
                  this.setState({ statusChanged: true })
                  store.transition({
                    type: 'HANDLE_PROPOSAL',
                    id: this.props.itemId,
                    action,
                    ...data
                  })
                }}
              />
            </ModalComponent>
          )
        }}
      </StaysProvider>
    )
  }
}

class StayDetailsModal extends React.Component {
  state = {
    showPaymentMethods: false
  }

  togglePaymentMethods = () =>
    this.setState({
      showPaymentMethods: !this.state.showPaymentMethods
    })

  componentDidMount () {
    this.props.load()
  }

  componentWillUnmount () {
    this.setState({ showPaymentMethods: false })
  }

  get data () {
    return this.props.data
  }

  get name () {
    const name = get(this.data, 'name', '')
    return name.split('Proposal for ').join('Proposal for\n')
  }

  get startDate () {
    const startDate = get(this.data, 'startDate', 0)
    return new Date(startDate)
  }

  get endDate () {
    const endDate = get(this.data, 'endDate', 0)
    return new Date(endDate)
  }

  get dates () {
    const start = formatDate(this.startDate, 'E, MM/dd/yy')
    const end = formatDate(this.endDate, 'E, MM/dd/yy')

    const month = formatDate(this.startDate, 'E').toUpperCase()
    const day = formatDate(this.startDate, 'dd')
    const calendar = { month, day }

    return { calendar, start, end }
  }

  get nights () {
    const number = differenceInDays(this.endDate, this.startDate)
    const text = pluralize('night', number)

    // eg. "2 nights"
    return `${number} ${text}`
  }

  get price () {
    if (!this.data.pricePerNight) return

    return {
      night: this.data.pricePerNight,
      host: this.data.hostPayout,
      traveler: this.data.travellerPayout
    }
  }

  get placeAddress () {
    const place = get(this.data, 'placeDetails', {})
    const address = { ...place }
    address.zipCode = address.zip

    const { lat, lng } = place

    const link = `/app/explore?type=explore_map&to_lat=${lat}&to_long=${lng}&zoom=12`

    return {
      ...getPlaceAddress(address),
      link
    }
  }

  render () {
    if (this.props.loading) {
      return (
        <div className='w-100 flex justify-center items-center mt4'>
          <Loader />
        </div>
      )
    }

    const isBusiness = this.data.type === 'business'

    return (
      <div>
        <p className='fw6 mb4' style={{ fontSize: 32 }}>
          Stay Details
        </p>

        <div className='flex flex-row items-baseline bb b--black-10 pb4 mb4'>
          <div
            className='flex flex-column justify-center items-start'
            style={{ minWidth: 52 }}
          >
            <span className='fw6' style={{ color: colors.red }}>
              {this.dates.calendar.month}
            </span>
            <span className='black-70 f3 fw6'>{this.dates.calendar.day}</span>
          </div>

          <p className='f3 lh-title mb0' style={{ whiteSpace: 'pre-line' }}>
            {this.name}
          </p>
        </div>

        <div className='flex flex-row mb3 black-80'>
          <Icon
            name='calendar'
            width={20}
            height={20}
            color={colors.blackRGBA(0.5)}
            className='mr2'
          />
          <span>
            {this.dates.start} - {this.dates.end} ({this.nights})
          </span>
        </div>

        {this.price && (
          <div className='flex flex-row mb3 black-80'>
            <Icon
              name='money'
              width={20}
              height={20}
              color={colors.blackRGBA(0.5)}
              className='mr2'
            />
            <span>
              US$
              {this.price.night} per night
            </span>
          </div>
        )}

        <div className='flex flex-row mb4 black-80'>
          <Icon
            name='pin'
            width={20}
            height={20}
            color={colors.blackRGBA(0.5)}
            className='mr2'
          />
          <div className='flex flex-column black-80'>
            <span className='fw6'>
              {get(this.data, 'placeDetails.name', '')}
            </span>
            <span className='lh-copy'>{this.placeAddress.firstLine}</span>
            <span className='lh-copy'>{this.placeAddress.secondLine}</span>
            <Link
              to={this.placeAddress.link}
              className='link dim'
              style={{ color: colors.blue }}
            >
              Open map <span className='f3'>→</span>
            </Link>
          </div>
        </div>

        <div className='flex flex-column flex-row-l gap2 items-start items-center-l black-60 mb5'>
          <div className='flex flex-column items-center mr3'>
            <Picture
              src={getUserPicture(this.data.host)}
              alt='Host picture'
              className='mb2'
            />
            <div className='tc'>
              <span className='fw6 db truncate'>
                {getFullName(this.data.host)}
              </span>
              <span className='db'>Host</span>
              {isBusiness && (
                <span className='db mt2 f6 green'>
                  Payout: ${this.price.host}
                </span>
              )}
            </div>
          </div>

          <div className='flex flex-column items-center'>
            <Picture
              src={getUserPicture(this.data.traveller)}
              alt='Traveler picture'
              className='mb2'
            />
            <div className='tc'>
              <span className='fw6 db truncate'>
                {getFullName(this.data.traveller)}
              </span>
              <span className='db'>
                {isBusiness ? 'Business ' : ''}
                Traveler
              </span>
              {isBusiness && (
                <span className='db mt2 f6 green'>
                  Payout: ${this.price.traveler}
                </span>
              )}
            </div>
          </div>

          <div className='ml4-l'>
            <ActionButtons
              data={this.data}
              loading={this.props.loadingStatus}
              accept={() => this.props.handleProposal('accept')}
              decline={() => this.props.handleProposal('decline')}
              cancel={() => this.props.handleProposal('cancel')}
              showPaymentMethods={() =>
                this.setState({ showPaymentMethods: true })
              }
            />
          </div>
        </div>

        <div className='flex flex-column items-center'>
          <p className='fw6 black-80 tc'>Booked Rooms(s)</p>

          <div>
            {get(this.data, 'rooms', []).map(room => (
              <RoomItem key={room.id} {...room} />
            ))}
          </div>
        </div>

        <PaymentMethodsModal
          retryPayment={(paymentMethodId, callback) => {
            this.props.handleProposal('retry', { paymentMethodId, callback })
          }}
          show={this.state.showPaymentMethods}
          onClose={this.togglePaymentMethods}
        />
      </div>
    )
  }
}

const ModalComponent = styled(ReactModal).attrs({
  className: 'scrollbar'
})`
  width: 600px;
  min-height: 100%;
  overflow-y: auto;
  padding: 4rem;
  background-color: #fff;
  box-shadow: -4px 0 8px 0 rgba(0, 0, 0, 0.1);

  &:focus {
    outline: none;
  }

  @media (max-width: 767px) {
    width: 96%;
  }

  .gap2 {
    gap: 2rem;
  }
`

const Picture = styled.img`
  box-shadow: inset 0px 0px 2px 0 rgba(0, 0, 0, 0.2);
  border-radius: 45px;
`

export default StayDetails
