import api from 'utils/api'

/**
 * const proposalData = {
    stay_for: business | fun,
    start_date: yyyy/MM/DD,
    end_date: yyyy/MM/DD,
    guests: Number,
    proposed_stay_ids: Array(ID),
    price_proposal_type: 'comparable' | 'actual',
    price_per_night: Number,
    payment_method_id: ID,
    host_id: ID
  }
 */

export const createStayProposal = async data => {
  return api.post('/stays', data)
}
