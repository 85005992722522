import pluralize from 'pluralize'

import root from 'utils/windowOrGlobal'
import logger from 'utils/logger'

import api from 'utils/api'

export async function getPlaces () {
  return api.get('/places')
}

export async function createPlace (place) {
  return api.post('/places', { place })
}

export async function updatePlace (place) {
  return api.put('/places/' + place.id, { place })
}

export async function setPrimaryPlace (id) {
  return api.put('/places/' + id + '/set_primary_residence')
}

export async function deletePlace (id) {
  const lookup = await deletePlaceLookup(id)

  if (!lookup) return [null, false]

  logger.captureBreadcrumb({
    message: 'EditPlace.delete',
    category: 'hosting',
    data: { id }
  })

  try {
    const [error] = await api.delete('/places/' + id)

    if (error) {
      throw new Error(error)
    } else {
      root.alert('Place deleted successfully')

      return [null, true]
    }
  } catch (error) {
    logger.captureException(error)

    const errorStr = Array.isArray(error) ? error[0] : error

    root.alert(`Uh oh! \n ${errorStr}`)

    return [null, false]
  }
}

export async function deletePlaceLookup (id) {
  logger.captureBreadcrumb({
    message: 'Places API deletePlaceLookup',
    category: 'hosting',
    data: { id }
  })

  const [err, lookup] = await api.get('/places/' + id + '/destroy_lookup')

  if (err) {
    logger.captureException(err)

    const errorStr = Array.isArray(err) ? err[0] : err

    root.alert(`Uh oh! \n ${errorStr}`)

    return false
  }

  let stays = []

  if (lookup) {
    stays = Object.keys(lookup).reduce(
      (all, key) => all.concat(lookup[key]),
      []
    )
  }

  if (stays.length > 0) {
    const length = stays.length
    const thereIsText = pluralize('is', length)
    const staysText = pluralize('stay', length)

    root.alert(
      `Cannot delete place \nThere ${thereIsText} ${length} upcoming ${staysText} in this place. In order to delete this place, first you'll need to cancel all the stays associated with it.`
    )

    return
  }
  return true
  // return new Promise(resolve => {
  //   const confirmed = root.confirm(
  //     `Are you sure? \nDeleting this place will permanently remove it from your account and the StayCircles platform.`
  //   )

  //   if (confirmed) {
  //     resolve(true)
  //   } else {
  //     resolve(false)
  //   }
  // })
}
