import React from 'react'

import Icon from 'components/Icon'
import Avatar from 'components/Avatar'
import ButtonPill from 'components/ButtonPill'
import { FullPageLoader } from 'components/Loader'
import { TextInput } from 'components/Input'

import AddStayPalButton from '../AddStayPalButton'
import { Avatar as SmallAvatar } from '../IntegratedStayPalsList'

import withSPStore from 'app/screens/explore/components/IntegratedStayPalsList/store'

import styled, { css } from 'styled-components'
import { get } from 'lodash'

import colors from 'styles/colors'

import root from 'utils/windowOrGlobal'
import getHeight from 'utils/getHeight'
import { getUserPicture } from 'utils/profile-info'

const Container = styled.div.attrs({
  id: 'PlaceDetails__Container'
})`
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  height: 100%;
  background: white;
  overflow: hidden;
  border-top: 6px solid ${props => (props.friend ? colors.red : colors.blue)};
  box-shadow: 0 2px 4px ${colors.blackRGBA(0.3)};

  width: 95%;

  @media (min-width: 992px) {
    width: 40vw;
  }

  @media (min-width: 1300px) {
    width: 38vw;
  }

  @media (min-width: 1600px) {
    width: 27vw;
  }

  @media (min-width: 2000px) {
    width: 580px;
  }

  .avatar {
    width: 140px;
    height: 140px;
    border-radius: 50%;
    border: 4px solid ${props => (props.friend ? colors.red : colors.blue)};
  }

  .close {
    top: 0;
    right: 0;
    position: absolute;
    width: 3rem;
    height: 3rem;
    cursor: pointer;
    padding: 0.75rem;
    transform: rotate(45deg);
    border-radius: 50%;
    border: none;
    background: white;
    transition: all 0.15s ease-in;

    :hover {
      background: #eee;
    }
  }

  .user-info {
    display: flex;
    flex-direction: row;
    padding: 24px;
  }

  .spOrSp2 {
    color: ${props => (props.friend ? colors.darkRed : colors.darkBlue)};
  }
`

const MessageContainer = styled.div`
  transition: height 0.15s ease-in;
  height: ${props => (props.showMessage ? '13rem' : 0)};
`

const buttonSize = css`
  font-size: 0.875rem;
  padding: 0.625rem 1.5rem;
`

const MessageButton = styled(ButtonPill)`
  ${buttonSize};
`

const InviteButton = styled(AddStayPalButton)`
  ${buttonSize};
`

const avatarOptions = {
  width: 320,
  height: 320
}

const SUBMIT_TEXT = {
  label: 'Send',
  loading: 'Sending...',
  success: '✔ Sent'
}

const defaultState = {
  search: '',
  inProgress: false,
  showMessage: false,
  submit: SUBMIT_TEXT.label,
  show: false
}

class PlaceDetails extends React.Component {
  state = {
    ...defaultState,
    firstOpen: true,
    listHeight: 300
  }

  shouldComponentUpdate (nextProps, nextState) {
    if (
      nextProps !== this.props ||
      nextState !== this.state ||
      nextProps.machineStore.state !== this.props.machineStore.state
    ) {
      return true
    }

    return false
  }

  updateHeights = () => {
    root.setTimeout(() => {
      const listHeight =
        getHeight('PlaceDetails__Container') -
        getHeight('PlaceDetails__UserInfo') -
        getHeight('PlaceDetails__MutualStayPalsHeader')

      this.setState({ listHeight, firstOpen: false })
    }, 500)
  }

  resetState = show =>
    this.setState({
      ...defaultState,
      show
    })

  componentDidUpdate (prevProps) {
    if (prevProps.activeUserID !== this.props.activeUserID) {
      this.resetState(!!this.props.activeUserID)
    }

    if (prevProps.loading && this.state.firstOpen && !this.props.loading) {
      this.updateHeights()
    }
  }

  get mutualStayPals () {
    const { mutualStayPals } = this.props
    const search = (this.state.search || '').toLowerCase()

    if (!search) {
      return mutualStayPals
    }

    const filterBy = (contact, field) =>
      contact[field].toLowerCase().includes(search)

    return mutualStayPals.filter(
      contact => filterBy(contact, 'name') || filterBy(contact, 'email')
    )
  }

  setSearch = e => this.setState({ search: e.target.value })

  moveToFriendPlace = place => {
    const { onFriendSelect } = this.props
    this.handleClose()
    onFriendSelect(place)
  }

  renderStayPal = item => {
    const picture = getUserPicture(item)

    return (
      <div
        className='pa2 flex flex-row items-center w-100 pointer'
        key={item.email}
        onClick={() =>
          item.primaryPlace &&
          item.primaryPlace.lat &&
          this.moveToFriendPlace(item?.primaryPlace)
        }
      >
        <div
          className='flex flex-row items-center pr3 w-60'
          style={{ flex: 1 }}
        >
          <SmallAvatar
            friend={item.friend}
            src={picture}
            alt={`${item.name}'s picture`}
          />

          <div className='ph2 h-100 w-90' style={{ flex: 1 }}>
            <p className='mb0 truncate'>{item.name}</p>
            <p className='mt1 mb0 f7 truncate'>{item.email}</p>
          </div>
        </div>
      </div>
    )
  }

  renderMutualStayPals = () => {
    const { search, listHeight } = this.state

    return (
      <div className='ph3'>
        <div id='PlaceDetails__MutualStayPalsHeader'>
          <p className='pt2 tc f4 mb0'>Mutual Friends</p>
          <div className='pv4'>
            <TextInput
              small
              onChange={this.setSearch}
              value={search}
              placeholder='Search by name or email...'
              className='w-100'
            />
          </div>
        </div>

        <div
          className='overflow-auto pb3 scrollbar'
          style={{ height: listHeight }}
        >
          {this.mutualStayPals.map(this.renderStayPal)}
        </div>
      </div>
    )
  }

  makeSP = () => {
    const {
      props: { user, machineStore, setSpRequestSuccess, stayPalRequestStatus }
    } = this

    const type =
      stayPalRequestStatus === 'received'
        ? 'ACCEPT_SP_REQUEST'
        : 'SEND_SP_REQUEST'

    machineStore.transition({
      type,
      sp: user,
      cb: () => setSpRequestSuccess({ id: user.id, stayPalRequestStatus })
    })
  }

  get inProgress () {
    const { machineStore, activeUserID } = this.props

    return get(machineStore, 'state.inProgress', []).includes(activeUserID)
  }

  toggleMessage = () => this.setState({ showMessage: !this.state.showMessage })

  selectHost = () => {
    const { user, place, mutualStayPals, selectHost } = this.props

    selectHost({
      ...user,
      place,
      mutualStayPals
    })
  }

  renderUserInfo = user => {
    const { place, stayPalRequestStatus, selectHost } = this.props

    return (
      <div className='ph3'>
        <p className='mt2 mb3'>
          <span className='f3 b dib mr2'>{user.name}</span>
          <span className='spOrSp2'>
            Friends
            {user.friend ? '' : '-of-Friends'}
          </span>
        </p>

        <p className='black-70 mb2'>{place.name}</p>
        <p className='black-50'>
          {place.address1}
          <br />
          {place.address2}
          {place.address2 && <br />}
          {place.city}, {place.state}, {place.country}
        </p>
        <div className='mt3'>
          {!user.friend && (
            <InviteButton
              huge
              label={selectHost ? 'Invite' : 'Send Friend Invite'}
              className='mr3'
              makeSP={this.makeSP}
              loading={this.inProgress}
              stayPalRequestStatus={stayPalRequestStatus}
            />
          )}

          <MessageButton
            onClick={selectHost ? this.selectHost : this.toggleMessage}
            children={selectHost ? 'Select Host' : 'Message'}
            color={colors.blackRGBA(0.4)}
            border={colors.blackRGBA(0.4)}
          />
        </div>
      </div>
    )
  }

  setMessageSuccess = form => {
    form.reset()

    this.setState({
      submit: SUBMIT_TEXT.success
    })

    root.setTimeout(
      () =>
        this.setState({
          submit: SUBMIT_TEXT.label
        }),
      3000
    )
  }

  handleSubmit = e => {
    e.preventDefault()
    const { sendMessage, user } = this.props

    const form = e.target
    const subject = form.elements.subject.value
    const message = form.elements.message.value

    if (!subject || !message) return

    this.setState({ submit: SUBMIT_TEXT.loading })

    sendMessage({
      data: { subject, message, userID: user.pid },
      cb: () => this.setMessageSuccess(form)
    })
  }

  handleClose = e => {
    this.setState({ show: false })
    this.props.onClose(e)
  }

  render () {
    if (!this.state.show) {
      return null
    }

    if (this.props.loading) {
      return (
        <Container>
          <FullPageLoader />
        </Container>
      )
    }

    const {
      props: { wrapperClassName, user },
      state: { submit }
    } = this

    const sendButtonColor =
      submit === SUBMIT_TEXT.success
        ? colors.pigmentGreen
        : colors.blackRGBA(0.4)

    return (
      <Container className={wrapperClassName} friend={user.friend}>
        <button className='close' onClick={this.handleClose}>
          <Icon name='plus' />
        </button>

        <div className='user-info' id='PlaceDetails__UserInfo'>
          <Avatar className='avatar' options={avatarOptions} user={user} />
          {this.renderUserInfo(user)}
        </div>

        <MessageContainer
          showMessage={this.state.showMessage}
          className='ph3 overflow-hidden'
        >
          <form onSubmit={this.handleSubmit}>
            <input
              className='br2 db mt3 ph3 pv2 w-100 mb3 ba b--black-20'
              placeholder='Subject'
              name='subject'
              required
            />
            <textarea
              className='br2 db mt3 ph3 pv2 w-100 mb3 ba b--black-20 lh-copy'
              placeholder="Don't be shy, say hi!"
              name='message'
              required
            />
            <ButtonPill
              children={submit}
              type='submit'
              disabled={submit !== SUBMIT_TEXT.label}
              color={sendButtonColor}
              border={sendButtonColor}
            />
          </form>
        </MessageContainer>

        {this.renderMutualStayPals()}
      </Container>
    )
  }
}

PlaceDetails.defaultProps = {
  wrapperClassName: '',
  place: {},
  mutualStayPals: [],
  user: {}
}

export default withSPStore(PlaceDetails)
