import React from 'react'
import PropTypes from 'prop-types'

import get from 'lodash/get'

import PlacesAutocomplete from 'react-places-autocomplete'

import styled from 'styled-components'

import logger from 'utils/logger'
import { geocodeAddress } from 'utils/location'
import parseAddress from 'utils/parse-address'

import Icon from 'components/Icon'
import Input from 'components/Input'

const searchOptions = {
  types: ['geocode']
}

class AddressSearchField extends React.Component {
  static propTypes = {
    form: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired
  }

  state = {
    address: '',
    focused: false
  }

  handlePlaceSelect = place => {
    if (place.failed) return

    const { form } = this.props

    const address = parseAddress(place)

    form.batch(() => {
      if (get(address, 'latitude')) {
        form.registerField('latitude', () => {}, {})
        form.registerField('longitude', () => {}, {})

        form.change('latitude', get(address, 'latitude'))
        form.change('longitude', get(address, 'longitude'))
      }

      form.change('address1', get(address, 'address'))
      form.change('city', get(address, 'city'))
      form.change('state', get(address, 'state'))
      form.change('zipCode', get(address, 'zipCode'))
      form.change('country', get(address, 'country'))
    })
  }

  handleError = error => {
    logger.captureException(error)

    this.handlePlaceSelect({
      failed: true
    })
  }

  handleChange = address => this.setState({ address })

  handleSelect = (address, placeID) => {
    logger.captureBreadcrumb({
      message: 'AddressSearchField',
      category: 'hosting-place',
      data: { address, placeID }
    })

    this.setState({ address })

    geocodeAddress(address, placeID)
      .then(this.handleGeocodeResult)
      .catch(this.handleError)
  }

  handleGeocodeResult = results => {
    let place = results[0]

    if (!place.geometry) {
      place = { ...place, failed: true }
    } else if (!place.name) {
      place = {
        ...place,
        name: place.formattedAddress
      }
    }

    this.handlePlaceSelect(place)
  }

  render () {
    const placeholder = this.state.focused ? 'Search address...' : 'Address'

    return (
      <PlacesAutocomplete
        value={this.state.address}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
        onError={this.handleError}
        searchOptions={searchOptions}
        highlightFirstSuggestion
      >
        {({ getInputProps, suggestions, getSuggestionItemProps }) => (
          <div className='w-100 relative'>
            <div className='relative'>
              <Input
                {...getInputProps({
                  placeholder,
                  // autoComplete: 'nope',
                  onFocus: e => {
                    this.setState({ focused: true })
                  },
                  onBlur: e => {
                    this.setState({ focused: false })
                  },
                  ...this.props
                })}
              />
            </div>

            {suggestions.length > 0 && (
              <SuggestionsContainer>
                {suggestions.map(suggestion => {
                  const {
                    mainText,
                    secondaryText
                  } = suggestion.formattedSuggestion

                  return (
                    <Suggestion
                      {...getSuggestionItemProps(suggestion, {
                        active: suggestion.active
                      })}
                    >
                      <PlaceIcon />
                      <span className='b dib mr2'>{mainText}</span>
                      <span className='f6 black-60'>{secondaryText}</span>
                    </Suggestion>
                  )
                })}
              </SuggestionsContainer>
            )}
          </div>
        )}
      </PlacesAutocomplete>
    )
  }
}

const SuggestionsContainer = styled.div`
  z-index: 1000000;
  position: absolute;
  top: 3rem;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.25);
  width: 100%;

  @media screen and (max-width: 1024px) {
    bottom: 100%;
    top: initial;
  }
`

const PlaceIcon = styled(Icon).attrs({
  height: '1rem',
  name: 'pin'
})`
  color: #555555;
  display: inline-block;
  margin: -0.35rem 0.5rem 0;
`

const Suggestion = styled.div`
  background-color: ${props => (props.active ? '#f0f0f0' : '#ffffff')};
  padding: 10px;
  color: #555555;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export default AddressSearchField
