import api from 'utils/api'

export const setPreference = ({ category, prefKey, checked }) => {
  const data = {
    preferences: {
      [category]: {
        [prefKey]: checked
      }
    }
  }

  return api.post('/preferences', data)
}

export const getPreferences = () => {
  return api.get('/preferences')
}
