import React, { PureComponent } from 'react'

import styled from 'styled-components'

import colors from 'styles/colors'

import Input, { TextInput } from './Input'

const SelectFieldInput = styled(TextInput)`
  appearance: none;
  background-color: ${colors.blackRGBA(0.01)};
  background-image: linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%),
    radial-gradient(transparent 70%, transparent 72%);
  background-position: calc(100% - 22px) calc(1em + 6px),
    calc(100% - 17px) calc(1em + 6px), calc(100% - 0.5em) 0.75em;
  background-size: 5px 5px, 5px 5px, 1.5em 1.5em;
  background-repeat: no-repeat;
  padding-right: 2.5rem;
  cursor: pointer;

  opacity: ${props => (props.disabled ? '0.6' : '1')};

  ~ label {
    display: ${props => (props.value ? 'block' : 'none')};
    color: ${props => (props.error ? colors.red : colors.blackRGBA(0.6))};
  }
`

export const SelectInput = ({
  renderOptions = () => {},
  placeholder,
  ...props
}) => (
  <SelectFieldInput
    as='select'
    {...props}
    type={undefined}
    onFocus={() => {}}
    onBlur={() => {}}
  >
    <option value=''>{placeholder}</option>

    {renderOptions()}
  </SelectFieldInput>
)

class Select extends PureComponent {
  render () {
    const { renderOptions, ...props } = this.props

    const InputComponent = p => (
      <SelectInput {...p} {...props} renderOptions={renderOptions} />
    )

    return <Input {...props} InputComponent={InputComponent} />
  }
}

export default Select
