import React, { useState, Fragment, useEffect } from 'react'
import styled from 'styled-components'
import config from 'config'
import { useNavigate, useLocation } from '@reach/router'
import { get } from 'lodash'
import Loader from 'components/Loader'
import Button from '../../components/Button'
import { Container } from '../../components/CommonStyles'
import LocationSearch from '../../../booking/components/LocationSearch'
import Icon from 'components/Icon'
import { AirportData } from '../store'

const STAGE = {
  STAGE_1: 'STAGE_1',
  STAGE_2: 'STAGE_2'
}

const BUTTON_TEXT = {
  [STAGE.STAGE_1]: 'Search',
  [STAGE.STAGE_2]: 'See flights!'
}

const SearchAirportScreen = props => {
  const navigate = useNavigate()
  const {
    state: { lat, lng }
  } = useLocation()
  const airportData = AirportData.useContainer()
  const {
    // error,
    setError,
    loading,
    // page,
    canAddAirports,
    potentialAirports,
    loadPotentialAirports,
    deleteOriginAirport,
    addOriginAirports,
    getCanAddAirports,
    prime,
    originAirports,
    isAddingOriginAirports
  } = airportData
  const [stage, setStage] = useState(() => STAGE.STAGE_1)
  const [activePlace, setActivePlace] = useState(null)
  const [airports, setAirports] = useState([])

  const _handleGPSLocation = async () => {
    await loadPotentialAirports(lat, lng, 1, true)
    setStage(STAGE.STAGE_2)
  }

  useEffect(() => {
    if (!canAddAirports) {
      navigate(`${window.location.pathname}`)
    }
    if (lat && lng) {
      _handleGPSLocation()
    }
  }, [])

  const _handleSearchButton = async () => {
    if (stage === STAGE.STAGE_1) {
      setStage(STAGE.STAGE_2)
      return
    }
    await addOriginAirports(airports)
    navigate(`${window.location.pathname}`)
  }

  const _setPlace = async placeDetails => {
    setActivePlace(placeDetails)
    const { lat, lng } = get(placeDetails, 'geometry.location', {})
    if (!lat || !lng) return
    await loadPotentialAirports(lat(), lng(), 1, true)
    setStage(STAGE.STAGE_2)
  }

  const getRegionName = airport => {
    const { regionalCity, country } = airport

    let name

    if (get(regionalCity, 'name')) {
      return get(regionalCity, 'name')
    }

    if (get(country, 'name')) {
      name = get(country, 'name')
    } else if (name && get(country, 'iso')) {
      name = `${name}, ${get(country, 'iso')}`
    }

    return name
  }

  const getCountry = airport => {
    const { country } = airport

    if (get(country, 'name')) {
      return get(country, 'name')
    } else if (get(country, 'iso')) {
      return get(country, 'iso')
    }
  }

  const _handleBackButton = () => setStage(STAGE.STAGE_1)
  const _handleCloseButton = () => navigate(`${window.location.pathname}`)

  const _handleOriginAirportClick = async data => {
    const airportIds = airports.map(airport => {
      return airport.id
    })
    if (data.selected && data.originAirportID) {
      await deleteOriginAirport(data.originAirportID)
      setAirports(airports.filter(airport => airport.id !== data.id))
    } else {
      if (airportIds.includes(data.id)) {
        setAirports(airports.filter(airport => airport.id !== data.id))
      } else {
        if (!getCanAddAirports(airports.length + originAirports.length)) {
          setError(`Limit exceeded!,
        You can add only ${
  prime ? config.AIRPORTS_PRIME : config.AIRPORTS_FREE
} airports in ${prime ? 'Prime' : 'Free'} membership.`)
          return
        }
        const { id, name, iataCode } = data
        setAirports([...airports, { id, name, iataCode }])
      }
    }
  }

  const _renderSearchGroup = () => {
    return (
      <Fragment>
        <div className='title-group'>
          <Icon name='explore' className='title-group--icon' />
          <div className='title-group--text'>
            <p className='text-title'>Search</p>
            <p className='text-description'>your address (street, city...)</p>
          </div>
        </div>
        <div className='search-group'>
          <LocationSearch handlePlaceSelect={_setPlace} />
        </div>
      </Fragment>
    )
  }

  const _renderResult = () => {
    const airportIds = airports.map(airport => {
      return airport.id
    })
    return (
      <div className='result-group'>
        <div className='title'>
          Adventure Machine airports close to: <br />{' '}
          {activePlace && activePlace.name && activePlace.name.split(',')[0]}
        </div>
        <ul className='result-list'>
          {potentialAirports.map(data => {
            const selected = data.selected || airportIds.includes(data.id)
            return (
              <li
                key={data.id}
                className={`result-item ${selected && 'result-item-selected'}`}
                onClick={() => _handleOriginAirportClick(data)}
              >
                {selected && (
                  <Icon
                    name='checkmark-solid'
                    width={44}
                    className='selected-checkmark'
                  />
                )}
                <p className='item-name'>
                  {getRegionName(data)} {data.iataCode && `(${data.iataCode})`}
                </p>
                <p className='address-name'>{`${data.name}\n${getCountry(
                  data
                )}`}</p>
              </li>
            )
          })}
        </ul>
      </div>
    )
  }

  const _renderAddingMessage = () => {
    const msg = `Loading ${isAddingOriginAirports?.currentAirport?.name} (${isAddingOriginAirports?.currentAirport?.iataCode}) (${isAddingOriginAirports?.currentPosition}/${isAddingOriginAirports?.total})`

    return <h4 className='mt2'>{msg}</h4>
  }

  const shouldRenderSearchGroup = stage === STAGE.STAGE_1
  const shouldRenderResult = stage === STAGE.STAGE_2

  if (loading) {
    return (
      <Container>
        <Card>
          <div className='flex flex-column justify-center items-center h-100 relative'>
            <Loader width='25px' height='35px' />
            {isAddingOriginAirports && _renderAddingMessage()}
          </div>
        </Card>
      </Container>
    )
  }

  return (
    <Container>
      <Card>
        {shouldRenderResult && (
          <div className='back-button' onClick={_handleBackButton}>
            <Icon name='arrow' />
          </div>
        )}
        <div className='close-button' onClick={_handleCloseButton}>
          <Icon name='close' />
        </div>
        {shouldRenderSearchGroup && _renderSearchGroup()}
        {shouldRenderResult && _renderResult()}
        <Button className='search-button' fluid onClick={_handleSearchButton}>
          {BUTTON_TEXT[stage] || 'Search'}
        </Button>
      </Card>
    </Container>
  )
}

const Card = styled.div`
  position: relative;
  overflow: hidden;
  background-color: #ffff;
  min-width: 100%;
  min-height: 550px;
  border: 1px solid #f2f2f2;
  box-sizing: border-box;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 992px) {
    min-width: 460px;
  }
  .title-group {
    display: flex;
    align-items: center;
    padding-top: 6.5rem;
    padding-left: 2rem;
    padding-right: 2rem;
    &--icon {
      height: 100%;
      width: auto;
      max-width: 40px;
      color: #5f9bed;
    }
    &--text {
      display: flex;
      flex-direction: column;
      & > * {
        margin: 0;
        margin-left: 1rem;
      }
      .text-title {
        color: rgba(0, 0, 0, 0.4);
      }
      .text-description {
        color: rgba(0, 0, 0, 0.7);
      }
    }
  }
  .close-button,
  .back-button {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    cursor: pointer;
    padding: 10px;
    top: 2rem;
    width: 2.5rem;
    height: 2.5rem;
    color: rgba(255, 255, 255, 0.8);
    background: rgba(0, 0, 0, 0.5);
    border-radius: 12px;
    &:hover {
      color: rgba(255, 255, 255, 1);
      background: rgba(0, 0, 0, 1);
    }
  }
  .close-button {
    right: 2rem;
  }
  .back-button {
    left: 2rem;
  }
  .search-group {
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 2rem;
    position: relative;
    .search-icon {
      color: rgba(0, 0, 0, 0.4);
      height: 1.5rem;
      width: auto;
      position: absolute;
      transform: translateX(-50%);
      z-index: 2;
      &--left {
        left: 3.25rem;
        top: 55%;
      }
      &--right {
        top: 60%;
        height: 1.2rem;
        right: 2rem;
      }
    }
    & > input {
      height: 2.8rem;
      background-color: #f7fafc;
      width: 100%;
      border: none;
      padding-left: 2.5rem;
      padding-right: 2.5rem;
      position: relative;
    }
  }
  .search-button {
    margin-top: auto;
  }
  .result-group {
    padding-top: 6.5rem;
    padding-left: 2rem;
    padding-right: 2rem;
    .title {
      text-align: center;
      font-size: 1.5rem;
      font-weight: 700;
    }
    .result-list {
      list-style: none;
      margin-top: 1.875rem;
      max-height: 400px;
      max-width: 500px;
      overflow-y: scroll;
      .result-item {
        position: relative;
        padding-left: 3.75rem;
        padding-bottom: 1.5rem;
        padding-top: 1.5rem;
        margin-bottom: 4px;
        .item-name {
          font-size: 1.25rem;
          color: #1a365e;
          font-size: 700;
          margin-bottom: 0.375rem;
        }
        .address-name {
          font-size: 0.875rem;
          color: #999999;
          font-size: 500;
        }
        &::before {
          content: '';
          width: 44px;
          height: 44px;
          border: 4px solid #999999;
          border-radius: 50%;
          position: absolute;
          left: 0;
        }
      }
      .result-item-selected {
        background: #bad9ff;
        .selected-checkmark {
          width: 44px;
          position: absolute;
          left: 0;
        }
      }
    }
  }
`

SearchAirportScreen.propTypes = {}

// export default withAirportsState(SearchAirportScreen)
export default SearchAirportScreen
