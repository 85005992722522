import React from 'react'

import styled from 'styled-components'
import { Link as GatsbyLink } from 'gatsby'

import { RenderOnAction } from 'utils/StateMachine'

import ErrorMessageView from 'components/ErrorMessageView'
import InfiniteList, { SmallLoader } from 'components/InfiniteList'
import { View } from 'components/Native'
import colors from 'styles/colors'
import { format } from 'date-fns'
import { formatFlightDeal } from 'utils/deals'

import ChatMessage from './ChatMessage'

class MessagesList extends React.PureComponent {
  componentDidMount () {
    if (this.props.id) {
      this.props.load()
    }
  }

  componentDidUpdate ({ id }) {
    if (!id && this.props.id) {
      this.props.load()
    } else if (id !== this.props.id) {
      this.props.reload()
    }
  }

  componentWillUnmount () {
    this.props.unload()
  }

  _renderItem = ({ item }) => {
    const { isViewerHost, detailsLink, id, conversationType } = this.props

    return (
      <ChatMessage
        message={item}
        isViewerHost={isViewerHost}
        detailsLink={detailsLink}
        conversationId={id}
        conversationType={conversationType}
      />
    )
  }

  _getItemHeight = index => {
    const { conversationType, flighDealDetails, conversationName } = this.props
    let { messages } = this.props
    if (conversationType === 'potential_hosting' && flighDealDetails?.active) {
      messages = [{ body: conversationName, isheader: true }, ...messages]
    }
    if (conversationType === 'potential_hosting' && !flighDealDetails?.active) {
      messages = [
        ...messages,
        { body: 'This flight deal has expired.', isfooter: true }
      ]
    }
    const m = messages[index]

    const charByLine = 52
    const lineHeight = 24
    const baseHeight = 80

    const lines = Math.ceil(m.body.length / charByLine)

    return lines * lineHeight + baseHeight
  }

  _renderDateRange = (whenFrom, whenTo) => {
    const dateFormat = 'MMM, yyyy'
    return (
      <DateRangeText>{`${format(new Date(whenFrom), dateFormat)} - ${format(
        new Date(whenTo),
        dateFormat
      )}`}</DateRangeText>
    )
  }

  _renderFlightDealsInfo = () => {
    const {
      flighDealDetails = {}
      // onDealDetails,
    } = this.props
    const {
      active = false,
      fromAirport = {},
      toAirport = {},
      whenFrom = '',
      whenTo = '',
      price
    } = flighDealDetails || {}
    const {
      regionalCity: { name: frmRegionalCity = '' } = {},
      iataCode: IATAFrom = ''
    } = fromAirport || {}
    const {
      regionalCity: { name: toRegionalCity = '' } = {},
      iataCode: IATATo = ''
    } = toAirport || {}
    return (
      <DealsContainer active={active} className='b--black-10 bb'>
        <Status>{active ? 'LIVE' : 'EXPIRED'}</Status>
        <DealsInfoContainer>
          <CityContainer>
            <NormalText>
              {frmRegionalCity} <IATA>{IATAFrom}</IATA>
            </NormalText>
            <BlueyRect src={require('../../adventure/assets/arrow-dot.png')} />
            <NormalText>
              {toRegionalCity} <IATA>{IATATo}</IATA>
            </NormalText>
          </CityContainer>
          <DateAndCostContainer>
            <SimpleCenteredContentRow>
              <Dot color={colors.greenish} />
              <DateRangeText>{`${format(
                new Date(whenFrom),
                'MMM, yyyy'
              )} - ${format(new Date(whenTo), 'MMM, yyyy')}`}</DateRangeText>
            </SimpleCenteredContentRow>
            <SimpleCenteredContentRow>
              <Dot color={colors.greenish} />
              <AmountText>{price}</AmountText>
            </SimpleCenteredContentRow>
          </DateAndCostContainer>
        </DealsInfoContainer>
        {active && (
          <OpenDealLink
            to={`/app/adventure/deals/${flighDealDetails.id}`}
            state={formatFlightDeal(flighDealDetails)}
          >
            Open
          </OpenDealLink>
        )}
        {/* {active && <Button>Open</Button>} */}
      </DealsContainer>
    )
  }

  _renderSharedDestinationHeader = () => {
    const {
      sharedDestinationGroupDetails: { sharedDestinationDeals = [] } = {}
    } = this.props
    const topDeal =
      sharedDestinationDeals.length > 0 ? sharedDestinationDeals[0] : {}
    const {
      flightDeal: {
        whenFrom = '',
        whenTo = '',
        destinationInfo: { regionalCity = '', country = '' } = {}
      } = {}
    } = topDeal || {}

    if (!sharedDestinationDeals || sharedDestinationDeals.length === 0) {
      return (
        <SharedDeals>
          <NoFlightFound>
            All flight alerts have expired due to higher prices.
          </NoFlightFound>
        </SharedDeals>
      )
    }

    return (
      <SharedDeals className='b--black-10 bb'>
        <TopDeal>
          <CityName fontSize={14}>
            {regionalCity}
            <CountryName> {country}</CountryName>
          </CityName>
          {this._renderDateRange(whenFrom, whenTo)}
        </TopDeal>
        {sharedDestinationDeals.map(deal => {
          const {
            flightDeal: {
              whenFrom = '',
              whenTo = '',
              price = '',
              originInfo: { regionalCity = '', iataCode = '' } = {}
            } = {}
          } = deal
          return (
            <DealItem>
              <DealCity fontSize={12}>
                {regionalCity}
                <IATA> ({iataCode})</IATA>
              </DealCity>
              <DateContainer>
                <Dot color={colors.red} />
                {this._renderDateRange(whenFrom, whenTo)}
              </DateContainer>
              <PriceContainer>
                <Dot color={colors.blue} />
                <Price>{price}</Price>
              </PriceContainer>
            </DealItem>
          )
        })}
      </SharedDeals>
    )
  }

  render () {
    const {
      retry,
      loadNextPage,
      hasMore,
      id,
      lastUpdated,
      contentHeight,
      loading,
      conversationType,
      flighDealDetails,
      conversationName
    } = this.props
    let { messages } = this.props
    const { active = false } = flighDealDetails || {}
    if (!id) {
      return (
        <ListWrapper height={contentHeight}>
          <p className='tc lh-copy ph6 black-70'>
            Select a conversation from the sidebar or start a new one
          </p>
        </ListWrapper>
      )
    }

    if (conversationType === 'potential_hosting' && active) {
      messages = [{ body: conversationName, isheader: true }, ...messages]
    }
    if (conversationType === 'potential_hosting' && !active) {
      messages = [
        ...messages,
        { body: 'This flight deal has expired.', isfooter: true }
      ]
    }
    return (
      <ListWrapper height={contentHeight}>
        <RenderOnAction value='showLoading'>
          <SmallLoader />
        </RenderOnAction>

        <RenderOnAction value='showError'>
          <ErrorMessageView retry={retry} />
        </RenderOnAction>

        <RenderOnAction value='showList'>
          {conversationType === 'potential_hosting'
            ? this._renderFlightDealsInfo()
            : conversationType === 'shared_destination'
              ? this._renderSharedDestinationHeader()
              : null}
          <ChatList
            className='messagelist'
            height={contentHeight}
            width='100%'
            alwaysScrollToLatest
            extraData={lastUpdated}
            itemHeight={this._getItemHeight}
            renderItem={this._renderItem}
            data={messages}
            loadMore={loadNextPage}
            hasMore={hasMore && !loading}
            loading={loading}
            scrollToAlignment='end'
          />
        </RenderOnAction>
      </ListWrapper>
    )
  }
}

const ListWrapper = styled(View)`
  background-color: white;
  justify-content: center;
  flex: 1;
  width: 100%;
  height: ${props => props.height}px;
`

const ChatList = styled(InfiniteList)`
  width: 100%;
  flex: 1;
`

// const VisitConversationNameContainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   border-width: 1px;
//   border-color: ${colors.red};
//   border-radius: 6px;
//   padding: 13px 20px;
//   margin: 15px;
// `

// const ConversationName = styled.p`
//   margin: 0;
//   padding: 0;
//   font-weight: 700;
//   font-size: 14px;
//   color: ${colors.red};
//   text-align: center;
//   padding: 5px;
// `
const TopDeal = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
`
const SharedDeals = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 30px;
  background-color: ${colors.white};
  border-color: ${colors.semiBlue};
`
const CityName = styled.p`
  display: inline;
  margin: 0 0 6px 0;
  padding: 0;
  font-size: ${props => props.fontSize}px;
  align-items: center;
  text-align: center;
  color: ${colors.darkestBlue};
`

const NoFlightFound = styled.p`
  margin: 0;
  padding: 0;
  font-size: 15px;
  align-items: center;
  text-align: center;
  padding: 10px 0px;
  color: ${colors.darkestBlue};
`
const DealCity = styled.p`
  margin: 0;
  padding: 0;
  diplay: flex;
  flex: 0.35;
  font-weight: 700;
  font-size: ${props => props.fontSize}px;
  color: ${colors.darkestBlue};
  display: flex;
  align-items: center;
  text-align: center;
`
const CountryName = styled.p`
  display: inline;
  margin: 0;
  padding: 0;
  font-size: 12px;
  text-align: center;
  color: ${colors.darkestBlue};
`

const IATA = styled.p`
  padding: 0;
  font-size: 12px;
  font-weight: normal;
  text-align: center;
  margin: 0px 8px;
  color: ${colors.lightBlueIII};
`
const DateRangeText = styled.p`
  margin: 0;
  padding: 0;
  font-size: 12px;
  align-items: center;
  text-align: center;
  color: ${colors.lightBlueIII};
`

const DealItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px;
`
const DateContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 0.4;
`
const Dot = styled.div`
  display: flex;
  flex-direction: column;
  width: 5px;
  height: 5px;
  margin: 0px 5px;
  border-radius: 2.5px;
  background-color: ${props => props.color};
`

const PriceContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  flex: 0.25;
`
const Price = styled.p`
  margin: 0;
  padding: 0;
  font-size: 12px;
  text-align: center;
  color: ${colors.darkestBlue};
`

// const DateHeader = styled.p`
//   margin: 0;
//   padding: 0;
//   font-size: 12px;
//   letter-spacing: 0.3;
//   color: ${colors.blackRGBA(0.3)};
//   text-align: center;
//   padding: 16px 0 8px;
// `

const DealsContainer = styled.div`
  display: flex;
  height: 70px;
  width: 100%;
  padding: 10px;
  flex-direction: row;
  align-items: center;
  background: ${props => (props.active ? colors.white : colors.lightestRed)};
`
const Status = styled.p`
  margin: 0;
  padding: 0;
  flex: 0.2;
  font-size: 12px;
  font-style: italic;
  padding: 0px 5px;
  font-weight: bold;
  color: ${colors.darkRed};
  font-weight: 700;
`
const DealsInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  padding: 0px 15px;
`
const CityContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`
const NormalText = styled.p`
  margin: 0;
  padding: 0;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${colors.darkestBlue};
`
const BlueyRect = styled.img`
  width: 28px;
  height: 5px;
  margin: 0px 10px;
  align-self: center;
`
const DateAndCostContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
`

const SimpleCenteredContentRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-right: 10px;
`
const AmountText = styled.p`
  margin: 0;
  padding: 0;
  font-weight: 700;
  font-style: normal;
  font-size: 12px;
  line-height: 14px;
  align-items: center;
  text-align: center;
  color: ${colors.darkestBlue};
`
const OpenDealLink = styled(GatsbyLink)`
  background: ${colors.bluesh};
  text-decoration: none;
  color: ${colors.white};
  padding: 0.5rem 1rem;
  border-radius: 5px;
`
// const OpenDeal = styled.TouchableOpacity`
//    flex: 0.2
//    height: 35px;
//    width: 65px;
//    background: ${colors.bluesh};
//    justify-content: center;
//    margin-left: 10px;
//    border-radius: 6px;
// `
// const OpenText = styled.p`
//   margin: 0;
//   padding: 0;
//   color: ${colors.white};
//   font-size: 12px;
//   text-align: center;
//   font-weight: 700;
//   font-style: italic;
//   font-weight: 700;
// `

export default MessagesList
