import React from 'react'

import styled from 'styled-components'

import words from 'lodash/words'
import cn from 'classnames'

import Avatar from 'components/Avatar'

import { getUserPicture, isDefaultPicture } from 'utils/profile-info'

import colors from 'styles/colors'

const ScrollingNameWrapper = styled.div`
  text-transform: capitalize;
  height: 100%;
  width: 100%;
  padding: 2px;
  border-radius: 50%;
  word-break: break-all;
  border-width: 0;
  background-color: ${colors.blue};
  color: #fff;
`

const ScrollingName = ({ stayPal, ...props }) => {
  const className = cn(
    'f5 ba bw2 b w-100 bg-white black tc flex items-center justify-center',
    { pointer: !!props.onClick }
  )

  let name

  if (stayPal.is3rdDegreeSp) {
    name = 'Housemate'
  } else {
    name = words(stayPal.firstName || stayPal.name)[0]
  }

  return (
    <ScrollingNameWrapper className={className} {...props}>
      {name}
    </ScrollingNameWrapper>
  )
}

const StayPalPictureWrapper = styled.div`
  background-color: #fff;
  border-radius: 50%;

  ${props =>
    props.degreeIndicator && `border: 3px solid ${colors.blue};`} ${props =>
  props.firstDegree && `border-color: ${colors.red};`}

  img {
    font-size: 0px;
    height: 100%;
    border-width: 0;
  }
`

class StayPalPicture extends React.PureComponent {
  render () {
    const {
      stayPal,
      isCurrentUser,
      isFirstDegree,
      showDegreeIndicator,
      circle = true,
      className,
      isPictureInvalid,
      getImgRef = () => {},
      children,
      ...props
    } = this.props

    const picture = getUserPicture(stayPal)

    const wrapperClassName = cn(className, {
      'current-user-pin': isCurrentUser
    })

    const imgClassName = cn('w-100', {
      'br-100': circle,
      pointer: !!props.onClick
    })

    const isValidPicture =
      picture && !isDefaultPicture(picture) && !isPictureInvalid

    return (
      <StayPalPictureWrapper className={wrapperClassName}>
        {isValidPicture && (
          <Avatar
            user={stayPal}
            className={imgClassName}
            getRef={getImgRef}
            {...props}
          />
        )}

        {!isValidPicture && <ScrollingName stayPal={stayPal} {...props} />}

        {children}
      </StayPalPictureWrapper>
    )
  }
}

export default StayPalPicture
