import React from 'react'

import { navigate } from 'gatsby'

import styled from 'styled-components'

import colors from 'styles/colors'

import { Headline } from 'components/Landings/Hero'

import SubscriptionPlans from './components/_SubscriptionPlansRelaunch'

import root from 'utils/windowOrGlobal'

import * as routes from 'config/routes'

class Prime extends React.PureComponent {
  handleBack = () => {
    navigate(routes.PROFILE)
    return false
  }

  componentDidMount () {
    root.addEventListener('popstate', this.handleBack)
  }

  componentWillUnmount () {
    root.removeEventListener('popstate', this.handleBack)
  }

  render () {
    return (
      <Container>
        <Headline className='p12h3 flex flex-row justify-center items-center center tc mb0'>
          <img
            alt='Adventure Machine'
            src='https://res.cloudinary.com/staycircles/image/upload/f_auto,q_auto,w_640,c_scale/assets/branding/am_logo_text_bold'
            className='v-mid w-80 w-50-ns'
            style={{ maxWidth: 300, marginTop: 50 }}
          />
        </Headline>
        <div className='tc f2 mb0 f2' style={{ marginTop: -12 }}>
          {/* Prime Subscription */}
        </div>
        <SubscriptionPlans {...this.props} />
      </Container>
    )
  }
}

const Container = styled.div`
  background: ${colors.ice};
  width: 100%;
  overflow: auto;
`
export default Prime
