import React from 'react'
import styled from 'styled-components'
import { useLocation } from '@reach/router'
import { parse } from 'qs'
// import PropTypes from 'prop-types'

// import EntryScreen from './screens/EntryScreen'
import SelectTypeScreen from './Screens/SelectTypeScreen'
import SearchAirportScreen from './Screens/SearchAirportScreen'
import OriginAirportsListScreen from './Screens/OriginAirportsListScreen'

import { STEPS } from '../config'

const STEP_CONTENT_MAPPING = {
  [STEPS[0]]: OriginAirportsListScreen,
  [STEPS[1]]: SelectTypeScreen,
  [STEPS[2]]: SearchAirportScreen
}

const AirportIndex = props => {
  const { search } = useLocation()
  const { step = STEPS[0] } = parse(search.substring(1))
  const PageContent = STEP_CONTENT_MAPPING[step] ?? (() => <div />)

  return (
    <AirportContainer>
      <PageContent {...props} step={+step} />
    </AirportContainer>
  )
}

const AirportContainer = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
  @media screen and (max-width: 991px) {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
`

// AirportScreen.propTypes = {
//   step: PropTypes.number,
// }

export default AirportIndex
