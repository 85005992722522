import React, { useEffect, useState } from 'react'
import colors from 'styles/colors'
import styled from 'styled-components'
import Modal from 'components/Modal'
import Icon from 'components/Icon'
import { AirportData } from '../Airports/store'
import Button from './Button'

const FilterByStatusOptions = [
  { label: 'Active', value: 'active' },
  { label: 'Expired', value: 'inactive' }
]
const FilterByDateOptions = [
  { label: 'Descending', value: 'desc' },
  { label: 'Ascending', value: 'asc' }
]

const DealFilters = ({ dealFilters, handleDealFiltersChange }) => {
  const airportData = AirportData.useContainer()
  const { originAirports } = airportData
  const [selectedFilters, setSelectedFilters] = useState(null)
  const [filterModalVisible, setFilterModalVisible] = useState(false)

  useEffect(() => {
    if (dealFilters) {
      setSelectedFilters(dealFilters)
    }
  }, [dealFilters])

  const _handleCloseButton = () => {
    setFilterModalVisible(false)
  }

  const _handleFilterButton = () => {
    handleDealFiltersChange(selectedFilters)
    _handleCloseButton()
  }

  const handleFilterChange = (filterType, filterName) => {
    if (filterType === 'fromAirports') {
      let airports
      if (
        selectedFilters[filterType] &&
        selectedFilters[filterType].includes(filterName)
      ) {
        airports = selectedFilters[filterType].filter(
          airport => airport !== filterName
        )
      } else {
        airports = [...selectedFilters[filterType], filterName]
      }
      if (airports.length === 0) {
        // need atleast one airport to filter
        return
      }
      return setSelectedFilters({ ...selectedFilters, fromAirports: airports })
    }
    const currentFilters = { ...selectedFilters }
    currentFilters[filterType] = filterName
    return setSelectedFilters({ ...currentFilters })
  }

  const isSelected = (filterType, filterName, optionType) => {
    if (optionType === 'array') {
      return selectedFilters && selectedFilters[filterType].includes(filterName)
    }
    return selectedFilters && selectedFilters[filterType] === filterName
  }

  if (!dealFilters || !selectedFilters) {
    return null
  }

  return (
    <>
      <Row onClick={() => setFilterModalVisible(true)}>
        <FilterContainer>
          <FilterLabel>Date</FilterLabel>
          <FilterButton>
            <ButtonText>{dealFilters?.dateOrder}</ButtonText>
          </FilterButton>
        </FilterContainer>
        <FilterContainer>
          <FilterLabel>Status</FilterLabel>
          <FilterButton>
            <ButtonText>{dealFilters?.status}</ButtonText>
          </FilterButton>
        </FilterContainer>
        <FilterContainer>
          <FilterLabel>Origin Airports</FilterLabel>
          <FilterButton>
            <ButtonText>{dealFilters?.fromAirports.join()}</ButtonText>
          </FilterButton>
        </FilterContainer>
      </Row>
      <Modal
        isOpen={filterModalVisible}
        onRequestClose={_handleCloseButton}
        contentLabel='FILTER MODAL'
      >
        <div className='center flex flex-column items-center w-100 w-90-ns mb0'>
          <TopBar>
            <HeadingText>Filters</HeadingText>
            <CloseButtonContainer>
              <div className='close-button' onClick={_handleCloseButton}>
                <Icon name='close' />
              </div>
            </CloseButtonContainer>
          </TopBar>

          <div className='mv2 w-100'>
            <FilterOptionsContainer>
              <ListContainer>
                <FilterHeader>Filter by status</FilterHeader>
                <ListContainer>
                  {FilterByStatusOptions.map((option, idx) => (
                    <FilterItemWrapper
                      key={idx}
                      onClick={() => handleFilterChange('status', option.value)}
                    >
                      <Item>
                        <ItemTitleWrapper>
                          <ItemSubtitleRow>
                            <ItemTitle>{option.label}</ItemTitle>
                          </ItemSubtitleRow>
                        </ItemTitleWrapper>

                        {isSelected('status', option.value, 'string') && (
                          <Icon
                            name='checkmark-solid'
                            width={20}
                            className='selected-checkmark'
                          />
                        )}
                      </Item>
                    </FilterItemWrapper>
                  ))}
                </ListContainer>
              </ListContainer>
              <ListContainer>
                <FilterHeader>Filter by date</FilterHeader>
                <ListContainer>
                  {FilterByDateOptions.map((option, idx) => (
                    <FilterItemWrapper
                      key={idx}
                      onClick={() =>
                        handleFilterChange('dateOrder', option.value)
                      }
                    >
                      <Item>
                        <ItemTitleWrapper>
                          <ItemSubtitleRow>
                            <ItemTitle>{option.label}</ItemTitle>
                          </ItemSubtitleRow>
                        </ItemTitleWrapper>
                        {isSelected('dateOrder', option.value, 'string') && (
                          <Icon
                            name='checkmark-solid'
                            width={20}
                            className='selected-checkmark'
                          />
                        )}
                      </Item>
                    </FilterItemWrapper>
                  ))}
                </ListContainer>
              </ListContainer>
              <AirportsListContainer>
                <FilterHeader>Filter by Origin Airports </FilterHeader>
                <AirportList>
                  {originAirports.map((option, idx) => {
                    return (
                      <FilterItemWrapper
                        key={idx}
                        onClick={() =>
                          handleFilterChange(
                            'fromAirports',
                            option?.airport?.iataCode
                          )
                        }
                      >
                        <Item>
                          <ItemTitleWrapper>
                            <ItemTitle>
                              {option?.airport?.iataCode &&
                                `${option?.airport?.iataCode}`}
                            </ItemTitle>
                            <ItemSubtitleRow>
                              <ItemSubtitle>
                                {`${option?.airport?.name}`}
                              </ItemSubtitle>
                            </ItemSubtitleRow>
                          </ItemTitleWrapper>
                          {isSelected(
                            'fromAirports',
                            option?.airport?.iataCode,
                            'array'
                          ) && (
                            <Icon
                              name='checkmark-solid'
                              width={20}
                              className='selected-checkmark'
                            />
                          )}
                        </Item>
                      </FilterItemWrapper>
                    )
                  })}
                </AirportList>
              </AirportsListContainer>
            </FilterOptionsContainer>
          </div>

          <Button className='search-button' fluid onClick={_handleFilterButton}>
            Apply filters
          </Button>
        </div>
      </Modal>
    </>
  )
}

export default DealFilters

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  @media screen and (min-width: 992px) {
    width: 551px;
    margin: 0 auto 30px;
  }
`

const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  padding: 0 10px;
  margin: 0 0;
  text-align: center;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
`

const FilterLabel = styled.span`
  font-weight: 600;
  text-align: center;
  font-size: 14px;
  margin-bottom: 8px;
  color: #737373;
`

const FilterButton = styled.div`
  padding: 4px 8px;
  border-color: ${colors.blue};
  border-style: solid;
  border-width: 1px;
  align-items: center;
  border-width: 1px;
  border-radius: 8px;
  flex-wrap: nowrap;
  display: flex;
  flex-direction: column;
`

const ButtonText = styled.span`
  color: ${colors.blue};
  font-size: 12px;
  text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`

const TopBar = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  width: 100%;
`

const HeadingText = styled.h3`
  font-style: normal;
  text-align: left;
  margin-bottom: 10px;
  margin-top: 20px;
`

const CloseButtonContainer = styled.div`
  .close-button {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 10px;
    width: 2.5rem;
    height: 2.5rem;
    color: rgba(255, 255, 255, 0.8);
    background: rgba(0, 0, 0, 0.5);
    border-radius: 12px;
    &:hover {
      color: rgba(255, 255, 255, 1);
      background: rgba(0, 0, 0, 1);
    }
  }
`

const AirportsListContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`

const AirportList = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
`

const FilterItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const Item = styled.div`
  width: 100%;
  padding-right: 4px;
  padding-top: 2px;
  padding-bottom: 2px;
  flex-direction: row;
  align-items: center;
  display: flex;
  position: relative;
  cursor: pointer;
  &::before {
    content: '';
    width: 20px;
    height: 20px;
    border: 2px solid #999999;
    border-radius: 50%;
    position: absolute;
    right: 4px;
  }
  .selected-checkmark {
    position: absolute;
    right: 4px;
  }
`

const ItemTitleWrapper = styled.div`
  flex: 1;
  flex-direction: column;
  justify-content: center;
`

const ItemTitle = styled.div`
  font-size: 14px;
  color: ${colors.disabledLightBlue};
  display: flex;
  flex-direction: column;
`

const ItemSubtitleRow = styled.div`
  flex-direction: row;
  margin-top: 4px;
  display: flex;
`

const ItemSubtitle = styled.div`
  font-size: 12px;
  color: ${colors.disabledLightBlue};
  margin-bottom: 4px;
  display: flex;
  flex-direction: column;
`
const FilterHeader = styled.div`
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  text-align: left;
  color: ${colors.darkestBlue};
  margin-vertical: 4px;
  margin-top: 14px;
  display: flex;
  flex-direction: column;
`

const ListContainer = styled.div`
  // flex: 1;
  display: flex;
  flex-direction: column;
`

const FilterOptionsContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`
