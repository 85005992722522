import React from 'react'

import get from 'lodash/get'

import { Router, Link } from '@reach/router'

import styled from 'styled-components'

import colors from 'styles/colors'

import Icon from 'components/Icon'
import ButtonPill from 'components/ButtonPill'

import MatchQueryModal from 'components/MatchQueryModal'
import Dropdown from 'components/SimpleDropdown'

import EditNameModal from './components/EditNameModal'
import EditAddressModal from './components/EditAddressModal'

import PlaceDetails from './components/PlaceDetails'
import Housemates from '../Housemates'
import Rooms from '../Rooms'
import ConfirmAlert from 'components/ConfirmAlert'
import { confirmAlert } from 'react-confirm-alert'

const MODALS = [
  { query: 'edit_name', Component: EditNameModal },
  { query: 'edit_address', Component: EditAddressModal }
]

const isActive = ({ isCurrent }) => {
  return isCurrent ? { active: 1 } : null
}

const isPartiallyActive = ({ isPartiallyCurrent }) => {
  return isPartiallyCurrent ? { active: 1 } : null
}

const Place = ({
  loading,
  places,
  placeId,
  toggleAttribute,
  updateData,
  navigate,
  reloadData,
  deletePlace
}) => {
  const place = places.find(place => place.id === placeId)

  if (!place && !loading) {
    return (
      <div className='pa4 flex flex-column justify-center items-center w-100'>
        <h3 className='f2 fw5 mb3'>Place not found</h3>
        <ButtonPill onClick={() => navigate('../')} color={colors.blue}>
          Back
        </ButtonPill>
      </div>
    )
  }

  const togglePrivacy = value => {
    updateData({ id: placeId, isAddressPublic: value })
  }

  const onSubmit = (values, cb) => {
    updateData({ id: placeId, ...values }, cb)
  }

  const openModal = modal => () => navigate('?' + modal + '=true')

  const onDeletePlace = () => {
    confirmAlert({
      customUI: ({ onClose }) => (
        <ConfirmAlert
          onClose={onClose}
          title={'Are you sure?'}
          subTitle={
            'Deleting this place will permanently remove it from your account and the StayCircles platform.'
          }
          onYes={handleDeletePlace}
        />
      )
    })
  }

  const handleDeletePlace = () => {
    deletePlace(place, errored => {
      if (!errored) {
        reloadData(() => {
          navigate('../')
        })
      }
    })
  }

  return (
    <div className='pa4-ns pa3'>
      <h3
        className='f2 fw5 mb3'
        style={{ cursor: 'text' }}
        onClick={openModal('edit_name')}
      >
        {get(place, 'name', '')}{' '}
        <LinkButton className='f6 ml1' onClick={openModal('edit_name')}>
          edit
        </LinkButton>
      </h3>

      <Dropdown
        className='mb4'
        renderButton={onClick => (
          <LinkButton
            className='tl flex flex-row items-center'
            onClick={onClick}
          >
            Change / add place <Caret />
          </LinkButton>
        )}
        renderMenu={() => (
          <ul>
            {places.map(item => {
              const id = get(item, 'id')
              const name = get(item, 'name', '')
              const selected = item.id === placeId ? 'o-60' : ''

              return (
                <li key={id}>
                  <Link
                    to={'../' + id}
                    className={`flex flex-row items-center ${selected}`}
                  >
                    <PinIcon />
                    <span>{name}</span>
                  </Link>
                </li>
              )
            })}

            <li>
              <Link to='../new_place' className='flex flex-row items-center'>
                <AddIcon />
                <span>Add new place</span>
              </Link>
            </li>
          </ul>
        )}
      />

      <div className='bb b--black-10 pb3 pr6-ns mb4 db-ns flex'>
        <TabButton to='./' getProps={isActive}>
          Place details
        </TabButton>
        <TabButton to='housemates' getProps={isPartiallyActive}>
          Housemates
        </TabButton>
        <TabButton to='rooms' getProps={isPartiallyActive}>
          Rooms
        </TabButton>
      </div>

      <Router>
        <PlaceDetails
          default
          togglePrivacy={togglePrivacy}
          toggleAttribute={toggleAttribute}
          onSubmitData={onSubmit}
          place={place}
          openModal={openModal}
          onDeletePlace={onDeletePlace}
          loading={loading}
        />

        <Housemates path='housemates/*' place={place} openModal={openModal} />

        <Rooms path='rooms/*' place={place} reloadPlaces={reloadData} />
      </Router>

      <MatchQueryModal modalsList={MODALS} place={place} onSubmit={onSubmit} />
    </div>
  )
}

export const LinkButton = styled.button.attrs({ type: 'button' })`
  border: none;
  background-color: transparent;
  color: ${colors.darkBlue};
  cursor: pointer;
  padding: 0 0 2px;
  border-bottom: 2px solid transparent;
  transition: all 0.25s ease;

  &:hover,
  &:focus {
    opacity: 0.9;
    border-bottom-color: ${colors.darkBlue};
  }

  &:active {
    opacity: 0.7;
  }
`

export const Caret = styled.div`
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid ${colors.darkBlue};
  margin-left: 0.25rem;
`

export const TabButton = styled(Link)`
  border: none;
  background-color: transparent;
  text-decoration: none;
  cursor: pointer;
  border-bottom: 2px solid transparent;
  padding: 0.5rem 0.25rem;
  margin-right: 2rem;
  font-size: 1rem;
  color: ${colors.blackRGBA(0.5)};
  transition: all 0.25s ease;

  &[active] {
    color: ${colors.blackRGBA(0.75)};
    border-bottom-color: ${colors.darkBlue};
  }

  &:hover,
  &:focus {
    color: ${colors.blackRGBA(0.75)};
    opacity: 0.9;
    border-bottom-color: ${colors.darkBlue};
  }

  &:active {
    opacity: 0.7;
  }
`

const PinIcon = styled(Icon).attrs({ name: 'pin' })`
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.75rem;
  opacity: 0.5;
`

export const AddIcon = styled(PinIcon).attrs({ name: 'uncheckmark' })`
  transform: rotate(45deg);
  margin-right: 0.5rem;
`

export default Place
