import React from 'react'
import get from 'lodash/get'

import { Form } from 'react-final-form'

import {
  composeValidators,
  required,
  validateEmail
} from 'utils/field-validators'
import navigateBack from 'utils/navigateBack'

import colors from 'styles/colors'

import Modal from 'components/Modal'
import Input, { ErrorText } from 'components/Input'
import { SelectInput } from 'components/Select'
import ButtonPill from 'components/ButtonPill'

import ListItems from './ListItems'

import ProfileStoreProvider from '../store'

const EditEmailsModal = () => (
  <ProfileStoreProvider>
    {({ store, user, reload }) => {
      const emails = get(user, 'emails', [])
      const loading = store.matchesActions('showLoading')

      const commonProps = { store, loading, emails, reload }

      return (
        <Modal isOpen onRequestClose={navigateBack} contentLabel='Edit emails'>
          <div className='center flex flex-column items-center w-100 w-90-ns mt4'>
            <h3 className='f3 bb b--black-05 mb4 pb4 w-100'>Edit emails</h3>

            <PendingEmailsList {...commonProps} />

            <PrimaryEmailField {...commonProps} />

            <AllEmailsList {...commonProps} />

            <AddEmailInput {...commonProps} />

            <div className='w-100 flex flex-row justify-center bt b--black-05 mt3 pt4'>
              <ButtonPill onClick={navigateBack}>Close</ButtonPill>
            </div>
          </div>
        </Modal>
      )
    }}
  </ProfileStoreProvider>
)

const PrimaryEmailField = ({ store, loading, emails, reload }) => {
  const primaryEmail = emails.find(e => e.primary)
  const confirmedEmails = emails.filter(e => e.confirmed)

  return (
    <div
      className={`w-100 flex flex-column mb4 ${loading ? '0-60' : ''}`}
      style={{
        cursor: loading ? 'progress' : 'default'
      }}
    >
      <label htmlFor='primary-email' className='f5 black-50 pl2 mb2'>
        Primary email
      </label>

      <SelectInput
        disabled={loading}
        style={{
          cursor: loading ? 'progress' : 'pointer'
        }}
        id='primary-email'
        name='primary-email'
        placeholder='Choose primary email'
        defaultValue={primaryEmail.address}
        onChange={e => {
          const value = e.target.value
          if (!value || value === primaryEmail.address) return

          store.transition({
            type: 'EDIT_PRIMARY_EMAIL',
            email: value,
            callback: reload
          })
        }}
        renderOptions={() => {
          return confirmedEmails.map(email => {
            return (
              <option key={email.address} value={email.address}>
                {email.address}
              </option>
            )
          })
        }}
      />
    </div>
  )
}

const PendingEmailsList = ({ store, loading, emails, reload }) => {
  const pendingEmails = emails.filter(e => !e.confirmed)
  // Don't render if no pending emails
  if (pendingEmails.length === 0) return null

  const resent = e => store.state.emailsResent.includes(e)

  const items = pendingEmails.map(email => ({
    value: email.address,
    actionText: resent(email.address) ? 'Resent ✓' : 'Resend Email',
    disabled: resent(email.address),
    actionClick: () => {
      if (resent(email.address)) return
      store.transition({
        type: 'RESEND_EMAIL',
        email: email.address,
        callback: reload
      })
    }
  }))

  return (
    <ListItems label='Pending confirmation' loading={loading} items={items} />
  )
}

const AllEmailsList = ({ store, loading, emails, reload }) => {
  // Don't render if only primary email
  if (emails.length === 1) return null

  const items = emails.map(email => ({
    value: email.address,
    valueClassName: !email.confirmed && 'i black-40',
    tooltip: !email.confirmed && 'Email pending confirmation',
    actionText: email.primary ? undefined : 'Remove',
    actionClick: () => {
      if (email.primary) return
      store.transition({
        type: 'REMOVE_EMAIL',
        email: email.address,
        callback: reload
      })
    }
  }))

  return <ListItems label='Account emails' loading={loading} items={items} />
}

const AddEmailInput = ({ store, reload }) => (
  <Form
    onSubmit={(values, form, callback) => {
      store.transition({
        type: 'ADD_EMAIL',
        email: values.email,
        callback: () => {
          form.reset()
          reload()
          callback()
        }
      })
    }}
    render={({ handleSubmit, submitting, invalid, submitError }) => (
      <form
        onSubmit={handleSubmit}
        className={`w-100 flex flex-column mt3 ${submitting ? '0-60' : ''}`}
      >
        <label htmlFor='email' className='f5 black-50 pl2 mb2'>
          Add another email
        </label>

        <div className='flex flex-column flex-row-l justify-between-l items-center-l mb3'>
          <div style={{ flex: 1 }} className='pr3-l mb3 mb0-l'>
            <Input
              id='email'
              name='email'
              placeholder='Enter new email'
              validate={composeValidators(required, validateEmail)}
              disabled={submitting}
              style={{ marginBottom: 0 }}
              floatingLabel={false}
            />
          </div>

          <div>
            <ButtonPill
              type='submit'
              children={submitting ? 'Adding...' : 'Add'}
              background={colors.blue}
              hoverBackground={colors.darkBlue}
              color={colors.white}
              border={colors.whiteRGBA(0.1)}
              disabled={submitting}
            />
          </div>
        </div>

        {invalid && submitError && <ErrorText>{submitError}</ErrorText>}
      </form>
    )}
  />
)

export default EditEmailsModal
