import api from 'utils/api'

/**
  const estimationParams = {
    startDate,
    endDate,
    roomIds,
    guests,
    proposedPrice
  }
**/

export const getEstimation = async params => {
  return api.get('/stay_estimations', params)
}
