import React from 'react'

import get from 'lodash/get'

import { cloudinary } from 'utils/profile-info'

import styled, { css } from 'styled-components'

import colors from 'styles/colors'

import Carousel from 'nuka-carousel'

import Icon from 'components/Icon'

import PhotoShowcaseModal from './PhotoShowcaseModal'

const SLIDES = 3

class PicturesCarousel extends React.Component {
  state = {
    openPictureIndex: null
  }

  render () {
    const { pictures, deletePicture } = this.props
    const { openPictureIndex } = this.state

    if (!pictures[0]) return null

    return (
      <React.Fragment>
        <Carousel
          slidesToShow={SLIDES}
          initialSlideHeight={100}
          cellSpacing={8}
          edgeEasing='easeLinear'
          renderCenterLeftControls={NavLeftButton}
          renderCenterRightControls={NavRightButton}
          renderBottomCenterControls={() => null}
        >
          {pictures.map((picture, i) => {
            const publicId = get(picture, 'cloudinaryPublicId')
            const sauce = cloudinary(publicId, {
              width: 140,
              height: 100,
              crop: 'fill'
            })

            return (
              <img
                key={publicId}
                src={sauce}
                className='br3'
                style={{ width: 140, height: 100 }}
                onClick={() => {
                  this.setState({ openPictureIndex: i })
                }}
              />
            )
          })}
        </Carousel>

        {openPictureIndex !== null && (
          <PhotoShowcaseModal
            pictures={pictures}
            pictureIndex={openPictureIndex}
            onClose={() => {
              this.setState({ openPictureIndex: null })
            }}
            deletePicture={deletePicture}
          />
        )}
      </React.Fragment>
    )
  }
}

const NavLeftButton = ({ previousSlide, currentSlide }) => (
  <NavButton left onClick={previousSlide} disabled={currentSlide === 0}>
    <LeftCaretIcon />
  </NavButton>
)

const NavRightButton = ({ nextSlide, currentSlide, slideCount }) => {
  const total = Math.ceil(Math.max(slideCount, SLIDES) / SLIDES)

  return (
    <NavButton right onClick={nextSlide} disabled={currentSlide >= total - 1}>
      <RightCaretIcon />
    </NavButton>
  )
}

const NavButton = styled.button.attrs({ type: 'button' })`
  background: transparent;
  border: none;
  outline: none;
  padding: 0;
  cursor: pointer;
  transition: opacity 0.25s ease;

  &:hover,
  &:focus {
    opacity: 0.7;
  }

  &[disabled] {
    opacity: 0.3;
  }

  ${props =>
    props.left &&
    css`
      margin-left: -1.25rem;
    `} ${props =>
  props.right &&
    css`
      margin-right: -1.25rem;
    `};
`

const LeftCaretIcon = styled(Icon).attrs({ name: 'caret' })`
  width: 1.25rem;
  height: 1.25rem;
  color: ${colors.blackRGBA(0.5)};

  transform: rotate(90deg);
`

const RightCaretIcon = styled(LeftCaretIcon)`
  transform: rotate(-90deg);
`

export default PicturesCarousel
