import React from 'react'

import styled from 'styled-components'

import colors from 'styles/colors'
import zIndex from 'styles/zIndex'

class Dropdown extends React.Component {
  static defaultProps = {
    align: 'left',
    renderMenu: () => (
      <ul>
        <li>{'<ul><li>Item</li></ul>'}</li>
      </ul>
    )
  }

  state = { open: false }

  componentDidMount () {
    document.addEventListener('click', this.handleClickOutside, false)
  }

  componentWillUnmount () {
    document.removeEventListener('click', this.handleClickOutside, false)
  }

  handleClickOutside = e => {
    if (!this.state.open || this.dropdown.contains(e.target)) {
      return
    }

    this.toggle()
  }

  toggle = () => {
    this.setState({ open: !this.state.open })
  }

  render () {
    const onClick = this.toggle
    const open = this.state.open

    return (
      <DropdownContainer
        ref={node => {
          this.dropdown = node
        }}
        open={open}
        align={this.props.align}
        className={this.props.className}
      >
        {this.props.renderButton(onClick)}
        {this.props.renderMenu(open, onClick)}
      </DropdownContainer>
    )
  }
}

const DropdownContainer = styled.div`
  position: relative;

  ul {
    position: absolute;
    z-index: ${zIndex.header};
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    list-style-type: none;
    padding: 0;
    margin: 0;

    background-color: ${colors.white};
    top: 1rem;
    ${props => `${props.align}: -0.5rem;`}
    border: 1px solid ${colors.blackRGBA(0.1)};
    box-shadow: 2px 2px 4px ${colors.blackRGBA(0.05)};
    display: ${props => (props.open ? 'block' : 'none')};
  }

  li {
    margin: 0;
    display: block;
  }

  li > a,
  li > button {
    padding: 1.5rem;
    min-width: 200px;
    display: flex;

    font-size: 1rem;
    font-weight: 600;
    text-align: left;
    text-decoration: none;
    color: ${colors.blackRGBA(0.6)};
    transition: all 0.2s ease-in-out;

    &:hover,
    &:focus {
      color: ${colors.blue};
      background-color: ${colors.blackRGBA(0.025)};
    }

    &:active {
      color: ${colors.darkerBlue};
    }
  }
`

export default Dropdown
