import React from 'react'

import Modal from 'components/Modal'

import { TextInput } from 'components/Input'
import InfiniteList from 'components/InfiniteList'
import AddStayPalButton from '../AddStayPalButton'
import { Avatar } from '../IntegratedStayPalsList'

import { getDefaultPicture } from 'utils/profile-info'

import config from 'config'

const defaultPicture = getDefaultPicture()

class GmailContactsModal extends React.Component {
  constructor (props) {
    super(props)

    this.listHeight = 0.45 * config.WINDOW_HEIGHT
  }

  renderItem = ({ item }) => {
    const { inProgress, sendSPRequest } = this.props

    return (
      <div className='pa2 flex flex-row items-center w-100' key={item.email}>
        <div
          className='flex flex-row items-center pr3 w-60'
          style={{ flex: 1 }}
        >
          <Avatar
            src={item.image || defaultPicture}
            alt={`${item.name}'s picture`}
          />

          <div className='ph2 h-100 w-90' style={{ flex: 1 }}>
            <p className='mb0 truncate'>{item.name}</p>
            <p className='mt1 mb0 f7 truncate'>{item.email}</p>
          </div>
        </div>

        <AddStayPalButton
          makeSP={() => sendSPRequest(item.email)}
          loading={inProgress.includes(item.email)}
          stayPalRequestStatus={item.stayPalRequestStatus}
        />
      </div>
    )
  }

  render () {
    const { props } = this

    return (
      <React.Fragment>
        <Modal
          isOpen={props.show}
          onRequestClose={() => props.toggle(false)}
          contentLabel='GmailContactsModal'
        >
          <div className='center flex flex-column mb0'>
            <h3 className='ma0 tc fw3'>
              Invite any Google contact to StayCircles:
            </h3>
            <div className='pv4'>
              <TextInput
                small
                value={props.term}
                className='w-100'
                placeholder='Search by name or email...'
                onChange={props.setTerm}
              />
            </div>

            <InfiniteList
              height={this.listHeight}
              width='100%'
              itemHeight={60}
              renderItem={this.renderItem}
              data={props.contacts}
              loading={props.loading}
              extraData={props}
            />
          </div>
        </Modal>
      </React.Fragment>
    )
  }
}

export default GmailContactsModal
