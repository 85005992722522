import React from 'react'

import styled from 'styled-components'

import { TouchableOpacity } from 'components/Native'
import Icon from 'components/Icon'

import colors from 'styles/colors'

const Counter = ({ decrement, increment, minValue, maxValue, value }) => {
  const minReached = value <= minValue
  const maxReached = value >= maxValue

  return (
    <Container>
      <TouchableOpacity onPress={decrement} disabled={minReached}>
        <IconButton name='minus' disabled={minReached} />
      </TouchableOpacity>

      <Text>{value}</Text>

      <TouchableOpacity onPress={increment} disabled={maxReached}>
        <IconButton name='plus' disabled={maxReached} />
      </TouchableOpacity>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const Text = styled.span`
  font-weight: 600;
  color: ${colors.blackRGBA(0.7)};
  font-size: 1.125;
  margin: 0 10px;
`

export const IconButton = styled(Icon)`
  height: 1.25rem;
  width: 1.25rem;
  padding: 1px;
  border: 1px solid
    ${props => (props.disabled ? colors.blackRGBA(0.3) : colors.blue)};
  border-radius: 100%;
  color: ${props => (props.disabled ? colors.blackRGBA(0.3) : colors.blue)};
`

export default Counter
