import React from 'react'

import get from 'lodash/get'

import styled from 'styled-components'

import colors from 'styles/colors'

import { getFullName } from 'utils/profile-info'
import formatDate from 'utils/formatDate'

import { View, Text } from 'components/Native'

import InfiniteList, { SmallLoader } from 'components/InfiniteList'
import ErrorMessageView from 'components/ErrorMessageView'
import EmptyMessageView from 'components/EmptyMessageView'

import TransactionsStoreProvider from '../store'

class TransactionsList extends React.Component {
  componentDidMount () {
    const transactionsType = this.transactionsType

    this.props.store.transition({ type: 'LOAD', transactionsType })
  }

  reload = () => {
    this.props.store.transition({
      type: 'RELOAD',
      transactionsType: this.transactionsType,
      page: 1
    })
  }

  get transactionsType () {
    const context = get(this.props, 'type')

    return {
      host: 'host',
      traveler: 'traveller',
      prime: 'subscription_payment',
      Transactions: 'subscription_payment' // Context will only be Transactions if it's AM Prime
    }[context]
  }

  get list () {
    return get(this.props, 'store.state.data.logs', [])
  }

  getLogName = log => {
    const userType = get(log, 'userType')
    const stayDetails = get(log, 'stayDetails')
    const primePlan = get(log, 'invoice.lineItems[0].plan.name')

    if (userType && stayDetails) {
      const staypal =
        userType === 'host'
          ? get(stayDetails, 'traveller')
          : get(stayDetails, 'host')

      return getFullName(staypal)
    } else if (primePlan) {
      return primePlan
    }

    return ''
  }

  getStayName = log => {
    const stayDetails = get(log, 'stayDetails')

    if (stayDetails) {
      const placeName = get(stayDetails, 'place.name')
      const roomName = get(stayDetails, 'rooms[0].name')
      const roomsLength = get(stayDetails, 'rooms.length', 1) - 1

      return `${placeName} - ${roomName}${
        roomsLength > 0 ? ' +' + roomsLength : ''
      } \n`
    }

    return ''
  }

  getPayStatus = log => {
    const status = (get(log, 'status') || '').toUpperCase()

    let type = (get(log, 'transactionType') || '').toUpperCase()
    type = type.replace('SUBSCRIPTION_', '')

    return `${type} ${status}`
  }

  getPayAmount = log => {
    const type = get(log, 'transactionType', '')
    const amount = get(log, 'amount', '')

    const prefix = {
      payout: '+$',
      refund: '+$',
      payment: '-$',
      subscription_payment: '$',
      default: '$'
    }[type || 'default']

    return prefix + amount
  }

  getProcessedDate = log => {
    const processedAt = get(log, 'processedAt')

    if (!processedAt) return ''

    return formatDate(processedAt, 'MM/dd/yy')
  }

  renderItem = ({ item }) => {
    const name = this.getLogName(item)
    const stayName = this.getStayName(item)
    const payStatus = this.getPayStatus(item)
    const payAmount = this.getPayAmount(item)
    const processedDate = this.getProcessedDate(item)
    const onPress = () => this.props.setLog(item)

    return (
      <div
        className='b--black-10 bb pa3 flex flex-row items-center bg-white pointer o-90 glow'
        key={item.id}
        onClick={onPress}
      >
        <div className='mb0 pr2 flex flex-column' style={{ flex: 1 }}>
          <NameText>{name}</NameText>
          <PlaceText>
            {stayName} <br />
            <StatusText>{payStatus}</StatusText>
          </PlaceText>
        </div>
        <AmountColumn>
          <AmountText>{payAmount}</AmountText>
          <DateText>{processedDate}</DateText>
        </AmountColumn>
      </div>
    )
  }

  render () {
    const { store } = this.props

    const transactionsType = this.transactionsType

    const showLoading = store.matchesActions('showLoading')
    const showError = store.matchesActions('showError')
    const showEmpty =
      !showLoading && (store.matchesActions('showEmpty') || !this.list.length)
    const showList = store.matchesActions('showList')
    const loadingNext = store.matchesActions('loadNext')

    return (
      <div>
        {showLoading && <SmallLoader />}

        {showError && (
          <ErrorMessageView
            retry={() => store.transition({ type: 'RECOVER' })}
          />
        )}

        {showEmpty && (
          <EmptyMessageView
            name='transactions here'
            onRefresh={() =>
              store.transition({ type: 'REFRESH', transactionsType })
            }
          />
        )}

        {showList && (
          <View style={{ flex: 1 }}>
            <InfiniteList
              height={400}
              width='100%'
              itemHeight={115}
              data={this.list}
              renderItem={this.renderItem}
              hasMore={store.state.params.hasMore}
              loadMore={() => store.transition('NEXT')}
              loading={loadingNext}
            />
          </View>
        )}
      </div>
    )
  }
}

const NameText = styled(Text)`
  font-weight: bold;
  font-size: 18px;
  color: ${colors.blackRGBA(0.7)};
  line-height: 1.5;
`

const PlaceText = styled(Text)`
  font-size: 16px;
  color: ${colors.blackRGBA(0.7)};
  line-height: 1.5;
`

const StatusText = styled.span`
  font-weight: bold;
  font-size: 14px;
  color: ${colors.blackRGBA(0.6)};
  letter-spacing: 0.3px;
`

const AmountColumn = styled(View)`
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
`

const AmountText = styled(Text)`
  font-weight: bold;
  font-size: 18px;
  color: ${colors.blackRGBA(0.8)};
  margin-bottom: 4px;
`

const DateText = styled(Text)`
  font-size: 16px;
  color: ${colors.blackRGBA(0.7)};
`

export default props => (
  <TransactionsStoreProvider>
    {store => <TransactionsList {...props} store={store} />}
  </TransactionsStoreProvider>
)
