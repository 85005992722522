import React from 'react'
import PropTypes from 'prop-types'

import isEqual from 'date-fns/isEqual'
import { get, debounce, isEmpty } from 'lodash'
import root from 'utils/windowOrGlobal'

import logger from 'utils/logger'

import * as api from './api'

import StateMachineProvider, { StateMachineContainer } from 'utils/StateMachine'

const statechart = {
  key: 'hosting-edit-room',
  initial: 'idle',
  states: {
    idle: {
      on: {
        LOAD: { idle: { actions: ['load', 'showLoading'] } },
        UPDATE: { idle: { actions: ['update'] } },
        UPDATE_DATES: { idle: { actions: ['updateDates', 'showLoading'] } },
        CREATE_NEW: { loading: { actions: ['create'] } },
        UPLOAD_PHOTO: {
          idle: { actions: ['uploadPhoto', 'showUploadProgress'] }
        },
        DELETE_PHOTO: { loading: { actions: ['deletePhoto'] } },
        DELETE: { loading: { actions: ['deleteRoom'] } },
        SUCCESS: 'idle',
        ERROR: 'error'
      }
    },
    loading: {
      onEntry: 'showLoading',
      on: {
        SUCCESS: 'idle',
        ERROR: 'error'
      }
    },
    error: {
      onEntry: 'showError',
      on: {
        RECOVER: 'idle'
      }
    }
  }
}

class RoomsContainer extends StateMachineContainer {
  constructor (props) {
    super(props)

    // Make sure to keep the state machine engine
    const stateMachine = this.state

    this.state = {
      ...stateMachine,
      data: {},
      updating: false
    }

    this.updateRequest = debounce(this.updateRequest, 1000)
  }

  componentDidTransition (prevStateMachine, event) {
    if (event && event.type) {
      switch (event.type) {
        case 'SUCCESS':
          this.setData(event.data)
          break
        case 'ERROR':
          this.setError(event.error)
          break
      }
    }
  }

  setData = async (data, shouldMerge = true) => {
    if (data) {
      if (shouldMerge) {
        await this.setState({
          data: Object.assign({}, this.state.data, data)
        })

        return
      }

      await this.setState({ data })
    }
  }

  setError = async error => {
    const errorStr = Array.isArray(error) ? error[0] : error
    await this.setState({ error: errorStr })
  }

  request = async (name, apiCall, data) => {
    logger.captureBreadcrumb({
      message: 'RoomsContainer.' + name,
      category: 'hosting',
      data
    })

    try {
      const [error, response] = await apiCall(data)

      if (error) {
        this.transition({ type: 'ERROR', error })
        throw new Error(error)
      } else {
        return response
      }
    } catch (error) {
      logger.captureException(error)

      return false
    }
  }

  load = async ({ room }) => {
    await this.setData(room, false)

    const dates = await this.loadDates()

    room = {
      ...room,
      unavailableDates: dates || room.unavailableDates
    }

    this.transition({ type: 'SUCCESS', data: room })
  }

  update = async ({ room: newData, callback }) => {
    // Cache initial data for recovery in case request goes wrong
    // and then optimistically update state before making request
    if (!this.state.cachedData) {
      const cachedData = this.state.data
      await this.setState({ cachedData })
    }

    await this.setData(newData)

    // The request here is extracted out because we need
    // to debounce it separately as optimistic update
    // always need to happen so that UI can be responsive
    await this.updateRequest(newData, callback)
  }

  updateRequest = async (newData, callback) => {
    await this.setState({ updating: true })

    if (this.state.cachedData) {
      newData = mergeDifference(this.state.cachedData, this.state.data)
    }

    if (!isEmpty(newData)) {
      const id = get(this.state, 'data.id')
      const res = await this.request('updateRequest', api.updateRoom, {
        id,
        room: newData
      })

      if (res) {
        const data = get(res, 'room', {})
        this.transition({ type: 'SUCCESS', data })
      } else {
        // Recover pre-update cached data if update went wrong
        const cachedData = get(this.state, 'cachedData', {})
        await this.setData(cachedData)
      }
    } else {
      // If newData is empty (ie. when updates matches original data)
      // make SUCCESS transition anyway because
      // 1) it didn't fail and 2) cleanup from UPDATE transition
      this.transition({ type: 'SUCCESS', data: {} })
    }

    await this.setState({ cachedData: undefined, updating: false })

    if (typeof callback === 'function') {
      callback()
    }
  }

  loadDates = async () => {
    const roomId = get(this.state, 'data.id')
    const res = await this.request('loadDates', api.getUnavailableDates, roomId)
    return get(res, 'unavailableDates', [])
  }

  updateDates = async ({ dates, callback = () => {} }) => {
    const currentDates = get(this.state, 'data.unavailableDates', [])

    if (currentDates.length > 0) {
      // Delete dates that differ from existent dates (ie. they were removed)
      // except for the ones that are unavailable for other reasons (eg. booked dates)
      const removedDates = currentDates.filter(
        cd =>
          cd.dueTo === 'blocked' && !dates.some(date => isEqual(cd.date, date))
      )

      if (removedDates.length > 0) {
        await this.deleteDates(removedDates)
      }

      // Exclude dates that intersect with existent dates
      dates = dates.filter(date => {
        const exists = currentDates.some(cd => isEqual(cd.date, date))
        return !exists
      })
    }

    if (dates.length > 0) {
      const roomId = get(this.state, 'data.id')

      await this.request('updateDates add', api.addUnavailableDates, {
        id: roomId,
        dates
      })
    }

    const newDates = await this.loadDates()

    const data = { unavailableDates: newDates }

    this.transition({ type: 'SUCCESS', data })

    if (typeof callback === 'function') {
      callback()
    }
  }

  deleteDates = async dates => {
    const res = await this.request(
      'deleteDates',
      api.deleteUnavailableDates,
      dates
    )

    if (res) {
      const currentDates = get(this.state, 'data.unavailableDates', [])
      const newDates = currentDates.filter(
        date => !dates.some(d => d.id === date.id)
      )
      await this.setData({
        unavailableDates: newDates
      })
    }
  }

  create = async ({ placeId, listLength, callback }) => {
    placeId = get(this.state, 'data.placeId', placeId)
    listLength = get(this.state, 'data.listLength', listLength)
    listLength++

    const data = {
      placeId,
      room: {
        name: `Guest Room #${listLength}`,
        peopleSize: '1'
      }
    }

    const res = await this.request('create', api.createRoom, data)

    if (res) {
      // Cleanup previous room data
      await this.setData({ placeId }, false)

      root.alert('Success! \n New room created!')

      // Add new room data
      const room = get(res, 'room')
      this.transition({ type: 'SUCCESS', data: { ...room, listLength } })

      if (typeof callback === 'function') {
        callback(room)
      }

      return
    }

    if (typeof callback === 'function') {
      callback()
    }
  }

  deleteRoom = async ({ callback }) => {
    const roomId = get(this.state, 'data.id')
    const res = await this.request('deleteRoom', api.deleteRoom, roomId)

    let errored

    if (res) {
      const placeId = get(this.state, 'data.placeId')
      // Cleanup deleted room data
      await this.setData({ placeId }, false)

      this.transition({ type: 'SUCCESS', data: {} })
    } else {
      errored = true
    }

    if (typeof callback === 'function') {
      callback(errored)
    }
  }

  uploadPhoto = async ({ file, callback }) => {
    if (!file) return

    const onUploadProgress = progress => {
      this.setState({ uploadProgress: progress })
    }

    const roomId = get(this.state, 'data.id')

    const params = {
      file,
      roomId,
      onUploadProgress
    }

    const res = await this.request('uploadPhoto', api.uploadRoomImage, params)

    if (res) {
      let images = get(this.state, 'data.images', [])
      images = [get(res, 'image')].concat(images)
      this.transition({ type: 'SUCCESS', data: { images } })
    }

    await this.setState({ uploadProgress: null })

    if (typeof callback === 'function') {
      callback()
    }
  }

  deletePhoto = async ({ imageId }) => {
    // Optimistically update and add progress
    let prevImages = get(this.state, 'data.images', [])
    const images = prevImages.filter(image => image.id !== imageId)
    await this.setData({ images })

    const roomId = get(this.state, 'data.id')

    const data = { imageId, roomId }

    const res = await this.request('deletePhoto', api.deletePhoto, data)

    if (res) {
      this.transition({ type: 'SUCCESS', data: { images } })
    } else {
      // Recover pre-request data if request went wrong
      await this.setData({ images: prevImages })
    }
  }
}

const container = new RoomsContainer({ statechart })

export default class RoomsStoreProvider extends React.PureComponent {
  static propTypes = {
    children: PropTypes.func.isRequired
  }

  componentDidMount () {
    this.loadData()
  }

  componentDidUpdate (prevProps) {
    if (get(prevProps, 'room.id', null) !== get(this.props, 'room.id', null)) {
      this.props.reloadData()
      this.loadData()
    }
  }

  loadData = async () => {
    const { room } = this.props
    if (room) {
      await container.transition({ type: 'LOAD', room })
    }
  }

  render () {
    return (
      <StateMachineProvider container={container}>
        {machineStore => this.props.children(machineStore)}
      </StateMachineProvider>
    )
  }
}

/**
 * Helpers
 */

function mergeDifference (prevObj, newObj) {
  return Object.keys(newObj).reduce((obj, key) => {
    const value = get(prevObj, key)
    const newValue = get(newObj, key)

    // Ignore objects
    if (typeof value !== 'object' && value !== newValue) {
      obj = { ...obj, [key]: newValue }
    }

    return obj
  }, {})
}
