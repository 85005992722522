export const getRelationshipName = (person, currentUser) => {
  if (isYourself(person, currentUser)) {
    return 'This is you'
  }
  return person.isFriend ? 'Friend' : 'Friend-of-Friend'
}

export const makeAnonymous = sp => ({
  ...sp,
  picture: null,
  firstName: 'Housemate',
  lastName: ''
})

export const isYourself = (person, currentUser) =>
  person.email === currentUser.email
