import React from 'react'

import styled from 'styled-components'

import { RenderOnAction } from 'utils/StateMachine'

import colors from 'styles/colors'

import Icon from 'components/Icon'
import { TouchableOpacity, Text, View } from 'components/Native'
import PendingRequestsModal from './PendingRequestsModal'

import withStore from './store'

const PendingRequestsButton = ({ number, onPress }) => {
  return (
    <Button onPress={onPress}>
      <Label>SEE PENDING FRIEND REQUESTS</Label>

      <BadgeWrapper>
        <Badge>{number}</Badge>
      </BadgeWrapper>

      <Caret name='caret' color='white' />
    </Button>
  )
}

const Button = styled(TouchableOpacity).attrs({ activeOpacity: 0.8 })`
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: ${colors.red};
`

const Label = styled(Text)`
  font-weight: bold;
  font-size: 16px;
  color: white;
`

const BadgeWrapper = styled(View)`
  justify-content: center;
  align-items: center;
  border-color: ${colors.darkerRed};
  background-color: ${colors.darkerRed};
  border-width: 1;
  border-radius: 14px;
  height: 28px;
  width: 28px;
  margin-left: 8px;
`

const Badge = styled(Text)`
  color: white;
`

const Caret = styled(Icon)`
  transform: rotate(-90deg);
  margin-left: 8px;
  height: 16px;
  width: 16px;
`

class PendingRequests extends React.Component {
  loadPending = () => {
    this.props.machineStore.transition({
      type: 'LOAD'
    })
  }

  toggleModal = showModal => {
    this.props.machineStore.transition({
      type: 'TOGGLE_MODAL',
      showModal
    })
  }

  resetError = () => {
    this.props.machineStore.transition({
      type: 'RESET_ERROR'
    })
  }

  manageRequest = data => {
    this.props.machineStore.transition({
      type: 'MANAGE_REQUEST',
      ...data
    })
  }

  componentDidMount () {
    this.loadPending()
  }

  render () {
    const {
      showModal,
      inProgress,
      hasMore,
      unread,
      contacts
    } = this.props.machineStore.state

    return (
      <RenderOnAction value='showLoading'>
        {loading => (
          <React.Fragment>
            {unread > 0 && (
              <PendingRequestsButton
                onPress={() => this.toggleModal(true)}
                number={unread}
              />
            )}

            <PendingRequestsModal
              {...{
                show: showModal,
                toggle: this.toggleModal,
                manageRequest: this.manageRequest,
                contacts,
                loading,
                inProgress,
                loadMore: this.loadPending,
                hasMore
              }}
            />
          </React.Fragment>
        )}
      </RenderOnAction>
    )
  }
}

export default withStore(PendingRequests)
