/* eslint-disable no-undef */
import { useEffect, useState } from 'react'

export const usePersistState = (localStorageKey = '', initialState = null) => {
  const [value, setValue] = useState(() => {
    const localStorageItem =
      typeof window !== 'undefined' &&
      window.localStorage.getItem(localStorageKey)
    if (localStorageItem === null) return initialState
    try {
      return JSON.parse(localStorageItem)
    } catch (err) {
      return initialState
    }
  })

  useEffect(() => {
    typeof window !== 'undefined' &&
      window.localStorage.setItem(localStorageKey, JSON.stringify(value))
  }, [value])

  const clearState = () =>
    typeof window !== 'undefined' &&
    window.localStorage.removeItem(localStorageKey)

  const getState = () =>
    typeof window !== 'undefined' &&
    window.localStorage.getItem(localStorageKey)

  return [value, setValue, clearState, getState]
}
