import React, { useState } from 'react'
import styled from 'styled-components'
import { useNavigate, Link } from '@reach/router'
import QueryString from 'qs'
import config from 'config'
import Loader from 'components/Loader'
import Button from '../../components/Button'
import { Container } from '../../components/CommonStyles'
import Icon from 'components/Icon'
import CheckmarkIconSrc from '../../assets/Checkmark.png'
import UncheckedRadioSrc from '../../assets/UncheckedRadio.png'
import AdventurerLogoSrc from '../../assets/adventurerlogo.png'
import { AirportData } from '../store'
import EntryScreen from './EntryScreen'

const STAGE = {
  LIST_STAGE: 'LIST_STAGE',
  EDIT_STAGE: 'EDIT_STAGE'
}

const OriginAirportsListScreen = props => {
  const navigate = useNavigate()
  const [stage, setStage] = useState(() => STAGE.LIST_STAGE)
  const [showEditPopUp, setShowEditPopUp] = useState(false)
  const [activeOriginAirport, setActiveOriginAirport] = useState(null)

  // Global store//
  const airportData = AirportData.useContainer()
  const {
    // error,
    loading,
    prime,
    // page,
    // potentialAirports,
    canAddAirports,
    originAirports,
    places,
    getAllPlaces,
    deleteOriginAirport,
    updateAirportAssociation
  } = airportData

  const _handleAddButton = () => {
    navigate(
      `${window.location.pathname}?${QueryString.stringify({
        step: 2
      })}`
    )
  }

  const _handleChangeButton = async () => {
    await getAllPlaces('airports')
    setStage(STAGE.EDIT_STAGE)
    setShowEditPopUp(false)
  }

  const _handleBackButton = () => {
    setStage(STAGE.LIST_STAGE)
    setActiveOriginAirport(null)
  }

  const _handlePopUpClose = () => {
    setShowEditPopUp(false)
    setActiveOriginAirport(null)
  }

  const _renderHeader = () => {
    return (
      <div className='title-group'>
        {shouldRenderAirportList && (
          <div>
            <div className='title'>Add Your Airports</div>
            <div className='subtitle'>
              I want Adventure Machine flight deals <br /> from these airports:
            </div>
          </div>
        )}
        {shouldRenderAirportEdit && (
          <div>
            <div className='nav-container'>
              <div className='back-button' onClick={_handleBackButton}>
                <Icon name='arrow' />
              </div>
            </div>
            <div className='title'>Airport-Place Association</div>
            <div className='subtitle editSubtitle'>
              {activeOriginAirport.name}
              <p className='subtitle-note'>{'New York City'}</p>
            </div>
            <div className='subheader'>
              I want to associate this airport wth the <br />
              following place:
            </div>
          </div>
        )}
      </div>
    )
  }

  const _handleAirportEdit = data => {
    setActiveOriginAirport(data)
    setShowEditPopUp(true)
  }

  const _handleAirportDeleteButton = async () => {
    await deleteOriginAirport(activeOriginAirport.id)
    setActiveOriginAirport(null)
    setShowEditPopUp(false)
  }

  const _handleChangePlace = async (place, active) => {
    !active && updateAirportAssociation(activeOriginAirport.id, place.id)
  }

  const _renderListContent = () => {
    return (
      <div className='content-group'>
        <ul className='content-airport-list'>
          {originAirports &&
            originAirports.map(data => {
              const {
                id = '',
                airport: { regionalCity = {}, name, iataCode } = {},
                place
              } = data || {}
              const placeName = place && place.name ? place.name : ''
              return (
                <li className='list-item' key={id}>
                  <div className='list-item-content'>
                    <p className='item-name'>
                      {(regionalCity && regionalCity.name) || name}{' '}
                      {iataCode ? `(${iataCode})` : null}
                    </p>
                    <p className='address-name'>{placeName}</p>
                  </div>
                  <div className='list-item-action-wrapper'>
                    <span
                      className='text-button'
                      onClick={() => _handleAirportEdit(data)}
                    >
                      Edit
                    </span>
                  </div>
                </li>
              )
            })}
        </ul>
      </div>
    )
  }

  const _renderEditConfirmation = () => {
    return (
      <PopUpContainer>
        <div className='popup-inner-container'>
          <div className='popup-content-container'>
            <div className='popup-nav-container'>
              <div className='close-button' onClick={_handlePopUpClose}>
                <Icon name='close' />
              </div>
            </div>
            <div className='popup-content'>
              <p className='title'>Airport:</p>
              <p className='subtitle'>
                {activeOriginAirport && activeOriginAirport.airport.name}
              </p>
              <p className='title'>Associated Place:</p>
              <p className='subtitle'>
                {activeOriginAirport && activeOriginAirport.place.name}
              </p>
            </div>
            <div className='popup-action-container'>
              <ActionButton
                className='delete-button'
                circleBorder
                bold
                onClick={_handleAirportDeleteButton}
              >
                {'Delete'}
              </ActionButton>
              <ActionButton
                className='change-place-button'
                circleBorder
                bold
                onClick={_handleChangeButton}
              >
                {'Change Place Association'}
              </ActionButton>
            </div>
          </div>
        </div>
      </PopUpContainer>
    )
  }

  const _renderEditContent = () => {
    return (
      <div className='content-group edit-content-group'>
        <ul className='content-airport-list airport-place-list'>
          {places &&
            places.map(place => {
              const active = place.id === activeOriginAirport.place.id
              return (
                <li
                  className='list-item'
                  onClick={() => _handleChangePlace(place, active)}
                >
                  <div className='list-item-content'>
                    {active ? (
                      <Checkmark src={CheckmarkIconSrc} />
                    ) : (
                      <Checkmark src={UncheckedRadioSrc} />
                    )}

                    <p className='item-name'>
                      {place.name} ({place.city})
                    </p>
                  </div>
                </li>
              )
            })}
        </ul>
      </div>
    )
  }

  const _renderFooter = () => {
    return (
      <div className='footer-group'>
        {canAddAirports && shouldRenderAirportList && (
          <Button
            className='add-button'
            width={280}
            circleBorder
            bold
            onClick={_handleAddButton}
          >
            {'+ADD'}
          </Button>
        )}
        {!prime && shouldRenderAirportList && (
          <div className='free-member-disclaimer'>
            <div className='free-member-disclaimer-left'>
              <p>You are a StayCircles Free member.</p>
              <p>
                You have selected{' '}
                <span>
                  {`${originAirports.length} of your ${config.AIRPORTS_FREE}`}
                </span>{' '}
                origin airports.
              </p>
              <br />
              <p>
                Want more airports? <br /> Please upgrade your membership to
                Adventurer.
              </p>
            </div>
            <div className='free-member-disclaimer-right'>
              <img src={AdventurerLogoSrc} />
              <JoinPrimeLink to={`/app/settings/prime`} circleBorder>
                Join{' '}
              </JoinPrimeLink>
            </div>
          </div>
        )}
        {shouldRenderAirportEdit && (
          <div className='footer-note-wrapper'>
            <p className='footer-note-title'>
              Why associate a place to my airports?
            </p>
            <p className='footer-note-subtitle'>
              Associating a place to an airport means <br />
              that when your friends get flight deals to <br />
              those airports, they will see you in their <br />
              email as a possible host for that location if
              <br /> your place is available for booking{' '}
            </p>
          </div>
        )}
      </div>
    )
  }

  const shouldRenderAirportList = stage === STAGE.LIST_STAGE
  const shouldRenderAirportEdit = stage === STAGE.EDIT_STAGE

  if (loading) {
    return (
      <Container>
        <Card>
          <div className='flex flex-row justify-center items-center h-100 relative'>
            <Loader width='25px' height='35px' />
          </div>
        </Card>
      </Container>
    )
  }

  return originAirports.length === 0 ? (
    <EntryScreen page={props.page} />
  ) : (
    <Container>
      <Card>
        {_renderHeader()}
        {shouldRenderAirportList && _renderListContent()}
        {shouldRenderAirportEdit && _renderEditContent()}
        {_renderFooter()}
        {showEditPopUp && _renderEditConfirmation()}
      </Card>
    </Container>
  )
}

const Card = styled.div`
  position: relative;
  overflow: hidden;
  background-color: #ffff;
  min-width: 100%;
  border: 1px solid #f2f2f2;
  box-sizing: border-box;
  border-radius: 12px;
  display: flex;
  flex: 1;
  flex-direction: column;
  @media screen and (min-width: 992px) {
    min-width: 560px;
  }
  .title-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 2rem;
    padding-left: 2rem;
    padding-right: 2rem;
    .title {
      text-align: center;
      font-size: 1.5rem;
      font-weight: 700;
      margin-bottom: 1rem;
      color: #1a365e;
    }
    .subtitle {
      text-align: center;
      font-size: 1rem;
      font-weight: 700;
    }
    .editSubtitle {
      color: #1a365e;
      .subtitle-note {
        font-size: 0.8rem;
        line-height: 15.6px;
        margin: 0.2rem 0;
      }
    }
    .subheader {
      font-weight: 500;
      color: #000000;
      margin: 1.5rem 0;
      text-align: center;
    }
    &--icon {
      height: 100%;
      width: auto;
      max-width: 40px;
      color: #5f9bed;
    }
    &--text {
      display: flex;
      flex-direction: column;
      & > * {
        margin: 0;
        margin-left: 1rem;
      }
      .text-title {
        color: rgba(0, 0, 0, 0.4);
      }
      .text-description {
        color: rgba(0, 0, 0, 0.7);
      }
    }
  }
  .nav-container {
    padding-bottom: 4rem;
  }
  .close-button,
  .back-button {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    cursor: pointer;
    padding: 10px;
    top: 2rem;
    width: 2.5rem;
    height: 2.5rem;
    color: rgba(255, 255, 255, 0.8);
    background: rgba(0, 0, 0, 0.5);
    border-radius: 12px;
    &:hover {
      color: rgba(255, 255, 255, 1);
      background: rgba(0, 0, 0, 1);
    }
  }
  .close-button {
    right: 2rem;
  }
  .back-button {
    left: 2rem;
  }
  .search-group {
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 2rem;
    position: relative;
    .search-icon {
      color: rgba(0, 0, 0, 0.4);
      height: 1.5rem;
      width: auto;
      position: absolute;
      transform: translateX(-50%);
      z-index: 2;
      &--left {
        left: 3.25rem;
        top: 55%;
      }
      &--right {
        top: 60%;
        height: 1.2rem;
        right: 2rem;
      }
    }
    & > input {
      height: 2.8rem;
      background-color: #f7fafc;
      width: 100%;
      border: none;
      padding-left: 2.5rem;
      padding-right: 2.5rem;
      position: relative;
    }
  }
  .add-button {
    margin-top: auto;
  }
  .content-group {
    padding-left: 2rem;
    padding-right: 2rem;
    flex: 1;
    .content-airport-list {
      list-style: none;
      margin-top: 1.875rem;
      margin-bottom: 0rem;
      max-height: 400px;
      max-width: 500px;
      overflow-y: scroll;

      .list-item {
        display: flex;
        flex-direction: row;
        flex: 1;
        justify-content: space-between;
        align-items: center;
        padding: 1.5rem 1rem;
        margin: 0;
        border-top: 1px solid rgba(0, 0, 0, 0.05);
        .list-item-content {
          flex: 1;
          .item-name {
            font-size: 1.25rem;
            color: #1a365e;
            font-size: 700;
            margin-bottom: 0.375rem;
          }
          .address-name {
            font-size: 0.875rem;
            color: #999999;
            font-size: 500;
          }
        }
        .list-item-action-wrapper {
          .text-button {
            cursor: pointer;
            color: #999999;
          }
        }
      }
    }
  }
  .edit-content-group {
    .airport-place-list {
      max-height: 230px;
      .list-item {
        .list-item-content {
          flex: 1;
          flex-direction: row;
          display: flex;
          align-items: center;
          .item-name {
            font-weight: 700;
            padding-left: 1rem;
          }
        }
      }
    }
  }
  .footer-group {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .footer-note-wrapper {
      display: flex;
      flex-direction: column;
      flex: 1;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-size: 14px;
      color: #999;
      font-weight: 500;
      line-height: 19.6px;
      .footer-note-title {
        font-weight: 700;
        margin-bottom: 17px;
      }
    }
    .free-member-disclaimer {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 1rem;
      .free-member-disclaimer-left {
        p {
          margin-bottom: 0.2rem;
          span {
            font-weight: 700;
          }
        }
      }
      .free-member-disclaimer-right {
        display: flex;
        flex: 1;
        flex-direction: column;
        align-items: center;
        padding: 0 1rem;
        img {
          width: 140px;
          height: auto;
        }
      }
    }
  }
`

const PopUpContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 0.5rem;
  @media screen and (min-width: 992px) {
    padding: 0 5rem;
  }
  .popup-inner-container {
    width: 100%;
    margin: 0;
    position: relative;
    .popup-nav-container {
      padding-bottom: 4rem;
      .close-button,
      .back-button {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        cursor: pointer;
        padding: 10px;
        top: 2rem;
        width: 2.5rem;
        height: 2.5rem;
        color: rgba(255, 255, 255, 0.8);
        background: rgba(0, 0, 0, 0.5);
        border-radius: 12px;
        &:hover {
          color: rgba(255, 255, 255, 1);
          background: rgba(0, 0, 0, 1);
        }
      }
      .close-button {
        right: 2rem;
      }
      .back-button {
        left: 2rem;
      }
    }
    .popup-content-container {
      background: #fff;
      display: flex;
      flex-direction: column;
      padding: 1rem 2rem;
      flex: 1;
      font-size: 0.9rem;
      border-radius: 12px;
      .popup-content {
        .title {
          line-height: 19.6px;
          margin-bottom: 2px;
          color: #999999;
        }
        .subtitle {
          margin-bottom: 10px;
          color: #000;
          font-weight: 500;
          line-height: 19.6px;
        }
      }
      .popup-action-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        flex: 1;
        width: 100%;
        margin: 1rem 0 0;
        background-color: #ffffff;
        .change-place-button {
          color: #2d89f8;
          border: 2px solid #2d89f8;
          &:hover {
            color: #fff;
          }
        }
      }
    }
  }
`
const Checkmark = styled.img``

const ActionButton = styled(Button)`
  background-color: transparent;
  border: 2px solid #999999;
  color: #999999;
  font-weight: 500;
  font-size: 0.8rem;
  margin: 0;
  width: initial;
  padding: 10px;
  margin-right: 1rem;
  &:hover {
    color: #fff;
  }
  @media (min-width: 992px) {
    font-size: 0.8rem;
    padding: 10px 20px;
    margin-right: 2rem;
  }
`
const JoinPrimeLink = styled(Link)`
  background-color: #f18472;
  color: #FFFFFF;
  width: ${({ width, fluid }) => (fluid ? '100%' : `${width || 100}px`)};
  padding: 18px 0;
  text-align: center;
  ${({ circleBorder }) => (circleBorder ? 'border-radius: 30px;' : '')}
  ${({ bottomBorder }) =>
    bottomBorder
      ? 'border-bottom-right-radius: 12px;border-bottom-left-radius: 12px;'
      : ''}
  font-weight: ${({ bold }) => (bold ? 700 : 400)};
  cursor: pointer;
  text-decoration: none;
`

OriginAirportsListScreen.propTypes = {}

// export default withAirportsState(OriginAirportsListScreen)
export default OriginAirportsListScreen
