import React from 'react'

import styled from 'styled-components'
import Icon from 'components/Icon'

import colors from 'styles/colors'

const friendshipNextSteps = [
  'Host accepts stay proposal.',
  'You have a lovely stay.'
]

const businessNextSteps = [
  'Host accepts stay proposal.',
  'Your payment method is applied.',
  'You have a lovely stay.',
  'Payouts to you and your host.'
]

const NextStepsList = ({ bookingType, isActive, ...props }) => {
  const nextSteps =
    bookingType === 'business' ? businessNextSteps : friendshipNextSteps

  return (
    <NextStepsWrapper active={isActive} {...props}>
      <NextText active={isActive}>
        Next
        {isActive && ' steps'}
      </NextText>

      {nextSteps.map(step => (
        <NextStep key={step}>
          <NextStepIcon active={isActive ? true : undefined} />
          <NextStepText active={isActive ? true : undefined}>
            {step}
          </NextStepText>
        </NextStep>
      ))}
    </NextStepsWrapper>
  )
}

const NextStepsWrapper = styled.div`
  flex: ${props => (props.noFlex ? 0 : 1)};
  padding: 8px 24px;
  background-color: ${props =>
    props.noBg
      ? 'transparent'
      : props.active
        ? colors.activeYellow
        : colors.white};
  @media (min-width: 992px) {
    padding: 16px 24px;
  }
`

const NextText = styled.p`
  font-size: 0.825rem;
  color: ${props => (props.active ? colors.darkBlue : colors.blackRGBA(0.4))};
  margin-bottom: 16px;
  @media (min-width: 992px) {
    font-size: 1.125rem;
  }
`

const NextStep = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;
`

const NextStepText = styled.p`
  margin: 0;
  font-size: 0.7rem;
  color: ${props =>
    props.active ? colors.blackRGBA(0.7) : colors.blackRGBA(0.5)};
  @media (min-width: 992px) {
    font-size: 0.875rem;
  }
`

const NextStepIcon = styled(Icon).attrs({
  name: 'reload'
})`
  color: ${props =>
    props.active ? colors.blackRGBA(0.5) : colors.blackRGBA(0.3)};
  height: 1.125rem;
  width: 1.125rem;
  margin-right: 6px;
`

export default NextStepsList
