const getCircularReplacer = () => {
  const seen = []
  return (key, value) => {
    if (typeof value === 'object' && value !== null) {
      if (seen.indexOf(value) >= 0) {
        return
      }
      seen.push(value)
    }
    return value
  }
}

/**
 * This avoids the error "JSON.stringify cannot serialize cyclic structures."
 *
 * Reference: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Errors/Cyclic_object_value
 */
export default function stringifyCyclic (obj) {
  return JSON.stringify(obj, getCircularReplacer())
}
