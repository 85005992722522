import React from 'react'
import styled from 'styled-components'
import { Provider } from 'unstated'
import { Router, Redirect } from '@reach/router'
// import { PAGE_CONFIG } from './config'
import Header from './components/Header'
import DealsScreen from './FlightDeals'
import AirportScreen from './Airports'
// import DestinationWithFriendsScreen from './DestinationWithFriends'
// import RecommendVisit from './RecommendVisit'

const MainAdventureContainer = styled.div`
  width: 100%;
  background-color: #f7fbfc;
  display: flex;
  flex-direction: column;
  flex: 1;
`
const MainAdventureRouter = styled(Router)`
  width: 100%;
  display: flex;
  flex: 1;
`
// const SCREEN_MAPPING = {
//   [PAGE_CONFIG.DEALS]: DealsScreen,
// }

const AdventureIndex = () => {
  return (
    <Provider>
      <MainAdventureContainer>
        <Header />
        <MainAdventureRouter>
          <Redirect noThrow from='/' to='deals' />
          <DealsScreen path='deals/*' />
          <AirportScreen path='manage_airports/*' />
          {/* <DestinationWithFriendsScreen path='destination_with_friends/*' />
          <RecommendVisit path='recommend_visit/*' /> */}
        </MainAdventureRouter>
      </MainAdventureContainer>
    </Provider>
  )
}

export default AdventureIndex
