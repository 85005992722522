import pluralize from 'pluralize'

import api from 'utils/api'
import uploadImage from 'utils/upload-image'
import root from 'utils/windowOrGlobal'
import logger from 'utils/logger'

export async function createRoom ({ placeId, room }) {
  return api.post('/rooms', { placeId, room })
}

export async function getRooms (placeId) {
  return api.get('/places/' + placeId + '/rooms')
}

export async function updateRoom ({ id, room }) {
  return api.put('/rooms/' + id, { room })
}

export async function getUnavailableDates (id) {
  return api.get('/rooms/' + id + '/unavailable_dates')
}

export async function addUnavailableDates ({ id, dates }) {
  const data = {
    roomId: id,
    unavailableDates: dates
  }

  return api.post('/unavailable_dates', data)
}

export async function deleteUnavailableDates (dates) {
  // Unfortunately this API needs some work to accept multiple dates
  // @TODO: Ask Vishal to change this behavior
  const responses = await Promise.all(
    dates.map(date => api.delete('/unavailable_dates/' + date.id))
  )

  const response = responses.find(res => !!res[0] || !!res[1])

  return response
}

export const uploadRoomImage = async ({ file, roomId, onUploadProgress }) => {
  try {
    const formData = new root.FormData()

    formData.append('upload_preset', 'uploads')
    formData.append('folder', '/rooms/' + roomId)
    formData.append('file', file, `room-${roomId}`)

    const [error, data] = await uploadImage(formData, onUploadProgress)

    if (error) {
      return [error, data]
    } else {
      const imageData = {
        cloudinaryImgUrl: data.secure_url,
        cloudinaryPublicId: data.public_id
      }

      const config = { 'content-type': 'multipart/form-data' }

      const url = '/rooms/' + roomId + '/upload_image'

      return api.post(url, imageData, config)
    }
  } catch (error) {
    return [error]
  }
}

export const deletePhoto = ({ roomId, imageId }) => {
  const data = { imageId }
  return api.delete('/rooms/' + roomId + '/remove_image', data)
}

export async function deleteRoom (id) {
  const lookup = await deleteRoomLookup(id)

  if (!lookup) return [null, false]

  logger.captureBreadcrumb({
    message: 'EditRoom.delete',
    category: 'hosting',
    data: { id }
  })

  try {
    const [error] = await api.delete('/rooms/' + id)

    if (error) {
      throw new Error(error)
    } else {
      root.alert('Room deleted successfully')
      return [null, true]
    }
  } catch (error) {
    logger.captureException(error)

    const errorStr = Array.isArray(error) ? error[0] : error

    root.alert(
      `Uh oh! \nAn error was encountered while trying to process. Please try again. \n(${errorStr})`
    )

    return [null, false]
  }
}

export async function deleteRoomLookup (id) {
  logger.captureBreadcrumb({
    message: 'Rooms API deleteRoomLookup',
    category: 'hosting',
    data: { id }
  })

  const [err, lookup] = await api.get('/rooms/' + id + '/destroy_lookup')

  if (err) {
    logger.captureException(new Error(err))

    const errorStr = Array.isArray(err) ? err[0] : err

    root.alert(
      `Uh oh! \nAn error was encountered while trying to process. Please try again. \n(${errorStr})`
    )

    return false
  }

  let stays = []

  if (lookup) {
    stays = Object.keys(lookup).reduce(
      (all, key) => all.concat(lookup[key]),
      []
    )
  }

  if (stays.length > 0) {
    const length = stays.length
    const thereIsText = pluralize('is', length)
    const staysText = pluralize('stay', length)

    root.alert(
      `Cannot delete room \nThere ${thereIsText} ${length} upcoming ${staysText} in this room. In order to delete this room, first you'll need to cancel all the stays associated with it.`
    )
    return
  }
  return true
  // return new Promise(resolve => {

  // const confirmed = root.confirm(
  //   `Are you sure? \nDeleting this room will permanently remove it from your account and the StayCircles platform.`
  // )

  // if (confirmed) {
  //   resolve(true)
  // } else {
  //   resolve(false)
  // }
  // })
}
