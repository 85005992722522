import React from 'react'

import get from 'lodash/get'

import { Link, Router } from '@reach/router'

import parseQueryString from 'utils/parse-querystring'

import styled from 'styled-components'

import colors from 'styles/colors'

import TransactionsList from './screens/TransactionsList'
import TransactionDetails from './screens/TransactionDetails'
import StayDetails from 'app/screens/stays/components/StayDetails'

const isPathActive = path => ({ location }) => {
  const isCurrent = location.pathname.match(path)
  return isCurrent ? { active: 1 } : null
}

const isHostActive = isPathActive(/host/)
const isTravelerActive = isPathActive(/traveler/)
const isPrimeActive = isPathActive(/subscription/)

class StaysIndex extends React.Component {
  state = {
    filter: 'All',
    log: undefined
  }

  changeFilter = filter => {
    this.setState({ filter })
  }

  setLog = log => this.setState({ log })

  componentDidMount () {
    if (!this.props.location.pathname.match(/host|traveler|prime/)) {
      this.props.navigate('./host')
    }
  }

  render () {
    const { filter, log } = this.state

    const { location, navigate } = this.props

    const queryString = get(location, 'search', '')
    const query = parseQueryString(queryString)

    return (
      <Container>
        <div className='flex flex-column items-center justify-center center w-100 mw7 mb4'>
          <h1 className='w-100 tc mb4'>Transactions</h1>

          <div className='w-100 bb b--black-10 pb3 mb4 tc'>
            <TabButton to='host' getProps={isHostActive}>
              Host
            </TabButton>
            <TabButton to='traveler' getProps={isTravelerActive}>
              Traveler
            </TabButton>
            <TabButton to='subscription' getProps={isPrimeActive}>
              Subscription
            </TabButton>
          </div>

          <Router className='w-100'>
            <TransactionsList
              path='host/*'
              default
              type='host'
              filter={filter}
              setLog={this.setLog}
            />
            <TransactionsList
              path='traveler/*'
              type='traveler'
              filter={filter}
              setLog={this.setLog}
            />
            <TransactionsList
              path='subscription/*'
              type='prime'
              filter={filter}
              setLog={this.setLog}
            />
          </Router>

          {!!log && (
            <TransactionDetails
              log={log}
              handleClose={() => this.setLog()}
              navigate={navigate}
              location={location}
            />
          )}
          {query.convo && (
            <StayDetails
              itemId={query.stay}
              convoId={query.convo}
              navigate={navigate}
              location={location}
            />
          )}
        </div>
      </Container>
    )
  }
}

const Container = styled.div`
  background-color: ${colors.ice};
  padding-top: 4rem;
  width: 100%;
  overflow: auto;
`

export const TabButton = styled(Link)`
  border: none;
  background-color: transparent;
  text-decoration: none;
  cursor: pointer;
  border-bottom: 2px solid transparent;
  padding: 0.5rem 0.25rem;
  margin-right: 2rem;
  font-size: 1rem;
  color: ${colors.blackRGBA(0.5)};
  transition: all 0.25s ease;

  &[active] {
    color: ${colors.blackRGBA(0.75)};
    border-bottom-color: ${colors.darkBlue};
  }

  &:hover,
  &:focus {
    color: ${colors.blackRGBA(0.75)};
    opacity: 0.9;
    border-bottom-color: ${colors.darkBlue};
  }

  &:active {
    opacity: 0.7;
  }
`

export const LinkButton = styled.button.attrs({ type: 'button' })`
  border: none;
  background-color: transparent;
  color: ${colors.blackRGBA(0.6)};
  cursor: pointer;
  padding: 0 0 2px;
  border-bottom: 2px solid transparent;
  transition: all 0.25s ease;

  &:hover,
  &:focus {
    opacity: 0.9;
    border-bottom-color: ${colors.blackRGBA(0.6)};
  }

  &:active {
    opacity: 0.7;
  }
`

export const Caret = styled.div`
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid ${colors.blackRGBA(0.6)};
  margin-left: 0.25rem;
`

export default StaysIndex
