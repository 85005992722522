import React, { Fragment } from 'react'

import { get, capitalize, isEmpty, isUndefined } from 'lodash'

// import { navigate } from 'gatsby'

// import * as routes from 'config/routes'

import styled from 'styled-components'

import colors from 'styles/colors'

import { headerHeight } from 'components/Header'
import Icon from 'components/Icon'
import { Checkbox } from 'components/Checkbox'

// import Button from 'components/ButtonPill'

const Sidebar = ({ type, infoStore, placesInfo }) => {
  const isBusiness = type === 'business'

  const { hostingInfo, preferences } = infoStore.state

  const placesActive = get(placesInfo, 'hasPlaces')
  const roomsActive = get(placesInfo, 'hasRooms')

  const staypalsActive = get(preferences, 'staypals')
  const staypals2Active = get(preferences, 'staypals2')
  const preferencesActive = staypalsActive || staypals2Active

  const isActive = placesActive && roomsActive && preferencesActive

  // Only show loading state if no info was loaded yet
  const loading =
    (infoStore.matchesActions('showLoading') && isEmpty(hostingInfo)) ||
    isUndefined(isActive)

  const statusText = isActive ? 'ON' : 'OFF'
  const statusTooltip = isActive ? 'TURN OFF' : 'TURN ON'
  const toggleStatus = () => {
    if (isActive) {
      infoStore.togglePreference('none')
    } else {
      infoStore.togglePreference('staypals')
      setTimeout(() => {
        infoStore.togglePreference('staypals2')
      }, 100)
    }
  }

  return (
    <Fragment>
      <SidebarFiller />
      <Container active={isActive} loading={loading}>
        <StatsBox isBusiness={isBusiness} info={hostingInfo} />

        <Wrapper>
          <div className='flex flex-row justify-center items-center tc mb4-l mb2'>
            <Title>{capitalize(type)} Stay Hosting</Title>

            <Status
              active={isActive}
              loading={loading}
              data-rh={statusTooltip}
              onClick={toggleStatus}
            >
              {loading ? '...' : statusText}
            </Status>
          </div>

          <Checklist
            placesActive={placesActive}
            roomsActive={roomsActive}
            preferencesActive={preferencesActive}
          />

          <hr className='bb b--black-0125 mv4-l mv2' />

          <SocialPreferences
            staypalsActive={staypalsActive}
            staypals2Active={staypals2Active}
            placesActive={placesActive}
            info={hostingInfo}
            onChange={infoStore.togglePreference}
          />

          <PotentialGuests info={hostingInfo} />
        </Wrapper>
      </Container>
    </Fragment>
  )
}

const StatsBox = ({ isBusiness, info }) => {
  const year = new Date().getFullYear()
  const earned = get(info, 'totalEarnedYear', 0)
  const nights = get(info, 'totalNightsYear', 0)

  return (
    <StatsBoxContainer>
      {isBusiness && (
        <StatsColumn>
          <StatsIcon name='salary' />
          <StatsInfoColumn>
            <StatsLabel>
              EARNED
              {'\n'}
              IN {year}
            </StatsLabel>
            <StatsValue>${earned}</StatsValue>
          </StatsInfoColumn>
        </StatsColumn>
      )}

      {isBusiness && <StarsLine />}

      <StatsColumn>
        <StatsIcon name='night' />
        <StatsInfoColumn>
          <StatsLabel>
            NIGHTS HOSTED
            {'\n'}
            IN {year}
          </StatsLabel>
          <StatsValue>{nights}</StatsValue>
        </StatsInfoColumn>
      </StatsColumn>
    </StatsBoxContainer>
  )
}

const Checklist = ({ placesActive, roomsActive, preferencesActive }) => {
  const list = {
    Place: placesActive,
    Room: roomsActive,
    Preference: preferencesActive
  }

  return (
    <div className='flex flex-row flex-column-md justify-center items-center'>
      {Object.keys(list).map(key => (
        <div key={key} className='flex flex-row items-center pv2 mr3'>
          <span className='dn-l mr2'>
            {list[key] ? <CheckmarkIcon /> : <CrossIcon />}
          </span>

          <ChecklistLabel>{key}</ChecklistLabel>

          <span className='dn di-l'>
            {list[key] ? <CheckmarkIcon /> : <CrossIcon />}
          </span>
        </div>
      ))}
    </div>
  )
}

const SocialPreferences = ({
  staypalsActive,
  staypals2Active,
  placesActive,
  info,
  onChange
}) => {
  const staypals = get(info, 'totalStaypals', 0)
  const staypals2 = get(info, 'totalStaypals2', 0)

  const list = [
    {
      name: 'staypals',
      checked: staypalsActive,
      label: 'Friends',
      helper: 'your friends',
      guests: staypals
    },
    {
      name: 'staypals2',
      checked: staypals2Active,
      label: 'Friends-of-Friends',
      helper: 'friends-of-friends',
      guests: staypals2
    },
    {
      name: 'none',
      checked: !staypalsActive && !staypals2Active,
      label: 'None',
      helper: 'I want to receive no\nfriendship stay proposals'
    }
  ]

  return (
    <div className='mb4-l mb2'>
      <h4 className='f5 fw5 black-90 mb2'>Preference</h4>
      <p className='f5 black-40 mb4-l mb2 '>
        Friends / Friends-of-Friends can send you stay proposals.
      </p>

      <p className='f5 black-60 mb2 mb4-l'>
        I will consider friendship travel stay proposals from:
      </p>

      {list.map(item => (
        <div key={item.name} className='w-100 mb4-l mb2'>
          <Checkbox
            name={item.name}
            value={!!item.checked}
            onChange={e => onChange(item.name)}
            disabled={!placesActive}
            renderLabel={() => (
              <div className='ml2'>
                <span className='black-60'>{item.label}</span>
                {typeof item.guests !== 'undefined' && (
                  <span className='black-40 i ml1'>
                    ({item.guests} potential guests)
                  </span>
                )}
                <br />
                <span className='black-40 pre'>{item.helper}</span>
              </div>
            )}
          />
        </div>
      ))}
    </div>
  )
}

const PotentialGuests = ({ info }) => {
  const staypals = get(info, 'totalStaypals', 0)
  const staypals2 = get(info, 'totalStaypals2', 0)
  const total = staypals + staypals2

  return (
    <div className='tc pb2'>
      <div className='tc di v-mid'>
        <span>You have</span>
        <span className='f3 v-mid'> {total} </span>
        <span>potential guests. </span>
      </div>

      <div className='di'>
        {/* <Button
          onClick={() => {
            navigate(routes.EXPLORE + '?activePanel=inviteSps')
          }}
          hoverBackground={colors.blueRGBA(0.1)}
          color={colors.darkBlue}
          border={colors.darkBlue}
        >
          Invite Friends
        </Button> */}
      </div>
    </div>
  )
}

const Container = styled.div`
  background-color: ${props =>
    props.active || props.loading ? colors.ice : 'hsla(9, 100%, 98%, 1)'};
  color: ${colors.blackRGBA(0.7)};
  padding: 0 1rem;

  @media (min-width: 992px) {
    z-index: 0;
    position: fixed;
    top: ${headerHeight};
    height: calc(100% - ${headerHeight});
    border-right: 1px solid ${colors.blackRGBA(0.05)};
    left: 10rem;
    width: 30%;
    flex: 0 0 33%;
    overflow-y: auto;
    padding: 0;
  }

  // @media (min-width: 1025px) {
  //   width: 38%;
  //   left: 10rem;
  //   // padding: 1rem;
  // }

  // @media (min-width: 1400px) {
  //   width: 30%;
  // }

  @media (min-width: 1600px) {
    width: 26%;
  }

  &::-webkit-scrollbar {
    width: 0.2em;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: ${colors.blackRGBA(0.1)};

    &:hover {
      background-color: ${colors.blackRGBA(0.3)};
    }

    &:active {
      background-color: ${colors.blackRGBA(0.4)};
    }
  }
`

const SidebarFiller = styled(Container)`
  position: relative;
  z-index: -1;
  border: none;
  left: inherit;
  width: 51%;
  background: #fff;

  @media (min-width: 992px) {
    position: relative;
    z-index: -1;
    border: none;
    left: inherit;
    width: 51%;
    background: #fff;
  }

  @media (max-width: 1024px) {
    width: 65%;
    left: inherit;
  }

  @media (min-width: 1400px) {
    width: 50%;
  }

  @media (min-width: 1600px) {
    width: 39%;
  }
`

const Wrapper = styled.div`
  @media (min-width: 1025px) {
    padding: 1rem;
  }

  @media (min-width: 1400px) {
    padding: 2rem;
  }
`

const StatsBoxContainer = styled.div`
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: ${colors.ice};

  @media (min-width: 1400px) {
    padding: 2rem;
  }
`

const StatsColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const StarsLine = styled.div`
  width: 1px;
  height: 80px;
  background-color: ${colors.blackRGBA(0.1)};
  margin: 0 16px;
`

const StatsIcon = styled(Icon)`
  width: 3rem;
  height: 3rem;
  color: ${colors.blackRGBA(0.1)};
  margin-right: 10px;
`

const StatsInfoColumn = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  @media (min-width: 992px) {
    flex-direction: column;
  }
`

const StatsLabel = styled.span`
  font-size: 0.875rem;
  letter-spacing: 0.2px;

  @media (min-width: 1025px) {
    white-space: pre;
  }
`

const StatsValue = styled.span`
  font-size: 2rem;
`

const Title = styled.span`
  font-size: 1rem;
  margin-right: 1rem;

  @media (min-width: 1400px) {
    font-size: 1.25rem;
    margin-right: 1.5rem;
  }
`

const Status = styled.span`
  border: 2px solid
    ${props =>
    props.loading
      ? colors.blackRGBA(0.3)
      : props.active
        ? 'rgba(150, 214, 177, 0.8)'
        : colors.terraCotta};
  border-radius: 6px;
  color: ${props =>
    props.loading
      ? colors.blackRGBA(0.3)
      : props.active
        ? colors.lightGreen
        : colors.terraCotta};
  text-align: center;
  padding: 0.3rem 0.8rem;
  font-size: 0.7rem;
  font-weight: 600;
  cursor: pointer;

  @media (min-width: 992px) {
    padding: 0.375rem 1rem;
    font-size: 1rem;
  }

  @media (min-width: 1400px) {
    font-size: 1.25rem;
    padding: 0.5rem 1.25rem;
  }
`

const ChecklistLabel = styled.span`
  font-size: 1rem;
  margin-right: 3px;

  @media (min-width: 1025px) {
    margin-right: 0.5rem;
  }

  @media (min-width: 1400px) {
    font-size: 1.25rem;
  }
`

const CheckmarkIcon = styled(Icon).attrs({ name: 'checkmark' })`
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 1.25rem;
  color: ${colors.darkBlue};

  @media (min-width: 1025px) {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 1.5rem;
  }
`

const CrossIcon = styled(CheckmarkIcon).attrs({ name: 'close' })`
  color: ${colors.terraCotta};
  padding: 0.3rem;
  border: 1px solid ${colors.terraCotta};
`

export default Sidebar
