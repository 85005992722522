import React from 'react'
import styled from 'styled-components'
import colors from 'styles/colors'

import ButtonPill from 'components/ButtonPill'

const InfoAlert = ({ title, subTitle, onClose, closeText = 'Ok' }) => {
  return (
    <CustomAlert>
      <h1>{title}</h1>
      {subTitle && <p>{subTitle}</p>}
      <div className='flex flex-row justify-between items-center w-100'>
        <ButtonPill
          onClick={() => {
            onClose()
          }}
          background={colors.blue}
          hoverBackground={colors.darkBlue}
          color={colors.white}
          border={colors.whiteRGBA(0.1)}
        >
          {closeText}
        </ButtonPill>
      </div>
    </CustomAlert>
  )
}

const CustomAlert = styled.div`
  text-align: left;
  width: calc(100% - 2%);
  max-width: 500px;
  padding: 40px;
  margin: 0 1%;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.23);
  @media (min-width: 992px) {
    width: 100%;
    margin: 0;
  }
`

export default InfoAlert
