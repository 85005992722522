import React from 'react'

import get from 'lodash/get'

import styled from 'styled-components'

import { RenderOnAction } from 'utils/StateMachine'

import withAddressVisibilityStore from './store'

import { CheckboxInput, CheckmarkIcon } from 'components/Checkbox'

import colors from 'styles/colors'

class AddressIndex extends React.Component {
  shouldComponentUpdate (nextProps) {
    if (
      nextProps !== this.props ||
      nextProps.machineStore.state !== this.props.machineStore.state
    ) {
      return true
    }

    return false
  }

  componentDidMount () {
    this.props.machineStore.transition('LOAD')
  }

  get isAddressPublic () {
    return get(this.props.machineStore, 'state.event.isAddressPublic', true)
  }

  handleToggle = () => {
    this.props.machineStore.transition({
      type: 'CHANGE_VISIBILITY',
      isAddressPublic: !this.isAddressPublic
    })
  }

  makeAddressPublic = () => {
    if (this.isAddressPublic) {
      return
    }

    this.handleToggle()
  }

  makeAddressPrivate = () => {
    if (!this.isAddressPublic) {
      return
    }

    this.handleToggle()
  }

  render () {
    const { isAddressPublic } = this

    return (
      <RenderOnAction value='showLoading'>
        {loading => (
          <div className='flex flex-column items-center justify-center center w-100 ph4 mb4'>
            <h1>Address Visibility</h1>

            <div className='mv3 w-100'>
              <p className='black-60 tc mb3'>
                Choose how location is shown on the map
              </p>

              <div className='pv2'>
                <div className='mv3'>
                  <p className='mb3 b'>
                    <Colored color={colors.blue}>Friends-of-Friends</Colored>
                  </p>
                  {/* <p className='i'>
                    StayPals2 are your friends-of-friends. They are the StayPals
                    of your StayPals.
                  </p> */}

                  <div className='flex flex-row mt3 mb4'>
                    <div className='flex flex-row items-center'>
                      <CheckboxInput
                        type='checkbox'
                        checked
                        disabled
                        readOnly
                      />
                      <CheckmarkIcon />
                      <SPIcon
                        className='ph3'
                        src='https://res.cloudinary.com/staycircles/image/upload/v1538751480/assets/staypals2-pin-cloud_wfdfly.png'
                      />
                    </div>
                    <div className='flex flex-column pr3' style={{ flex: 1 }}>
                      <p className='mb1'>General location</p>
                      <p className='gray'>
                        Your Friends-of-Friends only see the general location of
                        your place. This is the standard setting and cannot be
                        changed.
                      </p>
                    </div>
                  </div>
                </div>

                <div
                  className='mv3'
                  style={{
                    cursor: loading ? 'progress' : 'pointer'
                  }}
                >
                  <p className='mb3 b'>
                    <Colored color={colors.red}>Friends</Colored>
                  </p>
                  {/* <p className="i">
                    StayPals are your friends, those you choose to confirm on
                    the StayCircles platform, one by one.
                  </p> */}

                  <div
                    className='flex flex-row mt3 mb4'
                    onClick={this.makeAddressPublic}
                  >
                    <div className='flex flex-row items-center'>
                      <CheckboxInput
                        type='checkbox'
                        disabled={loading}
                        checked={isAddressPublic}
                        onChange={this.makeAddressPublic}
                      />
                      <CheckmarkIcon />
                      <SPIcon
                        className='ph3'
                        src='https://res.cloudinary.com/staycircles/image/upload/assets/staypals-pin-dot_nlfnog'
                      />
                    </div>
                    <div className='flex flex-column pr3' style={{ flex: 1 }}>
                      <p className='mb1'>Specific location</p>
                      <p className='gray'>
                        Show your Friends your specific location of your place.
                        This is the default setting.
                      </p>
                    </div>
                  </div>

                  <div
                    className='flex flex-row mt3 mb4'
                    onClick={this.makeAddressPrivate}
                  >
                    <div className='flex flex-row items-center'>
                      <CheckboxInput
                        type='checkbox'
                        disabled={loading}
                        checked={!isAddressPublic}
                        onChange={this.makeAddressPrivate}
                      />
                      <CheckmarkIcon />
                      <SPIcon
                        className='ph3'
                        src='https://res.cloudinary.com/staycircles/image/upload/assets/staypal-pin-cloud_aozdb5'
                      />
                    </div>
                    <div className='flex flex-column pr3' style={{ flex: 1 }}>
                      <p className='mb1'>General location</p>
                      <p className='gray'>
                        Show your Friends only your general location of your
                        place.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </RenderOnAction>
    )
  }
}

const SPIcon = styled.img`
  height: 100px;
`

const Colored = styled.span`
  color: ${props => props.color || colors.black};
`

export default withAddressVisibilityStore(AddressIndex)
