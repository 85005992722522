import api from 'utils/api'

export const changeVisibility = isAddressPublic => {
  const data = {
    preferences: {
      accountPreferences: {
        isAddressPublic
      }
    }
  }

  return api.post('/preferences', data)
}

export const getPreferences = () => {
  return api.get('/preferences')
}
