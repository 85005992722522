import React from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from '@reach/router'
import qs from 'qs'
import Button from '../../components/Button'
import { Container, Text } from '../../components/CommonStyles'

const EntryScreen = ({ page, step = 0 }) => {
  const navigate = useNavigate()
  const _onClick = () => {
    navigate(
      `${window.location.pathname}?${qs.stringify({ page, step: step + 2 })}`,
      step,
      page
    )
  }

  return (
    <Container>
      <Text>Add an airport to start receiving cheap flight deals!</Text>
      <Button width={280} circleBorder bold onClick={_onClick}>
        +ADD
      </Button>
    </Container>
  )
}

EntryScreen.propTypes = {
  page: PropTypes.string,
  step: PropTypes.number
}

export default EntryScreen
