import styled from 'styled-components'

import colors from 'styles/colors'

const ButtonPill = styled.button.attrs({
  type: props => props.type || 'button',
  className: 'bg-animate br-pill',
  background: props => props.background || 'transparent',
  hoverbackground: props => props.hoverBackground || colors.offwhite,
  color: props => props.color || colors.blackRGBA(0.5),
  border: props => props.border || colors.blackRGBA(0.1)
})`
  font-size: 1rem;
  font-weight: bold;
  padding: 0.75rem 2rem;
  background-color: ${props => props.background};
  color: ${props => props.color};
  border: 1px solid ${props => props.border};
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};

  opacity: ${props => (props.disabled ? 0.5 : 1)};

  &:hover {
    background-color: ${props => props.hoverbackground || props.background};
    color: ${props => props.hoverColor || props.color};
  }
`

export default ButtonPill
