import api from 'utils/api'

export const getSent = (page = 1) => {
  const params = {
    page,
    filter: 'outgoing'
  }

  return api.get('/connections', params)
}

export const getIgnored = async (page = 1, term = '') => {
  const params = { page, term }

  return api.get('/connections/ignored_connections', params)
}

export const sendRequest = async email => {
  const data = { emails: [email] }

  return api.post('/connections', data)
}
