import api from 'utils/api'

export const changePassword = ({ currentPassword, newPassword }) => {
  const data = {
    currentPassword,
    newPassword
  }

  return api.post('/account/change_password', data)
}
