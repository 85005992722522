import React, { Component } from 'react'

import { Link } from '@reach/router'
import styled from 'styled-components'

import get from 'lodash/get'

import Icon from 'components/Icon'
import { TextInput } from 'components/Input'

import MessagesStoreProvider from '../store/messages'

import colors from 'styles/colors'

import { getUserPicture } from 'utils/profile-info'
import { push } from 'utils/parse-querystring'
import * as routes from 'config/routes'
import root from 'utils/windowOrGlobal'

import Dropdown from 'components/SimpleDropdown'

import MessagesList from '../components/MessagesList'
import { Picture } from '../components/ChatMessage'
import EditConversationNamePrompt from '../components/EditConversationNamePrompt'
import { EmojiPicker } from 'components/emoji.js'

class Conversation extends Component {
  static defaultProps = {
    title: 'No conversation selected'
  }

  state = {
    showEditNamePrompt: false,
    text: '',
    emoji: false
  }

  get pathname () {
    return this.props.activeList === 'archive'
      ? routes.MESSAGES_ARCHIVE
      : routes.MESSAGES
  }

  toggleEmoji = () =>
    this.setState({
      emoji: !this.state.emoji
    })

  handleTextChange = e => {
    const text = e.target.value

    if (String(text).length > 1500) {
      root.alert(
        'A message cannot exceed 1500 characters. Please try sending a second message instead.'
      )

      this.input.focus()
      return
    }

    this.setState({ text })
  }

  handleEnterPress = (e, machineStore) => {
    if (e.key === 'Enter') {
      this.sendMessage(machineStore)
    }
  }

  sendMessage = (machineStore, message = this.state.text) => {
    machineStore.transition({ type: 'SEND_MESSAGE', message })
    this.setState({ text: '' })
  }

  openEditNameModal = () => {
    push({
      pathname: this.pathname,
      query: {
        ...this.props.query,
        'edit-conversation-name': true
      }
    })
  }

  closeEditNameModal = () => {
    push({
      pathname: this.pathname,
      query: {
        ...this.props.query,
        'edit-conversation-name': false
      }
    })
  }

  getRecepientPicture (machineStore) {
    const { recipientUser } = machineStore.state.data
    return getUserPicture(recipientUser, { width: 50 }, true)
  }

  getConversationTempName (machineStore) {
    const {
      recipientUsers,
      conversationType,
      recipientUser
    } = machineStore.state.data
    if (conversationType === 'shared_destination') {
      if (recipientUsers.length > 1) {
        return recipientUsers.map(single => single.firstName).join(',')
      } else {
        return `${recipientUser?.firstName || ''} ${recipientUser?.lastName ||
          ''}`
      }
    }
    return `${recipientUser?.firstName || ''} ${recipientUser?.lastName || ''}`
  }

  getDetailsLink = machineStore => {
    const id = get(machineStore, 'state.data.stayDetails.id')
    if (!id) return false
    return `${this.pathname}?conversation=${this.props.id}&stay=${id}`
  }

  handleArchive = async e => {
    e.preventDefault()
    root.document.dispatchEvent(new root.Event('click'))
    const { archive, id, activeList } = this.props
    const archiveAction = `${activeList === 'archive' ? 'un' : ''}archive`

    const ok = await archive(id, archiveAction)

    if (ok) {
      root.alert(`Success! Conversation successfully ${archiveAction}d`)
      push({
        pathname:
          activeList === 'archive' ? routes.MESSAGES : routes.MESSAGES_ARCHIVE
      })
    } else {
      root.alert(
        `Oops! Something went wrong and we couldn't ${archiveAction} this conversation. Please try again. Our team was notified and will be investigating.`
      )
    }
  }

  addEmoji = emoji => {
    this.setState({
      text: this.state.text + emoji.colons,
      emoji: false
    })

    this.input.focus()
  }

  renderUnavailableUser = () => {
    return (
      <p
        className='flex justify-center items-center ma0 pv3 ph2 flex-auto'
        style={{ backgroundColor: colors.blackRGBA(0.1) }}
      >
        This user no longer exists on StayCircles
      </p>
    )
  }

  renderFooter = machineStore => {
    const { id } = this.props
    const { recipientUsers } = machineStore.state.data
    if (
      recipientUsers &&
      recipientUsers.length === 1 &&
      recipientUsers[0]?.userExists === false
    ) {
      return this.renderUnavailableUser()
    }
    return (
      <>
        {this.state.emoji && <EmojiPicker onSelect={this.addEmoji} />}

        <MessageInput
          ref={node => {
            this.input = node
          }}
          className='w-100'
          placeholder='Send a message...'
          value={this.state.text}
          onChange={this.handleTextChange}
          onKeyPress={e => this.handleEnterPress(e, machineStore)}
          onFocus={() => {
            this.setState({ emoji: false })
          }}
          disabled={!id}
        />

        <div className='flex justify-end items-center absolute right-0'>
          <Icon
            name='smile'
            height={20}
            className='mr3 black-40 hover-black-70 pointer link'
            onClick={this.toggleEmoji}
          />

          <SendButton
            onClick={() => this.sendMessage(machineStore)}
            title='Send'
            disabled={!id}
          >
            <Icon name='send' height={18} className='white' />
          </SendButton>
        </div>
      </>
    )
  }

  render () {
    const {
      id,
      title: name,
      editName,
      activeList,
      contentHeight,
      query
    } = this.props
    const showEditNamePrompt = get(query, 'edit-conversation-name')
    const editNameLink = `${this.pathname}?conversation=${id}&edit-conversation-name=true`
    const archiveLabel = activeList === 'archive' ? 'Unarchive' : 'Archive'

    return (
      <MessagesStoreProvider>
        {machineStore => (
          <Container className='b--black-20 bl h-100 bg-black-20'>
            <div
              className='flex flex-row items-center b b--black-20 bb pa3'
              id='SC__Message__ConversationHeader'
            >
              <Picture src={this.getRecepientPicture(machineStore)} />
              <p className='black-70 f4 mb0 flex flex-auto'>
                {name || this.getConversationTempName(machineStore)}
              </p>
              {!!id && (
                <Dropdown
                  align='right'
                  renderButton={onClick => (
                    <Icon
                      name='ellipsis'
                      height={20}
                      className='black-40 hover-black-70 link pointer rotate-90 w2'
                      onClick={onClick}
                      ref={x => (this.dropdownIcon = x)}
                    />
                  )}
                  renderMenu={() => (
                    <ul>
                      <li>
                        <Link
                          to={editNameLink}
                          className='flex pointer flex-row items-center link'
                        >
                          <span>Rename</span>
                        </Link>
                      </li>

                      {this.getDetailsLink(machineStore) && (
                        <li>
                          <Link
                            to={this.getDetailsLink(machineStore)}
                            className='flex pointer flex-row items-center link'
                          >
                            <span>Show Details</span>
                          </Link>
                        </li>
                      )}

                      <li>
                        <Link
                          to='#'
                          onClick={this.handleArchive}
                          className='flex pointer flex-row items-center link'
                        >
                          <span>{archiveLabel}</span>
                        </Link>
                      </li>
                    </ul>
                  )}
                />
              )}
            </div>
            <MessagesList
              setListRef={machineStore.setListRef}
              id={id}
              messages={machineStore.state.data.messages}
              conversationType={machineStore.state.data.conversationType}
              flighDealDetails={machineStore.state.data.flighDealDetails}
              sharedDestinationGroupDetails={
                machineStore.state.data.sharedDestinationGroupDetails
              }
              conversationName={machineStore.state.data.name}
              lastUpdated={machineStore.state.data.lastUpdated}
              isViewerHost={machineStore.state.data.isViewerHost}
              detailsLink={this.getDetailsLink(machineStore)}
              load={() => machineStore.transition({ type: 'LOAD', id })}
              reload={() => machineStore.transition({ type: 'RELOAD', id })}
              unload={() => machineStore.transition('EXIT')}
              retry={() => machineStore.transition({ type: 'RETRY', id })}
              loadNextPage={() => machineStore.transition('NEXT')}
              hasMore={machineStore.state.hasMore}
              loading={machineStore.matchesActions('loadNext')}
              contentHeight={contentHeight}
            />
            <div
              className='w-100 flex items-center relative'
              id='SC__MessageInput'
            >
              {this.renderFooter(machineStore)}
            </div>
            {showEditNamePrompt && (
              <EditConversationNamePrompt
                name={name}
                onClose={this.closeEditNameModal}
                onSubmit={value => editName(id, value)}
              />
            )}
          </Container>
        )}
      </MessagesStoreProvider>
    )
  }
}

const Container = styled.div`
  background-color: white;
  flex: 1;
`

const MessageInput = styled(TextInput)`
  border: none;
  border-top: 1px solid ${colors.blackRGBA(0.1)};
  border-radius: 0px;
  padding: 1rem 7rem 1rem 2rem;
  font-size: 14px;

  &:hover {
    border: none;
    border-top: 1px solid ${colors.blackRGBA(0.1)};
  }

  &:focus {
    outline: 0;
    border: none;
    border-top: 1px solid ${colors.blackRGBA(0.1)};
    box-shadow: 0 -3px 40px rgba(0, 0, 0, 0.16);
  }

  &::placeholder {
    opacity: 1;
    color: ${colors.blackRGBA(0.5)};
    font-size: 14px;
  }
`

const SendButton = styled.button.attrs({ type: 'button' })`
  width: 40px;
  height: 40px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: ${colors.blue};
  transition: background-color 0.25s;
  margin-right: 1rem;

  &:enabled {
    cursor: pointer;
  }

  &:focus {
    outline: 0;
    background-color: ${colors.darkBlue};
  }

  &:enabled:hover {
    background-color: ${colors.darkBlue};
  }
`

export default Conversation
