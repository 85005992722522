import React from 'react'

import logger from 'utils/logger'
// import root from 'utils/windowOrGlobal'

import * as api from '../api/proposal'
import { getStays } from '../../stays/api'

import StateMachineProvider, { StateMachineContainer } from 'utils/StateMachine'

const reset = { RESET: { start: { actions: ['resetCurrentState'] } } }

// @TODO: add guards to prevent going to next state
// if current state doesn't have the required data
// @TODO: OR refactor statechart
const statechart = {
  key: 'stay-proposal',
  initial: 'start',
  states: {
    start: { on: { NEXT: 'selectDates', ...reset } },
    selectDates: { on: { NEXT: 'selectHost', ...reset } },
    selectHost: { on: { NEXT: 'chooseRoom', ...reset } },
    chooseRoom: { on: { NEXT: 'inputPrice', ...reset } },
    inputPrice: { on: { NEXT: 'selectPayment', ...reset } },
    selectPayment: { on: { NEXT: 'reviewProposal', ...reset } },
    reviewProposal: {
      onEntry: 'resetError',
      on: { SEND: 'sending', ...reset }
    },
    sending: {
      onEntry: ['showSending', 'sendStayProposal'],
      on: {
        LOAD_SUCCESS: 'success',
        LOAD_FAILURE: 'error'
      }
    },
    success: {
      onEntry: ['showSuccess'],
      on: {
        RESET: 'start'
      }
    },
    error: {
      onEntry: 'showError',
      on: { RECOVER: 'reviewProposal' }
    }
  }
}

const stateDataKeys = {
  selectDates: ['startDate', 'endDate', 'guests'],
  selectHost: ['host'],
  chooseRoom: ['rooms'],
  inputPrice: ['price'],
  selectPayment: ['card']
}

class ProposalContainer extends StateMachineContainer {
  constructor (props) {
    super(props)

    // Make sure to keep the state machine engine
    const stateMachine = this.state

    this.state = {
      ...stateMachine,
      currentUser: props.currentUser,
      data: {
        stayFor: undefined, // business | fun
        startDate: undefined, // yyyy/MM/DD
        endDate: undefined, // yyyy/MM/DD
        guests: undefined, // number
        host: undefined, // host user object { id, name, city }
        rooms: undefined, // Array[room object { id, name, sleeps }]
        price: undefined, // price proposed object { night, total }
        card: undefined // card object { id, brand, last4 }
      },
      stayData: {},
      errorMsg: null
    }
  }

  componentDidTransition (prevStateMachine, event) {
    if (event && event.type) {
      switch (event.type) {
        case 'NEXT':
          logger.captureBreadcrumb({
            message: 'BOOKING transition NEXT',
            category: 'stay-proposal',
            data: event
          })

          this.setData(event.data, event.next)
          this.checkBookingType(event)
          break
        case 'LOAD_SUCCESS':
          this.setStayData(event)
          break
        case 'LOAD_FAILURE':
          this.alertError(event.error)
          break
      }
    } else if (event === 'NEXT') {
      logger.captureBreadcrumb({
        message: 'BOOKING transition NEXT',
        category: 'stay-proposal',
        data: event
      })

      this.checkBookingType(event)
    }
  }

  async checkBookingType (event) {
    const { data, machineState } = this.state

    if (data.stayFor !== 'business' && machineState.value === 'inputPrice') {
      await this.transition('NEXT')
      await this.transition('NEXT')
    }
  }

  setData (data = {}, callback = () => {}) {
    this.setState(
      {
        data: {
          ...this.state.data,
          ...data
        }
      },
      callback
    )
  }

  setStayData ({ data, next }) {
    this.setState({ stayData: data }, next)
  }

  async resetCurrentState (event = {}) {
    // Walk from start to *new* current state
    // eg. if current state is 'selectPayment' but we call reset on 'selectHost',
    // go back to start and make transition NEXT once so it reaches 'selectHost' as current state.
    // Make sure to use AWAIT on transition
    if (!event.from) return

    let state = this.state.machineState
    let currentState = state.value

    while (event.from !== currentState) {
      state = await this.transition('NEXT')
      currentState = state.value
    }

    // Cleanup any data from future states as they will be reset too.
    const states = Object.keys(stateDataKeys)
    const currIndex = states.findIndex(key => key === event.from)
    const nextStates = states.slice(currIndex + 1, states.length)
    const dataToClear = nextStates.reduce((data, stateKey) => {
      stateDataKeys[stateKey].forEach(key => {
        data[key] = null
      })
      return data
    }, {})

    this.setData(dataToClear, event.next)
  }

  alertError = error => {
    const errorStr = Array.isArray(error) ? error[0] : error
    this.setState({ errorMsg: errorStr.toString() })
    // root.alert(
    //   `Uh oh! An error was encountered while trying to process.
    //   (${errorStr})`
    // )

    // this.transition('RECOVER')
  }

  resetError = () => {
    this.setState({ errorMsg: null })
  }

  sendStayProposal = async ({ next }) => {
    const { data } = this.state

    logger.captureBreadcrumb({
      message: 'ProposalContainer.sendStayProposal',
      category: 'stay-proposal',
      data
    })

    let proposalData = {
      stay_for: data.stayFor,
      start_date: data.startDate,
      end_date: data.endDate,
      guests: data.guests,
      proposed_stay_ids: data.rooms.map(r => r.id),
      host_id: data.host.id
    }

    if (data.stayFor === 'business') {
      proposalData = {
        ...proposalData,
        price_proposal_type: 'comparable',
        price_per_night: data.price.night,
        payment_method_id: data.card.id
      }
    }

    try {
      const [staysError, staysResponse] = await getStays({
        page: 1,
        filter: 'upcoming'
      })
      if (!staysError && staysResponse && staysResponse.stays) {
        const isAlreadyRequested = staysResponse.stays.find(stay => {
          const proposedStartDate = new Date(
            `${proposalData.start_date}`
          ).getTime()
          const proposedEndDate = new Date(
            `${proposalData.start_date}`
          ).getTime()
          const stayStartDate = new Date(`${stay.startDate}`).getTime()
          const stayEndDate = new Date(`${stay.endDate}`).getTime()
          if (
            (proposedStartDate >= stayStartDate &&
              proposedEndDate <= stayStartDate) ||
            (proposedEndDate >= stayStartDate && proposedEndDate <= stayEndDate)
          ) {
            return true
          }
        })
        if (isAlreadyRequested) {
          throw new Error(
            'You have already requested stay between the proposed dates'
          )
        }
      }
      const [error, response] = await api.createStayProposal(proposalData)

      if (error) {
        throw new Error(error)
      } else {
        this.transition({ type: 'LOAD_SUCCESS', data: response.stay, next })
        return true
      }
    } catch (error) {
      logger.captureException(error)

      this.transition({ type: 'LOAD_FAILURE', error })
      return false
    }
  }
}

export default class ProposalStoreProvider extends React.Component {
  constructor (props) {
    super(props)
    this.container = new ProposalContainer({
      statechart,
      currentUser: props.currentUser
    })
  }

  render () {
    return (
      <StateMachineProvider container={this.container}>
        {machineStore => this.props.children(machineStore)}
      </StateMachineProvider>
    )
  }
}
