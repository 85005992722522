import React from 'react'

import { Location, navigate } from '@reach/router'
import get from 'lodash/get'

import styled from 'styled-components'
import { AuthProvider } from 'stores/auth'

import PrimeStoreProvider from '../../prime/_store'

import Button from 'components/Button'
import queryString from 'utils/parse-querystring'
import root from 'utils/windowOrGlobal'

// import config from 'config'
import { getCheckoutSessionId, getManageStripeSubscriptionUrl } from '../_api'
import { FullPageLoader } from '../../../../../../components/Loader'
import SubscriptionPlansRenderer from './_SubscriptionPlansRenderer'

const firstClass = planObj => {
  if (/first/i.test(get(planObj, 'planDetails.subscriptionClass', ''))) {
    return true
  }
  if (/jetsetter/i.test(get(planObj, 'planDetails.name', ''))) {
    return true
  }
  return false
}

const getCurrentPlan = planObj => {
  if (firstClass(planObj)) {
    return 'primeFirst'
  } else if (planObj) {
    return 'prime'
  }
  return 'free'
}
// const planOrder = { free: 1, prime: 2, prime_first: 3, primeFirst: 3 }
// const packages = {
//   prime: [
//     {
//       id: 11,
//       // intervalPeriod: '12',
//       duration: '12',
//       durationType: 'months',
//       // intervalType: 'months',
//       price: '$3.49/mo',
//       saveNoteText: 'Save 25%'
//     },
//     {
//       id: 10,
//       duration: '6',
//       durationType: 'months',
//       price: '$3.99/mo',
//       saveNoteText: 'Save 25%',
//       activeTabText: 'MOST POPULAR'
//     },
//     {
//       id: 9,
//       duration: '1',
//       durationType: 'month',
//       price: '$4.99/mo'
//     }
//   ],
//   primeFirst: [
//     {
//       id: 14,
//       duration: '12',
//       durationType: 'months',
//       price: '$10.49/mo',
//       saveNoteText: 'Save 30%'
//     },
//     {
//       id: 13,
//       duration: '6',
//       durationType: 'months',
//       price: '$11.25/mo',
//       saveNoteText: 'Save 25%',
//       activeTabText: 'MOST POPULAR'
//     },
//     {
//       id: 12,
//       duration: '1',
//       durationType: 'month',
//       price: '$14.99/mo'
//     }
//   ]
// }

class SubscriptionPlans extends React.Component {
  constructor (props, context) {
    super(props, context)

    this.openStripeCheckoutPlan = this.openStripeCheckoutPlan.bind(this)
    this.openStripeManageSubscription = this.openStripeManageSubscription.bind(
      this
    )

    this.in = false
    this.state = {
      activePlan: null,
      activePlanPackage: null
    }
  }
  componentDidMount () {
    const query = queryString(root.location.search)
    let subscribePlan = get(query, 'plan', 'free')
    let subscribe = get(query, 'subscribe', false)

    if (subscribe && subscribePlan !== 'free') {
      this.openSubscriptionModal(subscribePlan)
    }
  }

  canManageSubscription = store => {
    const planType = get(store.state.subscription, 'paymentType', '')
    const isIAP = planType.match(/inApp/)
    return !isIAP
  }

  get iosInstructions () {
    return (
      <p className='f5 center lh-copy tc black-80'>
        To manage or cancel your Prime subscription, open the Settings app on
        your iOS device and follow these steps:
        <ol className='ml5 mt4 mw6 tl'>
          <li>Tap [your name] > iTunes & App Store.</li>
          <li>
            Tap your Apple ID at the top of the screen, then tap View Apple ID.
            You might need to sign in with your Apple ID.
          </li>
          <li>
            Scroll to Subscriptions, then tap it. Find and select your
            StayCircles Prime Subscription
          </li>
        </ol>
      </p>
    )
  }

  get androidInstructions () {
    return (
      <p className='f5 center lh-copy tc black-80'>
        To manage or cancel your Prime subscription, open the Google Play Store
        and follow these steps:
        <ol className='ml5 mt4 mw6 tl'>
          <li>Tap Menu (3 small horizontal lines) next to "Google Play".</li>
          <li>Tap "Account" and then "Subscriptions".</li>
          <li>Find and select your StayCircles Prime subscription.</li>
        </ol>
      </p>
    )
  }

  getMobileManageInstructions (store) {
    const isAppleIAP =
      get(store.state.subscription, 'paymentType') === 'appleInAppPurchase'

    if (isAppleIAP) {
      return this.iosInstructions
    }

    return this.androidInstructions
  }

  openSubscriptionModal = plan => {
    const page = root.location.pathname
    navigate(`${page}?subscribe=true&plan=${plan}`)
  }

  openLoginModal = (plan, modal = 'login') => {
    const page = root.location.pathname
    const nextPage = `${page}?subscribe=true&plan=${plan}`

    let url = `${page}?${modal}=true&next=${root.encodeURIComponent(nextPage)}`

    navigate(url)
  }

  async openStripeManageSubscription (
    subscribePlan = false,
    currentPlan = false,
    newPlanDetails = false,
    reactivate = false
  ) {
    this.setState({
      loading: true
    })
    const [error, response] = await getManageStripeSubscriptionUrl()
    if (error || !response || !response.manageUrl) {
      root.alert('Something went wrong')
      return
    }
    let tempManageUrl
    if (subscribePlan === 'free') {
      tempManageUrl = `${response.manageUrl}/subscriptions/${currentPlan.subscriptionStripeId}/cancel`
    } else {
      tempManageUrl = `${response.manageUrl}/subscriptions/${currentPlan.subscriptionStripeId}/preview/${newPlanDetails.stripeId}`
    }
    window.location.href = reactivate ? response.manageUrl : tempManageUrl
  }

  async openStripeCheckoutPlan (activePlan, activePlanPackage) {
    // const { activePlan, activePlanPackage } = this.state
    if (!activePlanPackage) {
      root.alert('Please select valid package')
      return
    }
    const [error, response] = await getCheckoutSessionId(activePlanPackage)
    if (error || !response || !response.checkoutUrl) {
      root.alert('Something went wrong')
      return
    }
    // this.redirectToCheckout(checkoutUrl)
    this.setState({ loading: true })
    window.location.href = response.checkoutUrl
  }

  // subscribe = (upgrading, subscribePlan, store) => async () => {
  //   if (
  //     this.state.activePlan !== subscribePlan &&
  //     !this.state.activePlanPackage
  //   ) {
  //     this.togglePlanPackages(subscribePlan)
  //     return
  //   }

  //   const { subscription } = store.state
  //   const currentPlan = getCurrentPlan(subscription)

  //   if (currentPlan !== 'free') {
  //     this.openStripeManageSubscription(subscribePlan)
  //   } else {
  //     this.openStripeCheckoutPlan()
  //   }
  // }

  // currentOrUpgrade = (store, plan) => {
  //   const loading = store.matchesActions('showLoading')
  //   if (loading) {
  //     return null
  //   }

  //   const { subscription } = store.state
  //   const { activePlanPackage } = this.state
  //   const currentPlan = getCurrentPlan(subscription)
  //   const currentPlanPackage = get(subscription, 'planDetails.id', null)
  //   const showReenable = plan !== 'free' && !get(subscription, 'autoRenewAt')

  //   if (currentPlan === plan && currentPlanPackage === activePlanPackage) {
  //     return (
  //       <Fragment>
  //         <SubscribeButton disabled>YOUR SUBSCRIPTION</SubscribeButton>
  //         {/* {showReenable && (
  //           <LearnMoreButton
  //             onClick={() => store.transition('REACTIVATE_SUBSCRIPTION_PLAN')}
  //           >
  //             Enable auto-renewal
  //           </LearnMoreButton>
  //         )} */}
  //       </Fragment>
  //     )
  //   } else {
  //     const upgrading = planOrder[currentPlan] < planOrder[plan]
  //     return (
  //       <SubscribeButton onClick={this.subscribe(upgrading, plan, store)}>
  //         {upgrading ? 'Purchase' : 'Change Plan'}
  //         <Divider name="button-divider" />
  //         <Logo name="stripe-lean" />
  //       </SubscribeButton>
  //     )
  //   }
  // }
  // togglePlanPackages(plan) {
  //   if (plan === 'free') {
  //     // this.onPurchasePress(true)
  //     return
  //   }
  //   this.setState(state => ({
  //     activePlan: state.activePlan === plan ? null : plan,
  //     activePlanPackage:
  //       state.activePlan === plan ? null : packages[plan][0].id,
  //   }))
  // }
  // renderPackages(plan) {
  //   const { activePlanPackage, activePlan } = this.state
  //   return (
  //     <Row noPadding>
  //       <PlanPackagesWrapper>
  //         {packages[plan] &&
  //           packages[plan].map((singlePackage, index) => {
  //             const isActivePlanPackage =
  //               singlePackage.id === activePlanPackage && plan === activePlan
  //             return (
  //               <PlanPackage
  //                 key={singlePackage.id || index}
  //                 active={isActivePlanPackage}
  //                 onClick={() =>
  //                   this.setState({
  //                     activePlanPackage: singlePackage.id,
  //                   })
  //                 }
  //               >
  //                 {singlePackage.activeTabText && isActivePlanPackage && (
  //                   <PlanPackagePopularTabWrapper>
  //                     <PlanPackagePopularTab>
  //                       <PlanPackagePopularTabText>
  //                         {singlePackage.activeTabText}
  //                       </PlanPackagePopularTabText>
  //                     </PlanPackagePopularTab>
  //                   </PlanPackagePopularTabWrapper>
  //                 )}
  //                 <PlanPackageIntervalText active={isActivePlanPackage}>
  //                   {singlePackage.duration} {singlePackage.durationType}
  //                 </PlanPackageIntervalText>
  //                 {/* <PlanPackageIntervalText
  //           active={index === activePlanPackage}
  //         >

  //         </PlanPackageIntervalText> */}
  //                 <PlanPackagePriceText active={isActivePlanPackage}>
  //                   {singlePackage.price}
  //                 </PlanPackagePriceText>

  //                 <PlanPackageSaveNoteText active={isActivePlanPackage}>
  //                   {singlePackage.saveNoteText || <>&nbsp;</>}
  //                 </PlanPackageSaveNoteText>
  //               </PlanPackage>
  //             )
  //           })}
  //       </PlanPackagesWrapper>
  //     </Row>
  //   )
  // }
  // syncActivePlan(subscription) {
  //   console.log('syncing current plan')
  //   const currentPlan = getCurrentPlan(subscription)
  //   const currentPlanPackage = get(subscription, 'planDetails.id', null)
  //   this.setState({
  //     activePlan: currentPlan,
  //     activePlanPackage: currentPlanPackage,
  //   })
  // }
  render () {
    // return <Spinner show />
    const { loading } = this.state
    if (loading) {
      return <FullPageLoader />
    }
    return (
      <Location>
        {({ location }) => {
          const query = queryString(location.search)
          const subscribePlan = get(query, 'plan')

          return (
            <AuthProvider>
              {authStore => {
                const isLoggedIn = authStore.isLoggedIn()
                return (
                  <PrimeStoreProvider
                    isLoggedIn={isLoggedIn}
                    subscribePlan={subscribePlan}
                  >
                    {store => {
                      // const canManage = this.canManageSubscription(store)
                      const { subscription, plans } = store.state
                      // const { activePlan } = this.state
                      const currentPlan = getCurrentPlan(subscription)
                      return (
                        <SubscriptionPlansRenderer
                          canManage
                          store={store}
                          stripeSubscriptionPlans={plans}
                          currentPlan={currentPlan}
                          subscription={subscription}
                          getMobileManageInstructions={
                            this.getMobileManageInstructions
                          }
                          openStripeManageSubscription={
                            this.openStripeManageSubscription
                          }
                          openStripeCheckoutPlan={this.openStripeCheckoutPlan}
                        />
                      )
                    }}
                  </PrimeStoreProvider>
                )
              }}
            </AuthProvider>
          )
        }}
      </Location>
    )
  }
}

// const Row = styled.div`
//   width: 100%;
//   cursor: pointer;
//   display: flex;
//   flex-direction: row;
//   padding: ${props => (props.noPadding ? '0px' : '0 14px')};
//   justify-content: space-between;

//   @media (max-width: 480px) {
//     flex-direction: column;
//   }
// `

// const Column = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
// `

// const WhatYouGet = styled.div`
//   max-width: 1280px;
//   margin: 64px auto;
//   box-shadow: 0px 2px 6px 0 rgba(0, 0, 0, 0.2);
//   background: white;
//   border-radius: 6px;

//   p {
//     font-size: 1.15rem;
//   }

//   @media (max-width: 1680px) {
//     zoom: 0.95;
//   }

//   @media (max-width: 1440px) {
//     zoom: 0.8;
//   }

//   @media (min-width: 1600px) {
//     p {
//       font-size: 1.25rem;
//     }
//   }
// `

// const WhatYouGet = styled.div`
//   /* max-width: 1280px; */
//   margin: 0 auto;
//   /* width: 50%; */
//   /* box-shadow: 0px 2px 6px 0 rgba(0, 0, 0, 0.2); */

//   p {
//     font-size: 1.15rem;
//   }

//   @media (min-width: 1600px) {
//     p {
//       font-size: 1.25rem;
//     }
//   }
// `

// const Icon = styled.img.attrs({
//   src: props =>
//     `https://res.cloudinary.com/staycircles/image/upload/f_auto,q_auto,c_scale,h_200/${
//       props.publicID
//     }`
// })`
//   height: 48px;
//   width: auto;
//   margin-bottom: 14px;
// `

// const Person = styled.img.attrs({
//   src: props =>
//     `https://res.cloudinary.com/staycircles/image/upload/f_auto,q_auto,c_scale,h_400/${props.publicID}`
// })`
//   height: 160px;
//   width: auto;
//   /* margin-bottom: 48px; */
//   /* margin-top: 48px; */
// `

const LearnMoreButton = styled(Button)`
  padding: 8px 16px;
  margin-bottom: 24px;
  margin-top: 16px;
`

LearnMoreButton.defaultProps = {
  link: true,
  children: 'Learn more'
}

// const PlanName = styled.h3`
//   text-align: center;
//   font-weight: 600;
//   font-size: 18px;
//   margin-bottom: 8px;
//   color: #153c6b;
//   /* text-transform: uppercase; */
//   letter-spacing: 0.06em;
// `

// const PlanText = styled.h5`
//   text-align: center;
//   font-size: 18px;
//   margin-bottom: 20px;
//   color: #2867b2;
//   font-weight: 400;
// `

// const DealsPercent = styled.img`
//   width: 100px;
//   height: 100px;
// `

// const SubscribeButton = styled(Button).attrs({
//   color: colors.white,
//   colorHover: colors.offwhite,
//   bgColor: colors.darkBlue,
//   bgColorHover: colors.blue
// })`
//   font-weight: 600;
//   letter-spacing: 0.06em;
//   width: 275px;
//   padding: 20px;
//   font-size: 18px;

//   :disabled {
//     background: #c7e1ff;
//   }
// `

// const SubscribeButton = styled(Button).attrs({
//   color: colors.white,
//   colorHover: colors.offwhite,
//   bgColor: colors.buttonBlue,
//   bgColorHover: colors.blue
// })`
//   font-weight: 600;
//   /* letter-spacing: 0.06em; */
//   width: 100%;
//   padding: 10px;
//   font-size: 18px;
//   border-top-left-radius: 0px;
//   border-top-right-radius: 0px;
//   :disabled {
//     background: #c7e1ff;
//   }
// `

// custom

// const PlanColumn = styled(View)`
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   margin-top: 24px;
//   /* padding: 0 14px; */
// `

// const MainRow = styled.div`
//   width: 100%;
//   display: flex;
//   flex-direction: column;
//   box-shadow: 0px 2px 6px 0 rgba(0, 0, 0, 0.2);
//   border-radius: 6px;
//   margin-bottom: 20px;

//   /* background: white; */
//   background: ${props => (props.active ? '#E6F1FF' : '#fff')};
//   @media (max-width: 480px) {
//     flex-direction: column;
//   }
// `

// const PlanPrice = styled.h5`
//   display: flex;
//   text-align: center;
//   justify-content: center;
//   align-items: center;
//   font-family: 600;
//   font-size: ${props => (props.small ? '11px' : '14px')};
//   margin-bottom: 6px;
//   color: #153c6b;
// `
// const PlanPriceSmall = styled.span`
//   text-align: center;
//   font-family: 600;
//   font-size: ${props => (props.small ? '11px' : '14px')};
//   margin-bottom: 6px;
//   color: #153c6b;
// `

// const Logo = styled(Icon)`
//   color: #333;
//   height: 24px;

//   @media (min-width: 1921px) {
//     height: 36px;
//   }
// `

// const Logo = styled(Icon)`
//   color: #333;
//   height: 16px;

//   /* @media (min-width: 1921px) {
//     height: 36px;
//   } */
// `

// const Divider = styled(Logo)`
//   padding-left: 10px;
//   padding-right: 10px;
//   height: 24px;
// `

// const PlanPackagesWrapper = styled(View)`
//   flex-direction: row;
//   width: 100%;
//   background-color: white};
// `

// const PlanPackage = styled(TouchableOpacity)`
//   flex: 1;
//   position: relative;
//   background-color: #f5f7fa;
//   justify-content: flex-start;
//   align-items: center;
//   padding-top: 25px;
//   padding-bottom: 5px;
//   border: 1px solid #dadee6;
//   border-color: ${props => (props.active ? '#1C81FF' : '#DADEE6')};
// `
// const PlanPackageIntervalText = styled(Text)`
//   font-family: 600;
//   font-size: 14px !important;
//   color: ${props => (props.active ? '#000000' : '#B7B7B7')};
// `
// const PlanPackagePopularTabWrapper = styled(View)`
//   display: block;
//   position: absolute;
//   width: 100%;
//   top: -20px;
//   padding: 8px;
// `
// const PlanPackagePopularTab = styled(Button).attrs({
//   bgColor: colors.buttonBlue
// })`
//   border-radius: 25px;
//   /* background-color: #1c81ff; */
//   /* background-color: #1c81ff; */
//   border-radius: 25px;
//   padding: 5px 10px;
// `

// const PlanPackagePopularTab = styled.div.attrs({})`
//   border-radius: 25px;
//   display: inline-block;
//   background-color: ${colors.buttonBlue};
//   /* background-color: #1c81ff; */
//   border-radius: 25px;
//   padding: 5px 10px;
// `

// const PlanPackagePriceText = styled(Text)`
//   font-family: 600;
//   font-size: 14px !important;
//   line-height: 22px;
//   margin-top: 5px;
//   margin-bottom: 5px;
//   color: ${props => (props.active ? '#1C81FF' : '#B7B7B7')};
// `
// const PlanPackageSaveNoteText = styled(Text)`
//   font-size: 12px !important;
//   color: ${props => (props.active ? '#000000' : '#B7B7B7')};
// `

// const PlanPackagePopularTabText = styled(Text)`
//   font-family: 600;
//   color: ${colors.white};
//   font-size: 8px !important;
//   text-align: center;
//   line-height: 10.8px;
// `

export default SubscriptionPlans
