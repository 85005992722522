import React from 'react'

import { required } from 'utils/field-validators'
import root from 'utils/windowOrGlobal'

import Input from 'components/Input'
import PromptModal from 'app/screens/hosting/components/PromptModal'

class EditConversationNamePrompt extends React.Component {
  state = {
    name: this.props.name
  }

  onSubmit = async e => {
    const { onSubmit, onClose } = this.props
    const { name } = this.state

    const ok = await onSubmit(name)

    if (ok) {
      onClose()
    } else {
      root.alert('Oops!\n' + 'Something went wrong, please try again.')
    }
  }

  setName = e =>
    this.setState({
      name: e.target.value
    })

  render () {
    const { name } = this.state

    return (
      <PromptModal
        title='Edit conversation name'
        getInitialValues={() => ({ name })}
        onSubmit={this.onSubmit}
      >
        {({ submitting }) => (
          <React.Fragment>
            <Input
              id='name'
              name='name'
              label={
                name ? 'Conversation name' : 'Give this conversation a name'
              }
              validate={required}
              onChange={this.setName}
              disabled={submitting}
            />
          </React.Fragment>
        )}
      </PromptModal>
    )
  }
}

export default EditConversationNamePrompt
