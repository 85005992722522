import React from 'react'

import EmailInviteField from './EmailInviteField'
import OpenGoogleContactsButton from '../GmailContacts/OpenGoogleContactsButton'
// import StayPalsInfo from './StayPalsInfo'
import RequestsSentIgnored from '../RequestsSentIgnored'
import MediaQuery from 'react-responsive'

class InvitePanel extends React.PureComponent {
  _renderContent = height => {
    const { sendInvite, loading } = this.props
    return (
      <div style={{ height }} className='overflow-auto scrollbar'>
        <EmailInviteField {...{ sendInvite, loading }} />
        <OpenGoogleContactsButton />
        <RequestsSentIgnored />
        {/* <StayPalsInfo /> */}
      </div>
    )
  }
  render () {
    const { height } = this.props

    return (
      <MediaQuery minWidth={992}>
        {matches => this._renderContent(matches ? height : 'initial')}
      </MediaQuery>
    )
  }
}

export default InvitePanel
