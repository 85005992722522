import React, { useState, useEffect } from 'react'
import { createContainer } from 'unstated-next'
import { isEmpty, uniqBy } from 'lodash'
import { formatFlightDeal } from 'utils/deals'
import { usePersistState } from 'utils/persistStorage'
import { getDeals } from '../api'

const useDealsData = (initialValue = []) => {
  const [value, setValue, clearState] = usePersistState('flightDeals')
  const [deals, setDeals] = useState(initialValue)
  const [loading, setLoading] = useState(false)
  const [pageDetails, setPageDetails] = useState({})
  const [
    cachedDealFilters,
    setCachedDealFilters,
    clearCachedDealFiltersState
    // getCachedDealFiltersState
  ] = usePersistState('dealFilters')

  // useEffect(() => {
  //   console.log('Initialize flight deals')
  //   initializeDeals()
  // }, [])

  useEffect(() => {
    if (cachedDealFilters) {
      fetchDeals()
    }
  }, [cachedDealFilters])

  // useEffect(() => {
  //   // Not a good way, but needs to do what asked; continously polling if no deals
  //   const dealsInterval = setInterval(() => {
  //     if (!loading && deals && deals.length === 0) {
  //       fetchDeals()
  //     }
  //   }, 30000)
  //   return () => clearInterval(dealsInterval)
  // }, [loading, deals])

  const initializeDeals = dealFilters => {
    setCachedDealFilters(dealFilters)
    if (!dealFilters) {
      setDeals([])
    }
  }

  // const initializeDeals = () => {
  //   if (!value) {
  //     return fetchDeals()
  //   }
  //   // show old deals if last deals fetched is not over 30 mins; since deals are usually updated every hour
  //   if (new Date(value.expirationDate) < new Date()) {
  //     clearState()
  //     return fetchDeals()
  //   }
  //   if (value.deals && value.deals.length === 0) {
  //     clearState()
  //     return fetchDeals()
  //   }
  //   const { deals, expirationDate, ...rest } = value
  //   setDeals(value.deals)
  //   setPageDetails({ ...rest })
  //   setLoading(false)
  // }

  const fetchDeals = async (page = 1) => {
    if (!cachedDealFilters) {
      return null
    }
    if (page === 1) {
      setPageDetails({})
      setLoading(true)
    }
    const [error, data] = await getDeals({
      page,
      ...cachedDealFilters,
      fromAirports: cachedDealFilters?.fromAirports?.join()
    })
    if (error) {
      setLoading(false)
    }
    if (!isEmpty(data)) {
      const formattedDeals =
        data && data.deals && data.deals.map(formatFlightDeal)
      const finalDeals =
        page === 1
          ? formattedDeals
          : uniqBy([...deals, ...formattedDeals], 'key')
      setDeals(finalDeals)
      if (data.deals) {
        const { page, perPageCount, totalDealsCount } = data
        setPageDetails({ page, perPageCount, totalDealsCount })
        // Flight deals data are huge, persist only upto page 2
        if (page <= 2) {
          setValue({
            deals: finalDeals,
            page,
            perPageCount,
            totalDealsCount,
            expirationDate: new Date(new Date().getTime() + 5 * 60 * 1000)
          })
        }
      }
      setLoading(false)
    }
  }

  const loadMore = async () => {
    setLoading(true)
    fetchDeals(+pageDetails.page + 1)
  }

  // Referesh deals when deal page is visited, refetch if past 5 mins without airports change
  const refreshDeals = async (force = false) => {
    if (force) {
      setLoading(true)
      setPageDetails({})
      return fetchDeals()
    }

    if (!value) {
      return fetchDeals()
    }
    // show old deals if last deals fetched is not over 5 mins; since deals are usually updated every hour
    if (new Date(value.expirationDate) < new Date()) {
      clearState()
      return fetchDeals()
    }
    if (value.deals && value.deals.length === 0) {
      clearState()
      return fetchDeals()
    }

    // const deals = getState()
    // if (deals) {
    //   return
    // }
    // if (!deals && !loading) {
    //   setLoading(true)
    //   setPageDetails({})
    //   return fetchDeals()
    // }
  }

  const handleDealFiltersChange = async dealFilters => {
    if (!dealFilters) {
      clearCachedDealFiltersState()
    } else {
      setCachedDealFilters(dealFilters)
    }
  }

  return {
    deals,
    loading,
    pageDetails,
    loadMore,
    refreshDeals,
    dealFilters: cachedDealFilters,
    handleDealFiltersChange,
    initializeDeals
  }
}

const DealData = createContainer(useDealsData)

const withDealsState = WrappedComponent => () => {
  return (
    <DealData.Provider>
      <WrappedComponent />
    </DealData.Provider>
  )
}

export { DealData }
export default withDealsState
