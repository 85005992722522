import api from 'utils/api'

export const getPotentialAirports = async (lat, long, page = 1) => {
  // const params = {
  //   lat,
  //   long,
  //   page,
  // }

  return api.get(`/airports?lat=${lat}&long=${long}&page=${page}`)
}
export const getOriginAirports = async () => {
  return api.get('/origin_airports')
}

export const delOriginAirport = async id => {
  return api.delete(`/origin_airports/${id}`)
}

export const postOriginAirports = async airportIds => {
  return api.post('/origin_airports', { airportIds })
}

export const getPlaces = async includeOnly => {
  return api.get('/places', {
    includeOnly
  })
}

export const addPlaceToOriginAirport = async (id, placeId) => {
  return api.put(`/origin_airports/${id}`, { placeId })
}
