import React from 'react'

import styled from 'styled-components'

import { get } from 'lodash'

import { RenderOnAction } from 'utils/StateMachine'

import colors from 'styles/colors'

import GmailContactsModal from './GmailContactsModal'
import GoogleAuthErrorModal from './GoogleAuthErrorModal'

import withStore from './store'

const Wrapper = styled.div`
  position: relative;

  padding-bottom: 16px;
  margin-bottom: 16px;

  text-align: center;

  @media (min-width: 992px) {
    padding-bottom: 32px;
    margin-bottom: 32px;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 10%;
    right: 10%;

    width: 80%;
    height: 1px;
    background-color: ${colors.silver};
  }
`

const GoogleWrapper = styled.div`
  padding: 0 16px;

  font-size: 18px;
  font-weight: 300;
  text-align: center;

  background-color: #fff;

  > span {
    display: block;

    > img {
      height: 24px;

      &:first-child {
        margin-top: -3px;
        margin-right: 2px;
      }
    }

    > b {
      font-weight: 500;
      color: ${colors.darkBlue};
    }
  }
`

const ContactsButton = styled.button`
  height: 40px;
  width: 50%;

  margin-left: 6px;

  background-color: ${colors.red};
  border: 1px solid ${colors.darkRed};
  cursor: pointer;

  color: white;
  font-weight: normal;
  font-size: 14px;
  white-space: normal;

  &:hover {
    background-color: ${colors.darkRed};
  }

  @media (min-width: 992px) {
    height: 60px;
    margin-left: 14px;
    font-size: 16px;
  }
`

class OpenContactsModal extends React.Component {
  getGmailContacts = () => {
    this.props.machineStore.transition({
      type: 'LOAD'
    })
  }

  toggleModal = showContactsModal => {
    this.props.machineStore.transition({
      type: 'TOGGLE_MODAL',
      showContactsModal
    })
  }

  resetError = () => {
    this.props.machineStore.transition({
      type: 'RESET_ERROR'
    })
  }

  sendSPRequest = email => {
    this.props.machineStore.transition({
      type: 'SEND_SP_REQUEST',
      email
    })
  }

  setTerm = e => {
    this.props.machineStore.transition({
      type: 'SET_TERM',
      term: e.target.value
    })
  }

  get contacts () {
    const term = this.props.machineStore.state.term.toLowerCase()

    const filterBy = (contact, field) =>
      contact[field].toLowerCase().includes(term)

    const filteredContacts = this.props.machineStore.state.contacts.filter(
      contact => filterBy(contact, 'name') || filterBy(contact, 'email')
    )

    return filteredContacts
  }

  get error () {
    return get(this.props.machineStore, 'state.event.error', {})
  }

  render () {
    const {
      showContactsModal,
      term,
      inProgress
    } = this.props.machineStore.state

    return (
      <RenderOnAction value='showLoading'>
        {loading => (
          <Wrapper>
            <GoogleWrapper className='center w-90-ns'>
              <span>
                View your contacts and send <b>selective</b> invites:
              </span>

              <span className='mt2 mt4-l'>
                <img
                  src='https://res.cloudinary.com/staycircles/image/upload/v1522076341/assets/google/gmail-icon.png'
                  className='dib'
                />
                <img
                  src='https://res.cloudinary.com/staycircles/image/upload/v1522076341/assets/google/google.png'
                  className='dib'
                />

                <ContactsButton
                  className='dib br-pill'
                  onClick={this.getGmailContacts}
                  theme='primary'
                >
                  <b className='fw9'>View</b> and <b className='fw9'>Select</b>{' '}
                  Contacts
                </ContactsButton>
              </span>
            </GoogleWrapper>

            <GmailContactsModal
              {...{
                show: showContactsModal,
                toggle: this.toggleModal,
                setTerm: this.setTerm,
                sendSPRequest: this.sendSPRequest,
                contacts: this.contacts,
                loading,
                term,
                inProgress
              }}
            />

            <GoogleAuthErrorModal
              reason={this.error.gmailAuth}
              onClose={this.resetError}
            />
          </Wrapper>
        )}
      </RenderOnAction>
    )
  }
}

export default withStore(OpenContactsModal)
