import React from 'react'

import get from 'lodash/get'

import styled from 'styled-components'

import { RenderOnAction } from 'utils/StateMachine'

import withNotificationPreferenceStore, { defaultPreferences } from './store'

import { CheckboxInput, CheckmarkIcon } from 'components/Checkbox'

const SETTINGS = [
  { title: 'New Friends Requests', prefKey: 'spRequest' },
  {
    title: 'Accepted Friend Invites',
    prefKey: 'scInvitationAcceptance'
  },
  { title: 'Unread Friends Messages', prefKey: 'unreadMessageFromSp' },
  {
    title: 'Accepted Friends Invites',
    prefKey: 'spInvitationAcceptance'
  },
  { title: 'Pending Payouts', prefKey: 'scAppReminders' },
  {
    title: 'Product news and updates',
    prefKey: 'scNewFeaturesAndUpdates'
  },
  {
    title: 'StayCircles Introduction Email Series',
    prefKey: 'scIntroEmailSeries'
  },
  { title: 'New Stay Proposal', prefKey: 'stayProposal' },
  { title: 'Accepted Stay Proposal', prefKey: 'stayAccepted' },
  { title: 'Declined Stay Proposal', prefKey: 'stayDecline' },
  { title: 'Cancelled Stay Proposal', prefKey: 'stayCanceled' },
  {
    title: 'Flight Deals',
    prefKey: 'flightDeals',
    subCategories: [
      {
        title: 'Economy Flight Deals',
        prefKey: 'economyFlightDeals'
      },
      {
        title: 'First or Business Class Flight Deals',
        prefKey: 'firstOrBusinessClassFlightDeals'
      }
      // {
      //   title: 'Adventure or First Class Flyer Hidden Flight Deals',
      //   prefKey: 'primeOrPrimeFirstHiddenFlightDeals'
      // }
    ]
  }
]

class Notifications extends React.Component {
  shouldComponentUpdate (nextProps) {
    if (
      nextProps !== this.props ||
      nextProps.machineStore.state !== this.props.machineStore.state
    ) {
      return true
    }

    return false
  }

  get preferences () {
    const pref = get(
      this.props.machineStore,
      'state.event.preferences',
      defaultPreferences
    )
    // console.log('----Preferences are---', pref)
    return pref
  }

  componentDidMount () {
    this.props.machineStore.transition('LOAD')
  }

  setPreference = item => {
    this.props.machineStore.transition({
      type: 'SET_PREFERENCE',
      preferences: this.preferences,
      item
    })
  }

  renderItem = (item, loading) => {
    const {
      category = 'emailNotifications',
      prefKey,
      title,
      subCategories
    } = item
    const checked = !!this.preferences[category][prefKey]
    const toggleItem = (index = false) => {
      if (index || index === 0) {
        const singleSubCategory = subCategories[index]
        const {
          subCategory = 'emailNotifications',
          prefKey: subCatPrefKey
        } = singleSubCategory
        const subCategorychecked = !!this.preferences[category][subCatPrefKey]
        return this.setPreference({
          category: subCategory,
          prefKey: subCatPrefKey,
          checked: !subCategorychecked
        })
      }
      return this.setPreference({
        category,
        prefKey,
        checked: !checked
      })
    }

    return (
      <div key={prefKey}>
        <div
          className='b--black-10 bb pa3 flex flex-row items-center'
          key={prefKey}
          onClick={() => toggleItem()}
        >
          <p className='mb0 pr2' style={{ flex: 1 }}>
            {title}
          </p>
          <CheckboxInput
            type='checkbox'
            disabled={loading}
            checked={checked}
            onChange={() => toggleItem()}
          />
          <CheckmarkIcon />
        </div>
        {subCategories && (
          <div
            className={
              'pl4 subCategoryWrapper ' + (checked ? 'categoryActive' : '')
            }
          >
            {subCategories.map((subItem, ind) => {
              const isChecked = !!this.preferences[category][subItem.prefKey]
              return (
                <div
                  className='b--black-10 bb pa3 flex flex-row items-center'
                  key={subItem.prefKey}
                  onClick={() => toggleItem(ind)}
                >
                  <p className='mb0 pr2' style={{ flex: 1 }}>
                    {subItem.title}
                  </p>
                  <CheckboxInput
                    type='checkbox'
                    disabled={loading}
                    checked={isChecked}
                    onChange={() => toggleItem(ind)}
                  />
                  <CheckmarkIcon />
                </div>
              )
            })}
          </div>
        )}
      </div>
    )
  }

  render () {
    return (
      <RenderOnAction value='showLoading'>
        {loading => (
          <div className='flex flex-column items-center justify-center center w-100 mb4'>
            <h1>Notification Preferences</h1>

            <div className='mv3 w-100'>
              <p className='black-60 mb3'>Send me emails when/about:</p>

              <NotificationListWrapper
                className='br3 overflow-hidden b--black-10 ba bg-white-80'
                style={{
                  cursor: loading ? 'progress' : 'pointer'
                }}
              >
                {/* {console.log('---Settings are----', SETTINGS)} */}
                {SETTINGS.map(item => this.renderItem(item, loading))}
              </NotificationListWrapper>
            </div>
          </div>
        )}
      </RenderOnAction>
    )
  }
}

const NotificationListWrapper = styled.div`
  .subCategoryWrapper {
    display: none;
  }
  .categoryActive {
    display: block;
  }
`

export default withNotificationPreferenceStore(Notifications)
