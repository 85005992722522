import { withStateMachine, StateMachineContainer } from 'utils/StateMachine'
import logger from 'utils/logger'

import { getReadableError } from 'utils/api'
import * as api from './api'

const ALL_ACTIONS = {
  LOAD: { loading: { actions: ['getRequests'] } },
  TOGGLE_MODAL: { idle: { actions: ['toggleModal'] } },
  RESET_ERROR: { idle: { actions: ['resetError'] } },
  MANAGE_REQUEST: { loading: { actions: ['manageRequest'] } },
  GET_COUNT: { loading: { actions: ['loadUnreadRequests'] } }
}

const statechart = {
  initial: 'idle',
  states: {
    idle: {
      on: {
        ...ALL_ACTIONS
      },
      onEntry: 'hideLoading'
    },
    loading: {
      onEntry: 'showLoading',
      on: {
        SUCCESS: 'idle',
        FAILURE: 'idle',
        ...ALL_ACTIONS
      }
    }
  }
}

const defaultState = {
  term: '',
  contacts: [],
  showModal: false,
  inProgress: [],
  page: 0,
  hasMore: true,
  unread: 0
}

class PendingRequestsContainer extends StateMachineContainer {
  constructor (props) {
    super(props)

    this.state = {
      ...this.state,
      ...defaultState
    }
  }

  request = async (apiName, data, event = {}) => {
    logger.captureBreadcrumb({
      message: 'PendingRequestsContainer.' + apiName,
      category: 'stayPals',
      data
    })

    try {
      const [error, response] = await api[apiName](data)

      if (error) {
        throw new Error(error)
      } else {
        return response
      }
    } catch (error) {
      logger.captureException(error)

      let err = getReadableError(error)

      this.transition({
        ...event,
        type: 'FAILURE',
        error: {
          [apiName]: err
        }
      })
      return false
    }
  }

  loadUnreadRequests = async () => {
    this.setState(defaultState)

    const data = await this.request('loadUnreadRequests')

    if (!data) {
      return
    }

    const unread = data.unreadSpRequests

    await this.setState({ unread })

    this.transition({
      type: 'SUCCESS'
    })
  }

  getRequests = async () => {
    const page = this.state.page + 1
    const data = await this.request('getRequests', page)

    if (!data) {
      return
    }

    const total = data.incomingConnectionsCount
    const perPage = data.resultsPerPage
    const totalPages = Math.ceil(Math.max(total, perPage) / perPage)

    let contacts = data.results.map(c => ({
      ...c,
      stayPalRequestStatus: 'received'
    }))

    if (page > 1) {
      contacts = this.state.contacts.concat(contacts)
    }

    await this.setState({
      contacts,
      page,
      hasMore: page < totalPages,
      unread: total
    })

    this.transition({
      type: 'SUCCESS'
    })
  }

  setTerm = ({ term }) => {
    this.setState({ term })
  }

  toggleModal = ({ showModal }) => {
    this.setState({ showModal })
  }

  resetError = () => {
    this.transition({
      error: {}
    })
  }

  addProgress = id => {
    this.setState({
      inProgress: this.state.inProgress.concat([id])
    })
  }

  removeProgress = id => {
    this.setState({
      inProgress: this.state.inProgress.filter(i => i !== id)
    })
  }

  manageRequest = async ({ email, id, cb, approve }) => {
    this.addProgress(email)

    const api = approve ? 'acceptRequest' : 'ignoreRequest'

    const ok = await this.request(api, id)

    this.removeProgress(email)

    if (ok) {
      const contacts = this.state.contacts.filter(c => c.email !== email)

      this.setState({
        contacts,
        unread: this.state.unread - 1
      })

      this.transition({
        type: 'SUCCESS'
      })

      if (typeof cb === 'function') {
        cb()
      }

      return true
    }
  }
}

const withStore = withStateMachine(
  null,
  new PendingRequestsContainer({ statechart })
)

export default withStore
