import React from 'react'

import get from 'lodash/get'
import noop from 'lodash/noop'

import styled from 'styled-components'

export const View = styled.div`
  display: flex;
  flex-direction: column;
`

export const Text = styled.p`
  margin: 0;
`

export const Image = styled.img.attrs({
  src: props => get(props, 'source.uri', props.src)
})``

export class TouchableOpacity extends React.PureComponent {
  static defaultProps = {
    children: '',
    type: 'button',
    onClick: noop,
    activeOpacity: 0.75
  }

  render () {
    const { onPress, onClick, ...props } = this.props

    return <Touchable {...props} onClick={onPress || onClick} />
  }
}

export const Touchable = styled.button`
  opacity: 1;
  transition: opacity 0.15s ease-in;
  padding: 0;
  margin: 0;
  background-color: transparent;
  border-style: none;
  border-width: 0;
  cursor: pointer;

  :active {
    opacity: ${props => props.activeOpacity};
  }
`
