import React from 'react'

import colors from 'styles/colors'

const ListItems = ({ label, loading, items }) => (
  <div
    className={`w-100 flex flex-column mb4 ${loading ? '0-60' : ''}`}
    style={{
      cursor: loading ? 'progress' : 'default'
    }}
  >
    <p className='f5 black-50 pl2 mb2'>{label}</p>

    <div className='br3 overflow-hidden b--black-10 ba bg-white-80'>
      {items.map(item => {
        const disabled = item.disabled || loading
        return (
          <div
            key={item.value}
            className='b--black-10 bb pa3 flex flex-row justify-between items-center black-70 bg-white-80'
          >
            <span
              className={`truncate pv2 ${item.valueClassName || ''}`}
              title={item.tooltip || undefined}
            >
              {item.value}
            </span>

            {item.actionText && (
              <a
                href='#'
                onClick={e => {
                  e.preventDefault()
                  item.actionClick()
                }}
                className={`link outline-0 ${disabled ? '' : 'dim'}`}
                style={{
                  color: disabled ? colors.blackRGBA(0.4) : colors.blue,
                  cursor: disabled ? 'default' : 'pointer'
                }}
                disabled={disabled}
              >
                {item.actionText}
              </a>
            )}
          </div>
        )
      })}
    </div>
  </div>
)

export default ListItems
