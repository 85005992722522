import React from 'react'
import PropTypes from 'prop-types'

import { get, merge } from 'lodash'

import logger from 'utils/logger'

import * as api from '../../api/places'

import { createRoom } from '../Rooms/api'

import StateMachineProvider, { StateMachineContainer } from 'utils/StateMachine'

const statechart = {
  key: 'hosting-create-place',
  initial: 'address',
  states: {
    address: {
      onEntry: ['showAddressForm'],
      on: {
        SET_DATA: 'apartment'
      }
    },
    apartment: {
      onEntry: ['showApartmentForm'],
      on: {
        BACK: 'address',
        CREATE_PLACE: { submitting: { actions: ['createPlace'] } }
      }
    },
    submitting: {
      onEntry: ['showLoading'],
      on: {
        SUBMIT_SUCCESS: { success: { actions: ['showSuccess'] } },
        SUBMIT_FAILURE: 'error'
      }
    },
    success: {
      onEntry: ['showSuccess']
    },
    error: {
      onEntry: 'showError',
      on: {
        RECOVER: 'address'
      }
    }
  }
}

class CreatePlaceContainer extends StateMachineContainer {
  constructor (props) {
    super(props)

    // Make sure to keep the state machine engine
    const stateMachine = this.state

    this.state = {
      ...stateMachine,
      data: {}
    }
  }

  componentDidTransition (prevStateMachine, event) {
    if (event && event.type) {
      switch (event.type) {
        case 'SET_DATA':
          this.setData(event.data)
          break
        case 'SUBMIT_SUCCESS':
          this.setData(event.data, true)
          break
        case 'SUBMIT_FAILURE':
          this.setError(event.error)
          break
      }
    }
  }

  setData = (data, shouldMerge = false) => {
    if (shouldMerge) {
      this.setState({
        data: merge({}, this.state.data, data)
      })
      return
    }

    this.setState({ data })
  }

  setError = async error => {
    const errorStr = Array.isArray(error) ? error[0] : error
    await this.setState({ error: errorStr })
  }

  request = async (name, apiCall, data) => {
    logger.captureBreadcrumb({
      message: 'CreatePlaceContainer.' + name,
      category: 'hosting',
      data
    })

    try {
      const [error, response] = await apiCall(data)

      if (error) {
        throw new Error(error)
      } else {
        return response
      }
    } catch (error) {
      logger.captureException(error)

      this.transition({ type: 'SUBMIT_FAILURE', error })
      return false
    }
  }

  createPlace = async ({ data, callback }) => {
    data = {
      ...this.state.data,
      ...data
    }

    const response = await this.request('createPlace', api.createPlace, data)

    if (!response) return

    const place = get(response, 'place', {})
    let room

    if (place.id) {
      // Ensure there is a primary place.
      // If this is the first created place it will set it as primary,
      // unless there is a primary place already.
      await this.ensurePrimaryPlace(place.id)

      // Auto-create place's first room. Feature requirement.
      room = await this.createFirstRoom(place.id)
    }

    if (typeof callback === 'function') {
      callback(null, () => {
        this.transition({
          type: 'SUBMIT_SUCCESS',
          data: { ...place, rooms: [room] }
        })
      })
    } else {
      this.transition({
        type: 'SUBMIT_SUCCESS',
        data: { ...place, rooms: [room] }
      })
    }

    return true
  }

  ensurePrimaryPlace = async placeId => {
    const response = await this.request(
      '[ensurePrimaryPlace] getPlaces',
      api.getPlaces
    )

    const primaryId = get(response, 'primaryPlaceId', [])

    if (primaryId) return

    await this.request(
      '[ensurePrimaryPlace] setPrimaryPlace',
      api.setPrimaryPlace,
      placeId
    )
  }

  createFirstRoom = async placeId => {
    const data = {
      placeId,
      room: {
        name: 'Guest Room',
        peopleSize: '1'
      }
    }

    const response = await this.request(
      '[createFirstRoom] createRoom',
      createRoom,
      data
    )

    return get(response, 'room')
  }
}

export default class CreatePlaceStoreProvider extends React.Component {
  static propTypes = {
    children: PropTypes.func.isRequired
  }

  constructor (props) {
    super(props)
    this.container = new CreatePlaceContainer({ statechart })
  }

  render () {
    return (
      <StateMachineProvider container={this.container}>
        {machineStore => this.props.children(machineStore)}
      </StateMachineProvider>
    )
  }
}
