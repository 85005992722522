import React from 'react'

import get from 'lodash/get'

import styled from 'styled-components'

import { getFullName, getUserPicture, isFriend } from 'utils/profile-info'

import { TextInput } from 'components/Input'
import InfiniteList from 'components/InfiniteList'

import colors from 'styles/colors'

import ProvideStayPals from '../store/staypals'

const ITEM_HEIGHT = 60

class StayPalsList extends React.Component {
  state = {
    invited: [],
    inviting: []
  }

  setInviting = id => {
    this.setState(state => ({
      inviting: state.inviting.concat(id)
    }))
  }

  setInvited = id => {
    this.setState(state => ({
      invited: state.invited.concat(id),
      inviting: state.inviting.filter(i => i !== id)
    }))
  }

  sendInvite = staypal => {
    const placeId = get(this.props, 'store.state.data.placeId')
    const email = staypal.email

    this.setInviting(staypal.id)

    return new Promise(resolve => {
      this.props.store.transition({
        type: 'INVITE',
        email,
        placeId,
        callback: async () => {
          this.setInvited(staypal.id)
          resolve()
        }
      })
    })
  }

  getHousemates = () => {
    return get(this.props, 'store.getHousemates', () => [])()
  }

  isHousemate (staypal, pending) {
    return this.getHousemates()
      .filter(hm => {
        return pending ? hm.status === 'pending' : !hm.status
      })
      .some(hm => hm.id === staypal.id)
  }

  isInvited (staypal) {
    return this.state.invited.some(id => id === staypal.id)
  }

  isInviting (staypal) {
    return this.state.inviting.some(id => id === staypal.id)
  }

  filterStaypals = list => {
    return list
      .filter(staypal => !this.isHousemate(staypal))
      .map(staypal => {
        const pending =
          this.isHousemate(staypal, true) || this.isInvited(staypal)
        const friend = isFriend(staypal)
        const inviting = this.isInviting(staypal)

        return { ...staypal, friend, pending, inviting }
      })
  }

  renderStayPalItem = ({ item }) => {
    const invite = () => this.sendInvite(item)
    const pending = item.pending
    const inviting = item.inviting

    const name = getFullName(item)
    const picture = getUserPicture(item)
    let address = ''

    if (item.primaryPlace) {
      const { city, country } = item.primaryPlace
      address = `${city}, ${country}`
    }

    const inviteButtonText = inviting
      ? 'Inviting...'
      : pending
        ? 'Invited'
        : 'Invite'

    return (
      <div className='pv2 pr3 flex flex-row items-center' key={item.id}>
        <div className='flex flex-row items-center pr4' style={{ flex: 1 }}>
          <Avatar
            src={picture}
            alt={`${name}'s picture`}
            friend={item.friend}
          />

          <div className='ph2'>
            <p className='mb0'>{name}</p>
            {!!address && <p className='mt1 mb0 f7 dn di-ns'>{address}</p>}
          </div>
        </div>

        <InviteButton
          onClick={invite}
          children={inviteButtonText}
          disabled={inviting || pending}
          className='pointer bg-transparent ba f7 pa2 mb0 br2 bg-animate hover-bg-near-white'
        />
      </div>
    )
  }

  getExtraData = n => {
    const invitedString = this.state.invited.join('+')
    const invitingString = this.state.inviting.join('+')

    return invitedString + n + invitingString
  }

  render () {
    const loadingHousemates = this.props.store.matchesActions('showLoading')

    return (
      <ProvideStayPals>
        {stayPals => {
          const list = this.filterStaypals(stayPals.state.list)

          const loading = stayPals.state.loading || loadingHousemates
          const hasMore = stayPals.state.totalPages > stayPals.state.page

          const extra = this.getExtraData(stayPals.state.page)

          return (
            <div
              className='br3 overflow-hidden b--black-10 ba bg-white-80'
              style={{
                cursor: loading ? 'progress' : 'default'
              }}
            >
              <div className='pa3'>
                <TextInput
                  small
                  className='w-100'
                  placeholder='Search Friend`s names...'
                  onChange={e => stayPals.search(e.target.value)}
                />
              </div>

              <div className='ph3'>
                <InfiniteList
                  height={300}
                  width='100%'
                  itemHeight={ITEM_HEIGHT}
                  renderItem={this.renderStayPalItem}
                  data={list}
                  loadMore={() => stayPals.loadNextPage()}
                  loading={loading}
                  hasMore={hasMore}
                  renderEmptyComponent={EmptyComponent}
                  extraData={extra}
                />
              </div>
            </div>
          )
        }}
      </ProvideStayPals>
    )
  }
}

const Avatar = styled.img`
  width: 44px;
  height: 44px;
  border-radius: 50%;
  border: 2px solid
    ${props => (props.friend ? colors.redRGBA(0.5) : colors.blueRGBA(0.5))};
`

const InviteButton = styled.button.attrs({ type: 'button' })`
  color: ${colors.darkBlue};
  border-color: ${colors.blueRGBA(0.4)};

  &[disabled] {
    color: ${colors.blackRGBA(0.5)};
    border-color: ${colors.blackRGBA(0.1)};
    cursor: default;

    &:hover {
      background-color: white;
    }
  }
`

const EmptyComponent = () => <p>No Friends found.</p>

export default StayPalsList
