import api, { getDefaultResponse } from 'utils/api'
import camelize, { decamelize } from 'utils/camelize'
import sauce from 'config/api'
import config from 'config'

const baseURLv2 = config.API_URL.replace('v1', 'v2')

export const fetchCommunityNumbers = data => {
  return api.post('/my_community', data)
}

export const fetchOwnPlaces = () => {
  return api.get('/map_search/current_user_places')
}

export const fetchDetails = ({ userID, id }) => {
  return api.get(
    '/map_search/place_details',
    { id, userId: userID },
    {
      baseURL: baseURLv2
    }
  )
}

export const fetchInvitationStatus = userID => {
  return api.get(`connections/${userID}/connection_type`)
}

export const sendMessage = ({ subject, userID, message }) => {
  return api.post('/conversations', {
    name: subject,
    attributes: { userIds: [userID] },
    message
  })
}

export const sendSPRequest = emails => {
  if (!Array.isArray(emails)) {
    emails = [emails]
  }

  return api.post('/connections', { emails })
}

export const getStayPals = params => {
  return api.get('/stay_pals/sp_and_sp2', {
    ...params,
    rpp: 30
  })
}

export const categorySearch = async ({ path, data }) => {
  const { checkIn, checkOut, ...rest } = data

  const response = await getDefaultResponse(sauce.post, `/${path}`, {
    ...decamelize(rest),
    checkIn,
    checkOut
  })

  return camelize(response)
}

export const plainApi = ({ path, method, data, config }) => {
  return api[method](`/${path}`, data, {
    baseURL: baseURLv2
  })
}
