import React, { useEffect } from 'react'

import { Router, Location } from '@reach/router'

// import MediaQuery from 'react-responsive'

import * as routes from 'config/routes'

import root from 'utils/windowOrGlobal'

import { AuthProvider } from 'stores/auth'
// import withDestinationWithFriendsState from './screens/adventure/DestinationWithFriends/store'
import { DealData } from './screens/adventure/FlightDeals/store'
import { AirportData } from './screens/adventure/Airports/store'

import styled from 'styled-components'

import Layout from 'components/Layout'

import Explore from './screens/explore'
import Booking from './screens/booking'
import Hosting from './screens/hosting'
import Adventure from './screens/adventure'
import Stays from './screens/stays'
import Messages from './screens/messages'
// import Help from './screens/help'
import Settings from './screens/settings'
import Prime from './screens/settings/screens/prime'

// import Placeholder from './screens/placeholder'

import Sidebar from './components/Sidebar'

// const AllPlaceholders = () => (
//   <PageWrapper>
//     <Placeholder default page='home' />
//     <Placeholder path={routes.EXPLORE} page='explore' />
//     {/* <Placeholder path={routes.BOOKING_BUSINESS} page='bookingBusiness' /> */}
//     <Placeholder path={routes.BOOKING_FRIENDS} page='bookingFriendship' />
//     {/* <Placeholder path={routes.HOSTING_BUSINESS} page='hostingBusiness' /> */}
//     <Placeholder path={routes.HOSTING_FRIENDS} page='hostingFriendship' />
//     <Placeholder path={routes.ADVENTURE_SETTINGS} page='adventure' />
//     <Placeholder path={routes.MESSAGES} page='messages' />
//     <Placeholder path={routes.STAYS} page='stays' />
//   </PageWrapper>
// )

const AppContentWithStore = ({ authStore }) => {
  const airportData = AirportData.useContainer()
  const {
    originAirports,
    hasOriginAirportsChanged,
    resetOriginAirportsChange
  } = airportData
  const dealsData = DealData.useContainer()
  const { dealFilters, initializeDeals } = dealsData

  const syncCacheAndOriginFilters = async () => {
    try {
      const savedAirports = dealFilters?.fromAirports
      const originAirportsIata = originAirports.map(
        airportDetail => airportDetail.airport.iataCode
      )
      let isInSync = true
      savedAirports.every(airport => {
        if (!originAirportsIata.includes(airport)) {
          isInSync = false
          return false
        }
        return true
      })
      if (!isInSync) {
        const autoDealFilterOption = {
          dateOrder: 'desc',
          fromAirports: [originAirports[0].airport.iataCode],
          status: 'active'
        }
        return initializeDeals(autoDealFilterOption)
      }
    } catch (err) {}
  }

  useEffect(() => {
    if (hasOriginAirportsChanged || originAirports.length > 0) {
      // if origin airports changed and all origin airports deleted
      if (!originAirports || originAirports.length === 0) {
        return initializeDeals(null)
      }

      const autoDealFilterOption = {
        dateOrder: 'desc',
        fromAirports: [originAirports[0].airport.iataCode],
        status: 'active'
      }

      // if origin airports changed and has origin airports
      if (hasOriginAirportsChanged) {
        resetOriginAirportsChange()
        return initializeDeals(autoDealFilterOption)
      }

      if (!dealFilters && originAirports.length > 0) {
        return initializeDeals(autoDealFilterOption)
      }
      if (dealFilters && originAirports.length > 0) {
        syncCacheAndOriginFilters()
      }

      // const initialDealFilters = hasOriginAirportsChanged
      //   ? autoDealFilterOption
      //   : dealFilters || autoDealFilterOption

      // initializeDeals(initialDealFilters)
    }
  }, [hasOriginAirportsChanged, originAirports, dealFilters])

  return (
    <BodyWrapper className='flex flex-row w-100'>
      <Sidebar />

      {/* <MediaQuery maxWidth={1024}>
                    {console.log('MAX WIDTH')}
                    <AllPlaceholders />
                  </MediaQuery> */}

      {/* <MediaQuery> */}
      <PageWrapper>
        <Explore default path={routes.EXPLORE} />

        <Booking
          path={`${routes.BOOKING}/:type`}
          user={authStore.getCurrentUser()}
        />

        <Hosting path={`${routes.HOSTING_BUSINESS}/*`} type='business' />
        <Hosting path={`${routes.HOSTING_FRIENDS}/*`} type='friendship' />

        <Adventure path={`${routes.ADVENTURE_SETTINGS}/*`} />

        <Stays path={`${routes.STAYS}/*`} />

        <Messages path={`${routes.MESSAGES}/*`} />

        <Prime path={routes.PRIME_SUBSCRIPTION} />

        <Settings
          path={`${routes.SETTINGS}/*`}
          user={authStore.getCurrentUser()}
        />
      </PageWrapper>
      {/* </MediaQuery> */}

      {/* Re-enable pages one-by-one as they're ready
                  <PageWrapper>
                    <Booking path={`${routes.BOOKING}/:type`} />
                    <Adventure path={routes.ADVENTURE_SETTINGS} />
                    <Settings path={`${routes.SETTINGS}/*`} />
                    <Stays path={routes.STAYS} />
                    <Help path={routes.HELP} />
                  </PageWrapper> */}
    </BodyWrapper>
  )
}

const AppContent = props => {
  return (
    <DealData.Provider>
      <AirportData.Provider>
        <AppContentWithStore {...props} />
      </AirportData.Provider>
    </DealData.Provider>
  )
}

const AppPage = () => {
  // const containerData = DestinationWithFriendsData.useContainer()
  // const { fetchSharedDestination } = containerData
  // fetchSharedDestination()
  return (
    <Layout>
      <Location>
        {({ location }) => (
          <AuthProvider>
            {store => {
              if (!store.isLoggedIn()) {
                // If we’re not logged in, redirect to the home page.
                const next = encodeURIComponent(
                  `${location.pathname}${location.search}`
                )
                root.location = `/${routes.LOGIN}&next=${next}`
                return null
              }
              return <AppContent authStore={store} />
            }}
          </AuthProvider>
        )}
      </Location>
    </Layout>
  )
}

const BodyWrapper = styled.div.attrs({
  id: 'BodyWrapper'
})`
  position: relative;
  height: calc(100vh - 60px);
  overflow: hidden;

  // @media (min-width: 768px) {
  //   height: 93.4vh;
  // }
`

const PageWrapper = styled(Router)`
  display: flex;
  flex: 1;
  overflow: auto;
  @media (max-width: 991px) {
    margin-bottom: 100px;
  }
`

// export default withDestinationWithFriendsState(AppPage)
export default AppPage
