import React from 'react'

import Carousel from 'nuka-carousel'

import get from 'lodash/get'

import styled from 'styled-components'

import colors from 'styles/colors'

import { cloudinary } from 'utils/profile-info'

import Icon from 'components/Icon'
import Modal from 'components/Modal'

class PhotoShowcaseModal extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      slideIndex: props.pictureIndex || 0
    }
  }

  render () {
    const { pictures, deletePicture, onClose } = this.props

    return (
      <PhotoModal isOpen onRequestClose={onClose} contentLabel='Room pictures'>
        <div className='relative hide-child'>
          <Carousel
            edgeEasing='easeLinear'
            heightMode='current'
            initialSlideHeight={720}
            slideIndex={this.state.slideIndex}
            afterSlide={slideIndex => this.setState({ slideIndex })}
            renderCenterLeftControls={NavLeftButton}
            renderCenterRightControls={NavRightButton}
          >
            {pictures.map(picture => {
              const publicId = get(picture, 'cloudinaryPublicId')
              const sauce = cloudinary(publicId)

              return (
                <div key={publicId} className='w-100 center tc'>
                  <Image src={sauce} className='mw-100' />
                </div>
              )
            })}
          </Carousel>

          <div className='child w-100 bg-black-30 flex flex-row justify-between items-center pa4 absolute bottom-0 left-0 right-0'>
            {deletePicture && (
              <button
                type='button'
                className='white-80 hover-white-70 fw6 link pointer bg-transparent bn pa0'
                onClick={() => {
                  const picture = pictures[this.state.slideIndex]
                  deletePicture(picture)
                }}
              >
                Delete
              </button>
            )}

            <button
              type='button'
              className='white-50 hover-white-70 underline-hover fw5 link pointer bg-transparent bn pa0'
              style={{ marginLeft: 'auto' }} // always align right
              onClick={onClose}
            >
              Close
            </button>
          </div>
        </div>
      </PhotoModal>
    )
  }
}

const PhotoModal = styled(Modal)`
  padding: 0;
  max-height: 720px;
  max-width: 1080px;
  background-color: ${colors.blackRGBA(0.9)};
`

const Image = styled.img`
  min-height: 600px;
  max-height: 720px;
`

const NavLeftButton = ({ previousSlide, currentSlide }) => (
  <NavButton
    left
    onClick={previousSlide}
    disabled={currentSlide === 0}
    className='child'
  >
    <LeftCaretIcon />
  </NavButton>
)

const NavRightButton = ({ nextSlide, currentSlide, slideCount }) => (
  <NavButton
    right
    onClick={nextSlide}
    disabled={currentSlide === slideCount - 1}
    className='child'
  >
    <RightCaretIcon />
  </NavButton>
)

const NavButton = styled.button.attrs({ type: 'button' })`
  background: transparent;
  border: none;
  outline: none;
  padding: 0;
  cursor: pointer;
  transition: opacity 0.25s ease;
  margin: 0 1rem;
  color: ${colors.whiteRGBA(0.7)};

  &:hover,
  &:focus {
    opacity: 0.7;
  }

  &[disabled] {
    color: ${colors.whiteRGBA(0.3)};
    cursor: default;
  }
`

const LeftCaretIcon = styled(Icon).attrs({ name: 'caret' })`
  width: 3rem;
  height: 3rem;

  transform: rotate(90deg);
`

const RightCaretIcon = styled(LeftCaretIcon)`
  transform: rotate(-90deg);
`

export default PhotoShowcaseModal
