import { createGlobalStyle } from 'styled-components'

const MapGlobalStyle = createGlobalStyle`
#search-page .sc-room-pics .sc-pics-wrapper .sc-idle-slider,
#search-page .sc-search .sc-multi-user-cluster-ctr,
#search-page .sc-search .sc-user .sc-user-details .sc-place-type .sc-guest-host > div {
  display: flex;
  justify-content: center;
  align-items: center;
}

#search-page {
  /*End general rules*/
}
#search-page .sc-search-header {
  width: 100%;
  height: 50px;
  margin-top: -50px;
}
@media (min-width: 768px) {
  #search-page .sc-search-header {
    height: 6.6vh;
    margin-top: -6.6vh;
  }
}
#search-page input[type=number]::-webkit-inner-spin-button,
#search-page input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
#search-page > section {
  padding: 0;
  overflow-x: hidden;
}
#search-page .DateRangePicker__tether--show.DateRangePicker__tether-element,
#search-page .DateRangePicker__tether--invisible.DateRangePicker__tether-element {
  top: 30px !important;
}
@media (max-width: 720px) {
  #search-page .DateRangePicker__tether--show.DateRangePicker__tether-element,
#search-page .DateRangePicker__tether--invisible.DateRangePicker__tether-element {
    top: 50% !important;
    left: 50% !important;
    transform: translateX(-50%) translateY(0%) translateZ(0px) !important;
  }
  #search-page .DateRangePicker__tether--show.DateRangePicker__tether-element:before,
#search-page .DateRangePicker__tether--invisible.DateRangePicker__tether-element:before {
    content: "";
    display: block;
    width: 200vw;
    height: 200vh;
    position: fixed;
    top: -50vh;
    left: -50vw;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
  }
}
#search-page .sc-disabled-section .DateInput:last-child:before {
  background-color: transparent;
}
@media (min-width: 768px) {
  #search-page .pac-container {
    margin-left: 5px;
  }
}
#search-page .sc-disabled-section .DateInput,
#search-page .sc-disabled-section .DateRangePickerInput__arrow {
  background-color: #4c4c4c !important;
  border-color: #4c4c4c !important;
  color: #b5b5b5 !important;
}
#search-page .sc-disabled-section .DateInput__display-text {
  color: #b5b5b5 !important;
}
#search-page .sc-disabled-section .sc-guests-wrapper {
  border-color: #4c4c4c !important;
  background-color: #4c4c4c !important;
}
#search-page .sc-disabled-section .sc-guests-wrapper select {
  color: #b5b5b5 !important;
  background-color: #4c4c4c !important;
  border-color: #4c4c4c !important;
}
#search-page .sc-search-form {
  float: left;
  margin-top: -2px;
}
#search-page .sc-search-form input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  opacity: 1;
  color: #555;
}
#search-page .sc-search-form input::-moz-placeholder {
  /* Firefox 19+ */
  opacity: 1;
  color: #555;
}
#search-page .sc-search-form input:-moz-placeholder {
  /* Firefox 18- */
  opacity: 1;
  color: #555;
}
#search-page .sc-search-form input:-ms-input-placeholder {
  /* IE 10+ */
  opacity: 1;
  color: #555;
}
#search-page .sc-search-form input::-ms-input-placeholder {
  /* Edge */
  opacity: 1;
  color: #555;
}
#search-page .sc-search-form input::placeholder {
  opacity: 1;
  color: #555;
}
#search-page .sc-search-form input,
#search-page .sc-search-form select {
  width: 90px;
  height: 30px;
  color: #000;
  border: none;
  margin: 0 3px;
  text-align: center;
  padding: 0 10px;
  font-size: 14px;
  background-color: #fff;
}
#search-page .sc-search-form #location {
  width: 220px;
  text-align: left;
  line-height: initial;
}
@media (min-width: 768px) and (max-width: 899px) {
  #search-page .sc-search-form #location {
    width: 180px;
  }
}
@media (min-width: 900px) {
  #search-page .sc-search-form #location {
    width: 260px;
  }
}
#search-page .sc-search-form .sc-disabled-section * {
  color: #000 !important;
}
#search-page .sc-search-form .sc-disabled-section * svg {
  fill: #000 !important;
}
#search-page .sc-search {
  position: relative;
  background-color: #f8f8f8;
}
#search-page .sc-search .loading-spinner {
  z-index: 1000;
  width: 10rem;
  position: absolute;
  top: -40px;
  right: 0px;
}
#search-page .sc-search .loading-spinner * {
  font-size: 4px;
}
#search-page .sc-search .loading-spinner.center-spinner {
  display: block;
  position: absolute;
  -webkit-transform: translate(-50%, -50%) !important;
  -moz-transform: translate(-50%, -50%) !important;
  -ms-transform: translate(-50%, -50%) !important;
  -o-transform: translate(-50%, -50%) !important;
  transform: translate(-50%, -50%) !important;
  top: 50%;
  left: 50%;
  right: 50%;
}
#search-page .sc-search .loading-spinner.center-spinner * {
  font-size: 10px;
}
#search-page .sc-search .sc-search-map {
  margin-top: 0;
}
#search-page .sc-search .sc-user .sc-user-details {
  min-width: 280px;
  max-width: 320px;
  position: absolute;
  z-index: 1008;
  background: #222223;
  color: #fff;
  border: 3px solid #222223;
  border-radius: 4px;
  -webkit-transform: translate(-120%, -50%);
  -moz-transform: translate(-120%, -50%);
  -ms-transform: translate(-120%, -50%);
  -o-transform: translate(-120%, -50%);
  transform: translate(-120%, -50%);
}
#search-page .sc-search .sc-user .sc-user-details.sc-msg-staypal {
  -webkit-transform: translate(-120%, -100%);
  -moz-transform: translate(-120%, -100%);
  -ms-transform: translate(-120%, -100%);
  -o-transform: translate(-120%, -100%);
  transform: translate(-120%, -100%);
}
#search-page .sc-search .sc-user .sc-user-details.sc-msg-staypal .sc-invitation-group {
  width: 320px;
}
#search-page .sc-search .sc-user .sc-user-details .sc-closer {
  color: #fff;
  text-decoration: none;
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 16px;
  line-height: 16px;
}
@media (max-width: 767px) {
  #search-page .sc-search .sc-user .sc-user-details {
    top: calc(50% + 65px);
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  #search-page .sc-search .sc-user .sc-user-details:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    box-shadow: 0 0 50px 300px rgba(0, 0, 0, 0.5);
    z-index: -999;
  }
}
@media (min-width: 768px) {
  #search-page .sc-search .sc-user .sc-user-details .sc-closer {
    display: none;
  }
  #search-page .sc-search .sc-user .sc-user-details:before {
    content: "";
    width: 0;
    height: 0;
    border-width: 15px;
    border-style: solid;
    border-color: transparent transparent transparent #292a2b;
    position: absolute;
    top: 40%;
    right: -33px;
    z-index: 999;
  }
}
#search-page .sc-search .sc-user .sc-user-details .row {
  margin: 0;
  padding: 9px;
}
#search-page .sc-search .sc-user .sc-user-details .row img {
  width: 57px;
  border-radius: 50%;
}
#search-page .sc-search .sc-user .sc-user-details .row > div {
  padding: 0;
}
#search-page .sc-search .sc-user .sc-user-details .row h3,
#search-page .sc-search .sc-user .sc-user-details .row h4,
#search-page .sc-search .sc-user .sc-user-details .row h5,
#search-page .sc-search .sc-user .sc-user-details .row p {
  margin: 0;
}
#search-page .sc-search .sc-user .sc-user-details .row h3 {
  font-size: 16px;
}
#search-page .sc-search .sc-user .sc-user-details .row label {
  position: relative;
  width: 100%;
}
#search-page .sc-search .sc-user .sc-user-details .sc-user-rooms {
  font-size: 14px;
  padding-bottom: 0;
  border-radius: 4px 4px 0 0;
  line-height: 14px;
}
#search-page .sc-search .sc-user .sc-user-details .sc-user-rooms img {
  float: left;
  margin-right: 6px;
}
#search-page .sc-search .sc-user .sc-user-details .sc-user-rooms .sc-user-info h3 {
  font-family: "Lato", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  font-weight: 900;
}
#search-page .sc-search .sc-user .sc-user-details .sc-user-rooms .sc-select-room {
  margin-top: 6px;
  text-align: center;
  padding: 6px 0 4px;
  font-size: 14px;
}
#search-page .sc-search .sc-user .sc-user-details .sc-user-rooms .sc-two-rooms {
  border-bottom: 1px solid #222223;
}
#search-page .sc-search .sc-user .sc-user-details .sc-user-rooms .sc-two-rooms:after {
  content: "";
  width: 1px;
  height: 100%;
  background: #222223;
  position: absolute;
  left: calc(50% - 1px);
  bottom: 0;
}
#search-page .sc-search .sc-user .sc-user-details .sc-user-rooms .sc-two-rooms .sc-room {
  margin: 0;
  float: left;
}
#search-page .sc-search .sc-user .sc-user-details .sc-user-rooms .sc-two-rooms .sc-room:first-of-type {
  margin-right: -1px;
}
#search-page .sc-search .sc-user .sc-user-details .sc-user-rooms .sc-single-room .sc-room {
  border-bottom: 1px solid #222223;
  margin: auto;
}
#search-page .sc-search .sc-user .sc-user-details .sc-user-rooms .sc-two-rooms .sc-room,
#search-page .sc-search .sc-user .sc-user-details .sc-user-rooms .sc-single-room .sc-room {
  width: 50%;
  display: block;
  font-weight: normal;
  font-size: 13px;
  line-height: 13px;
  padding: 2px;
  cursor: pointer;
  text-align: center;
}
#search-page .sc-search .sc-user .sc-user-details .sc-user-rooms .sc-two-rooms .sc-room:hover,
#search-page .sc-search .sc-user .sc-user-details .sc-user-rooms .sc-single-room .sc-room:hover {
  background-color: #53a0ff;
  color: #fff;
  border-radius: 2px;
}
#search-page .sc-search .sc-user .sc-user-details .sc-user-rooms .sc-two-rooms .sc-room .sc-room--name,
#search-page .sc-search .sc-user .sc-user-details .sc-user-rooms .sc-single-room .sc-room .sc-room--name {
  font-size: 12px;
  text-align: center;
}
#search-page .sc-search .sc-user .sc-user-details .sc-user-rooms .sc-two-rooms input[type=checkbox]:checked + .sc-room,
#search-page .sc-search .sc-user .sc-user-details .sc-user-rooms .sc-single-room input[type=checkbox]:checked + .sc-room {
  background-color: #53a0ff;
  color: #fff;
  border-radius: 2px;
}
#search-page .sc-search .sc-user .sc-user-details .sc-place-type {
  text-align: center;
  font-family: "Lato", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  border-top: 3px solid #222223;
  border-radius: 0 0 4px 4px;
  font-size: 14px;
}
#search-page .sc-search .sc-user .sc-user-details .sc-place-type > h3 {
  font-family: "Lato", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  font-weight: 900;
  margin-bottom: 10px;
  display: inline-block;
  background: #f18472;
  color: #fff;
  padding: 3px 30px;
  border-radius: 3px;
}
#search-page .sc-search .sc-user .sc-user-details .sc-place-type .sc-dates-nights-guests {
  text-align: left;
  width: 90%;
  margin: auto;
  float: none;
  text-align: center;
}
#search-page .sc-search .sc-user .sc-user-details .sc-place-type .sc-dates-nights-guests .sc-title {
  display: inline-block;
  width: 30%;
}
#search-page .sc-search .sc-user .sc-user-details .sc-place-type .sc-dates-nights-guests .sc-data {
  text-transform: capitalize;
}
#search-page .sc-search .sc-user .sc-user-details .sc-place-type .sc-price {
  margin: 10px 0;
}
#search-page .sc-search .sc-user .sc-user-details .sc-place-type .sc-price input,
#search-page .sc-search .sc-user .sc-user-details .sc-place-type .sc-price select {
  color: #000;
}
#search-page .sc-search .sc-user .sc-user-details .sc-place-type .sc-price label {
  width: 25%;
  float: left;
  margin: 0;
  font-weight: normal;
  text-align: center;
}
#search-page .sc-search .sc-user .sc-user-details .sc-place-type .sc-price label span.sc-price-input {
  padding: 1px 6px;
}
#search-page .sc-search .sc-user .sc-user-details .sc-place-type .sc-price label input {
  width: 50px;
  color: #4c4c4c;
  text-align: center;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
}
#search-page .sc-search .sc-user .sc-user-details .sc-place-type .sc-price label[for=price-input]:after {
  content: "$";
  position: absolute;
  left: 11px;
  bottom: 1px;
  font-size: 14px;
  color: #4c4c4c;
}
#search-page .sc-search .sc-user .sc-user-details .sc-place-type .sc-price .sc-payment-method {
  width: 50%;
  padding: 0;
  margin: 0;
  text-align: center;
}
#search-page .sc-search .sc-user .sc-user-details .sc-place-type .sc-price .sc-payment-method select {
  width: 95%;
  margin: auto;
  height: 17px;
  font-size: 11px;
}
#search-page .sc-search .sc-user .sc-user-details .sc-place-type .sc-guest-host {
  margin: 8px 0;
}
#search-page .sc-search .sc-user .sc-user-details .sc-place-type .sc-guest-host > div {
  width: 95%;
  margin: auto;
  min-height: 1px;
  overflow: hidden;
}
#search-page .sc-search .sc-user .sc-user-details .sc-place-type .sc-guest-host > div img {
  display: block;
  width: 35px;
  float: left;
}
#search-page .sc-search .sc-user .sc-user-details .sc-place-type .sc-guest-host > div .sc-name {
  width: 55%;
  float: left;
  font-size: 15px;
  line-height: 15px;
  text-align: left;
  padding: 0 6px;
  text-transform: capitalize;
}
#search-page .sc-search .sc-user .sc-user-details .sc-place-type .sc-guest-host > div .sc-name .sc-type {
  font-size: 12px;
  line-height: 12px;
}
#search-page .sc-search .sc-user .sc-user-details .sc-place-type .sc-guest-host > div .sc-cash {
  display: inline-block;
  width: 25%;
  align-self: baseline;
}
#search-page .sc-search .sc-user .sc-user-details .sc-place-type .sc-guest-host .sc-payouts-text {
  width: 34%;
  float: right;
  font-size: 12px;
  margin-top: -15px;
  padding: 10px 0;
}
#search-page .sc-search .sc-user .sc-user-details .sc-place-type .sc-cash {
  padding: 1px 5px;
  font-size: 14px;
}
#search-page .sc-search .sc-user .sc-user-details .sc-place-type .sc-propose-stay {
  font-weight: 900;
  padding-top: 9px;
}
#search-page .sc-search .sc-user .sc-user-details .sc-place-type .sc-propose-stay p {
  text-transform: capitalize;
}
#search-page .sc-search .sc-user .sc-user-details .sc-place-type .sc-propose-stay button {
  font-family: "Lato", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  font-weight: 900;
  color: #fff;
  background-color: #53a0ff;
  line-height: 14px;
  padding: 4px 8px;
  text-transform: capitalize;
}
#search-page .sc-search .sc-user .sc-user-details.sc-msg-staypal .sc-place-type {
  border-top: none;
  padding-top: 0;
}
#search-page .sc-search .sc-cluster {
  cursor: pointer;
  position: relative;
}
#search-page .sc-search .sc-cluster-nobg-ctr {
  cursor: pointer;
  width: 67px;
  position: relative;
  top: -17px;
  left: -30px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
#search-page .sc-search .sc-cluster-nobg-ctr img {
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-bottom: -14px;
}
#search-page .sc-search .sc-cluster-nobg-ctr img:nth-child(even) {
  margin-left: -14px;
}
#search-page .sc-search .sc-multi-user-cluster-ctr {
  text-align: center;
  position: absolute;
  width: 70px;
  height: 55px;
  left: -35px;
  top: -27.5px;
  overflow: hidden;
  border-radius: 50%;
}
#search-page .sc-search .sc-multi-user-cluster {
  height: 40px;
  width: 56px;
  border-radius: 8px;
  font-size: 12px;
  color: #222223;
  font-family: "Lato", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  text-transform: capitalize;
}
#search-page .sc-search .sc-multi-user-cluster p {
  padding: 2px 0 0;
  margin: 0;
}
#search-page .sc-search .sc-multi-user-cluster img {
  height: 100%;
  border-radius: 50%;
  float: left;
  z-index: 2;
  position: relative;
}
#search-page .sc-search .sc-multi-user-cluster .sc-remaining-housemates {
  width: 26px;
  height: 40px;
  right: 2px;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  z-index: 1;
}
#search-page .sc-search .sc-multi-user-cluster .sc-remaining-housemates img {
  width: 20px;
  height: 20px;
  z-index: 1;
  top: -2px;
  border-width: 2px;
}
#search-page .sc-search .sc-multi-user-cluster .sc-remaining-housemates-full {
  width: 70px;
  height: auto;
  left: 50%;
  right: initial;
  transform: translate(-50%, -50%);
}
#search-page .sc-search .sc-multi-user-cluster .sc-remaining-housemates-full img {
  width: 35px;
  height: 35px;
  border-width: 3px;
}
#search-page .sc-search .sc-multi-user-cluster .sc-remaining-housemates-full img:last-child {
  left: -10px;
}
#search-page .sc-search .sc-multi-user-cluster .sc-remaining-housemates-alone img {
  top: 50%;
  transform: translateY(-50%);
}
#search-page .sc-search .sc-multi-user-cluster .sc-remaining-housemates-length {
  font-size: 11px;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
#search-page .sc-search .sc-remaining-housemates-length {
  top: 45%;
  z-index: 1;
  font-size: 13px;
  font-family: "Lato", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  font-weight: bold;
  position: absolute;
  display: inline-block;
  text-shadow: 1px 1px 1rem white;
}
#search-page .sc-search .sc-remaining-housemates-length::after {
  content: "";
  position: absolute;
  top: -20%;
  left: -20%;
  background: white;
  height: 140%;
  width: 140%;
  z-index: -1;
  filter: blur(4px);
}
#search-page .sc-search .sc-zoom-range {
  position: fixed;
}
@media (max-width: 767px) {
  #search-page .sc-search .sc-zoom-range {
    display: none;
  }
}
@media (min-width: 768px) {
  #search-page .sc-search .sc-zoom-range {
    z-index: 9999;
    right: 30px;
  }
}
#search-page .sc-search .sc-pics-wrapper {
  width: 165px;
  height: 100px;
}
#search-page .sc-room-pics {
  position: relative;
  margin: 10px 0 0;
  padding-bottom: 9px;
}
#search-page .sc-room-pics .sc-next,
#search-page .sc-room-pics .sc-previous {
  width: 20px;
  height: 28px;
  position: absolute;
  top: 35%;
  right: 8px;
  font-size: 22px;
  text-align: center;
  cursor: pointer;
  z-index: 999;
}
#search-page .sc-room-pics .sc-previous {
  right: initial;
  left: 8px;
}
#search-page .sc-room-pics .sc-pics-wrapper {
  margin: auto;
  position: relative;
  display: flex;
  overflow: hidden;
}
#search-page .sc-room-pics .sc-pics-wrapper .sc-pic {
  position: relative;
  min-width: 100%;
  min-height: 100%;
}
#search-page .sc-room-pics .sc-pics-wrapper .sc-pic.sc-item {
  cursor: -webkit-zoom-in;
}
#search-page .sc-room-pics .sc-pics-wrapper .sc-idle-slider {
  font-size: 14px;
  border: 1px solid;
  padding: 5px;
}
#search-page .sc-room-pics .sc-pics-wrapper .sc-idle-slider p {
  text-align: center;
}
#search-page .sc-mutual-staypals {
  -webkit-transform: translateY(-25%);
  -moz-transform: translateY(-25%);
  -ms-transform: translateY(-25%);
  -o-transform: translateY(-25%);
  transform: translateY(-25%);
}
#search-page .jrWrapperModal {
  max-width: 464px;
  margin: 20vh auto 0;
}
#search-page .jrWrapperModal.jrIntro {
  margin: 16vh auto 0;
}
@media (max-width: 375px) {
  #search-page .jrWrapperModal {
    width: 100vw;
  }
  #search-page .jrWrapperModal.jrIntro {
    margin: 10vh auto;
  }
}
#search-page .jrWrapperModal [class~=modal-content] {
  border: none !important;
}
#search-page .disappearAppear {
  opacity: 0;
  visibility: hidden;
}
#search-page .appear {
  opacity: 1;
  visibility: visible;
  -webkit-transition: opacity 2s ease-in-out 1s;
  -moz-transition: opacity 2s ease-in-out 1s;
  -ms-transition: opacity 2s ease-in-out 1s;
  -o-transition: opacity 2s ease-in-out 1s;
  transition: opacity 2s ease-in-out 1s;
}
#search-page .disappear {
  opacity: 0;
  -webkit-transition: opacity 2s ease-in-out 1s;
  -moz-transition: opacity 2s ease-in-out 1s;
  -ms-transition: opacity 2s ease-in-out 1s;
  -o-transition: opacity 2s ease-in-out 1s;
  transition: opacity 2s ease-in-out 1s;
}

.Search--modal .Search--modal-dialog {
  min-width: 200px;
  width: 300px;
  margin: 0;
  display: block;
  position: absolute;
  -webkit-transform: translate(-50%, -50%) !important;
  -moz-transform: translate(-50%, -50%) !important;
  -ms-transform: translate(-50%, -50%) !important;
  -o-transform: translate(-50%, -50%) !important;
  transform: translate(-50%, -50%) !important;
  top: 50%;
  left: 50%;
  right: 50%;
}
@media (max-width: 767px) {
  .Search--modal .Search--modal-dialog {
    max-width: 340px;
  }
}
.Search--modal .Search--modal-dialog div[class~=modal-content] {
  border-radius: 0;
  background-color: #333;
  color: #fff;
  padding-bottom: 15px;
}
.Search--modal .Search--modal-dialog div[class~=modal-content] .Search--modal-header {
  text-align: center;
  border-bottom: 2px dotted #fff;
  margin-bottom: 10px;
  padding-bottom: 10px;
}
.Search--modal .Search--modal-dialog div[class~=modal-content] .Search--modal-header p {
  margin: 10px 0 0;
}
.Search--modal .Search--modal-dialog div[class~=modal-content] .Search--modal-header h3 {
  font-family: "Lato", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  font-weight: 900;
  font-size: 16px;
  margin: 0;
}
.Search--modal .Search--modal-dialog div[class~=modal-content] .Search--modal-header .Search--modal-closer {
  width: 18px;
  height: 18px;
  line-height: 18px;
  position: absolute;
  top: 2px;
  right: 2px;
  color: #fff;
  text-decoration: none;
  font-size: 30px;
}
.Search--modal .Search--modal-dialog div[class~=modal-content] form .btn {
  padding: 0 15px;
  margin: 10px 30px;
  background-color: #f18472;
}
.Search--modal .Search--modal-dialog div[class~=modal-content] form .btn:hover {
  color: #fff;
  background-color: #ec5c44;
}
.Search--modal .Search--modal-dialog .Search--modal-btn {
  font-family: "Lato", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  font-weight: 900;
  background-color: #f18472;
  border: none;
  color: #fff !important;
  height: 40px;
  padding-left: 20px;
  padding-right: 20px;
  margin: 0 !important;
}
.Search--modal .Search--modal-dialog .Search--modal-btn:hover {
  background-color: #ef705b;
}
.SC-ExploreMap-Pin {
  position: absolute;
  left: 14px;
  top: 50%;
  margin-top: -29px;
  cursor: pointer;
}
.SC-ExploreMap-Pin--multiple {
  position: relative;
  float: left;
  left: 54px;
  margin-top: -70px;
  margin-right: 5px;
}
.SC-ExploreMap-Pin--multiple .SC-ExploreMap-Pin__tail {
  top: 36px;
}
.SC-ExploreMap-Pin--multiple .SC-ExploreMap-Pin__tail .SC-ExploreMap-Pin__tail-ring {
  top: 2px;
  left: 1px;
}
.SC-ExploreMap-Pin--multiple .SC-ExploreMap-Pin__tail .SC-ExploreMap-Pin__tail-circle {
  top: 2px;
  left: -5px;
}
.SC-ExploreMap-Pin--multiple.SC-ExploreMap-Pin--second .SC-ExploreMap-Pin__tail-circle {
  top: 3px;
  left: 1px;
}
.SC-ExploreMap-Pin--blurred {
  filter: blur(2px);
}
.SC-ExploreMap-Pin--detached {
  position: relative;
  left: inherit;
  top: inherit;
  margin: inherit;
}
.SC-ExploreMap-Pin--alone .SC-ExploreMap-Pin__tail {
  top: 50%;
  left: -6px;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}
.SC-ExploreMap-Pin--private .SC-ExploreMap-Pin__tail-ring {
  display: none;
}
.SC-ExploreMap-Pin--private .SC-ExploreMap-Pin__tail-circle {
  width: 56px;
  height: 56px;
  background: rgba(241, 132, 114, 0.5);
  left: 1px;
  transform: translate(-100%, -50%);
  filter: blur(2px);
}
.SC-ExploreMap-Pin--second .SC-ExploreMap-Pin__avatar {
  border-color: #53a0ff;
}
.SC-ExploreMap-Pin--second .SC-ExploreMap-Pin__nameBadge {
  color: #53a0ff;
  border-color: #53a0ff;
}
.SC-ExploreMap-Pin--second .SC-ExploreMap-Pin__tail {
  background: #53a0ff;
}
.SC-ExploreMap-Pin--second .SC-ExploreMap-Pin__tail-circle {
  background: rgba(83, 160, 255, 0.5);
}
.SC-ExploreMap-Pin__avatar, .SC-ExploreMap-Pin__nameBadge {
  position: relative;
  left: 30px;
  z-index: 9999;
  cursor: pointer;
}
.SC-ExploreMap-Pin__avatar:hover, .SC-ExploreMap-Pin__nameBadge:hover {
  z-index: 10000;
}
.SC-ExploreMap-Pin__avatar {
  width: 73px;
  height: 73px;
  border: 3px solid #f18472;
  border-radius: 50%;
}
.SC-ExploreMap-Pin__avatar .overlay {
  opacity: 0;
  transition: opacity 0.2s ease-in;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  overflow: hidden;
}
.SC-ExploreMap-Pin__avatar .overlay-scrolling-text {
  -webkit-transform: translateX(100%);
  -moz-transform: translateX(100%);
  -ms-transform: translateX(100%);
  -o-transform: translateX(100%);
  transform: translateX(100%);
}
.SC-ExploreMap-Pin__nameBadge {
  color: #f18472;
  border: 2px solid #f18472;
  background-color: white;
  padding: 4px 12px;
  border-radius: 12px;
  text-align: center;
  font-family: "Lato", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  font-size: 12px;
  white-space: nowrap;
}
.SC-ExploreMap-Pin__link {
  position: absolute;
  top: 50%;
  width: 5px;
  height: 5px;
  right: -8px;
  background-color: #f18472;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}
.SC-ExploreMap-Pin__link-active {
  background: #f18472;
}
@keyframes movetoleft {
  0% {
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    -ms-transform: translateX(100%);
    -o-transform: translateX(100%);
    transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -o-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}
.SC-ExploreMap-Pin:hover .SC-ExploreMap-Pin__avatar .overlay {
  opacity: 1;
}
.SC-ExploreMap-Pin:hover .SC-ExploreMap-Pin__avatar .overlay .overlay-scrolling-text {
  animation: movetoleft 4s linear infinite;
}
.SC-ExploreMap-Pin__tail {
  position: absolute;
  width: 36px;
  height: 5px;
  background: #f18472;
  top: 26px;
  left: -65%;
  z-index: -1;
  transform: rotate(-16deg);
  transform-origin: right;
}
.SC-ExploreMap-Pin__tail-ring {
  width: 16px;
  height: 16px;
  background: #f18472;
  border-radius: 50%;
  position: absolute;
  left: initial;
  top: inherit;
  -webkit-transform: translate(-100%, -50%);
  -moz-transform: translate(-100%, -50%);
  -ms-transform: translate(-100%, -50%);
  -o-transform: translate(-100%, -50%);
  transform: translate(-100%, -50%);
}
.SC-ExploreMap-Pin__tail-circle {
  width: 6px;
  height: 6px;
  background: #fff;
  border-radius: 50%;
  position: absolute;
  top: inherit;
  left: inherit;
  -webkit-transform: translate(-80%, -50%);
  -moz-transform: translate(-80%, -50%);
  -ms-transform: translate(-80%, -50%);
  -o-transform: translate(-80%, -50%);
  transform: translate(-80%, -50%);
}
.SC-ExploreMap-Pin__pals-degree {
  font-family: "Lato", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  position: absolute;
  top: 16px;
  right: -51px;
  width: 24px;
  height: 25px;
  line-height: 22px;
  z-index: 9999;
  color: #fff;
  font-size: 15px;
  padding-top: 4px;
  background: #53a0ff;
  border-radius: 0px 10px 11px 1px;
}
.SC-ExploreMap-Pin--active .SC-ExploreMap-Pin-circle {
  background: rgba(255, 190, 94, 0.6) !important;
}
.SC-ExploreMap-Pin--active .SC-ExploreMap-Pin__avatar {
  border: 5px solid #ffbe5e !important;
}
.SC-ExploreMap-Pin--active .SC-ExploreMap-Pin__link {
  right: -10px;
}
.SC-ExploreMap-Pin--active .SC-ExploreMap-Pin__pals-degree {
  background: #ffbe5e !important;
}
.SC-ExploreMap-Pin--active .SC-ExploreMap-Pin__tail,
.SC-ExploreMap-Pin--active .SC-ExploreMap-Pin__nameBadge,
.SC-ExploreMap-Pin--active .SC-ExploreMap-Pin__tail-ring, .SC-ExploreMap-Pin--housemate-active .SC-ExploreMap-Pin__tail,
.SC-ExploreMap-Pin--housemate-active .SC-ExploreMap-Pin__nameBadge,
.SC-ExploreMap-Pin--housemate-active .SC-ExploreMap-Pin__tail-ring {
  background: #ffbe5e !important;
}
.SC-ExploreMap-Pin--active .SC-ExploreMap-Pin__nameBadge, .SC-ExploreMap-Pin--housemate-active .SC-ExploreMap-Pin__nameBadge {
  color: #fff;
  border-color: #ffbe5e !important;
}
.SC-ExploreMap-Pin--active .SC-ExploreMap-Pin__tail-circle, .SC-ExploreMap-Pin--housemate-active .SC-ExploreMap-Pin__tail-circle {
  background: rgba(255, 190, 94, 0.6);
}
.SC-ExploreMap-Pin--active.SC-ExploreMap-Pin--second .SC-ExploreMap-Pin__tail-circle, .SC-ExploreMap-Pin--active.SC-ExploreMap-Pin--second .SC-ExploreMap-Pin__tail-circle, .SC-ExploreMap-Pin--housemate-active.SC-ExploreMap-Pin--second .SC-ExploreMap-Pin__tail-circle, .SC-ExploreMap-Pin--housemate-active.SC-ExploreMap-Pin--second .SC-ExploreMap-Pin__tail-circle {
  background: rgba(255, 190, 94, 0.6) !important;
}

.multiple-pins-container,
.SC-ExploreMap-Pin {
  transition: 0.2s ease all;
}
.multiple-pins-container:hover .top-overlay,
.multiple-pins-container .show-overlay,
.SC-ExploreMap-Pin:hover .top-overlay,
.SC-ExploreMap-Pin .show-overlay {
  opacity: 1;
  visibility: visible;
}
.MultiUserHousehold {
  height: 64px;
  width: 64px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.MultiUserHousehold--container {
  position: absolute;
  height: 64px;
  width: 64px;
  border-radius: 50%;
  top: 0;
  left: 0;
}
.MultiUserHousehold--container .sc-pin {
  top: 0;
  left: 0;
  margin-top: 0;
}
.MultiUserHousehold--container .SC-ExploreMap-Pin__avatar {
  z-index: 9999;
  left: 0;
  height: 62px;
  width: 62px;
  border-radius: 50%;
}
.MultiUserHousehold--pin {
  transition: all 0.2s ease-in;
  height: 62px;
  width: 62px;
  border-radius: 50%;
  top: 0;
  left: 0;
  position: absolute;
  opacity: 0;
  position: absolute;
  background-position: center;
  background-size: cover;
}
.MultiUserHousehold--house {
  height: 64px;
  width: 64px;
  border-radius: 50%;
  background: #f8f8f8;
  position: relative;
  border: 3px solid #53a0ff;
  z-index: 9999;
  transition: all 0.2s ease-in-out;
}
.MultiUserHousehold--house.open {
  height: 16px;
  width: 16px;
  border-radius: 50%;
}
.MultiUserHousehold--house.open .avatar {
  opacity: 0;
}
.MultiUserHousehold--house.open.blurred {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #53a0ff;
  filter: blur(2px);
}
.MultiUserHousehold--house .avatar {
  background-image: url("https://res.cloudinary.com/staycircles/image/upload/f_auto,q_auto:best,w_60,c_scale/v1507614625/assets/default-avatar.png");
  position: absolute;
  border-radius: 50%;
  background-position: center;
  background-size: cover;
}
.MultiUserHousehold--house [class*=avatar-2] {
  top: 16px;
  width: 28px;
  height: 28px;
}
.MultiUserHousehold--house .avatar-2-0 {
  left: 4px;
}
.MultiUserHousehold--house .avatar-2-1 {
  right: 4px;
}
.MultiUserHousehold--house [class*=avatar-3] {
  width: 28px;
  height: 28px;
}
.MultiUserHousehold--house .avatar-3-0 {
  top: 2px;
  left: 17px;
}
.MultiUserHousehold--house .avatar-3-1 {
  top: 24px;
  left: 4px;
}
.MultiUserHousehold--house .avatar-3-2 {
  top: 24px;
  right: 4px;
}
.MultiUserHousehold--house [class*=avatar-4] {
  width: 24px;
  height: 24px;
}
.MultiUserHousehold--house .avatar-4-0 {
  top: 7px;
  left: 7px;
}
.MultiUserHousehold--house .avatar-4-1 {
  top: 7px;
  right: 7px;
}
.MultiUserHousehold--house .avatar-4-2 {
  left: 7px;
  bottom: 7px;
}
.MultiUserHousehold--house .avatar-4-3 {
  bottom: 7px;
  right: 7px;
}
.MultiUserHousehold--house [class*=avatar-5] {
  width: 20px;
  height: 20px;
}
.MultiUserHousehold--house .avatar-5-0 {
  top: 4px;
  left: 18px;
}
.MultiUserHousehold--house .avatar-5-1 {
  top: 14px;
  left: 5px;
}
.MultiUserHousehold--house .avatar-5-2 {
  top: 14px;
  left: 33px;
}
.MultiUserHousehold--house .avatar-5-3 {
  top: 32px;
  left: 10px;
}
.MultiUserHousehold--house .avatar-5-4 {
  top: 32px;
  left: 28px;
}
.MultiUserHousehold--house [class*=avatar-6] {
  width: 18px;
  height: 18px;
}
.MultiUserHousehold--house .avatar-6-0 {
  top: 4px;
  left: 19px;
}
.MultiUserHousehold--house .avatar-6-1 {
  top: 12px;
  left: 5px;
}
.MultiUserHousehold--house .avatar-6-2 {
  top: 12px;
  left: 34px;
}
.MultiUserHousehold--house .avatar-6-3 {
  top: 28px;
  left: 5px;
}
.MultiUserHousehold--house .avatar-6-4 {
  top: 28px;
  left: 34px;
}
.MultiUserHousehold--house .avatar-6-5 {
  top: 37px;
  left: 19px;
}
.MultiUserHousehold--house [class*=avatar-7] {
  width: 18px;
  height: 18px;
}
.MultiUserHousehold--house .avatar-7-0 {
  top: 2px;
  left: 20px;
}
.MultiUserHousehold--house .avatar-7-1 {
  top: 11px;
  left: 4px;
}
.MultiUserHousehold--house .avatar-7-2 {
  top: 11px;
  left: 36px;
}
.MultiUserHousehold--house .avatar-7-3 {
  top: 28px;
  left: 4px;
}
.MultiUserHousehold--house .avatar-7-4 {
  top: 28px;
  left: 36px;
}
.MultiUserHousehold--house .avatar-7-5 {
  top: 37.5px;
  left: 20px;
}
.MultiUserHousehold--house .avatar-7-6 {
  top: 20px;
  left: 20px;
}
.MultiUserHousehold--house [class*=avatar-8] {
  width: 15px;
  height: 15px;
}
.MultiUserHousehold--house .avatar-8-0 {
  top: 14px;
  left: 5px;
}
.MultiUserHousehold--house .avatar-8-1 {
  top: 14px;
  left: 16px;
}
.MultiUserHousehold--house .avatar-8-2 {
  top: 14px;
  left: 27px;
}
.MultiUserHousehold--house .avatar-8-3 {
  top: 14px;
  left: 38px;
}
.MultiUserHousehold--house .avatar-8-4 {
  top: 31px;
  left: 5px;
}
.MultiUserHousehold--house .avatar-8-5 {
  top: 31px;
  left: 16px;
}
.MultiUserHousehold--house .avatar-8-6 {
  top: 31px;
  left: 27px;
}
.MultiUserHousehold--house .avatar-8-7 {
  top: 31px;
  left: 38px;
}
.MultiUserHousehold--house [class*=avatar-9] {
  width: 15px;
  height: 15px;
}
.MultiUserHousehold--house .avatar-9-0 {
  top: 6px;
  left: 14px;
}
.MultiUserHousehold--house .avatar-9-1 {
  top: 6px;
  left: 28px;
}
.MultiUserHousehold--house .avatar-9-2 {
  top: 16px;
  left: 5px;
}
.MultiUserHousehold--house .avatar-9-3 {
  top: 16px;
  left: 37px;
}
.MultiUserHousehold--house .avatar-9-4 {
  top: 28px;
  left: 5px;
}
.MultiUserHousehold--house .avatar-9-5 {
  top: 38px;
  left: 14px;
}
.MultiUserHousehold--house .avatar-9-6 {
  top: 38px;
  left: 28px;
}
.MultiUserHousehold--house .avatar-9-7 {
  top: 28px;
  left: 37px;
}
.MultiUserHousehold--house .avatar-9-8 {
  top: 22px;
  left: 21px;
}
`

export default MapGlobalStyle
