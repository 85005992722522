import React from 'react'

import { Location } from '@reach/router'
import { snakeCase, isUndefined, isEmpty, get } from 'lodash'

import OpenSidebar from './components/OpenSidebar'
import SPMap from './components/Map'
import { TABS } from './components/IntegratedStayPalsList'

import { getCurrentUser } from 'stores/auth'
import withStore from './store'

import { push, parseQueryString } from 'utils/parse-querystring'
import * as routes from 'config/routes'
import { RenderOnAction } from 'utils/StateMachine'
import root from 'utils/windowOrGlobal'

class ExploreIndex extends React.Component {
  shouldComponentUpdate (nextProps) {
    if (
      nextProps !== this.props ||
      nextProps.machineStore.state !== this.props.machineStore.state
    ) {
      return true
    }

    return false
  }

  render () {
    return (
      <div className='w-100 h-100'>
        <Location>
          {({ location }) => {
            const isExplore = location.pathname.includes('explore')
            const query = parseQueryString(location.search)

            return (
              <div className='h-100 db flex-l flex-row-l'>
                {isExplore && (
                  <OpenSidebar
                    query={query}
                    mapStore={this.props.machineStore}
                  />
                )}

                <section className='sc-search flex flex-auto h-100'>
                  <RenderOnAction value='showLoading'>
                    {loading => (
                      <MapWrapper
                        query={query}
                        loading={loading}
                        {...this.props}
                        {...this.props.machineStore.state}
                      />
                    )}
                  </RenderOnAction>
                </section>
              </div>
            )
          }}
        </Location>
      </div>
    )
  }
}

class MapWrapper extends React.PureComponent {
  constructor (props) {
    super(props)

    this.state = {
      showBigLoading: false,
      panelOpenStates: {
        activePlace: false,
        stayPalsManager:
          root.matchMedia &&
          (!root.matchMedia('(max-width: 768px)').matches ||
            (root.matchMedia('(max-width: 768px)').matches &&
              TABS.find(
                ({ routeKey }) => routeKey === props.query.activePanel
              )))
      },
      isMobile: false
    }

    this.currentUser = getCurrentUser()
  }

  run = (type, data = {}) => {
    this.props.machineStore.transition({
      type: snakeCase(type).toUpperCase(),
      ...data
    })
  }

  componentDidMount () {
    root.document.body.setAttribute('id', 'search-page')

    root.addEventListener('resize', this.setWindowSize)
    this.setWindowSize()
    this.setupMapViaQuery()

    const {
      result,
      loading,
      // paymentMethods,
      location,
      ownPlacesFetched
    } = this.props

    if (isEmpty(result) && !loading) {
      this.run('fetchCommunityNumbers')
    }

    // if (isEmpty(paymentMethods)) {
    //   this.run('fetchPaymentMethods')
    // }

    if (!ownPlacesFetched) {
      this.run('fetchOwnPlaces')
    }

    if (location.origin === 'form') {
      this.run('setActivePanel', {
        panel: 'search'
      })
    } else {
      this.setActivePanelViaQuery()
    }
  }

  componentWillUnmount () {
    root.document.body.setAttribute('id', '')
    root.removeEventListener('resize', this.setWindowSize)
    root.clearTimeout(this.loadingTimeout)
  }

  componentWillReceiveProps (nextProps) {
    const { loading, activeItem } = this.props

    if (this.props.query.activePanel !== nextProps.query.activePanel) {
      this.setActivePanelViaQuery(nextProps.query)
    }

    if (activeItem !== nextProps.activeItem) {
      this.togglePanel('activePlace', true, () => {
        if (this.state.isMobile) {
          this.togglePanel('sidebar', false)
        }
      })
    }

    if (!loading && nextProps.loading) {
      root.clearTimeout(this.loadingTimeout)
      this.loadingTimeout = root.setTimeout(() => {
        this.setState({ showBigLoading: true })
      }, 3000)
    } else if (loading && !nextProps.loading) {
      this.setState({ showBigLoading: false })
      root.clearTimeout(this.loadingTimeout)
    }

    this.keepQueryUpdated(nextProps)
  }

  setWindowSize = (saveState = true) => {
    const matches =
      root.matchMedia && root.matchMedia('(max-width: 767px)').matches

    if (saveState) {
      this.setState({ isMobile: matches })
    }

    this.run('setMobileSize', matches)

    return matches
  }

  setupMapViaQuery = (query = this.props.query) => {
    // Query format: ?type=explore_map&to_lat=21.036501&to_long=-86.877098
    if (query.type === 'explore_map') {
      this.run('setActivePanel', {
        panel: 'myCommunity'
      })
      this.run('setParams', {
        searchFor: 'business'
      })
      if (!this.state.isMobile) {
        this.togglePanel('stayPalsManager', true)
      }
    } else if (query.type === 'stay_search') {
      this.run('setActivePanel', {
        panel: 'search'
      })
      this.run('setParams', {
        searchFor: 'business'
      })
    }

    if (
      query.to_lat &&
      query.to_long &&
      !isNaN(query.to_lat) &&
      !isNaN(query.to_long)
    ) {
      const zoom = !isNaN(query.zoom) ? Number(query.zoom) : 10

      const center = {
        lat: Number(query.to_lat),
        lng: Number(query.to_long)
      }

      if (query.item && query.user) {
        const item = {
          ...center,
          id: query.item
        }

        this.setActivePin(item, query.user, zoom, true)
      } else {
        this.run('setLocation', {
          location: {
            zoom,
            center
          },
          origin: 'query'
        })
      }
    }
  }

  keepQueryUpdated = nextProps => {
    if (
      this.props.activePanel !== nextProps.activePanel ||
      (this.props.location.center && this.props.location.center.lat) !==
        (nextProps.location.center && nextProps.location.center.lat) ||
      (this.props.location.center && this.props.location.center.lng) !==
        (nextProps.location.center && nextProps.location.center.lng) ||
      this.props.location.zoom !== nextProps.location.zoom ||
      this.props.activeItem !== nextProps.activeItem ||
      this.props.activeUserID !== nextProps.activeUserID
    ) {
      const {
        query,
        activePanel,
        location,
        activeItem,
        activeUserID
      } = nextProps

      let type

      if (activePanel === 'search') {
        type = 'stay_search'
      } else if (activePanel === 'myCommunity') {
        type = 'explore_map'
      }

      const pathname = get(root, 'location.pathname', routes.EXPLORE)

      push({
        pathname,
        query: {
          ...query,
          type,
          to_lat: (location.center && location.center.lat) || query.to_lat,
          to_long: (location.center && location.center.lng) || query.to_long,
          zoom: location.zoom,
          item: activeItem || undefined,
          user: activeUserID || undefined
        }
      })
    }
  }

  setActivePanelViaQuery = (query = this.props.query) => {
    let panel, searchFor

    if (TABS.find(({ routeKey }) => routeKey === query.activePanel)) {
      panel = 'myCommunity'
      searchFor = 'business'
      if (!this.state.isMobile) {
        this.togglePanel('stayPalsManager', true)
      }
    } else if (query.activePanel === 'businessStay') {
      panel = 'search'
      searchFor = 'business'
    } else if (query.activePanel === 'funStay') {
      panel = 'search'
      searchFor = 'fun'
    }

    if (panel && searchFor) {
      this.run('setActivePanel', { panel })
      this.run('setParams', { searchFor })
    }
  }

  toggleSidebarPanel (panel) {
    const { activePanel, query } = this.props
    const { isMobile } = this.state

    if (typeof panel !== 'undefined') {
      this.run('setActivePanel', { panel })

      if (
        !isMobile &&
        panel === 'myCommunity' &&
        !query.joyride &&
        !this.props.joyrideDotActive
      ) {
        this.togglePanel('stayPalsManager', true)
      }
    } else {
      if (activePanel === 'search') {
        this.run('setActivePanel', { panel: 'myCommunity' })
        if (!isMobile && !query.joyride && !this.props.joyrideDotActive) {
          this.togglePanel('stayPalsManager', true)
        }
      } else {
        this.run('setActivePanel', { panel: 'search' })
        // Trigger search
        this.run('triggerSearch', { form: 'search-inline' })
      }
    }
  }

  togglePanel = (panel, state, cb) => {
    const { panelOpenStates } = this.state

    const targetState = !isUndefined(state) ? state : !panelOpenStates[panel]

    this.setState(
      {
        panelOpenStates: {
          ...this.state.panelOpenStates,
          [panel]: targetState
        }
      },
      () => {
        cb && cb()
        if (panel === 'stayPalsManager' && targetState === false) {
          const { query } = this.props

          push({
            pathname: routes.EXPLORE,
            query: { ...query, activePanel: undefined }
          })
        }
      }
    )
  }

  togglePanels = (panelOpenStates = {}, cb) => {
    // panelOpenStates = {panelName: state}
    this.setState(
      {
        panelOpenStates: {
          ...this.state.panelOpenStates,
          ...panelOpenStates
        }
      },
      cb
    )
  }

  setActivePin = (item, userID, zoom, shouldZoomIn = false) => {
    const { location } = this.props

    zoom = typeof zoom !== 'undefined' ? zoom : 15

    if (location.zoom > zoom) {
      zoom = location.zoom
    }

    this.run('setActivePin', {
      itemID: item.id,
      userID,
      location: {
        ...location,
        zoom: shouldZoomIn ? zoom : location.zoom,
        center: {
          lat: item.lat || item.latitude || item.fakeLatitude,
          lng: item.lng || item.longitude || item.fakeLongitude
        }
      },
      subject: this.props.activePanel === 'search' ? 'stay' : 'sp'
    })
  }

  receiveMessage (msg) {
    this.run('fetchNotificationsUnreadCount')
  }

  isJoyrideActive () {
    const { isJoyrideCompleted, query, joyrideDotActive } = this.props
    return !isJoyrideCompleted && (query.joyride || joyrideDotActive)
  }

  render () {
    const { params } = this.props

    return (
      <SPMap
        setActivePin={this.setActivePin}
        setupMapInternals={({ map, maps }) => {
          this.map = map
          this.mapsApi = maps
        }}
        searchFor={params.searchFor}
        currentUser={this.currentUser}
        run={this.run}
        {...this.props}
      />
    )
  }
}

export default withStore(ExploreIndex)
