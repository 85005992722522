import React from 'react'

import { Link } from '@reach/router'

import get from 'lodash/get'

import styled from 'styled-components'

import colors from 'styles/colors'

import MatchQueryModal from 'components/MatchQueryModal'
import Icon from 'components/Icon'
import Dropdown from 'components/SimpleDropdown'
import FileInput from 'components/FileInput'
import ButtonPill from 'components/ButtonPill'

import { LinkButton, Caret, AddIcon } from '../Place'
import { CheckboxInput } from '../Place/components/PlaceDetails'
import { FullLoader } from '../Housemates'
import Button from '../../components/SmallButton'

import RoomsStoreProvider from './store'

import EditNameModal from './components/EditNameModal'
import SleepsCounter from './components/SleepsCounter'
import PicturesCarousel from './components/PicturesCarousel'
import Calendar from './components/Calendar'
import ConfirmAlert from 'components/ConfirmAlert'
import { confirmAlert } from 'react-confirm-alert' // Import

const MODALS = [{ query: 'edit_room_name', Component: EditNameModal }]

const roomAttributes = [
  { name: 'privateRoom', label: 'Private room' },
  { name: 'privateBathroom', label: 'Private bathroom' }
]

class Rooms extends React.Component {
  componentDidMount () {
    this.navigateRoomId()
  }

  componentDidUpdate (prevProps) {
    if (
      get(prevProps, 'place.rooms', []).length === 0 &&
      get(this.props, 'place.rooms', []).length > 0
    ) {
      this.navigateRoomId()
    }
  }

  componentWillUnmount () {
    const { reloadPlaces } = this.props
    reloadPlaces()
  }

  navigateRoomId () {
    const { place, location, navigate } = this.props

    if (get(place, 'rooms[0].id')) {
      const uri = get(location, 'pathname')
      const someRoomIsOpen = place.rooms.some(room => uri.includes(room.id))

      if (!someRoomIsOpen) {
        const room = place.rooms[0]
        navigate(room.id, { replace: true })
      }
    }
  }

  get room () {
    const { place, location } = this.props

    if (get(place, 'rooms[0]')) {
      const uri = get(location, 'pathname')

      const room = place.rooms.find(room => uri.includes(room.id))

      return room || place.rooms[0]
    }
  }

  render () {
    const { navigate, place, reloadPlaces } = this.props

    const rooms = get(place, 'rooms', [])

    if (!place) return <FullLoader />

    return (
      <RoomsStoreProvider room={this.room} reloadData={reloadPlaces}>
        {store => {
          const updating = get(store, 'state.updating')
          const loading = updating || store.matchesActions('showLoading')

          const room = get(store, 'state.data', this.room)

          const roomId = get(room, 'id')

          const pictures = get(room, 'images', [])

          const openModal = modal => () => {
            navigate(roomId + '?' + modal + '=true')
          }

          const updateData = (room, cb) => {
            store.transition({ type: 'UPDATE', room, callback: cb })
          }

          const createNewRoom = () => {
            const callback = item => {
              reloadPlaces(() => {
                if (item) {
                  navigate(item.id)
                } else {
                  navigate('../')
                }
              })
            }

            store.transition({
              type: 'CREATE_NEW',
              placeId: get(place, 'id'),
              listLength: get(rooms, 'length'),
              callback
            })
          }

          const incrementSleeps = () => {
            const sleeps = get(room, 'peopleSize')
            updateData({ peopleSize: Math.min(sleeps + 1, 9) })
          }

          const decrementSleeps = () => {
            const sleeps = get(room, 'peopleSize')
            updateData({ peopleSize: Math.max(sleeps - 1, 1) })
          }

          const uploadPicture = file => {
            store.transition({ type: 'UPLOAD_PHOTO', file })
          }

          const uploadProgress = get(store, 'state.uploadProgress')

          const deletePicture = picture => {
            store.transition({ type: 'DELETE_PHOTO', imageId: picture.id })
          }

          const updateUnavailableDates = (dates, callback) => {
            store.transition({ type: 'UPDATE_DATES', dates, callback })
          }

          const handleDeleteClick = () => {
            confirmAlert({
              customUI: ({ onClose }) => (
                <ConfirmAlert
                  onClose={onClose}
                  title={'Are you sure?'}
                  subTitle={
                    'Deleting this room will permanently remove it from your account and the StayCircles platform.'
                  }
                  onYes={deleteRoom}
                />
              )
            })
          }

          const deleteRoom = () => {
            const callback = errored => {
              if (!errored) {
                reloadPlaces(() => {
                  navigate('../rooms')
                })
              }
            }
            store.transition({ type: 'DELETE', callback })
          }

          if (!this.room && !loading) {
            return (
              <div className='pa4 flex flex-column justify-center items-center w-100'>
                <h3 className='f3 fw5 mb3 tc lh-copy'>
                  Setup a room to make <br /> your place available for booking
                </h3>
                <ButtonPill
                  onClick={createNewRoom}
                  background={colors.blue}
                  hoverBackground={colors.darkBlue}
                  color={colors.white}
                  border={colors.whiteRGBA(0.1)}
                >
                  Add room
                </ButtonPill>
              </div>
            )
          }

          return (
            <div className='pt3'>
              <h3
                className='f3 fw5 mb3'
                style={{ cursor: 'text' }}
                onClick={openModal('edit_room_name')}
              >
                {get(room, 'name', '')}{' '}
                <LinkButton
                  className='f6 ml1'
                  onClick={openModal('edit_room_name')}
                >
                  edit
                </LinkButton>
              </h3>

              <ChangeRoomDropdown
                rooms={rooms}
                room={room}
                createNewRoom={createNewRoom}
              />

              <div className='flex flex-row items-center mb5 flex-wrap flex-nowrap-ns'>
                <div className='black-70 mr4 flex flex-row items-center mb2 mb0-ns'>
                  <span>Sleeps:</span>
                  <SleepsCounter
                    value={get(room, 'peopleSize', 1)}
                    minValue={1}
                    maxValue={9}
                    decrement={decrementSleeps}
                    increment={incrementSleeps}
                  />
                </div>

                {roomAttributes.map(attr => (
                  <CheckboxInput
                    key={attr.name}
                    name={attr.name}
                    value={get(room, attr.name, false)}
                    onChange={e => {
                      updateData({ [attr.name]: e.target.checked })
                    }}
                    label={attr.label}
                    disabled={loading}
                    className='mr4 mb2 mb0-ns'
                  />
                ))}
              </div>

              <div className='mb5'>
                <div className='flex flex-row items-center mb3'>
                  <h4 className='fw5 black-90 mv0'>Pictures</h4>

                  {!!pictures[0] && (
                    <UploadPictureButton
                      onChange={uploadPicture}
                      className='f6 ml2'
                    >
                      + Upload
                    </UploadPictureButton>
                  )}

                  {uploadProgress && (
                    <span
                      className='ml3 lh-copy f5 tc b'
                      style={{ color: colors.blue }}
                    >
                      {uploadProgress < 99
                        ? `Uploading... ${uploadProgress}%`
                        : 'Processing...'}
                    </span>
                  )}
                </div>

                {!pictures[0] && (
                  <UploadPictureButton
                    onChange={uploadPicture}
                    className='f6 ml3'
                  >
                    + Upload picture
                  </UploadPictureButton>
                )}

                <div
                  className={`ml3 mr3 mr0-ns ${uploadProgress ? 'o-40' : ''}`}
                  style={{ maxWidth: 420 }}
                >
                  <PicturesCarousel
                    pictures={pictures}
                    deletePicture={deletePicture}
                  />
                </div>
              </div>

              <div className='mb4'>
                <h4 className='fw5 mb2 black-90'>Unavailable dates</h4>
                <p className='lh-copy pl3 black-70'>
                  Mark dates this room is unavailable for stays below and click
                  save.
                  <br />
                  Tip: Click and drag to select multiple dates.
                </p>

                <div className='pl3'>
                  <Calendar
                    onSubmit={updateUnavailableDates}
                    defaultMarkedDates={get(room, 'unavailableDates', [])}
                    loading={loading}
                  />
                </div>
              </div>

              <div className='bt b--black-10 pt4 mb5'>
                <LinkButton
                  className='f6'
                  // onClick={deleteRoom}
                  onClick={handleDeleteClick}
                  style={{ color: colors.blackRGBA(0.6) }}
                >
                  Delete this room
                </LinkButton>
              </div>

              <MatchQueryModal
                modalsList={MODALS}
                room={room}
                onSubmit={(data, cb) => {
                  updateData(data, reloadPlaces)
                  cb()
                }}
              />
            </div>
          )
        }}
      </RoomsStoreProvider>
    )
  }
}

const UploadPictureButton = ({ onChange, ...props }) => (
  <FileInput
    onChange={(e, value) => {
      const file = get(value, '[0][1]')
      onChange(file)
    }}
  >
    <Button blueBorder {...props} />
  </FileInput>
)

const ChangeRoomDropdown = ({ rooms, room, createNewRoom }) => (
  <Dropdown
    className='mb4'
    renderButton={onClicking => (
      <LinkButton
        className='tl flex flex-row items-center'
        onClick={onClicking}
      >
        Change / add room <Caret />
      </LinkButton>
    )}
    renderMenu={() => (
      <ul>
        {rooms.map(item => {
          const id = get(item, 'id')
          let selected = ''

          if (id === room.id) {
            item = room
            selected = 'o-60'
          }

          return (
            <li key={id}>
              <Link
                to={'./' + id}
                className={`flex flex-row items-center ${selected}`}
              >
                <BedIcon />
                <span>{get(item, 'name', '')}</span>
              </Link>
            </li>
          )
        })}

        <li>
          <Link
            to='../'
            onClick={e => {
              e.preventDefault()
              createNewRoom()
            }}
            className='flex flex-row items-center'
          >
            <AddIcon />
            <span>Add new room</span>
          </Link>
        </li>
      </ul>
    )}
  />
)

const BedIcon = styled(Icon).attrs({ name: 'better-bed' })`
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.75rem;
  opacity: 0.5;
`

export default Rooms
