import React, { Fragment } from 'react'

import { Match, Link } from '@reach/router'

import * as routes from 'config/routes'

import styled, { css } from 'styled-components'

import colors from 'styles/colors'
import zIndex from 'styles/zIndex'

import { headerHeight } from 'components/Header'
import Icon from 'components/Icon'
import VideoView from 'components/Landings/VideoView'
import MediaQuery from 'react-responsive'

export const LIST = [
  {
    key: 'explore',
    route: routes.EXPLORE,
    icon: 'explore',
    name: 'Explore Map and \n Friends',
    shortName: 'Explore \nFriends',
    bullets: [
      'See where friends and friends-of-friends live',
      'Send Friends Invitations',
      'Invite friends to StayCircles'
    ]
  },
  {
    key: 'bookingFriends',
    route: routes.BOOKING_FRIENDS,
    icon: 'people',
    name: 'Book a \nFriendship Stay',
    shortName: 'Book \nFriendship Stay',
    bullets: ['Book a stay with a friend or \nfriend-of-friend for free'],
    video: {
      name: 'Meet Laura',
      picture:
        'https://res.cloudinary.com/staycircles/image/upload/f_auto,q_auto/v1538641812/assets/image.png',
      url: 'https://www.youtube.com/embed/hWS7XmmDSAk?rel=0&autoplay=1'
    }
  },
  {
    key: 'hostingFriends',
    route: routes.HOSTING_FRIENDS,
    icon: 'better-bed',
    name: 'Host Friends \nTraveling for Pleasure',
    shortName: 'Host \nFriendship Stays',
    bullets: ['Host a friend or friend-of-friend for free']
  },
  {
    key: 'adventure',
    route: routes.ADVENTURE_SETTINGS,
    icon: 'am-mark-mono',
    name: 'Adventure Machine \nCheap Flights to Friends',
    shortName: 'Adventure \nMachine',
    bullets: [
      'Receive Adventure Machine cheap flight \ndeals to destinations worldwide',
      'See and or stay with friends or \nfriends-of-friends'
    ],
    video: {
      name: 'Meet Jones',
      picture:
        'https://res.cloudinary.com/staycircles/image/upload/f_auto,q_auto/v1538641812/assets/Capture.png',
      url: 'https://www.youtube.com/embed/vgPMR3-rsbQ?rel=0&autoplay=1'
    }
  }
]

const Sidebar = () => (
  <Match path={routes.APP}>
    {({ match }) => (
      <SidebarWrapper>
        <MediaQuery minWidth={992}>
          <SidebarFiller open={match} />
        </MediaQuery>

        <Container open={match}>
          {LIST.map(item => (
            <RowContainer
              key={item.key}
              to={item.route}
              open={match}
              getProps={({ isPartiallyCurrent }) => ({
                active: isPartiallyCurrent ? 1 : undefined
              })}
            >
              <i>
                <NavIcon name={item.icon} className={item.icon} />
              </i>

              {!match && <LinkShortName>{item.shortName}</LinkShortName>}

              {match && (
                <Fragment>
                  <MediaQuery minWidth={992}>
                    <LinkName>{item.name}</LinkName>

                    {item.bullets.length && (
                      <BulletList className='list'>
                        {item.bullets.map(bullet => (
                          <li key={bullet}>
                            <i>
                              <CheckmarkIcon />
                            </i>{' '}
                            <span>{bullet}</span>
                          </li>
                        ))}
                      </BulletList>
                    )}

                    {item.video && (
                      <VideoView videoUrl={item.video.url}>
                        {({ onClick }) => (
                          <WatchVideoButtonWrapper
                            onClick={e => {
                              e.preventDefault()
                              onClick()
                            }}
                          >
                            <img
                              src={item.video.picture}
                              title={item.video.name}
                              alt={item.video.name}
                            />

                            <WatchVideoButton>
                              <WatchVideoIcon />
                              {item.video.name}
                            </WatchVideoButton>
                          </WatchVideoButtonWrapper>
                        )}
                      </VideoView>
                    )}

                    {!item.video && <VideoSpaceFiller />}

                    <CaretIcon />
                  </MediaQuery>
                  <MediaQuery maxWidth={991}>
                    <LinkShortName>{item.shortName}</LinkShortName>
                  </MediaQuery>
                </Fragment>
              )}
            </RowContainer>
          ))}
        </Container>
      </SidebarWrapper>
    )}
  </Match>
)

const NavIcon = styled(Icon)`
  width: 3rem;
  height: 3rem;
  margin: 0 1rem;

  @media (max-width: 1024px) {
    width: 2rem;
    height: 2rem;
  }

  @media (min-width: 1025px) and (max-width: 1900px) {
    width: 2.5rem;
    height: 2.5rem;
  }

  &.am-mark-mono {
    width: 4.5rem;
    height: 4rem;
    margin: 0 0.25rem;

    @media (max-width: 1024px) {
      width: 3rem;
      height: 3rem;
      margin: 0 0.5rem;
    }

    @media (min-width: 1025px) and (max-width: 1900px) {
      width: 3.5rem;
      height: 3rem;
      margin: 0 0.5rem;
    }
  }
`

const LinkName = styled.span`
  line-height: 1.5;
  color: ${colors.blackRGBA(0.8)};
  white-space: pre;
  margin: 0 1rem;

  font-size: calc(14px + 6 * ((100vw - 320px) / 1580));
`

const LinkShortName = styled.span`
  line-height: 1.5;
  color: ${colors.blackRGBA(0.5)};
  text-align: center;
  white-space: pre;
  margin-top: 0.5rem;

  font-size: calc(12px + 4 * ((100vw - 320px) / 1580));

  // @media (max-width: 1024px) {
  //   display: none;
  // }
`

const BulletList = styled.ul`
  margin: 0 0 0 auto;
  width: 36%;

  li {
    display: flex;
    flex-direction: row;

    span {
      color: ${colors.blackRGBA(0.5)};
      font-size: calc(10px + 4 * ((100vw - 320px) / 1580));
      line-height: 1.5;
      white-space: pre;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  @media (max-width: 1024px) {
    display: none;
  }

  @media (min-width: 1025px) and (max-width: 1900px) {
    li {
      margin: 0 0 4px;
    }
  }
`

export const CheckmarkIcon = styled(Icon).attrs({ name: 'checkmark' })`
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
  color: ${colors.blue};
`

const WatchVideoButtonWrapper = styled.span`
  cursor: pointer;
  width: 15%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0.8;
  margin-right: 1rem;

  img {
    border: 1px solid ${colors.blue};
    width: 60px;
    height: auto;
    border-radius: 60px;
    margin-bottom: 0.5rem;
  }

  &,
  img,
  span {
    transition: all 0.25s ease;
  }

  &:hover {
    opacity: 1;

    span {
      background-color: ${colors.darkIce};
    }
  }

  @media (max-width: 1024px) {
    display: none;
  }

  @media (min-width: 1025px) and (max-width: 1900px) {
    img {
      width: 48px;
    }

    span {
      padding: 0.25rem 0.5rem;
      border-radius: 6px;
    }

    svg {
      width: 1.25rem;
      height: 1.25rem;
    }
  }
`

const WatchVideoButton = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 0.75rem;
  padding: 0.5rem 1rem;
  border-radius: 10px;
  background-color: ${colors.white};
  color: ${colors.blue};
  border: 1px solid ${colors.blueRGBA(0.5)};
  white-space: pre;
`

const WatchVideoIcon = styled(Icon).attrs({ name: 'yt-play' })`
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.5rem;
  color: ${colors.blue};
`

const VideoSpaceFiller = styled.div`
  width: 15%;
  margin-right: 1rem;
`

const CaretIcon = styled(Icon).attrs({ name: 'caret' })`
  width: 2rem;
  color: ${colors.blackRGBA(0.1)};
  transform: rotate(-90deg);

  @media (max-width: 1024px) {
    margin-left: auto;
  }
`

const RowContainer = styled(Link)`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${colors.white};
  padding: 0rem;
  text-decoration: none;
  box-shadow: 1px -1px rgba(0, 0, 0, 0.05), 2px 0px 4px rgba(0, 0, 0, 0.05);
  transition: all 0.25s ease;
  @media (min-width: 424px) {
    padding: 1rem;
  }

  ${NavIcon} {
    color: ${colors.blackRGBA(0.4)};
  }

  &[active] {
    background: ${colors.ice};
    border-left: 3px solid ${colors.darkBlue};
    cursor: default;
    border-bottom: 1px solid ${colors.blackRGBA(0.05)};
    box-shadow: none;

    ${NavIcon} {
      color: ${colors.blue};
    }

    span {
      color: ${colors.blackRGBA(0.9)};
    }
  }

  ${props =>
    props.open &&
    css`
      @media (min-width: 992px) {
        flex-direction: row;
        flex-direction: row;
        justify-content: flex-start;
      }

      box-shadow: none;

      ${NavIcon} {
        color: ${colors.blue};
      }
    `} &:hover {
    background-color: ${colors.ice};

    > span {
      color: ${colors.blackRGBA(0.9)};
    }

    ${BulletList} {
      svg {
        color: ${colors.darkerBlue};
      }

      span {
        color: ${colors.blackRGBA(0.7)};
      }
    }

    ${CaretIcon} {
      color: ${colors.blackRGBA(0.35)};
    }
  }
`

const Container = styled.div`
  z-index: ${zIndex.sidebar};
  position: fixed;
  bottom: 0;
  flex-direction: row;
  height: 100px;
  top: auto;
  width: 100%;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;

  @media (min-width: 992px) {
    top: ${headerHeight};
    left: 0;
    flex-direction: column;
    width: ${props => (props.open ? '44vw' : '10rem')};
    height: calc(100vh - ${headerHeight});
  }

  // @media (max-width: 1024px) {
  //   width: ${props => (props.open ? '100%' : '4rem')};
  // }

  @media (min-width: 1025px) {
    width: ${props => (props.open ? '55vw' : '10rem')};
  }

  @media (min-width: 1600px) {
    width: ${props => (props.open ? '44vw' : '10rem')};
  }
`

const SidebarFiller = styled(Container)`
  position: relative;
  z-index: -1;
  box-shadow: none;
  @media (max-width: 1024px) {
    display: none;
  }
`
const SidebarWrapper = styled.div``

export default Sidebar
