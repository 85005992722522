import api from 'utils/api'

export async function getHousemates (placeId) {
  return api.get(`/places/${placeId}/housemates`)
}

export async function getPendingHousemates (placeId) {
  const params = { status: 'pending' }
  return api.get(`/places/${placeId}/housemate_requests`, params)
}

export async function getPendingOwner (placeId) {
  const params = { status: 'pending' }
  return api.get(`/places/${placeId}/ownership_requests`, params)
}

export async function inviteHousemate ({ placeId, email }) {
  const data = { staypalEmail: email }
  return api.post(`/places/${placeId}/housemate_requests`, data)
}

export async function cancelHousemateInvite ({ placeId, housemateId }) {
  const data = { status: 'cancel', placeId }
  return api.put(`/housemate_requests/${housemateId}`, data)
}

export async function removeHousemate ({ placeId, housemateId }) {
  const data = { housemateId }
  return api.put(`/shared_places/${placeId}/remove_as_housemate`, data)
}

export async function inviteOwner ({ placeId, email }) {
  const data = { housemateEmail: email }
  return api.post(`/places/${placeId}/ownership_requests`, data)
}

export async function cancelOwnerInvite ({ placeId, housemateId }) {
  const data = { status: 'cancel', placeId }
  return api.put(`/ownership_requests/${housemateId}`, data)
}
