import React, { useState, useEffect } from 'react'
import { createContainer } from 'unstated-next'
import { getCurrentUser } from 'stores/auth'
import logger from 'utils/logger'
// import root from 'utils/windowOrGlobal'
import { unionBy } from 'lodash'
import config from 'config'
import {
  getPotentialAirports,
  getOriginAirports,
  delOriginAirport,
  postOriginAirports,
  getPlaces,
  addPlaceToOriginAirport
} from '../api'
import { removeLocalStorage } from 'utils/cache'
import InfoAlert from 'components/InfoAlert'
import { confirmAlert } from 'react-confirm-alert'

const useAirportsData = (initialValue = []) => {
  const [originAirports, setOriginAirports] = useState([])
  const [potentialAirports, setPotentialAirports] = useState(initialValue)
  const [loading, setLoading] = useState(false)
  const [showDone, setShowDone] = useState(false)
  const [page, setPage] = useState(1)
  const [hasMore, setHasMore] = useState(false)
  const [error, setError] = useState(null)
  const [places, setPlaces] = useState([])
  const [hasOriginAirportsChanged, setHasOriginAirportsChanged] = useState(
    false
  )
  const [isAddingOriginAirports, setIsAddingOriginAirports] = useState(null)

  useEffect(() => {
    fetchOriginAirports()
  }, [])

  useEffect(() => {
    if (error) {
      const errorStr = Array.isArray(error) ? error[0] : error

      // root.alert(
      //   `Uh oh! An error was encountered while trying to process.
      // (${errorStr})`
      // )
      // TODO: add this on screen instead of store.
      confirmAlert({
        customUI: ({ onClose }) => (
          <InfoAlert
            onClose={onClose}
            title={'Error'}
            subTitle={errorStr}
            // onYes={handleDeletePlace}
          />
        )
      })
    }
    setError(null)
  }, [error])

  const getCanAddAirports = airportsAdded => {
    const currentUser = getCurrentUser()
    const prime = !!currentUser.subscriptionClass
    if (prime) {
      return airportsAdded < config.AIRPORTS_PRIME
    }

    return airportsAdded < config.AIRPORTS_FREE
  }

  const resetOriginAirportsChange = () => {
    setHasOriginAirportsChanged(false)
  }

  const fetchOriginAirports = async () => {
    logger.captureBreadcrumb({
      message: 'AirportsContainer.fetchOriginAirports',
      category: 'airports'
    })

    setLoading(true)

    const [error, data] = await getOriginAirports()

    if (error) {
      logger.captureException(error)
      setError(error)
      setLoading(false)
      return
    }

    const originAirports = data.origins.map(_addDummyPlace)

    setOriginAirports(originAirports)
    setLoading(false)

    return originAirports
  }

  const _addDummyPlace = airport => ({
    ...airport,
    place: airport.place || {
      id: Math.random(),
      name: ''
    }
  })

  const loadPotentialAirports = async (
    lat,
    long,
    preview = false,
    initial = false
  ) => {
    logger.captureBreadcrumb({
      message: 'AirportsContainer.loadPotentialAirports',
      category: 'airports',
      data: { lat, long }
    })
    setLoading(initial)
    setShowDone(false)
    const [error, data] = await getPotentialAirports(
      lat,
      long,
      initial ? 1 : page
    )
    if (error) {
      setLoading(false)
      setError(error)
      console.log('[AirportStore loadPotentialAirports] Error: ', error)
      logger.captureException(error)
      return
    }
    const filterAirports = []
    const { totalAirports = 0 } = data
    data.airports = (data.airports || []).forEach(airport => {
      const {
        activeOdBusinessAndFirst,
        activeOdEconomy,
        id: airportId
      } = airport
      const isActive = activeOdBusinessAndFirst || activeOdEconomy
      if (isActive) {
        const originAirport = originAirports.find(
          ({ airport: { id } = {} }) => id === airportId
        )
        filterAirports.push({
          ...airport,
          selected: !!originAirport,
          originAirportID: originAirport ? originAirport.id : null
        })
      }
    })
    setPotentialAirports(
      initial ? [...filterAirports] : [...potentialAirports, ...filterAirports]
    )
    setLoading(false)
    setHasMore(totalAirports > potentialAirports.length)
    setPage(totalAirports > potentialAirports.length ? page + 1 : page)
  }

  const deleteOriginAirport = async id => {
    logger.captureBreadcrumb({
      message: 'AirportsContainer.deleteOriginAirport',
      category: 'airports',
      data: { id }
    })
    setLoading(true)
    const [error] = await delOriginAirport(id)

    if (error) {
      setLoading(false)
      setError(error)

      console.log('[AirportStore deleteOriginAirport] Error: ', error)
      logger.captureException(error)

      return
    }

    const originAirportsNew = originAirports.filter(
      airport => airport.id !== id
    )
    const potentialAirportsNew = potentialAirports.map(a => {
      if (a.originAirportID === id) {
        return {
          ...a,
          selected: false,
          originAirportID: null
        }
      }

      return a
    })
    setOriginAirports(originAirportsNew)
    setPotentialAirports(potentialAirportsNew)
    setLoading(false)
    setHasOriginAirportsChanged(true)
    removeLocalStorage('flightDeals')
    removeLocalStorage('destinationWithFriends')
    return originAirportsNew
  }

  // const addOriginAirports = async airportIds => {
  //   logger.captureBreadcrumb({
  //     message: 'AirportsContainer.addOriginAirport',
  //     category: 'airports',
  //     data: { airportIds }
  //   })
  //   setLoading(true)

  //   const [error, data] = await postOriginAirports(airportIds)

  //   if (error) {
  //     setLoading(false)
  //     setError(error)

  //     console.log('[AirportStore addOriginAirport] Error: ', error)
  //     logger.captureException(error)

  //     return
  //   }

  //   const airport = _addDummyPlace(data.origin)
  //   const originAirportsNew = originAirports.concat([airport])
  //   const potentialAirportsNew = potentialAirports.map(a => {
  //     if (airportIds.includes(a.id)) {
  //       return {
  //         ...a,
  //         selected: true,
  //         originAirportID: data.origin.id
  //       }
  //     }

  //     return a
  //   })
  //   setLoading(false)
  //   setShowDone(true)
  //   setOriginAirports(originAirportsNew)
  //   setPotentialAirports(potentialAirportsNew)
  //   setHasOriginAirportsChanged(true)
  //   removeLocalStorage('flightDeals')
  //   removeLocalStorage('destinationWithFriends')
  //   return originAirportsNew
  // }

  const addOriginAirports = async airports => {
    logger.captureBreadcrumb({
      message: 'AirportsContainer.addOriginAirport',
      category: 'airports',
      data: { airports }
    })
    const airportIds = airports.map(airport => {
      return airport.id
    })
    setLoading(true)
    let originData = []
    let apiError = null
    // some issue with backend, sending one by one as requested by backend
    for (const [index, airport] of airports.entries()) {
      setIsAddingOriginAirports({
        total: airports.length,
        currentAirport: airport,
        currentPosition: index + 1
      })
      const [error, data] = await postOriginAirports([airport.id])
      if (error) {
        apiError = error
        break
      }
      originData.push(data.origin[0])
    }

    if (apiError) {
      setLoading(false)
      setError(error)

      console.log('[AirportStore addOriginAirport] Error: ', error)
      logger.captureException(error)

      // return
    }

    const airport = originData.map(_addDummyPlace)
    const originAirportsNew = [...originAirports, ...airport]
    const potentialAirportsNew = potentialAirports.map(a => {
      if (airportIds.includes(a.id)) {
        return {
          ...a,
          selected: true,
          originAirportID: originData.id
        }
      }

      return a
    })
    setLoading(false)
    setShowDone(true)
    setOriginAirports(originAirportsNew)
    setPotentialAirports(potentialAirportsNew)
    setHasOriginAirportsChanged(true)
    setIsAddingOriginAirports(null)
    removeLocalStorage('flightDeals')
    removeLocalStorage('destinationWithFriends')
    return originAirportsNew
  }

  const getAllPlaces = async includeOnly => {
    logger.captureBreadcrumb({
      message: 'PlacesContainer.getAllPlaces',
      category: 'airports',
      data: { includeOnly }
    })
    setLoading(true)

    const [error, data] = await getPlaces(includeOnly)

    if (error) {
      console.log('[PlacesStore getAllPlaces] Error: ', error)
      logger.captureException(error)
      setLoading(true)
      setError(error)
      return
    }
    setPlaces(data.places)
    setLoading(false)

    return data.places
  }

  const updateAirportAssociation = async (id, placeID) => {
    logger.captureBreadcrumb({
      message: 'AirportsContainer.updateAirportAssociation',
      category: 'airports',
      data: { id, placeID }
    })
    setLoading(true)

    const [error, data] = await addPlaceToOriginAirport(id, placeID)

    if (error) {
      setLoading(false)
      setError(error)

      console.log('[AirportStore updateAirportAssociation] Error: ', error)
      logger.captureException(error)

      return
    }

    const originAirportsNew = unionBy([data.origin], originAirports, 'id')
    setOriginAirports(originAirportsNew)
    setLoading(false)
  }

  return {
    loadPotentialAirports,
    fetchOriginAirports,
    deleteOriginAirport,
    addOriginAirports,
    getAllPlaces,
    updateAirportAssociation,
    getCanAddAirports,
    potentialAirports,
    originAirports,
    loading,
    hasMore,
    page,
    error,
    setError,
    showDone,
    places,
    prime: !!getCurrentUser().subscriptionClass,
    canAddAirports: getCanAddAirports(originAirports.length),
    hasOriginAirportsChanged,
    isAddingOriginAirports,
    resetOriginAirportsChange
  }
}

const AirportData = createContainer(useAirportsData)

const withAirportsState = WrappedComponent => () => {
  return (
    <AirportData.Provider>
      <WrappedComponent />
    </AirportData.Provider>
  )
}

export { AirportData }
export default withAirportsState
