import React from 'react'

import styled from 'styled-components'
import { get } from 'lodash'

import colors from 'styles/colors'

import { validateEmail } from 'utils/field-validators'
import Field from './Field'

const Wrapper = styled.div`
  position: relative;

  padding-bottom: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;

  @media (min-width: 992px) {
    padding-bottom: 32px;
    margin-top: 16px;
    margin-bottom: 32px;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 10%;
    right: 10%;

    width: 80%;
    height: 1px;
    background-color: ${colors.silver};
  }
`

const Label = styled.p`
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 12px;
`

class EmailInviteField extends React.PureComponent {
  state = {
    emails: '',
    disabled: true,
    error: ''
  }

  setEmails = e => {
    const emails = get(e, 'target.value', '')

    this.setState({ emails })

    const emailArray = emails.split(',').map(s => s.trim())
    const hasError = emailArray.map(validateEmail).findIndex(e => e)

    if (hasError > -1 && !!emailArray[hasError]) {
      this.setState({
        error: `${emailArray[hasError]} doesn't look like a valid email.`,
        disabled: true
      })
    } else {
      this.setState({
        disabled: false,
        error: ''
      })
    }
  }

  handleSubmit = e => {
    e.preventDefault()

    const emails = this.state.emails
      .split(',')
      .map(s => s.trim())
      .filter(s => !!s)

    if (emails.length < 1) {
      return
    }

    this.setState({ emails: '' })

    this.props.sendInvite(emails)
  }

  render () {
    const { emails, disabled, error } = this.state
    const { loading } = this.props

    return (
      <form onSubmit={this.handleSubmit} className='mb1 mb3-l'>
        <Wrapper>
          <Label>Invite by email</Label>
          <Field.Wrapper>
            <Field.Input small value={emails} onChange={this.setEmails} />

            <Field.Button disabled={disabled || loading} />
            {!!error && <p className='mb0 mt2 f6 dark-red'>{error}</p>}
          </Field.Wrapper>
        </Wrapper>
      </form>
    )
  }
}

export default EmailInviteField
