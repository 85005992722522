import React, { Fragment } from 'react'
import get from 'lodash/get'

import { required } from 'utils/field-validators'

import Input from 'components/Input'
import PromptModal from '../../../components/PromptModal'

const EditNameModal = ({ room, onSubmit }) => {
  const name = get(room, 'name')

  return (
    <PromptModal
      title='Edit Room Name'
      getInitialValues={() => ({ name })}
      onSubmit={onSubmit}
    >
      {({ submitting }) => (
        <Fragment>
          <Input
            id='name'
            name='name'
            label={name ? 'Room name' : 'Give this room a name'}
            validate={required}
            disabled={submitting}
          />
        </Fragment>
      )}
    </PromptModal>
  )
}

export default EditNameModal
