import { withStateMachine, StateMachineContainer } from 'utils/StateMachine'
import logger from 'utils/logger'
import root from 'utils/windowOrGlobal'

import config from 'config'
import { getReadableError } from 'utils/api'
import * as api from './api'

const ALL_ACTIONS = {
  LOAD: { loading: { actions: ['getGmailContacts'] } },
  SET_TERM: { idle: { actions: ['setTerm'] } },
  TOGGLE_MODAL: { idle: { actions: ['toggleModal'] } },
  RESET_ERROR: { idle: { actions: ['resetError'] } },
  SEND_SP_REQUEST: { loading: { actions: ['sendSPRequest'] } },
  INIT_CONTACTS_MODAL: { loading: { actions: ['initContactsModal'] } }
}

const statechart = {
  initial: 'idle',
  states: {
    idle: {
      on: {
        ...ALL_ACTIONS
      },
      onEntry: 'hideLoading'
    },
    loading: {
      onEntry: 'showLoading',
      on: {
        SUCCESS: 'idle',
        FAILURE: 'idle',
        ...ALL_ACTIONS
      }
    }
  }
}

class GmailContactsContainer extends StateMachineContainer {
  constructor (props) {
    super(props)

    this.state = {
      ...this.state,
      term: '',
      contacts: [],
      showContactsModal: false,
      inProgress: []
    }
  }

  request = async (apiName, data, event = {}) => {
    logger.captureBreadcrumb({
      message: 'GmailContactsContainer.' + apiName,
      category: 'stayPals',
      data
    })

    try {
      const [error, response] = await api[apiName](data)

      if (error) {
        throw new Error(error)
      } else {
        return response
      }
    } catch (error) {
      logger.captureException(error)

      let err = getReadableError(error)

      this.transition({
        ...event,
        type: 'FAILURE',
        error: {
          [apiName]: err
        }
      })
      return false
    }
  }

  initContactsModal = () => {
    this.setState({
      showContactsModal: true,
      contacts: [],
      term: '',
      inProgress: []
    })
  }

  getGmailContacts = async () => {
    const { gapi } = root

    const googleConfig = {
      client_id: config.GOOGLE_CLIENT_ID,
      scope: 'https://www.googleapis.com/auth/contacts.readonly',
      prompt: 'select_account',
      authuser: -1
    }

    gapi.auth2.authorize(googleConfig, async result => {
      if (result && !result.error) {
        this.transition({
          type: 'INIT_CONTACTS_MODAL'
        })

        const response = await this.request('getGmailContacts', {
          source: 'gmail',
          accessToken: result.access_token
        })

        if (response) {
          this.setState({
            contacts: response.contacts
          })
        }
      } else if (
        result.error === 'idpiframe_initialization_failed' &&
        result.details &&
        (result.details.toLowerCase().includes('cookies') ||
          result.details.toLowerCase().includes('localstorage'))
      ) {
        let reason = result.details.toLowerCase().includes('cookies')
          ? 'cookies'
          : 'third-party'
        // Show error to user if cookies or third party data is blocked from the browser
        this.transition({
          type: 'FAILURE',
          error: {
            gmailAuth: reason
          }
        })
      }
    })
  }

  setTerm = ({ term }) => {
    this.setState({ term })
  }

  toggleModal = ({ showContactsModal }) => {
    this.setState({ showContactsModal })
  }

  resetError = () => {
    this.transition({
      error: {}
    })
  }

  addProgress = id => {
    this.setState({
      inProgress: this.state.inProgress.concat([id])
    })
  }

  removeProgress = id => {
    this.setState({
      inProgress: this.state.inProgress.filter(i => i !== id)
    })
  }

  sendSPRequest = async ({ email, cb }) => {
    this.addProgress(email)

    const ok = await this.request('sendSPRequest', email)

    this.removeProgress(email)

    if (ok) {
      const contacts = this.state.contacts.map(c => {
        if (c.email !== email) return c

        return {
          ...c,
          stayPalRequestStatus: 'pending'
        }
      })

      this.setState({
        contacts
      })

      this.transition({
        type: 'SUCCESS'
      })

      if (typeof cb === 'function') {
        cb()
      }

      return true
    }
  }
}

const withStore = withStateMachine(
  null,
  new GmailContactsContainer({ statechart })
)

export default withStore
