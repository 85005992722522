import React from 'react'

import Modal from 'components/Modal'

import styled from 'styled-components'

import colors from 'styles/colors'

const Text = styled.p`
  text-align: center;
  font-size: 16px;
`

class GoogleAuthErrorModal extends React.Component {
  render () {
    const { onClose, reason } = this.props
    const toEnable = reason === 'cookies' ? 'cookies' : '"third-party cookies"'

    return (
      <Modal
        isOpen={!!reason}
        onRequestClose={onClose}
        contentLabel='GoogleAuthErrorModal'
      >
        <div className='center flex flex-column items-center w-100 w-90-ns mb0'>
          <h3 className='mt0 mb3'>Google Authentication Failed</h3>

          <div className='mv4'>
            <Text>
              Your privacy settings are blocking us from connecting to your
              Google account.
              <br />
              <br />
              You might have blocked cookies in your browser or your browser
              might have it disabled by default.
              <br />
              <br />
              For StayCircles to work properly you need to enable {toEnable} in
              your browser.
            </Text>
          </div>

          <button
            type='button'
            onClick={onClose}
            children='OK'
            style={{ backgroundColor: colors.blue }}
            className='ba b--white-10 white f5 pv2 ph4 mb0 br2 pointer'
          />
        </div>
      </Modal>
    )
  }
}

export default GoogleAuthErrorModal
