import React from 'react'

import styled from 'styled-components'

import { debounce, get } from 'lodash'

import { TextInput } from 'components/Input'
import InfiniteList from 'components/InfiniteList'

import CannotDeleteModal from './components/CannotDeleteModal'
import ConfirmDeleteModal from './components/ConfirmDeleteModal'
import DeleteSuccesModal from './components/DeleteSuccesModal'

import { getFullName, getUserPicture } from 'utils/profile-info'
import { RenderOnAction } from 'utils/StateMachine'

import colors from 'styles/colors'

import withMaintenanceStore, {
  defaultStayPals,
  defaultDestroyModal
} from './store'

const ITEM_HEIGHT = 60

class Maintenance extends React.Component {
  shouldComponentUpdate (nextProps) {
    if (
      nextProps !== this.props ||
      nextProps.machineStore.state !== this.props.machineStore.state
    ) {
      return true
    }

    return false
  }

  get error () {
    return get(this.props.machineStore, 'state.event.error', {})
  }

  get stayPals () {
    return get(this.props.machineStore, 'state.event.stayPals', defaultStayPals)
  }

  get destroyModal () {
    return get(
      this.props.machineStore,
      'state.event.destroyModal',
      defaultDestroyModal
    )
  }

  unStayPal = ({ id }) => {
    this.props.machineStore.transition({
      type: 'UN_STAYPAL',
      stayPals: this.stayPals,
      stayPalID: id,
      destroyModal: this.destroyModal
    })
  }

  loadMore = clearOld => {
    const { stayPals } = this

    this.props.machineStore.transition({
      type: 'LOAD',
      clearOld,
      stayPals: {
        ...stayPals,
        page: clearOld ? 1 : stayPals.page + 1
      },
      destroyModal: this.destroyModal
    })
  }

  delayedLoad = debounce(() => this.loadMore(true), 500, {
    trailing: true,
    leading: false
  })

  handleChange = e => {
    const term = e.target.value

    this.props.machineStore.transition({
      stayPals: {
        ...this.stayPals,
        term
      },
      destroyModal: this.destroyModal
    })

    this.delayedLoad()
  }

  handleModalClose = () => {
    this.props.machineStore.transition({
      destroyModal: {
        ...this.destroyModal,
        show: false
      },
      stayPals: this.stayPals
    })
  }

  destroyLookup = () => {
    this.props.machineStore.transition({
      type: 'DESTROY_LOOKUP',
      destroyModal: this.destroyModal,
      stayPals: this.stayPals
    })
  }

  handleDestroy = password => {
    this.props.machineStore.transition({
      type: 'DESTROY_ACCOUNT',
      password,
      destroyModal: this.destroyModal,
      stayPals: this.stayPals
    })
  }

  renderItem = ({ item }) => {
    const unStayPal = () => this.unStayPal(item)
    const name = getFullName(item)
    const picture = getUserPicture({ ...item, friend: true })
    let address = ''

    if (item.primaryPlace) {
      const { city, country } = item.primaryPlace
      address = `${city}, ${country}`
    }

    return (
      <div className='pv2 pr3 flex flex-row items-center' key={item.id}>
        <div className='flex flex-row items-center pr4' style={{ flex: 1 }}>
          <Avatar src={picture} alt={`${name}'s picture`} />

          <div className='ph2'>
            <p className='mb0'>{name}</p>
            {!!address && <p className='mt1 mb0 f7 dn di-ns'>{address}</p>}
          </div>
        </div>

        <button
          type='button'
          onClick={unStayPal}
          children='UNFRIEND'
          className='pointer bg-transparent ba b--black-10 black-50 f7 pa2 mb0 br2 bg-animate hover-bg-near-white'
        />
      </div>
    )
  }

  renderEmptyComponent = () => <p>No Friends found!</p>

  render () {
    const { stayPals, destroyModal, error } = this

    return (
      <RenderOnAction value='showLoading'>
        {loading => (
          <div className='flex flex-column items-center justify-center center w-100 mb4'>
            <h1>Manage Friends</h1>

            <div className='mv3 w-100'>
              <p className='black-60 mb3 tc tl-ns pl3'>Remove Friends</p>

              <div
                className='br3 overflow-hidden b--black-10 ba bg-white-80'
                style={{
                  cursor: loading ? 'progress' : 'default'
                }}
              >
                <div className='pa3'>
                  <TextInput
                    small
                    className='w-100'
                    placeholder='Search by name...'
                    onChange={this.handleChange}
                  />
                </div>

                <div className='ph3'>
                  <InfiniteList
                    height={400}
                    width='100%'
                    itemHeight={ITEM_HEIGHT}
                    renderItem={this.renderItem}
                    data={stayPals.list}
                    loadMore={() => this.loadMore()}
                    loading={loading}
                    hasMore={stayPals.hasMore}
                    renderEmptyComponent={this.renderEmptyComponent}
                    extraData={stayPals.page}
                  />
                </div>
              </div>
            </div>

            {/* <div className='mv3 w-100'>
              <button
                type='button'
                className='w-100 tc tl-ns black-60 bg-white br3 b--black-10 ba bg-white-80 pa3 mb0 pointer bg-animate hover-bg-near-white'
                onClick={this.destroyLookup}
              >
                Permanently delete account
              </button>
            </div> */}

            <CannotDeleteModal
              show={destroyModal.show && !!destroyModal.reason}
              close={this.handleModalClose}
              reason={destroyModal.reason}
            />

            <ConfirmDeleteModal
              show={destroyModal.show && !destroyModal.reason}
              close={this.handleModalClose}
              destroy={this.handleDestroy}
              error={error.destroyAccount}
              loading={loading}
            />

            <DeleteSuccesModal show={destroyModal.destroyed} />
          </div>
        )}
      </RenderOnAction>
    )
  }
}

const Avatar = styled.img`
  width: 44px;
  height: 44px;
  border-radius: 50%;
  border: 2px solid ${colors.redRGBA(0.5)};
`

export default withMaintenanceStore(Maintenance)
