import React from 'react'
import PropTypes from 'prop-types'

import get from 'lodash/get'

import { navigate } from 'gatsby'

import { PROFILE } from 'config/routes'
import navigateBack from 'utils/navigateBack'
import * as imageUtils from 'utils/upload-image'
import { getFullName, getUserPicture } from 'utils/profile-info'
import isMobileDevice from 'utils/isMobileDevice'

import styled from 'styled-components'

import colors from 'styles/colors'

import Cropper from 'tinycrop'

import FileInput from 'components/FileInput'
import Modal from 'components/Modal'
import ButtonPill from 'components/ButtonPill'

import ProfileStoreProvider from '../store'

const MAX_SIZE = 300

const LargeModal = styled(Modal)`
  max-width: 720px;
`

const PictureContainer = styled.div`
  width: ${props => props.size || '8rem'};
  height: ${props => props.size || '8rem'};
  position: relative;
  display: inline-block;
  border: 3px solid ${colors.ice};

  @media (max-width: 768px) {
    max-width: 100%;
    height: auto;
    border-radius: 100%;
    overflow: hidden;
  }
`

const UserPictureImg = styled.img`
  width: 100%;
`

const PictureOverlay = styled.span.attrs({
  className:
    'w-100 h-100 absolute absolute--fill flex justify-center items-center'
})``

export const UserPicture = () => (
  <ProfileStoreProvider>
    {({ store, user }) => {
      const name = getFullName(user)

      const size = 8 * 16 // 8rem
      const picture = getUserPicture(user, {
        width: size,
        height: size,
        crop: 'fill'
      })

      return (
        <FileInput
          className='link hide-child mb4 dib pointer'
          onChange={(e, value) => {
            const file = get(value, '[0][1]')
            store.setPictureFile(file)
            navigate(`${PROFILE}/edit_picture`)
          }}
        >
          <PictureContainer
            className='br-100'
            style={{ borderColor: colors.blackRGBA(0.1) }}
          >
            <UserPictureImg src={picture} alt={`${name}'s picture`} />

            <PictureOverlay className='child bg-black-50 white br-100'>
              Update
            </PictureOverlay>
          </PictureContainer>
        </FileInput>
      )
    }}
  </ProfileStoreProvider>
)

export class EditUserPictureModal extends React.PureComponent {
  state = { croppedImage: null }

  onCropEnd = async (file, region) => {
    if (region) {
      file = await imageUtils.cropImage(file, region)
    }

    this.setState({ croppedImage: file })
  }

  render () {
    return (
      <ProfileStoreProvider>
        {({ store, user, reload }) => {
          const { pictureFile: uploadedFile, uploadProgress } = store.state

          const loading = store.matchesActions('showUploadProgress')

          return (
            <LargeModal
              isOpen
              onRequestClose={navigateBack}
              contentLabel='Edit profile picture'
            >
              <div className='center flex flex-column items-center w-100 w-90-ns mt4'>
                <h3 className='f3 bb b--black-05 mb4 pb4 w-100 tc'>
                  <span className='dn di-ns'>Adjust your photo</span>
                  <span className='di dn-ns'>Upload Profile Picture</span>
                </h3>

                <div className='w-100 flex flex-row justify-between items-center'>
                  <div
                    style={{ flex: 1 }}
                    className='flex flex-column justify-center items-center'
                  >
                    {uploadedFile && (
                      <PictureContainer size={MAX_SIZE + 'px'}>
                        <CreateBlobURL file={uploadedFile}>
                          {(url, adjustedFile) =>
                            url && (
                              <PicturePreview
                                url={url}
                                file={adjustedFile || uploadedFile}
                                onCropEnd={this.onCropEnd}
                              />
                            )
                          }
                        </CreateBlobURL>
                      </PictureContainer>
                    )}

                    <FileInput
                      className='w-100 flex flex-row justify-center mt3'
                      onChange={(e, value) => {
                        const file = get(value, '[0][1]')
                        store.setPictureFile(null)
                        store.setPictureFile(file)
                      }}
                    >
                      <ButtonPill color={colors.blue}>
                        {!uploadedFile
                          ? 'Select file'
                          : 'Choose different photo'}
                      </ButtonPill>
                    </FileInput>
                  </div>

                  {uploadedFile && (
                    <div
                      style={{ flex: 1 }}
                      className='dn flex-ns flex-column justify-center items-center ph5 tc ml3'
                    >
                      <PictureContainer
                        size='64px'
                        className='br-100 overflow-hidden'
                        style={{ borderColor: colors.red }}
                      >
                        <CreateBlobURL
                          file={this.state.croppedImage || uploadedFile}
                        >
                          {url =>
                            url && (
                              <UserPictureImg
                                src={url}
                                alt='New picture preview'
                              />
                            )
                          }
                        </CreateBlobURL>
                      </PictureContainer>

                      <span className='lh-copy f6 mt3 black-70'>
                        Preview how your pin will look like on the map
                      </span>
                    </div>
                  )}
                </div>

                <div className='w-100 flex flex-row justify-between items-center bt b--black-05 mt3 pt4'>
                  <ButtonPill onClick={navigateBack}>Cancel</ButtonPill>

                  {loading && (
                    <span
                      className='lh-copy f5 tc b'
                      style={{ color: colors.blue }}
                    >
                      Uploading...
                      {uploadProgress && uploadProgress < 99
                        ? uploadProgress + '%'
                        : ''}
                    </span>
                  )}

                  <ButtonPill
                    background={colors.blue}
                    hoverBackground={colors.darkBlue}
                    color={colors.white}
                    border={colors.whiteRGBA(0.1)}
                    disabled={!uploadedFile || loading}
                    onClick={() => {
                      store.transition({
                        type: 'UPLOAD_PHOTO',
                        user,
                        file: this.state.croppedImage || uploadedFile,
                        callback: () => {
                          navigateBack()
                          reload()
                        }
                      })
                    }}
                  >
                    Upload
                  </ButtonPill>
                </div>
              </div>
            </LargeModal>
          )
        }}
      </ProfileStoreProvider>
    )
  }
}

class PicturePreview extends React.PureComponent {
  static propTypes = {
    url: PropTypes.string.isRequired
  }

  componentDidMount () {
    this.createCropCanvas()
  }

  componentDidUpdate () {
    this.createCropCanvas()
  }

  createCropCanvas () {
    if (this.cropCanvas && !this.crop) {
      const { url } = this.props

      this.crop = Cropper.create({
        parent: this.cropCanvas,
        image: url,
        bounds: {
          width: '50%',
          height: '100%'
        },
        selection: {
          aspectRatio: 1,
          color: colors.blue,
          activeColor: colors.red
        }
      })

      this.crop
        .on('change', region => {
          this.props.onCropEnd(this.props.file, region)
        })
        .on('end', region => {
          this.props.onCropEnd(this.props.file, region)
        })
    } else {
      this.props.onCropEnd(this.props.file)
    }
  }

  componentWillUnmount () {
    this.crop && this.crop.dispose()
  }

  render () {
    // Don't render cropper on mobile (not compatible)
    if (isMobileDevice()) {
      return <UserPictureImg src={this.props.url} alt='Picture preview' />
    }

    return (
      <div
        ref={node => {
          this.cropCanvas = node
        }}
        className='image-crop'
      />
    )
  }
}

class CreateBlobURL extends React.Component {
  state = { url: '', file: null }

  componentDidMount () {
    this.getURL()
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.file !== this.props.file) {
      this.clearURL && this.clearURL()
      this.getURL()
    }
  }

  componentWillUnmount () {
    this.clearURL && this.clearURL()
  }

  getURL = async () => {
    // `file` is result of components/FileInput
    let file = this.props.file

    if (!file) return

    file = await imageUtils.resizeImage(file, MAX_SIZE)

    // We won't let users crop images when on mobile,
    // so we crop it to the default size just to make it square
    if (isMobileDevice()) {
      const region = {
        left: 0,
        top: 0,
        width: MAX_SIZE,
        height: MAX_SIZE
      }
      file = await imageUtils.cropImage(file, region)
    }

    const blobURL = imageUtils.createBlobURL(file)

    if (get(blobURL, 'url')) {
      this.clearURL = blobURL.clearURL
      this.setState({ url: blobURL.url, file: file })
    }
  }

  render () {
    const { url, file } = this.state
    return this.props.children(url, file)
  }
}
