import React from 'react'
import PropTypes from 'prop-types'

import get from 'lodash/get'
import pluralize from 'pluralize'

import styled from 'styled-components'

import colors from 'styles/colors'

import camelize from 'utils/camelize'
import { cloudinary } from 'utils/profile-info'

import Icon from 'components/Icon'
import { TouchableOpacity } from 'components/Native'

const propTypes = {
  name: PropTypes.string,
  peopleSize: PropTypes.number,
  privateRoom: PropTypes.bool,
  privateBathroom: PropTypes.bool,
  onPress: PropTypes.func,
  selected: PropTypes.bool,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      url: PropTypes.string
    })
  )
}

const defaultProps = {
  onPress: () => {},
  selected: false,
  images: []
}

const getPictureUrl = picture => {
  let url

  if (get(picture, 'url') && !get(picture, 'cloudinaryPublicId')) {
    url = picture.url
  }

  const publicId = get(picture, 'cloudinaryPublicId')
  if (publicId) {
    url = cloudinary(publicId, {
      width: 150,
      height: 150,
      crop: 'fill'
    })
  }

  return url
}

const RoomItem = props => {
  props = camelize(props)

  // First is actually the last because it
  // comes in descending order from the backend
  const firstImage = props.images && props.images[props.images.length - 1]
  const imageUrl = firstImage && getPictureUrl(firstImage)
  const hasImages = !!imageUrl

  return (
    <Container onPress={props.onPress} selected={props.selected}>
      <Info>
        <Name selected={props.selected}>{props.name}</Name>
        <Text selected={props.selected}>
          {props.peopleSize} {pluralize('Sleep', props.peopleSize)}
        </Text>
        <Text selected={props.selected}>
          {props.privateRoom ? 'Private' : 'Shared'} room
        </Text>
        <Text selected={props.selected}>
          {props.privateBathroom ? 'Private' : 'Shared'} bathroom
        </Text>
      </Info>

      {hasImages && <Picture src={imageUrl} />}

      {!hasImages && (
        <NoPictureContainer>
          <Icon
            name='better-bed'
            style={{ width: 80, height: 80 }}
            color={colors.blackRGBA(0.3)}
          />
        </NoPictureContainer>
      )}
    </Container>
  )
}

RoomItem.propTypes = propTypes
RoomItem.defaultProps = defaultProps

const Container = styled(TouchableOpacity).attrs({ activeOpacity: 0.8 })`
  background-color: ${props => (props.selected ? colors.blue : colors.white)};
  border-radius: 6px;
  height: 150px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid
    ${props => (props.selected ? colors.blue : colors.blackRGBA(0.05))};
  overflow: hidden;
  margin-bottom: 16px;
  box-shadow: 0 2px 6px ${colors.blackRGBA(0.2)};
`

const Info = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
`

const Name = styled.p`
  font-size: 18px;
  color: ${props => (props.selected ? colors.white : colors.blackRGBA(0.8))};
  margin-bottom: 10px;
`

const Text = styled.p`
  font-size: 14px;
  color: ${props => (props.selected ? colors.white : colors.blackRGBA(0.6))};
  margin-bottom: 4px;
`

const Picture = styled.img`
  width: 150px;
  height: 150px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
`

const NoPictureContainer = styled.div`
  width: 150px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.offwhite};
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
`

export default RoomItem
