import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const Text = styled.p`
  font-weight: 700;
  font-size: 1.5rem;
`

export const DivineText = styled.p`
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
  font-size: 0.9rem;
  font-weight: 700;
  color: #1a365e;
`

export const NavigateButton = styled.div`
  display: inline-block;
  color: ${({ color }) => color || '#2d89f8'};
  background: ${({ background }) => background || '#e1eafd'};
  border: 1px solid #bad0ff;
  box-sizing: border-box;
  border-radius: ${({ flat }) => (flat ? '4px' : '22px')};
  padding: 8px 30px;
  ${({ mgTop }) => (mgTop ? `margin-top: ${mgTop}px` : '')};
  ${({ margin }) => (margin ? `margin: ${margin}` : '')};
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`
export const Image = styled.img``
