import isEqual from 'lodash/isEqual'
import uniqWith from 'lodash/uniqWith'
import isPlainObject from 'lodash/isPlainObject'

import { fitBounds } from 'google-map-react/utils'
import root from 'utils/windowOrGlobal'

import { geocodeByAddress, geocodeByPlaceId } from 'react-places-autocomplete'

import countries from './allCountries'
import { getPlaceAddress } from './profile-info'
import parseAddress from './parse-address'
import camelize from './camelize'

export const allCountries = countries

export const geocodeAndParseAddress = address => {
  if (isPlainObject(address)) {
    address = getPlaceAddress(address, false).fullAddress
  }

  return new Promise((resolve, reject) => {
    geocodeAddress(address)
      .then(results => {
        if (results[0]) {
          const parsedAddress = parseAddress(results[0], true)
          resolve(parsedAddress)
        } else {
          reject(results)
        }
      })
      .catch(reject)
  })
}

export const geocodeAddress = (
  address,
  placeID,
  useOnlyGivenPlaceID = false
) => {
  return new Promise((resolve, reject) => {
    function geocode (id, retryGivenPlaceID = false) {
      if (id) {
        geocodeByPlaceId(id)
          .then(results => camelize(results))
          .then(resolve)
          .catch(error => {
            console.error(
              '[geocodeByPlaceId] Error while geocoding by place id.'
            )
            console.error('PlaceID: ', id)
            console.error('Error: ', error)

            // Run fallback
            // If `retryGivenPlaceID` is true, try again using place ID provided by autocomplete.
            // Otherwise, use geocode by address.
            if (retryGivenPlaceID && placeID) {
              geocode(placeID, true)
            } else {
              geocode()
            }
          })
      } else {
        geocodeByAddress(address)
          .then(results => camelize(results))
          .then(resolve)
          .catch(error => {
            console.error(
              '[geocodeByAddress] Error while geocoding by address.'
            )
            console.error('Address: ', address)
            console.error('Error: ', error)
            reject(error)
          })
      }
    }

    if (!useOnlyGivenPlaceID) {
      // Use `getPlaceIDUsingTextSearch` to try to get a more accurate place ID
      // that is similar to what maps.google.com would return
      getPlaceIDUsingTextSearch(address)
        .then(accuratePlaceID => {
          geocode(accuratePlaceID || placeID, true)
        })
        .catch(error => {
          console.log(
            '[getPlaceIDUsingTextSearch] Could not get more accurate place ID: ',
            error
          )
          geocode(placeID)
        })
    } else {
      geocode(placeID)
    }
  })
}

export const getPlaceIDUsingTextSearch = address => {
  if (isPlainObject(address)) {
    address = getPlaceAddress(address, false).fullAddress
  }

  return new Promise((resolve, reject) => {
    // Places Services require a map to work, but we don't need
    // it to have a context in this case, so we can fake one here
    let mapEl = document.createElement('div')
    mapEl.id = 'mapUsedByTextSearch'
    mapEl.style.display = 'none'
    document.body.appendChild(mapEl)

    let map = new root.google.maps.Map(mapEl)

    const service = new root.google.maps.places.PlacesService(map)

    service.textSearch({ query: address }, (results, status) => {
      if (
        status === root.google.maps.places.PlacesServiceStatus.OK &&
        results[0]
      ) {
        const placeID = results[0].place_id
        resolve(placeID)
      } else {
        reject(results)
      }

      mapEl.parentNode.removeChild(mapEl)
    })
  })
}

export const getBounds = bounds => {
  const defaultBounds = {
    nw: { lat: null, lng: null },
    se: { lat: null, lng: null }
  }

  if (!bounds) {
    return defaultBounds
  }

  if (Array.isArray(bounds)) {
    if (!bounds[0]) {
      return defaultBounds
    }

    // bounds: [NW_lat, NW_lng, SE_lat, SE_lng]
    return {
      nw: { lat: bounds[0], lng: bounds[1] },
      se: { lat: bounds[2], lng: bounds[3] }
    }
  } else {
    const NWLat = bounds.getNorthEast().lat()
    const NWLng = bounds.getSouthWest().lng()
    const SELat = bounds.getSouthWest().lat()
    const SELng = bounds.getNorthEast().lng()

    return {
      nw: { lat: NWLat, lng: NWLng },
      se: { lat: SELat, lng: SELng }
    }
  }
}

export const fitMapToMarkers = (markers, mapDimensions) => {
  if (typeof root.google !== 'undefined') {
    markers = uniqWith(markers, isEqual)

    let bounds = new root.google.maps.LatLngBounds()

    markers.forEach(marker => {
      let position = new root.google.maps.LatLng(marker.lat, marker.lng)
      bounds.extend(position)
    })

    bounds = getBounds(bounds)

    const { center, zoom } = fitBounds(bounds, mapDimensions)

    return {
      center,
      zoom: Math.min(zoom - 1, 18)
    }
  } else {
    return {
      center: { lat: 0, lng: 0 },
      zoom: 18
    }
  }
}

export const offsetCenter = ({ center, offsetX, offsetY, zoom }, map, maps) => {
  // latLng is the apparent centre-point
  // offsetX is the distance you want that point to move to the right, in pixels
  // offsetY is the distance you want that point to move upwards, in pixels
  // offset can be negative
  // offsetX and offsetY are both optional

  maps = maps || root.google.maps

  const latLng = new maps.LatLng(center.lat, center.lng)

  zoom = zoom || map.getZoom()

  const scale = Math.pow(2, zoom)

  const worldCoordinateCenter = map.getProjection().fromLatLngToPoint(latLng)
  const pixelOffset = new maps.Point(offsetX / scale || 0, offsetY / scale || 0)

  const worldCoordinateNewCenter = new maps.Point(
    worldCoordinateCenter.x - pixelOffset.x,
    worldCoordinateCenter.y + pixelOffset.y
  )

  const newCenter = map
    .getProjection()
    .fromPointToLatLng(worldCoordinateNewCenter)

  map.setCenter(newCenter)

  return {
    lat: newCenter.lat(),
    lng: newCenter.lng()
  }
}

export function extractLocation (place) {
  let bounds = new root.google.maps.LatLngBounds()

  if (place.geometry.viewport) {
    bounds.union(place.geometry.viewport)
  } else {
    bounds.extend(place.geometry.location)
  }

  const NElat = bounds.getNorthEast().lat()
  const SWlng = bounds.getSouthWest().lng()
  const SWlat = bounds.getSouthWest().lat()
  const NElng = bounds.getNorthEast().lng()

  return {
    address: place.formattedAddress,
    lat: place.geometry.location.lat(),
    lng: place.geometry.location.lng(),
    bounds: [NElat, SWlng, SWlat, NElng]
  }
}
