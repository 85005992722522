import React from 'react'
import PropTypes from 'prop-types'

import get from 'lodash/get'
// import debounce from 'lodash/debounce'

import logger from 'utils/logger'

import * as api from '../api/info'

import StateMachineProvider, { StateMachineContainer } from 'utils/StateMachine'

const statechart = {
  key: 'hosting',
  initial: 'idle',
  states: {
    idle: {
      on: { LOAD: 'loading' }
    },
    loading: {
      onEntry: ['load', 'showLoading'],
      on: {
        LOAD_SUCCESS: 'idle',
        LOAD_FAILURE: 'error'
      }
    },
    error: {
      onEntry: 'showError',
      on: { RECOVER: 'loading' }
    }
  }
}

class HostingContainer extends StateMachineContainer {
  constructor (props) {
    super(props)

    // this.updatePreferences = debounce(this.updatePreferences, 500)

    // Make sure to keep the state machine engine
    const stateMachine = this.state

    this.state = {
      ...stateMachine,
      hostingType: null,
      hostingInfo: {},
      preferences: {}
    }
  }

  componentDidTransition (prevStateMachine, event) {
    if (event && event.type) {
      switch (event.type) {
        case 'LOAD_SUCCESS':
          this.setData(event.data)
          break
        case 'LOAD_FAILURE':
          this.setError(event.error)
          break
      }
    }
  }

  setData = async data => {
    await this.setState(data)
  }

  setError = async error => {
    const errorStr = Array.isArray(error) ? error[0] : error
    await this.setState({ error: errorStr })
  }

  request = async (name, apiCall, data) => {
    logger.captureBreadcrumb({
      message: 'HostingContainer.' + name,
      category: 'hosting',
      data
    })

    try {
      const [error, response] = await apiCall(data)

      if (error) {
        throw new Error(error)
      } else {
        return response
      }
    } catch (error) {
      logger.captureException(error)

      this.transition({ type: 'LOAD_FAILURE', error })
      return false
    }
  }

  load = async ({ hostingType }) => {
    logger.captureBreadcrumb({
      message: 'HostingContainer.load',
      category: 'hosting'
    })

    const hostingInfo = await this.loadHostingInfo()
    const preferences = await this.loadPreferences(hostingType)

    if (!hostingInfo || !preferences) return

    this.transition({
      type: 'LOAD_SUCCESS',
      data: {
        hostingType,
        hostingInfo,
        preferences
      }
    })
  }

  loadHostingInfo = () => {
    return this.request('loadHostingInfo', api.getHostingInfo)
  }

  loadPreferences = async hostingType => {
    const data = await this.request(
      'loadPreferences',
      api.getHostingPreferences
    )

    if (!data) return

    const hosting = get(data, 'preferences.hosting')

    if (hostingType === 'business') {
      return {
        staypals: hosting.businessStaypals,
        staypals2: hosting.businessStaypals2
      }
    }

    return {
      staypals: hosting.friendshipStaypals,
      staypals2: hosting.friendshipStaypals2
    }
  }

  togglePreference = async key => {
    if (!['none', 'staypals', 'staypals2'].includes(key)) {
      throw new Error(
        'Invalid key provided for togglePreference. Gotta be one of these: none | staypals | staypals2'
      )
    }

    let data = this.state.preferences

    if (key === 'none') {
      data = { staypals: false, staypals2: false }
    } else {
      data = { ...data, [key]: !data[key] }
    }

    this.updatePreferences(data)
  }

  updatePreferences = async data => {
    // Optimistically update UI
    const previousData = this.state.preferences

    await this.setData({ preferences: data })

    const { hostingType } = this.state
    if (hostingType === 'business') {
      data = { business: data }
    } else {
      data = { friendship: data }
    }

    const response = await this.request(
      'updatePreferences',
      api.updateHostingPreferences,
      data
    )

    if (!response) {
      // Recover pre-update data if update went wrong
      await this.setData({ preferences: previousData })
    }

    return response
  }
}

const businessContainer = new HostingContainer({ statechart })
const friendshipContainer = new HostingContainer({ statechart })

export default class HostingStoreProvider extends React.Component {
  static propTypes = {
    children: PropTypes.func.isRequired
  }

  constructor (props) {
    super(props)

    if (props.hostingType === 'business') {
      this.container = businessContainer
    } else {
      this.container = friendshipContainer
    }
  }

  componentDidMount () {
    this.loadData()
  }

  loadData = async () => {
    const { hostingType } = this.props
    await this.container.transition({ type: 'LOAD', hostingType })
  }

  render () {
    return (
      <StateMachineProvider container={this.container}>
        {machineStore => this.props.children(machineStore)}
      </StateMachineProvider>
    )
  }
}
