import React from 'react'

import { get } from 'lodash'

// import { TextInput } from 'components/Input'

import ButtonPill from 'components/ButtonPill'

import CannotDeleteModal from './components/CannotDeleteModal'
import ConfirmDeleteModal from './components/ConfirmDeleteModal'
import DeleteSuccesModal from './components/DeleteSuccesModal'

import { RenderOnAction } from 'utils/StateMachine'

import colors from 'styles/colors'

import withDeleteAccountStore, { defaultDestroyModal } from './store'

class Deleteaccount extends React.Component {
  state = {
    password: ''
  }

  setPassword = e => {
    this.setState({
      password: e.target.value
    })
  }

  shouldComponentUpdate (nextProps) {
    if (
      nextProps !== this.props ||
      nextProps.machineStore.state !== this.props.machineStore.state
    ) {
      return true
    }

    return false
  }

  get error () {
    return get(this.props.machineStore, 'state.event.error', {})
  }

  get destroyModal () {
    return get(
      this.props.machineStore,
      'state.event.destroyModal',
      defaultDestroyModal
    )
  }

  handleModalClose = () => {
    this.props.machineStore.transition({
      destroyModal: {
        ...this.destroyModal,
        show: false
      }
    })
  }

  destroyLookup = () => {
    this.props.machineStore.transition({
      type: 'DESTROY_LOOKUP',
      destroyModal: this.destroyModal
    })
  }

  handleDestroy = password => {
    const { passwordSet } = this.props
    this.props.machineStore.transition({
      type: 'DESTROY_ACCOUNT',
      password,
      passwordSet,
      destroyModal: this.destroyModal
    })
  }

  render () {
    const { destroyModal, error } = this
    const { passwordSet } = this.props

    return (
      <RenderOnAction value='showLoading'>
        {loading => (
          <div className='flex flex-column items-center justify-center center w-100 mb4'>
            <div className='center flex flex-column items-center w-100 w-90-ns mb0'>
              <h1 className='mt0 mb3'>Permanently Delete Account</h1>

              <div className='mv4'>
                <p className='tl mb4 lh-copy ml4'>
                  By deleting your account you won't be able to:
                  <br />
                  <br />
                  <ul className='tl'>
                    <li>Access Staycircles's services</li>
                    <li>
                      All your personal data will be deleted including user
                      info, device info, friends list and airport details
                    </li>
                  </ul>
                  <br />
                  <br />
                </p>

                {error && error.destroyAccount && (
                  <p
                    className='tc lh-copy b mt4 mb3'
                    style={{ color: colors.darkRed }}
                  >
                    {error.destroyAccount}
                  </p>
                )}
              </div>

              <div className='tc'>
                <ButtonPill
                  onClick={() => {
                    // if (!this.state.password) return
                    // this.handleDestroy(this.state.password)
                    this.destroyLookup()
                  }}
                  children='Delete account'
                  background={colors.darkRed}
                  hoverBackground={colors.red}
                  color={colors.white}
                  border={colors.whiteRGBA(0.1)}
                  style={{
                    cursor: loading ? 'not-allowed' : 'pointer'
                  }}
                  disabled={loading}
                />
              </div>
            </div>

            <CannotDeleteModal
              show={destroyModal.show && !!destroyModal.reason}
              close={this.handleModalClose}
              reason={destroyModal.reason}
            />

            <ConfirmDeleteModal
              show={destroyModal.show && !destroyModal.reason}
              close={this.handleModalClose}
              destroy={this.handleDestroy}
              error={error.destroyAccount}
              loading={loading}
              passwordSet={passwordSet}
            />

            <DeleteSuccesModal show={destroyModal.destroyed} />
          </div>
        )}
      </RenderOnAction>
    )
  }
}

export default withDeleteAccountStore(Deleteaccount)
