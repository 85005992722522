import React from 'react'

import styled, { css } from 'styled-components'
import { noop } from 'lodash'

import colors from 'styles/colors'

import ButtonPill from 'components/ButtonPill'

const InviteButton = styled.button`
  background: white;
  font-size: 14px;
  border-radius: 9999px;
  min-width: 70px;
  height: 30px;
  margin-left: 8px;

  ${props =>
    props.disabled &&
    css`
      color: ${colors.blackRGBA(0.5)};
      border: 1px solid transparent;
      cursor: default;
    `};

  ${props =>
    !props.disabled &&
    css`
      color: ${colors.blue};
      border: 1px solid ${colors.blue};
      cursor: pointer;
    `};
`

const HugeButton = props => (
  <ButtonPill color={colors.darkerBlue} border={colors.darkerBlue} {...props} />
)

class AddStayPalButto extends React.PureComponent {
  static defaultProps = {
    stayPalRequestStatus: '',
    makeSP: noop,
    loading: false,
    label: 'Add Friend'
  }

  get label () {
    const { loading, stayPalRequestStatus, label } = this.props

    if (loading) {
      return 'Loading'
    }

    if (stayPalRequestStatus === 'received') {
      return 'Accept'
    }

    if (stayPalRequestStatus === 'pending') {
      return 'Invited'
    }

    return label
  }

  get disabled () {
    const { loading, stayPalRequestStatus } = this.props

    if (loading || stayPalRequestStatus === 'pending') {
      return true
    }

    return false
  }

  render () {
    const { label, disabled, props } = this
    const { huge, className, makeSP } = props
    const Container = huge ? HugeButton : InviteButton

    return (
      <Container
        onClick={makeSP}
        children={label}
        disabled={disabled}
        className={className}
      />
    )
  }
}

export default AddStayPalButto
