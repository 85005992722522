import api from 'utils/api'

export const getStays = ({ page, filter }) => {
  const params = {
    page,
    filter,
    rpp: 30
  }

  return api.get('/stays', params)
}

export const getStayDetails = id => {
  return api.get(`/conversations/${id}`)
}

export const getReceipt = stayID => {
  return api.get(`/stays/${stayID}/receipt`)
}

export const acceptStay = id => {
  return api.put(`/stays/${id}/approve`)
}

export const declineStay = id => {
  return api.put(`/stays/${id}/deny`)
}

export const cancelStay = id => {
  return api.put(`/stays/${id}/cancel`)
}

export const retryPayment = (id, paymentMethodId) => {
  return api.put(`/stays/${id}`, {
    paymentMethodId
  })
}

export const handleProposal = ({ id, action, paymentMethodId }) => {
  switch (action) {
    case 'accept':
      return acceptStay(id)
    case 'decline':
      return declineStay(id)
    case 'cancel':
      return cancelStay(id)
    case 'retry':
      return retryPayment(id, paymentMethodId)
  }
}
