import React from 'react'

import Modal from 'components/Modal'

import colors from 'styles/colors'

class InviteSuccesModal extends React.Component {
  render () {
    const { show, onClose, emails } = this.props

    return (
      <Modal
        isOpen={show}
        onRequestClose={onClose}
        contentLabel='InviteSuccesModal'
      >
        <div className='center flex flex-column items-center w-100 w-90-ns mb0'>
          <h3 className='mt0 mb3'>These emails were invited successfully:</h3>

          <div className='mv4'>
            <ul>
              {emails.map(email => (
                <li key={email}>{email}</li>
              ))}
            </ul>
          </div>

          <button
            type='button'
            onClick={onClose}
            children='OK'
            style={{ backgroundColor: colors.blue }}
            className='ba b--white-10 white f5 pv2 ph4 mb0 br2 pointer'
          />
        </div>
      </Modal>
    )
  }
}

export default InviteSuccesModal
