import React, { PureComponent } from 'react'

import isEqual from 'lodash/isEqual'
import isEmpty from 'lodash/isEmpty'
import toArray from 'lodash/toArray'
import uniqBy from 'lodash/uniqBy'
import get from 'lodash/get'

import { Location } from '@reach/router'

import { fitBounds } from 'google-map-react/utils'

import root from 'utils/windowOrGlobal'
import { getBounds, fitMapToMarkers, extractLocation } from 'utils/location'
import ptInBounds from './ptInBounds'

import ClusterMarker from './ClusterMarker'
import MapWithClusteringFactory from './MapWithClusteringFactory'
import ZoomRange from './ZoomRange'
import StayPalMarker from './StayPalMarker'
import MapGlobalStyle from './MapGlobalStyle'
import Spinner from './Spinner'
import ControlPanel from '../ControlPanel'
import PlaceDetails from '../PlaceDetails'

const FORM_NAME = 'search-inline'

const GoogleMapWithClustering = MapWithClusteringFactory(
  ClusterMarker,
  ClusterMarker
)

class SearchMap extends PureComponent {
  state = {
    isHoverChild: false
  }

  componentDidMount () {
    const { location, activePanel, defaultBounds, currentUser } = this.props

    if (!isEmpty(location.bounds)) {
      this.fitBounds()
    } else if (location.origin !== 'query') {
      if (currentUser && currentUser.lat && currentUser.lng) {
        // Fit to currentUser's location
        this.props.run('setLocation', {
          location: {
            center: { lat: currentUser.lat, lng: currentUser.lng },
            zoom: activePanel === 'myCommunity' ? 4 : 3
          }
        })
      } else {
        // Fit to default country (USA)
        this.fitBounds(null, defaultBounds)
      }
    }
  }

  componentWillReceiveProps (nextProps) {
    if (
      !isEqual(this.props.location, nextProps.location) &&
      !isEmpty(nextProps.location.bounds) &&
      nextProps.location.origin === 'form'
    ) {
      this.fitBounds(nextProps.location)
    }
  }

  fitBounds = (location, bounds) => {
    location = location || this.props.location
    bounds = bounds ? getBounds(bounds) : getBounds(location.bounds)

    const size = this.getMapDimensions()

    let { center, zoom } = fitBounds(bounds, size)

    if (location.origin === 'form') {
      zoom = zoom - 1
    }

    if (!isEqual(location.center, center)) {
      this.props.run('setLocation', { location: { center, zoom } })
    } else {
      this.props.run('setLocation', { location: { zoom } })
    }
  }

  fitMapToMarkers = markers => {
    const singleLatLng =
      markers.length === 1 ||
      uniqBy(markers, i => `${i.lat}-${i.lng}`).length === 1

    if (singleLatLng) {
      // If there is only one marker/latLng to fit, we zoom to it manually
      // because otherwise `fitMapToMarkers` would focus on other
      // random location for some reason.
      this.props.run('setLocation', {
        location: { center: markers[0], zoom: 17 }
      })
      return
    }

    this.fitMarkers = true

    const mapDimensions = this.getMapDimensions()

    const newLocation = fitMapToMarkers(markers, mapDimensions)

    this.props.run('setLocation', { location: newLocation })
  }

  getMapDimensions = () => {
    return {
      width: this.mapWrapper.clientWidth,
      height: this.mapWrapper.clientHeight || root.innerHeight
    }
  }

  getItems = (result, activePanel) => {
    if (!result) {
      result = this.props.result
    }

    if (!activePanel) {
      activePanel = this.props.activePanel
    }

    const items = get(result, [activePanel, 'results'], [])

    if (items[0] && items[0].housemates) return items

    return items.filter(m => {
      const bounds = getBounds(this.props.location.bounds)
      return m && ptInBounds(bounds, m)
    })
  }

  getCenter () {
    const {
      location: { center },
      query,
      defaultCenter
    } = this.props

    if (center) {
      return center
    }

    if (query.to_lat && query.to_long) {
      return {
        lat: Number(query.to_lat),
        lng: Number(query.to_long)
      }
    }

    return defaultCenter
  }

  getZoom () {
    const {
      location: { zoom },
      query,
      defaultZoom
    } = this.props

    return zoom || Number(query.zoom) || defaultZoom
  }

  getLocationProps () {
    return {
      bounds: getBounds(this.props.location.bounds),
      center: this.getCenter(),
      zoom: this.getZoom()
    }
  }

  isBoundsAlreadyCached = newBounds => {
    const { map, bounds } = this.state

    if (!bounds) {
      return false
    }

    const newBoundsArray = toArray(newBounds)
    const shouldUpdate = newBoundsArray.some(latLng => !bounds.contains(latLng))

    if (!shouldUpdate) {
      return true
    }

    this.setState({ bounds: map.getBounds() })

    return false
  }

  handleBoundsChange = ({ center, zoom, bounds }) => {
    if (this.changeTimeout) {
      root.clearTimeout(this.changeTimeout)
    }

    const { location, loading } = this.props

    if (Math.abs(center.lat) > 90 || Math.abs(center.lng) > 180) {
      // Hotfix when the impossible happens
      this.state.map && this.state.map.setCenter(location.center)

      return
    }

    const offset = 0.01

    // bounds: [NW_lat, NW_lng, SE_lat, SE_lng]
    const mappedBounds = [
      bounds.nw.lat + offset,
      bounds.nw.lng - offset,
      bounds.se.lat - offset,
      bounds.se.lng + offset
    ]
    const boundsAlreadyCached = this.isBoundsAlreadyCached(bounds)

    const newLocation = {
      ...center,
      zoom,
      bounds: mappedBounds,
      boundsAlreadyCached
    }

    this.props.run('setLocation', { location: newLocation, origin: 'map' })

    this.changeTimeout = root.setTimeout(() => {
      if ((location.origin === 'form' && loading) || this.fitMarkers) return

      this.props.run('triggerSearch', {
        location: newLocation,
        form: FORM_NAME
      })
    }, 500)

    if (this.fitMarkers) {
      this.fitMarkers = false
      root.clearTimeout(this.changeTimeout)
    }
  }

  handleFilterToggle = type => {
    if (this.changeTimeout) {
      root.clearTimeout(this.changeTimeout)
    }

    const { includeOnly } = this.props.params
    const params = {}
    const otherType = Math.max(1, (type + 1) % 3)

    if (includeOnly === otherType) {
      params.includeOnly = undefined
    } else {
      params.includeOnly = otherType
    }

    this.props.run('setParams', {
      params,
      location: this.props.location,
      form: FORM_NAME
    })
  }

  handlePlaceSelect = place => {
    if (place.failed) {
      return
    }

    const location = extractLocation(place)

    this.fitBounds(location)
  }

  renderMarkers () {
    const {
      searchFor,
      activePanel,
      activeItem,
      activeUserID,
      currentUser,
      location,
      params,
      setActivePin,
      loading
    } = this.props

    let markers = []
    const items = this.getItems()

    if (items && items[0] && !isEmpty(location.bounds)) {
      markers = items.map((item, i) => (
        <StayPalMarker
          key={item.id}
          {...{ item, currentUser, activeUserID, params, loading }}
          active={activeItem === item.id}
          unfocused={!!activeItem && activeItem !== item.id}
          lat={item.lat}
          lng={item.lng}
          onClick={setActivePin}
          searchFor={activePanel === 'search' ? searchFor : 'community'}
          zoom={this.getZoom()}
          onMouseEnter={() => {
            this.setHoverChild(true)
          }}
          onMouseLeave={() => {
            this.setHoverChild(false)
          }}
        />
      ))
    }

    return markers
  }

  setHoverChild = state => {
    this.setState({ isHoverChild: state })
    root.clearTimeout(this.hoverTimeout)
    if (state) {
      // Clean out the hovered state because onMouseLeave doesn't
      // always work properly.
      this.hoverTimeout = root.setTimeout(() => {
        this.setState({ isHoverChild: !state })
      }, 3000)
    }
  }

  setZoom = zoom =>
    this.props.run('setLocation', { location: { zoom }, origin: 'map' })

  setSpRequestSuccess = data => this.props.run('setSpRequestSuccess', data)

  sendMessage = data => this.props.run('sendMessage', data)

  onMapClick = () => {
    // Clear active item/pin when clicking on map
    if (this.props.activeItem && !this.state.isHoverChild) {
      this.props.run('setActiveItem')
    }
  }

  closePlaceDetails = () => this.props.run('setActiveItem')

  _setupMapInternals = ({ map, maps }) => {
    this.setState({ map, bounds: map.getBounds() })
    this.props.setupMapInternals({ map, maps })
  }

  onFriendSelect = data => {
    const { lat, lng } = data
    const offset = 0.01

    // bounds: [NW_lat, NW_lng, SE_lat, SE_lng]
    const mappedBounds = [
      lat + offset,
      lng - offset,
      lat - offset,
      lng + offset
    ]
    this.props.run('setActiveItem')
    this.fitBounds(data, mappedBounds)
  }

  render () {
    const {
      activeUserID,
      activeItem,
      activeDetails,
      selectHost,
      toggleSearchPreference
    } = this.props
    const zoom = this.getZoom()
    const center = this.getCenter()

    return (
      <div className='sc-search-map w-100'>
        <MapGlobalStyle />

        <Location>
          {({ location }) => {
            const showControls = /explore|booking/i.test(
              get(location, 'pathname', '')
            )

            return (
              showControls && (
                <React.Fragment>
                  <PlaceDetails
                    {...{
                      ...activeDetails,
                      activeUserID,
                      activeItem,
                      selectHost,
                      onClose: this.closePlaceDetails,
                      setSpRequestSuccess: this.setSpRequestSuccess,
                      sendMessage: this.sendMessage,
                      onFriendSelect: this.onFriendSelect
                    }}
                  />

                  <ControlPanel
                    {...this.props.params}
                    handleFilterToggle={this.handleFilterToggle}
                    handlePlaceSelect={this.handlePlaceSelect}
                    toggleSearchPreference={toggleSearchPreference}
                  />
                </React.Fragment>
              )
            )
          }}
        </Location>

        <ZoomRange current={zoom} setZoom={this.setZoom} />

        <Spinner show={this.props.loading} />

        <div
          ref={ref => {
            this.mapWrapper = ref
          }}
        >
          <GoogleMapWithClustering
            options={{
              maxZoom: 17,
              disableDefaultUI: true,
              clickableIcons: false,
              // Double clicking on a pin should zoom in on the pin, and for
              // that to work we need to disable double click on map when a
              // pin is active and hovered.
              disableDoubleClickZoom:
                this.props.activeItem && this.state.isHoverChild
            }}
            center={center}
            zoom={zoom}
            onChange={this.handleBoundsChange}
            locationProps={this.getLocationProps()}
            clusterOptions={{ radius: 50 }}
            clusterProps={{
              unfocused: !!this.props.activeItem,
              onClick: this.fitMapToMarkers
            }}
            onGoogleApiLoaded={this._setupMapInternals}
            onClick={this.onMapClick}
            onChildMouseEnter={() => {
              this.setHoverChild(true)
            }}
            onChildMouseLeave={() => {
              this.setHoverChild(false)
            }}
          >
            {this.renderMarkers()}
          </GoogleMapWithClustering>
        </div>
      </div>
    )
  }
}

SearchMap.defaultProps = {
  // Start on United States by default
  defaultCenter: [37.09024, -95.71289100000001], // [0.000000000001, 0]
  defaultZoom: 4,
  defaultBounds: [49.38, -124.38999999999999, 25.82, -66.94]
}

export default SearchMap
