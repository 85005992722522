import { get } from 'lodash'

import { withStateMachine, StateMachineContainer } from 'utils/StateMachine'
import logger from 'utils/logger'

import { getReadableError } from 'utils/api'
import * as api from './api'

const ALL_ACTIONS = {
  LOAD: { loading: { actions: ['getStayPals'] } },
  SEARCH_STAYPAL: { loading: { actions: ['searchStayPals'] } },
  SET_TERM: { idle: { actions: ['setTerm'] } },
  SET_TAB: { idle: { actions: ['setTab'] } },
  ACCEPT_SP_REQUEST: { loading: { actions: ['acceptSPRequest'] } },
  SEND_SP_REQUEST: { loading: { actions: ['sendSPRequest'] } },
  GET_GMAIL_CONTACTS: { loading: { actions: ['getGmailContacts'] } }
}

const statechart = {
  initial: 'idle',
  states: {
    idle: {
      on: {
        ...ALL_ACTIONS
      },
      onEntry: 'hideLoading'
    },
    loading: {
      onEntry: 'showLoading',
      on: {
        SUCCESS: 'idle',
        FAILURE: 'idle',
        ...ALL_ACTIONS
      }
    }
  }
}

export const defaultStayPals = {
  page: 0,
  term: '',
  hasMore: true,
  list: []
}

export const defaultStayPals2 = {
  page: 0,
  term: '',
  hasMore: true,
  list: []
}

class IntegratedStayPalsContainer extends StateMachineContainer {
  constructor (props) {
    super(props)

    this.state = {
      ...this.state,
      activeTab: 'stayPals2',
      stayPals: defaultStayPals,
      stayPals2: defaultStayPals2,
      error: {},
      inProgress: []
    }
  }

  requestQueue = []

  request = async (apiName, data, event = {}) => {
    logger.captureBreadcrumb({
      message: 'IntegratedStayPalsContainer.' + apiName,
      category: 'stayPals',
      data
    })

    try {
      const [error, response] = await api[apiName](data)

      if (error) {
        throw new Error(error)
      } else {
        return response
      }
    } catch (error) {
      logger.captureException(error)

      let err = getReadableError(error)

      this.transition({
        ...event,
        type: 'FAILURE',
        error: {
          [apiName]: err
        }
      })
      return false
    }
  }

  addProgress = id => {
    this.setState({
      inProgress: this.state.inProgress.concat([id])
    })
  }

  removeProgress = id => {
    this.setState({
      inProgress: this.state.inProgress.filter(i => i !== id)
    })
  }

  setTerm = ({ term, spType = 'stayPals' }) => {
    this.setState({
      [spType]: {
        ...this.state[spType],
        term
      }
    })
  }

  setTab = ({ activeTab }) => this.setState({ activeTab })

  getStayPals = async ({ spType = 'stayPals2' }) => {
    const page = get(this.state[spType], 'page') + 1
    const term = get(this.state[spType], 'term')

    const includeOnly = spType === 'stayPals' ? 1 : 2
    const response = await this.request('getStayPals', {
      page,
      term,
      includeOnly
    })

    if (response) {
      let list = get(response, 'results', []).map(
        ({ connection, mutualConnections }) => ({
          ...connection,
          mutualStayPals: mutualConnections
        })
      )

      list = this.state[spType].list.concat(list)

      const hasMore = list.length < response.totalResults

      this.setState({
        [spType]: {
          ...this.state[spType],
          page,
          hasMore,
          list
        }
      })

      this.transition({
        type: 'SUCCESS'
      })

      return true
    }
  }

  searchStayPals = async ({ term, spType = 'stayPals2' }) => {
    const requestID = `${spType}-${term}`
    this.requestQueue.push(requestID)

    const includeOnly = spType === 'stayPals' ? 1 : 2
    const response = await this.request('getStayPals', {
      page: 1,
      term,
      includeOnly
    })
    this.requestQueue = this.requestQueue.filter(id => id !== requestID)

    if (this.requestQueue.length > 0) {
      // abort if there is another request in the queue
      return
    }

    if (response) {
      const list = get(response, 'results', []).map(
        ({ connection, mutualConnections }) => ({
          ...connection,
          mutualStayPals: mutualConnections
        })
      )

      const hasMore = list.length < response.totalResults

      this.setState({
        [spType]: {
          ...this.state[spType],
          page: 1,
          hasMore,
          list
        }
      })

      this.transition({
        type: 'SUCCESS'
      })

      return true
    }
  }

  sendSPRequest = async ({ sp: { id, email }, cb }) => {
    this.addProgress(id)

    const ok = await this.request('sendSPRequest', email)

    this.removeProgress(id)

    if (ok) {
      const list = this.state.stayPals2.list.map(sp2 => {
        if (sp2.id === id) {
          return {
            ...sp2,
            connectionValue: 1
          }
        }

        return sp2
      })

      this.setState({
        stayPals2: {
          ...this.state.stayPals2,
          list
        }
      })

      this.transition({
        type: 'SUCCESS'
      })

      if (typeof cb === 'function') {
        cb()
      }

      return true
    }
  }

  acceptSPRequest = async ({ sp: { id }, cb }) => {
    this.addProgress(id)

    const ok = await this.request('acceptSPRequest', id)

    this.removeProgress(id)

    if (ok) {
      const list = this.state.stayPals2.list.filter(sp2 => sp2.id !== id)

      this.setState({
        stayPals2: {
          ...this.state.stayPals2,
          list
        }
      })

      this.transition({
        type: 'SUCCESS'
      })

      if (typeof cb === 'function') {
        cb()
      }

      return true
    }
  }
}

const withMaintenanceStore = withStateMachine(
  null,
  new IntegratedStayPalsContainer({ statechart })
)

export default withMaintenanceStore
