import React from 'react'
import PropTypes from 'prop-types'

import get from 'lodash/get'
import uniqBy from 'lodash/uniqBy'

import logger from 'utils/logger'

import { getCurrentUser } from 'stores/auth'

import * as api from './api'

import StateMachineProvider, { StateMachineContainer } from 'utils/StateMachine'

const statechart = {
  key: 'stays',
  initial: 'start',
  states: {
    start: {
      on: {
        LOAD: 'loading',
        LOAD_STAY_DETAILS: {
          loadingStayDetails: { actions: ['loadStayDetails'] }
        }
      }
    },
    loading: {
      onEntry: ['loadStays', 'showLoading'],
      on: {
        LOAD_SUCCESS: 'list',
        LOAD_FAILURE: 'error'
      }
    },
    error: {
      onEntry: 'showError',
      on: { RELOAD: 'loading' }
    },
    list: {
      onEntry: 'showList',
      on: {
        LOAD: 'loading',
        RELOAD: 'loading',
        NEXT: { list: { actions: ['loadNext'] } },
        NEXT_CANCEL: 'list',
        LOAD_SUCCESS: 'list',
        LOAD_FAILURE: 'list',
        LOAD_STAY_DETAILS: {
          loadingStayDetails: { actions: ['loadStayDetails'] }
        },
        HANDLE_PROPOSAL: {
          stayDetails: { actions: ['handleProposal'] }
        }
      }
    },
    loadingStayDetails: {
      onEntry: ['showLoadingStayDetails'],
      on: {
        LOAD_SUCCESS: 'stayDetails',
        LOAD_FAILURE: 'error'
      }
    },
    stayDetails: {
      onEntry: ['showStayDetails'],
      on: {
        HANDLE_PROPOSAL: {
          stayDetails: { actions: ['handleProposal', 'loadingStatus'] }
        },
        STAY_CHANGED: 'stayDetails',
        CLOSE_DETAILS: { list: { actions: ['cleanDetails'] } }
      }
    }
  }
}

class StaysContainer extends StateMachineContainer {
  constructor (props) {
    super(props)

    // Make sure to keep the state machine engine
    const stateMachine = this.state

    this.state = {
      ...stateMachine,
      data: { stays: [] },
      params: {
        page: 1,
        filter: ''
      },
      receipts: {},
      stayDetails: {}
    }
  }

  componentDidTransition (prevStateMachine, event) {
    if (event && event.type && event.type === 'LOAD_SUCCESS') {
      this.setStays(event)
    }
  }

  setStays = event => {
    let stays = event.data.stays

    if (event.data.page > 1) {
      stays = uniqBy(this.state.data.stays.concat(stays), 'id')
    }

    const data = { ...event.data, stays }

    this.setState({ data })
  }

  loadStays = async (event = {}) => {
    logger.captureBreadcrumb({
      message: 'StaysScreen.loadStays',
      category: 'stays',
      data: event
    })

    let params = {
      page: event.page || 1,
      filter: event.filter
    }

    if (event.type === 'RELOAD') {
      params = this.state.params
    }

    try {
      await this.setState({ params })

      const [error, data] = await api.getStays(params)

      if (error) {
        throw new Error(error)
      } else {
        this.transition({ type: 'LOAD_SUCCESS', data })
      }
    } catch (error) {
      logger.captureException(error)
      this.transition({ type: 'LOAD_FAILURE', error })
    }
  }

  loadNext = async () => {
    const { params, data } = this.state
    const nextPage = Number.isNaN(params.page) ? 1 : params.page + 1

    const totalPages = Math.ceil(data.totalStayCount / data.perPageCount)

    if (nextPage > totalPages) {
      this.transition('NEXT_CANCEL')
      return
    }

    this.loadStays({
      ...params,
      page: nextPage
    })
  }

  getReceiptUrl = async (id, callback) => {
    logger.captureBreadcrumb({
      message: 'StaysScreen.getReceiptUrl',
      category: 'stays',
      data: { id }
    })

    try {
      const [error, data] = await api.getReceipt(id)

      if (error) {
        throw new Error(error)
      } else {
        this.setState({
          receipts: {
            ...this.state.receipts,
            [id]: data
          }
        })

        if (typeof callback === 'function') {
          const url = get(data, 'downloadUrl')
          callback(url)
        }
      }
    } catch (error) {
      logger.captureException(error)

      if (typeof callback === 'function') {
        callback(null)
      }
    }
  }

  loadStayDetails = async ({ id, convoId }) => {
    logger.captureBreadcrumb({
      message: 'StaysScreen.loadStayDetails',
      category: 'stays',
      data: { id }
    })

    if (!convoId) {
      const stay = this.state.data.stays.find(stay => stay.id === id)
      if (!stay) return

      convoId = stay.conversationId
    }

    try {
      const [error, data] = await api.getStayDetails(convoId)

      if (error) {
        throw new Error(error)
      } else {
        const user = getCurrentUser()
        const friend = data.recipientUser

        const isViewerHost = data.stayDetails.hostId === user.pid

        const host = isViewerHost ? user : friend
        const traveller = isViewerHost ? friend : user

        const stayDetails = {
          conversationId: convoId,
          ...data.stayDetails,
          isViewerHost,
          host,
          traveller
        }

        this.setState({ stayDetails })
        this.transition('LOAD_SUCCESS')
      }
    } catch (error) {
      logger.captureException(error)
      this.transition({ type: 'LOAD_FAILURE', error })
    }
  }

  cleanDetails = () => {
    this.setState({ stayDetails: {} })
  }

  handleProposal = async ({ id, action, callback, ...other }) => {
    const { data } = this.state

    const stayID = id || get(data, 'stayDetails.id')

    logger.captureBreadcrumb({
      message: 'StaysScreen.handleProposal',
      category: 'stays',
      data: { action, stayID }
    })

    try {
      const [error, data] = await api.handleProposal({
        id: stayID,
        action,
        ...other
      })

      if (error) {
        if (typeof callback === 'function') {
          callback(error)
        }
        throw new Error(error)
      } else {
        const status = get(data, 'stay.statusName')
        const paymentStatus = get(data, 'stay.paymentStatusName')

        const stayDetails = {
          ...this.state.stayDetails,
          status,
          paymentStatus
        }

        const stays = this.state.data.stays.map(stay => {
          if (stay.id === stayID) {
            return { ...stay, ...get(data, 'stay', {}) }
          }

          return stay
        })

        this.setState({
          stayDetails,
          data: {
            ...this.state.data,
            stays
          }
        })

        if (typeof callback === 'function') {
          callback(data)
        }

        this.transition('STAY_CHANGED')
        this.transition('LOAD_SUCCESS')
      }
    } catch (error) {
      logger.captureException(error)
    }
  }
}

export default class StaysStoreProvider extends React.PureComponent {
  static propTypes = {
    children: PropTypes.func.isRequired
  }

  static defaultProps = {
    shouldLoadStays: true
  }

  constructor (props) {
    super(props)
    this.container = new StaysContainer({ statechart })
  }

  componentDidMount () {
    if (this.props.shouldLoadStays) {
      this.loadData()
    }
  }

  loadData = async () => {
    const { type } = this.props
    await this.container.transition({ type: 'LOAD', filter: type })
  }

  render () {
    return (
      <StateMachineProvider container={this.container}>
        {machineStore => this.props.children(machineStore)}
      </StateMachineProvider>
    )
  }
}
