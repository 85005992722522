import React from 'react'

import styled from 'styled-components'

import colors from 'styles/colors'

import Icon from 'components/Icon'

const SleepsCounter = ({ decrement, increment, minValue, maxValue, value }) => {
  const minReached = value <= minValue
  const maxReached = value >= maxValue

  return (
    <div className='flex flex-row items-center mh2'>
      <Button onClick={decrement} disabled={minReached}>
        <MinusIcon />
      </Button>

      <span className='black-70 b mh2'>{value}</span>

      <Button onClick={increment} disabled={maxReached}>
        <PlusIcon />
      </Button>
    </div>
  )
}

const Button = styled.button.attrs({ type: 'button' })`
  background: transparent;
  border-radius: 30px;
  padding: 0;
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;

  color: ${colors.darkBlue};
  border: 2px solid ${colors.blueRGBA(0.5)};
  transition: opacity 0.25s ease;
  opacity: 1;

  &:hover,
  &:focus {
    opacity: 0.7;
  }

  &[disabled] {
    opacity: 0.7;
    border-color: ${colors.blackRGBA(0.3)};
    color: ${colors.blackRGBA(0.5)};
  }
`

const MinusIcon = styled(Icon).attrs({ name: 'minus' })`
  width: 1rem;
  height: 1rem;
`

const PlusIcon = styled(MinusIcon).attrs({ name: 'plus' })``

export default SleepsCounter
