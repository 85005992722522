import React from 'react'
import PropTypes from 'prop-types'

import get from 'lodash/get'

import { Router } from '@reach/router'

// import styled from 'styled-components'

import PlacesStoreProvider from './store/places'

import HostingInfoProvider from './store/info'

import PlacesEmpty from './screens/PlacesEmpty'
import Place from './screens/Place'
import AddPlace from './screens/AddPlace'

import Sidebar from './components/Sidebar'

class HostingIndex extends React.PureComponent {
  render () {
    const { type } = this.props

    return (
      <HostingInfoProvider hostingType={type}>
        {infoStore => (
          <PlacesStoreProvider>
            {store => {
              const places = get(store, 'state.data.places', [])

              const hasPlaces = !!places[0]
              const hasRooms = places.some(place => !!get(place, 'rooms[0]'))

              const updating = get(store, 'state.updating')
              const loading = updating || store.matchesActions('showLoading')

              const reloadData = callback => {
                infoStore.transition({ type: 'LOAD', hostingType: type })
                store.transition({ type: 'LOAD', callback })
              }

              return (
                <div className='w-100 db flex-l flex-row-l relative'>
                  <Sidebar
                    type={type}
                    infoStore={infoStore}
                    placesInfo={{ hasPlaces, hasRooms }}
                  />

                  <Router className='flex w-100 flex-row'>
                    <Place
                      path=':placeId/*'
                      loading={loading}
                      places={places}
                      toggleAttribute={(place, key, value) => {
                        store.transition({
                          type: 'TOGGLE_ATTRIBUTE',
                          place,
                          key,
                          value
                        })
                      }}
                      updateData={(place, cb) => {
                        store.transition({
                          type: 'UPDATE',
                          place,
                          callback: cb
                        })
                      }}
                      reloadData={reloadData}
                      deletePlace={(place, callback) => {
                        store.transition({ type: 'DELETE', place, callback })
                      }}
                    />

                    <AddPlace
                      path='new_place/*'
                      placesLength={places.length}
                      onCreatePlace={reloadData}
                    />

                    <PlacesEmpty
                      default
                      type={type}
                      places={places}
                      loading={loading}
                    />
                  </Router>
                </div>
              )
            }}
          </PlacesStoreProvider>
        )}
      </HostingInfoProvider>
    )
  }
}

HostingIndex.propTypes = {
  type: PropTypes.oneOf(['business', 'friendship']).isRequired
}

export default HostingIndex
