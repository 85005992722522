import React from 'react'

import get from 'lodash/get'

import styled from 'styled-components'

import colors from 'styles/colors'

import { getUserPicture, getFullName } from 'utils/profile-info'

import Loader from 'components/Loader'
import MatchQueryModal from 'components/MatchQueryModal'

import HousematesStoreProvider from './store'

import Button from '../../components/SmallButton'
import AddHousemateModal from './components/AddHousemateModal'

const MODALS = [{ query: 'add_housemate', Component: AddHousemateModal }]

export const FullLoader = () => (
  <div className='flex justify-center items-center mt6'>
    <Loader />
  </div>
)

const Housemates = ({ place, navigate }) => {
  if (!place) return <FullLoader />

  const openModal = modal => () => navigate('?' + modal + '=true')

  return (
    <HousematesStoreProvider place={place}>
      {store => {
        const loading = store.matchesActions('showLoading')

        const data = store.getHousemates()

        const owner = data.find(hm => hm.status === 'owner')
        const housemates = data.filter(hm => hm.status !== 'owner')

        const hasHousemates = !!get(housemates, '[0]')

        return (
          <div className={loading ? 'o-60' : ''}>
            <div className='mb4'>
              <h4 className='fw5 mb2 black-90'>Housemate Manager</h4>

              <p className='lh-copy pl3 black-70'>
                Housemate managers can add / remove housemates, reassign the
                housemate
                <br />
                manager role and change place or room details.
              </p>

              <PersonAvatar person={owner} />
            </div>

            <div className='mb4'>
              <div className='flex flex-row items-center mb2'>
                <h4 className='fw5 black-90 mb0'>Housemates</h4>

                {hasHousemates && (
                  <Button
                    blueBorder
                    onClick={openModal('add_housemate')}
                    className='f6 ml3'
                  >
                    Add
                  </Button>
                )}
              </div>

              <p className='lh-copy pl3 black-70'>
                Housemates can recieve and approve / decline stay proposals.
              </p>

              {!hasHousemates && (
                <Button
                  blueBorder
                  onClick={openModal('add_housemate')}
                  className='f6 ml3'
                >
                  Add a housemate
                </Button>
              )}

              <div className='flex flex-row flex-wrap'>
                {housemates.map(housemate => (
                  <PersonAvatar
                    key={housemate.id}
                    person={housemate}
                    transition={store.transition}
                  />
                ))}
              </div>
            </div>

            <MatchQueryModal modalsList={MODALS} store={store} />
          </div>
        )
      }}
    </HousematesStoreProvider>
  )
}

const getStatusTitle = person => {
  const statuses = {
    pending: 'Invite pending',
    pendingOwner: 'Manager - invite pending'
  }

  const status = get(person, 'status')

  return get(statuses, status, '')
}

const getActionLinks = (person, transition) => {
  const optionsMap = {
    default: [
      {
        label: 'Remove housemate',
        onClick: () => transition({ type: 'REMOVE', id: person.id })
      },
      {
        label: 'Invite to be place manager',
        onClick: () => {
          transition({ type: 'INVITE_OWNER', email: person.email })
        }
      }
    ],
    pending: [
      {
        label: 'Cancel housemate invite',
        onClick: () => {
          transition({ type: 'CANCEL_INVITE', id: person.id })
        }
      }
    ],
    pendingOwner: [
      {
        label: 'Cancel housemate manager invite',
        onClick: () => {
          transition({ type: 'CANCEL_OWNER', id: person.id })
        }
      }
    ]
  }

  const status = get(person, 'status')

  return get(optionsMap, status, optionsMap.default)
}

const PersonAvatar = ({ person, transition }) => {
  const picture = getUserPicture(person)
  const name = getFullName(person) || person.email
  const status = getStatusTitle(person)
  const links = getActionLinks(person, transition)

  return (
    <PersonContainer className='w4 flex flex-column items-center'>
      <Picture src={picture} alt={name + "'s picture"} />

      <p className='f6 black-80 tc w4 mt2 mb0'>{name}</p>
      {status && <p className='f6 black-50 tc w4 mt2 mb0'>{status}</p>}

      {get(person, 'status') !== 'owner' &&
        links.map(link => (
          <a
            key={link.label}
            href='#'
            className='f7 tc w4 mt2 mb0 link dim'
            style={{ color: colors.blue }}
            onClick={e => {
              e.preventDefault()
              link.onClick()
            }}
          >
            {link.label}
          </a>
        ))}
    </PersonContainer>
  )
}

const PersonContainer = styled.div`
  width: 30%;
  margin-right: 10%;
  margin-bottom: 3rem;
`

const Picture = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 60px;
  box-shadow: inset 0px 0px 3px ${colors.blackRGBA(0.2)};
`

export default Housemates
