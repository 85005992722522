import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import colors from 'styles/colors'
import format from 'date-fns/format'

const DealDateCard = ({ item: date, flightDeal }) => {
  console.log('-date--', date)

  const getGFUrl = (item, date) => {
    const fromIata = item.fromAirport.iataCode
    const toIata = item.toAirport.iataCode

    const depart = date.departDate
    const retrn = date.returnDate

    const dealType =
      item.dealType === 'first_class' && item.isBusinessDeal
        ? 'business'
        : item.dealType === 'first_class'
          ? 'first_class'
          : 'economy'
    return `https://amdc.staycircles.com/gflk?origin=${fromIata}&destination=${toIata}&startDate=${depart}&endDate=${retrn}&dealType=${dealType}`
  }

  const handlePress = () => {
    const url = getGFUrl(flightDeal, date)
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  return (
    <DealDateItemContainer onClick={handlePress}>
      <DealDateItem>
        <DealDateColumn>
          <Text left alpha={0.6} size={14} medium>
            Departure
          </Text>
          <Text left size={14} medium>
            {format(new Date(date.departDate), 'EEE, MMM dd')}
          </Text>
        </DealDateColumn>
        <DealDateColumn>
          <Text left alpha={0.6} size={14} medium>
            Return
          </Text>
          <Text left size={14} medium>
            {format(new Date(date.returnDate), 'EEE, MMM dd')}
          </Text>
        </DealDateColumn>
        <DealDateColumn>
          {date.price && (
            <Text right bold alpha={0.8} size={14}>
              {String(date.price || '').split(' ')[0]}
            </Text>
          )}
          <Text right alpha={0.6} size={14} medium>
            {date.airline || 'round trip'}
          </Text>
        </DealDateColumn>
      </DealDateItem>
    </DealDateItemContainer>
  )
}

const DealDateItemContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background: #ffffff;
  border: 0.5px solid #2d89f8;
  box-sizing: border-box;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  margin-bottom: 10px;
  width: 100%;
  padding: 15px 20px;
  cursor: pointer;
`

const DealDateItem = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const DealDateColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const Text = styled.p`
  font-weight: ${props => (props.bold ? 700 : props.medium ? 500 : 400)};
  text-align: ${props =>
    props.left ? 'left' : props.right ? 'right' : 'center'};
  font-size: ${props => (props.big ? 36 : props.size || 16)}px;
  color: ${props =>
    props.alpha
      ? colors.blackRGBA(props.alpha)
      : props.color
        ? props.color
        : colors.black};
  margin: ${props => props.mv || '4px'} ${props => props.mh || '0px'};
`

DealDateCard.propTypes = {
  item: PropTypes.object,
  flightDeal: PropTypes.object
}

export default DealDateCard
