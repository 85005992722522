import React, { Fragment } from 'react'

import styled from 'styled-components'

import colors from 'styles/colors'

import { withPaymentsStore } from '../store/payments'

import Modal from 'components/Modal'
import Icon from 'components/Icon'
import { SelectInput } from 'components/Select'
import ButtonPill from 'components/ButtonPill'
import { ErrorText } from 'components/Input'
import { IconButton } from 'components/Counter'

import CreatePaymentForm from 'app/screens/settings/screens/prime/components/_CreatePaymentForm'

class SubscriptionModal extends React.PureComponent {
  state = {
    createPayment: false
  }

  toggleModal = e => {
    e && e.preventDefault()
    this.setState(({ createPayment }) => ({ createPayment: !createPayment }))
  }

  render () {
    const { createPayment } = this.state
    const {
      loading,
      methods,
      error,
      submitMethod,
      selectPayment,
      card
    } = this.props
    const hasPayment = !!methods[0]

    return (
      <Fragment>
        {hasPayment && (
          <td colSpan={2}>
            <SelectPayment
              loading={loading}
              items={methods}
              selected={card && card.last4}
              onSelect={async (method, callback) => {
                await selectPayment(method)
                callback && callback()
              }}
            />
          </td>
        )}

        <td colSpan={2}>
          <AddPaymentButton openModal={this.toggleModal} loading={loading} />
        </td>

        <ModalWrapper
          show={createPayment}
          error={error}
          loading={loading}
          closeModal={this.toggleModal}
        >
          <CreatePaymentForm
            onSubmit={async (card, callback) => {
              const ok = await submitMethod(card)
              if (ok) this.toggleModal()
              callback && callback()
            }}
            renderSubmitButton={() => <PayButton loading={loading} />}
          />
        </ModalWrapper>
      </Fragment>
    )
  }
}

const ModalWrapper = ({ show, closeModal, error, children }) => (
  <Modal
    isOpen={show}
    onRequestClose={closeModal}
    contentLabel='Subscribe Membership'
  >
    <div>
      <header className='tc bb b--black-10 pb3 mh3 mt0 mb4'>
        <Logo />
      </header>

      <div className='mv4 tc'>{children}</div>

      <footer className='tc bt b--black-10 pt4 mh3'>
        <p className='tc black-50 mt0 mb4'>
          StayCircles is a 256-bit SSL encrypted platform. You're safe.
        </p>

        <a href='https://stripe.com/' target='_blank'>
          <Icon name='stripe' height={24} />
        </a>
      </footer>

      {error && (
        <ErrorText center style={{ marginBottom: '2rem' }}>
          {error}
        </ErrorText>
      )}
    </div>
  </Modal>
)

const Logo = styled(Icon).attrs({ name: 'sc-logo' })`
  color: #333;
  height: 24px;
`

const SelectPayment = ({ items, loading, onSelect, selected }) => {
  return (
    <div
      className={`w-100 flex flex-column mv3 ${loading ? '0-60' : ''}`}
      style={{ cursor: loading ? 'progress' : 'default' }}
    >
      <SelectInput
        disabled={loading}
        style={{ cursor: loading ? 'progress' : 'pointer' }}
        id='payment-method'
        name='payment-method'
        placeholder='Choose Payment Method'
        defaultValue={selected}
        onChange={e => {
          const value = e.target.value
          if (!value) return
          const paymentMethod = items.find(i => i.last4 === value)

          if (paymentMethod) {
            onSelect(paymentMethod)
          }
        }}
        renderOptions={() =>
          items.map(item => (
            <option key={item.last4} value={item.last4}>
              {item.brand} ••••
              {item.last4}
            </option>
          ))
        }
      />
    </div>
  )
}

const AddPaymentButton = ({ loading, openModal }) => (
  <a
    href='#'
    disabled={loading}
    className='link pv3 db dim'
    style={{ color: colors.blue, cursor: loading ? 'progress' : 'pointer' }}
    onClick={openModal}
  >
    <IconButton name='plus' disabled={loading} />
    <span className='ml2'>Add new payment method</span>
  </a>
)

const PayButton = ({ onClick, loading, success }) => (
  <ButtonPill
    background={colors.blue}
    hoverBackground={colors.darkBlue}
    color={colors.white}
    border={colors.whiteRGBA(0.1)}
    disabled={loading}
    type={onClick ? 'button' : 'submit'}
    onClick={onClick}
  >
    {success ? 'Sweet' : 'Add Payment Method'}
  </ButtonPill>
)

export default withPaymentsStore(SubscriptionModal)
