import React, { useEffect } from 'react'
import styled from 'styled-components'
import InfiniteList from 'components/InfiniteList'
import LoaderAM from '../../components/LoaderAM'
import { Container } from '../../components/CommonStyles'
import TripCard from '../../components/TripCard'
import { DealData } from '../store'
import DealFilters from '../../components/DealFilters'

const DealsContainer = styled(Container)`
  height: auto;
  margin-top: 40px;
  width: 100%;
`

const DealsListWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  width: 100%;
`

const DealsList = () => {
  const dealData = DealData.useContainer()
  const {
    deals,
    loading,
    pageDetails,
    loadMore,
    refreshDeals,
    dealFilters,
    handleDealFiltersChange
  } = dealData
  const total = pageDetails && +pageDetails.totalDealsCount
  const perPage = pageDetails && +pageDetails.perPageCount
  const currentPage = pageDetails && +pageDetails.page
  const totalPages = Math.ceil(Math.max(total, perPage) / perPage)
  const hasMore = totalPages > currentPage

  useEffect(() => {
    // Refresh deals after 5 mins if needed
    refreshDeals()
  }, [])

  const loadMoreDeals = () => {
    if (totalPages <= currentPage) {
      return
    }
    loadMore()
  }

  const getItemHeight = index => {
    if (deals[index].active && !deals[index].visibleOnAm) {
      return 400
    }
    return 520
  }
  // show big loader on first render, after that default small loader handled by infinitelist
  return (
    <DealsContainer>
      <DealFilters
        dealFilters={dealFilters}
        handleDealFiltersChange={handleDealFiltersChange}
      />
      {loading && !currentPage ? (
        <LoaderAM centered size={32} />
      ) : (
        deals && (
          <DealsListWrapper>
            <InfiniteList
              height={deals.length === 0 ? 0 : 1000}
              width='100%'
              itemHeight={getItemHeight}
              renderItem={({ item }) => <TripCard key={item?.id} {...item} />}
              data={deals}
              loadMore={loadMoreDeals}
              loading={loading}
              hasMore={hasMore}
              renderEmptyComponent={EmptyComponent}
            />
          </DealsListWrapper>
        )
      )}
    </DealsContainer>
  )
}

export default DealsList

const EmptyComponent = () => <p>No Deals found.</p>
