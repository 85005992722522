import React from 'react'

import { Form } from 'react-final-form'

import { composeValidators, required } from 'utils/field-validators'

import * as utils from 'utils/credit-card'

import Input, { ErrorText } from 'components/Input'

const CreatePaymentForm = ({ onSubmit, renderSubmitButton }) => (
  <Form
    onSubmit={(values, _, callback) => {
      const exp = utils.parseExpiryDate(values.expiration)

      const card = {
        number: values.number,
        expMonth: exp.month,
        expYear: exp.year,
        cvc: values.cvc,
        addressZip: values.addressZip
      }

      onSubmit(card, callback)
    }}
    render={({ handleSubmit, submitting, invalid, submitError }) => (
      <form
        onSubmit={handleSubmit}
        className='flex flex-column items-center justify-center center w-100 ph4-ns mb0'
      >
        <p className='f4 black-50 mv3'>Add credit card</p>

        <div className='w-100 flex flex-row-ns flex-column justify-center'>
          <div className='w-60-ns w-100 mr3'>
            <Input
              name='number'
              label='Card number'
              disabled={submitting}
              format={utils.formatCreditCardNumber}
              validate={composeValidators(required, utils.validateCardNumber)}
            />
          </div>

          <div className='w-30-ns w-100'>
            <Input
              name='cvc'
              label='CVC'
              disabled={submitting}
              format={utils.formatCVC}
              validate={composeValidators(required, utils.validateCVC)}
            />
          </div>
        </div>

        <div
          className='w-100 flex flex-row-ns flex-column justify-center'
          style={{ marginBottom: '1rem' }}
        >
          <div className='w-60-ns w-100 mr3'>
            <Input
              name='expiration'
              label='Expiration date (MM/YY)'
              placeholder='MM/YY'
              disabled={submitting}
              format={utils.formatExpiryDate}
              validate={composeValidators(required, utils.validateExpiryDate)}
            />
          </div>

          <div className='w-30-ns w-100'>
            <Input
              name='addressZip'
              label='ZIP code'
              disabled={submitting}
              validate={composeValidators(required, utils.validateZipCode)}
            />
          </div>
        </div>

        {invalid &&
          submitError && (
          <ErrorText center style={{ marginBottom: '2rem' }}>
            {submitError}
          </ErrorText>
        )}

        {renderSubmitButton(handleSubmit)}
      </form>
    )}
  />
)

export default CreatePaymentForm
