import api from 'utils/api'

export const acceptStay = id => {
  return api.put(`/stays/${id}/approve`)
}

export const declineStay = id => {
  return api.put(`/stays/${id}/deny`)
}

export const cancelStay = id => {
  return api.put(`/stays/${id}/cancel`)
}

export const handleProposal = ({ id, action }) => {
  switch (action) {
    case 'accept':
      return acceptStay(id)
    case 'decline':
      return declineStay(id)
    case 'cancel':
      return cancelStay(id)
  }
}
