import React from 'react'

import { get, capitalize } from 'lodash'
import formatDate from 'utils/formatDate'

import ReactModal from 'react-modal'

import styled from 'styled-components'

import colors from 'styles/colors'
import zIndex from 'styles/zIndex'

import { getFullName } from 'utils/profile-info'
import root from 'utils/windowOrGlobal'

import { View, Text, TouchableOpacity } from 'components/Native'
import Icon from 'components/Icon'

ReactModal.setAppElement('#___gatsby')

class TransactionDetails extends React.Component {
  get data () {
    return get(this.props, 'log', {})
  }

  get stayDetails () {
    return get(this.data, 'stayDetails') || {}
  }

  showStayDetails = () => {
    const { navigate, location, handleClose } = this.props

    handleClose()
    navigate(location.pathname + '?convo=' + this.stayDetails.conversationId)
  }

  getTransactionName () {
    const type = get(this.stayDetails, 'stayType')

    if (type) {
      return type === 'business' ? 'Business Stay' : 'Friendship Stay'
    } else {
      return 'Subscription'
    }
  }

  getPayAmount = () => {
    const amount = get(this.data, 'amount')

    if (amount) {
      return '$' + amount
    }
  }

  getTransactionAgainst () {
    const against = get(this.data, 'transactionAgainst')
    const type = get(this.data, 'transactionType')

    if (against) {
      const label = {
        payout: 'Payout to',
        refund: 'Payout to',
        payment: 'Paid with',
        subscription_payment: 'Paid with',
        default: 'Paid with'
      }[type || 'default']

      const value = against.replace('xxxxxxxxxx', 'x-')

      return { label, value }
    }
  }

  getProcessedDate = () => {
    const processedAt = get(this.data, 'processedAt')

    if (processedAt) {
      const label = 'Processed Date'
      const value = formatDate(processedAt, 'MM/dd/yy')

      return { label, value }
    }
  }

  getPayStatus = () => {
    const status = get(this.data, 'status')
    const type = get(this.data, 'transactionType')

    if (status) {
      const label = 'Status'
      const value = capitalize(status)

      let info

      if (['payout', 'refund'].includes(type)) {
        const infoPress = () => {
          root.alert(
            'Info\n' +
              'Please allow 3-5 business days for this payout to appear in your bank account.'
          )
        }

        info = (
          <TouchableOpacity onPress={infoPress} className='outline-0'>
            <Icon
              name='info'
              className='ml2'
              style={{ width: 24 }}
              color={colors.blackRGBA(0.5)}
            />
          </TouchableOpacity>
        )
      }

      return { label, value, info }
    }
  }

  getCause = () => {
    const cause = get(this.data, 'cause')
    if (cause) {
      const label = 'Cause'
      const value = cause
      return { label, value }
    }
  }

  getPrimePlan = () => {
    const primePlan = get(this.data, 'invoice.lineItems[0].plan.name')
    if (primePlan) {
      const label = 'Plan'
      const value = primePlan
      return { label, value, column: true }
    }
  }

  getInvoiceNumber = () => {
    const invoiceNumber = get(this.data, 'invoice.invoiceNumber')
    if (invoiceNumber) {
      const label = 'Invoice #'
      const value = invoiceNumber
      return { label, value }
    }
  }

  renderRows () {
    const rows = [
      this.getTransactionAgainst(),
      this.getProcessedDate(),
      this.getPayStatus(),
      this.getCause(),
      this.getInvoiceNumber(),
      this.getPrimePlan()
    ]

    return rows.map(row => {
      if (!row) return

      const Wrapper = row.column ? Column : Row

      return (
        <Wrapper key={row.label}>
          <LabelText>{row.label}: </LabelText>
          <ValueText>{row.value}</ValueText>

          {row.info}
        </Wrapper>
      )
    })
  }

  getPlaceName () {
    return get(this.stayDetails, 'place.name')
  }

  getTravelerInfo () {
    const traveler = get(this.stayDetails, 'traveller')
    const name = getFullName(traveler)
    const email = traveler.email
    return { name, email }
  }

  getHostInfo () {
    const host = get(this.stayDetails, 'host')
    const name = getFullName(host)
    const email = host.email
    return { name, email }
  }

  render () {
    return (
      <ModalComponent
        style={{
          overlay: {
            backgroundColor: colors.whiteRGBA(0.4),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            zIndex: zIndex.modal
          }
        }}
        isOpen
        contentLabel='TransactionDetails Details'
        onRequestClose={this.props.handleClose}
      >
        <Panel>
          <Header>
            <TitleText>{this.getTransactionName()}</TitleText>
            <AmountText>{this.getPayAmount()}</AmountText>
          </Header>

          <Line />

          {this.renderRows()}

          {!!this.getPlaceName() && (
            <React.Fragment>
              <Line />
              <Row>
                <LabelText>Stay at: </LabelText>
                <ValueText>{this.getPlaceName()}</ValueText>
              </Row>

              <Column>
                <LabelText>
                  {get(this.stayDetails, 'stayType') === 'business'
                    ? 'Business '
                    : ''}
                  Traveler
                </LabelText>
                <ValueText>
                  {this.getTravelerInfo().name} <br />
                  {this.getTravelerInfo().email}
                </ValueText>
              </Column>

              <Column>
                <LabelText>Host</LabelText>
                <ValueText>
                  {this.getHostInfo().name} <br />
                  {this.getHostInfo().email}
                </ValueText>
              </Column>
            </React.Fragment>
          )}
        </Panel>

        {this.stayDetails.conversationId && (
          <div
            className='b--black-10 bb pa3 flex flex-row items-center bg-white pointer o-90 glow'
            onClick={this.showStayDetails}
          >
            <LabelText className='link dim' style={{ color: colors.blue }}>
              See Stay Details <span className='f3'>→</span>
            </LabelText>
          </div>
        )}
      </ModalComponent>
    )
  }
}

const ModalComponent = styled(ReactModal).attrs({
  className: 'scrollbar'
})`
  width: 600px;
  min-height: 100%;
  overflow-y: auto;
  padding: 4rem;
  background-color: ${colors.white};
  box-shadow: -4px 0 8px 0 rgba(0, 0, 0, 0.1);

  &:focus {
    outline: none;
  }

  @media (max-width: 767px) {
    width: 96%;
  }
`

const Panel = styled(View)`
  background-color: ${colors.white};
  elevation: 1;
  padding: 16px;
  border-radius: 6px;
  margin-bottom: 24px;
`

const Row = styled(View)`
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 16px;
`

const Column = styled(View)`
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 16px;
`

const Header = styled(Row)`
  justify-content: space-between;
`

const Line = styled(View)`
  width: 100%;
  height: 1px;
  background-color: ${colors.blackRGBA(0.1)};
  margin-bottom: 16px;
`

const TitleText = styled(Text)`
  font-size: 32px;
  font-weight: 600;
  color: ${colors.blackRGBA(0.9)};
`

const AmountText = styled(Text)`
  font-size: 32px;
  font-weight: bold;
  color: ${colors.blackRGBA(0.9)};
`

const LabelText = styled(Text)`
  font-size: 18px;
  font-weight: bold;
  color: ${colors.blackRGBA(0.8)};
  margin-right: 6px;
`

const ValueText = styled(Text)`
  display: flex;
  flex-direction: column;
  font-size: 18px;
  color: ${colors.blackRGBA(0.8)};
  line-height: 1.5;
`

export default TransactionDetails
