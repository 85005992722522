import Payment from 'payment'

import getAssetUrl from 'utils/getAssetUrl'

function clearNumber (value = '') {
  return value.replace(/\D+/g, '')
}

/**
 * Validating
 */

export function validateCardNumber (value) {
  const valid = Payment.fns.validateCardNumber(value)

  return valid ? undefined : 'Enter a valid credit card number'
}

export function validateCVC (value, allValues = {}) {
  let issuer

  if (allValues.number) {
    issuer = Payment.fns.cardType(allValues.number)
  }

  const valid = Payment.fns.validateCardCVC(value, issuer)

  return valid ? undefined : 'Enter a valid card code'
}

export function validateExpiryDate (value) {
  const valid = Payment.fns.validateCardExpiry(value)

  return valid ? undefined : 'Enter a valid expiration date'
}

export function validateZipCode (value) {
  const valid = value.length >= 5 && value.length <= 10

  return valid ? undefined : 'Enter a valid zip code'
}

/**
 * Formatting
 */

export function formatCreditCardNumber (value) {
  if (!value) {
    return value
  }

  const issuer = Payment.fns.cardType(value)
  const clearValue = clearNumber(value)
  let nextValue

  switch (issuer) {
    case 'amex':
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        10
      )} ${clearValue.slice(10, 15)}`
      break
    case 'dinersclub':
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        10
      )} ${clearValue.slice(10, 14)}`
      break
    default:
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        8
      )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 19)}`
      break
  }

  return nextValue.trim()
}

export function formatCVC (value, prevValue, allValues = {}) {
  const clearValue = clearNumber(value)
  let maxLength = 4

  if (allValues.number) {
    const issuer = Payment.fns.cardType(allValues.number)
    maxLength = issuer === 'amex' ? 4 : 3
  }

  return clearValue.slice(0, maxLength)
}

export function formatExpiryDate (value) {
  const clearValue = clearNumber(value)

  if (clearValue.length === 1 && clearValue < 10 && clearValue > 1) {
    return `0${clearValue}`
  }

  if (clearValue.length === 2 && clearValue > 12) {
    return `${clearValue.slice(0, 1)}`
  }

  if (clearValue.length >= 3) {
    return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`
  }

  return clearValue
}

/**
 * Parsing
 */

export function parseExpiryDate (value) {
  return Payment.fns.cardExpiryVal(value)
}

export function parseCardIssuer (cardNumber) {
  const issuers = {
    visa: 'Visa',
    mastercard: 'MasterCard',
    amex: 'American Express',
    discover: 'Discover',
    dinersclub: 'Diners Club',
    jcb: 'JCB'
  }

  const issuerSlug = Payment.fns.cardType(cardNumber)

  return issuers[issuerSlug]
}

/**
 * Logos
 */

export function getIssuerLogo (issuer) {
  const logo = {
    Visa: getAssetUrl('assets/card-issuers/visa'),
    MasterCard: getAssetUrl('assets/card-issuers/mastercard'),
    'American Express': getAssetUrl('assets/card-issuers/amex'),
    Discover: getAssetUrl('assets/card-issuers/discover'),
    'Diners Club': getAssetUrl('assets/card-issuers/dinersclub'),
    JCB: getAssetUrl('assets/card-issuers/jcb')
  }[issuer]

  return logo
}
