import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { isYourself } from './utils'
import { getUserPicture } from 'utils/profile-info'

import MultiPin from './MultiPin'

class MultiUserHousehold extends PureComponent {
  constructor (props) {
    super(props)

    this.state = {
      open: props.active
    }
  }

  isFirstDegree () {
    const { houseMembers, currentUser } = this.props
    const stayPal = houseMembers[0]

    return (
      (stayPal.friend && stayPal.isAddressPublic) ||
      isYourself(stayPal, currentUser)
    )
  }

  getStayPalStyle = i => {
    let transform = ''
    let opacity = 0
    let zIndex = 1

    if (this.state.open) {
      const d = 31 + 6 * this.props.houseMembers.length
      const baseAngle = 360 / this.props.houseMembers.length
      const ang = baseAngle * (i + 1)
      transform = `rotate(${ang}deg) translate(${d}px) rotate(-${ang}deg)`
      opacity = 1
      zIndex = 100000
    }

    return { opacity, transform, zIndex }
  }

  componentWillReceiveProps ({ active }) {
    if (this.props.active && !active) {
      this.setState({ open: false })
    }
  }

  render () {
    const {
      props: { houseMembers },
      state: { open }
    } = this
    const avatars = houseMembers.slice(0, 8)

    return (
      <div className='MultiUserHousehold'>
        <div
          className={cx('MultiUserHousehold--house pointer', {
            open,
            blurred: open && !this.isFirstDegree()
          })}
          onClick={e => {
            this.setState({ open: !open })
          }}
        >
          {avatars.map((stayPal, i) => (
            <div
              key={i}
              className={`avatar avatar-${avatars.length}-${i}`}
              style={{ backgroundImage: `url("${getUserPicture(stayPal)}")` }}
            />
          ))}
        </div>
        <div className='MultiUserHousehold--container'>
          {houseMembers.map((stayPal, i) => {
            return (
              <div
                key={i}
                className='MultiUserHousehold--pin'
                style={this.getStayPalStyle(i)}
              >
                <MultiPin stayPal={stayPal} open={open} {...this.props} />
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}

MultiUserHousehold.propTypes = {
  houseMembers: PropTypes.array.isRequired
}

export default MultiUserHousehold
