import api from 'utils/api'

export const getTransactions = ({ page, transactionsType: type } = {}) => {
  const params = {
    page,
    type,
    rpp: 10
  }

  return api.get('/transaction_logs', params)
}
