import get from 'lodash/get'

import { withStateMachine, StateMachineContainer } from 'utils/StateMachine'
import logger from 'utils/logger'

import * as api from './api'

const statechart = {
  initial: 'idle',
  states: {
    idle: {
      on: {
        LOAD: { loading: { actions: ['loadPreferences'] } },
        SET_PREFERENCE: { loading: { actions: ['setPreference'] } }
      },
      onEntry: 'hideLoading'
    },
    loading: {
      onEntry: 'showLoading',
      on: {
        LOAD_SUCCESS: 'idle',
        LOAD_FAILURE: 'error'
      }
    },
    error: {
      on: { RECOVER: 'idle' },
      onEntry: 'showError'
    }
  }
}

export const defaultPreferences = {
  emailNotifications: {},
  amPreferences: {}
}

class NotificationPreferenceContainer extends StateMachineContainer {
  request = async (apiName, data) => {
    logger.captureBreadcrumb({
      message: 'NotificationPreferenceContainer.' + apiName,
      category: 'preferences',
      data
    })

    try {
      const [error, response] = await api[apiName](data)

      if (error) {
        throw new Error(error)
      } else {
        return response
      }
    } catch (error) {
      logger.captureException(error)

      this.transition({ type: 'LOAD_FAILURE', error })
      return false
    }
  }

  loadPreferences = async () => {
    const data = await this.request('getPreferences')
    // console.log('-----CUrrent preferences are-----', data)
    if (data) {
      this.transition({
        type: 'LOAD_SUCCESS',
        preferences: get(data, 'preferences', defaultPreferences)
      })

      return true
    }
  }

  setPreference = async ({ item }) => {
    const data = await this.request('setPreference', item)

    if (data) {
      this.transition({
        type: 'LOAD_SUCCESS',
        preferences: get(data, 'preferences', defaultPreferences)
      })

      return true
    }
  }
}

const withNotificationPreferenceStore = withStateMachine(
  null,
  new NotificationPreferenceContainer({ statechart })
)

export default withNotificationPreferenceStore
