import React, { PureComponent } from 'react'

import { getUserPicture, getDefaultPicture } from 'utils/profile-info'
import { isYourself } from './utils'

import styled from 'styled-components'

import colors from 'styles/colors'

const defaultSPPicture = getDefaultPicture({ friend: true })
const defaultSP2Picture = getDefaultPicture({ friend: false })

const AvatarWithBorder = styled.img.attrs({
  alt: props => (props.friend ? 'Friend' : 'Friend-of-Friend'),
  src: props =>
    props.src ? props.src : props.friend ? defaultSPPicture : defaultSP2Picture
})`
  border: 3px solid ${props => (props.friend ? colors.red : colors.blue)};
  background: white;
`

class ClusterMarker extends PureComponent {
  constructor (props) {
    super(props)

    if (props.markerIcon) {
      this.points = this.getPointsWithAvatar(props.cluster.points)
    }
  }

  get currentUser () {
    return this.props.cluster.points[0].child.props.currentUser
  }

  getPointsWithAvatar = points => {
    let poinstWithAvatar = []

    for (let i = 0; i < points.length; i++) {
      if (this.userHasPicture(points[i])) {
        poinstWithAvatar.push(points[i])
        if (poinstWithAvatar.length === 4) break
      }
    }

    return poinstWithAvatar
  }

  componentWillReceiveProps (nextProps) {
    if (
      nextProps.markerIcon &&
      nextProps.cluster.points !== this.props.cluster.points
    ) {
      this.points = this.getPointsWithAvatar(nextProps.cluster.points)
    }
  }

  getHouseOwner = point => point.child.props.item.houseMembers[0]

  getAvatar = point => getUserPicture(this.getHouseOwner(point))

  userHasPicture = point => {
    const user = this.getHouseOwner(point)
    return (
      user.cloudinaryImgUrl ||
      user.picture ||
      user.profilePicture ||
      user.pictureUrl
    )
  }

  getCluster = () => {
    if (this.points.length > 1) {
      const remainingHousemates =
        this.props.cluster.points.length - this.points.length

      return (
        <div className='sc-cluster-nobg-ctr'>
          {this.points.map((point, i) => {
            const sp = this.getHouseOwner(point)
            const { id, friend } = sp

            return (
              point && (
                <AvatarWithBorder
                  key={id + i}
                  src={this.getAvatar(point)}
                  friend={friend || isYourself(sp, this.currentUser)}
                />
              )
            )
          })}

          {remainingHousemates > 0 && (
            <div className='sc-remaining-housemates-length'>
              +{remainingHousemates}
            </div>
          )}
        </div>
      )
    }

    let sp =
      this.points.length === 1 ? this.points[0] : this.props.cluster.points[0]
    const spOwner = this.getHouseOwner(sp)

    const remainingHousemates = this.props.cluster.points.filter(hm => {
      const hmOwner = this.getHouseOwner(hm)

      return hmOwner.id !== spOwner.id
    })

    const hasStayPals = remainingHousemates.some(hm => {
      const hmOwner = this.getHouseOwner(hm)
      return hmOwner.friend
    })

    const hasStayPals2 = remainingHousemates.some(hm => {
      const hmOwner = this.getHouseOwner(hm)
      return !hmOwner.friend
    })

    const hasOneHousemate = remainingHousemates.length === 1

    const hasMultipleHousematesOfOneKind =
      remainingHousemates.length > 1 && (!hasStayPals || !hasStayPals2)

    const isStayPal = spOwner.friend || isYourself(spOwner, this.currentUser)

    if (this.points.length === 1) {
      return (
        <div className='sc-multi-user-cluster-ctr'>
          <div className='sc-multi-user-cluster'>
            <AvatarWithBorder src={this.getAvatar(sp)} friend={isStayPal} />

            <div
              className={`sc-remaining-housemates ${
                hasOneHousemate ? 'sc-remaining-housemates-alone' : ''
              }`}
            >
              {hasStayPals2 && <AvatarWithBorder friend={false} />}

              {hasStayPals && <AvatarWithBorder friend />}

              {hasMultipleHousematesOfOneKind && (
                <AvatarWithBorder friend={hasStayPals} />
              )}

              {!hasOneHousemate && (
                <div className='sc-remaining-housemates-length'>
                  +{remainingHousemates.length}
                </div>
              )}
            </div>
          </div>
        </div>
      )
    }

    return (
      <div className='sc-multi-user-cluster-ctr'>
        <div className='sc-multi-user-cluster'>
          <div className='sc-remaining-housemates sc-remaining-housemates-full'>
            <AvatarWithBorder friend={isStayPal} />

            <AvatarWithBorder friend={hasStayPals} />

            {!hasOneHousemate && (
              <div className='sc-remaining-housemates-length'>
                +{remainingHousemates.length}
              </div>
            )}
          </div>
        </div>
      </div>
    )
    /* eslint-enable */
  }

  render () {
    const { markerIcon, text, onClick, unfocused } = this.props

    const child = markerIcon ? this.getCluster() : text

    return (
      <div
        className={`sc-cluster ${unfocused ? 'sc-cluster--unfocused' : ''}`}
        onClick={onClick}
      >
        {child}
      </div>
    )
  }
}

export default ClusterMarker
