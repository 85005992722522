import api from 'utils/api'

export const acceptSPRequest = id => {
  return api.put(`/connections/${id}`, {
    status: 'approve'
  })
}

export const sendSPRequest = emails => {
  if (!Array.isArray(emails)) {
    emails = [emails]
  }

  return api.post('/connections', { emails })
}

export const getStayPals = params => {
  return api.get('/stay_pals/sp_and_sp2', {
    ...params,
    rpp: 30
  })
}
