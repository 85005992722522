import React, { PureComponent } from 'react'
import Cluster from 'points-cluster'

import uniqBy from 'lodash/uniqBy'

// Animations are disabled for performance reasons for the moment.
// import TransitionGroupMarker from './utils/TransitionGroupMarker.js'
// import animatedMarkerFactory from './utils/animatedMarkerFactory.js'

import GMap from './GMap'

const MapWithClusteringFactory = (ClusteredMarker, SpiderMarker) => {
  // const AnimatedClusteredMarker = animatedMarkerFactory(ClusteredMarker)
  // const AnimatedSpiderMarker = animatedMarkerFactory(SpiderMarker)

  class MapWithClustering extends PureComponent {
    constructor (props) {
      super(props)

      this.SPIDERYFY_ZOOM_LEVEL = 20 // 15
      this.getClusteredMarkers = this.getClusteredMarkers
      this.getLatLngs = this.getLatLngs
      this.getClusterer = this.getClusterer
      this.getSpiderMarkers = this.getSpiderMarkers.bind(this)
      this.getBBoxforMap = this.getBBoxforMap.bind(this)
      this.getClusters = this.getClusters.bind(this)
      this._distanceToMouse = this._distanceToMouse.bind(this)
      this.onGoogleApiLoaded = this.onGoogleApiLoaded.bind(this)

      this.state = {
        latLngToClusterMap: this.getLatLngToClusterMap(props),
        previousLatLngToClusterMap: null
      }
    }

    componentWillReceiveProps (nextProps) {
      this.setState({
        latLngToClusterMap: this.getLatLngToClusterMap(nextProps),
        latLngToPoints: this.getLatLngToPoints(nextProps),
        previousLatLngToClusterMap: this.state.latLngToClusterMap
      })
    }

    isPointPartOfAnySpider (latLng) {
      if (!this.state.map) {
        return false
      }

      if (this.state.map.getZoom() < this.SPIDERYFY_ZOOM_LEVEL) {
        return false
      }

      let latLngList = this.state.latLngToPoints
        ? this.state.latLngToPoints[JSON.stringify(latLng)]
        : null

      if (!latLngList) {
        // If Null -> Hasn't been added to cluster.
        return true
      }

      return latLngList.length > 1
    }

    getClusteredMarkers (clusters) {
      if (!clusters) return null

      return clusters.map((cluster, index) => {
        // There is a bug currently where for some reason there will be clusters with only one child
        // or even clusters of duplicated markers (same place, id, latLng, etc).
        // Identify them as one marker here.
        const onlyOnePoint =
          cluster.numPoints === 1 ||
          uniqBy(cluster.points, 'child.key').length === 1

        if (onlyOnePoint) {
          return React.cloneElement(cluster.points[0].child, {
            latLngToClusterMap: this.state.latLngToClusterMap,
            previousLatLngToClusterMap: this.state.previousLatLngToClusterMap,
            map: this.state.map
          })

          // let childProps = cluster.points[0].child.props

          // Disable transition for now
          // return (
          //   <TransitionGroupMarker
          //     key={`${index}-${childProps.id}`}
          //     {...childProps}
          //   >
          //     {React.cloneElement(cluster.points[0].child, {
          //       latLngToClusterMap: this.state.latLngToClusterMap,
          //       previousLatLngToClusterMap: this.state
          //         .previousLatLngToClusterMap,
          //       map: this.state.map
          //     })}
          //   </TransitionGroupMarker>
          // )
        } else {
          // # Cluster Icon
          const markersLatLng = cluster.points.map(item => ({
            lat: item.lat,
            lng: item.lng
          }))

          let { onClick, ...clusterProps } = this.props.clusterProps

          return (
            <ClusteredMarker
              key={'cluster' + index}
              lat={cluster.y}
              lng={cluster.x}
              markerIcon={cluster.numPoints}
              map={this.state.map}
              cluster={cluster}
              isCluster
              latLngToClusterMap={this.state.latLngToClusterMap}
              previousLatLngToClusterMap={this.state.previousLatLngToClusterMap}
              onClick={() => {
                onClick && onClick(markersLatLng)
              }}
              {...clusterProps}
            />
          )

          // Disable transition for now
          // return (
          //   <TransitionGroupMarker
          //     key={'cluster' + index}
          //     lat={cluster.y}
          //     lng={cluster.x}
          //   >
          //     <AnimatedClusteredMarker
          //       key={'cluster' + index}
          //       lat={cluster.y}
          //       lng={cluster.x}
          //       markerIcon={cluster.numPoints}
          //       map={this.state.map}
          //       cluster={cluster}
          //       isCluster
          //       latLngToClusterMap={this.state.latLngToClusterMap}
          //       previousLatLngToClusterMap={
          //         this.state.previousLatLngToClusterMap
          //       }
          //       onClick={() => {
          //         onClick && onClick(markersLatLng)
          //       }}
          //       {...clusterProps}
          //     />
          //   </TransitionGroupMarker>
          // )
        }
      })
    }

    getSpiderMarkers () {
      let bboxMap = this.getBBoxforMap()

      return Object.keys(this.state.latLngToPoints).map((latLng, index) => {
        let pointsList = this.state.latLngToPoints[latLng]

        if (pointsList.length === 1) {
          const Marker = () => <div>{pointsList[0].child}</div>

          return <Marker key={`${index}-${pointsList[0].child.props.id}`} />

          // Disable transition for now
          // return (
          //   <TransitionGroupMarker
          //     key={`${index}-${pointsList[0].child.props.id}`}
          //     map={this.state.map}
          //     {...pointsList[0].child.props}
          //   >
          //     {pointsList[0].child}
          //   </TransitionGroupMarker>
          // )
        } else {
          let latLngsObject = JSON.parse(latLng)

          return (
            <SpiderMarker
              key={'spider' + pointsList[0].child.props.id}
              lat={latLngsObject.lat}
              lng={latLngsObject.lng}
              markerIcon={pointsList.length}
              map={this.state.map}
              bboxMap={bboxMap}
              cluster={pointsList}
            />
          )

          // Disable transition for now
          // return (
          //   <TransitionGroupMarker
          //     key={'spider' + pointsList[0].child.props.id}
          //     lat={latLngsObject.lat}
          //     lng={latLngsObject.lng}
          //   >
          //     <AnimatedSpiderMarker
          //       key={'spider' + pointsList[0].child.props.id}
          //       lat={latLngsObject.lat}
          //       lng={latLngsObject.lng}
          //       markerIcon={pointsList.length}
          //       map={this.state.map}
          //       bboxMap={bboxMap}
          //       cluster={pointsList}
          //     />
          //   </TransitionGroupMarker>
          // )
        }
      })
    }

    getBBoxforMap () {
      let element = this.state.map.getDiv()
      return element.getBoundingClientRect()
    }

    getLatLngToClusterMap (props) {
      let clusters = this.getClusters(props)

      if (!clusters) return null

      let latLngToClusterMap = {}

      clusters.map((cluster, index) => {
        cluster.points.map(point => {
          let latLng = {
            lat: point.lat,
            lng: point.lng
          }

          latLngToClusterMap[JSON.stringify(latLng)] = cluster
        })
      })

      return latLngToClusterMap
    }

    getLatLngToPoints (props) {
      let clusters = this.getClusters(props)

      if (!clusters) return null

      let latLngToPoints = {}

      clusters.map((cluster, index) => {
        cluster.points.map(point => {
          let latLng = {
            lat: point.lat,
            lng: point.lng
          }

          if (latLngToPoints[JSON.stringify(latLng)]) {
            latLngToPoints[JSON.stringify(latLng)].push(point)
          } else {
            latLngToPoints[JSON.stringify(latLng)] = [point]
          }
        })
      })

      return latLngToPoints
    }

    getLatLngs (children) {
      if (!children) return null

      return children.map(child => {
        return {
          lat: child.props.lat,
          lng: child.props.lng,
          child: child
        }
      })
    }

    getClusterer (latLngs) {
      if (!latLngs) return null

      if (this.props.clusterOptions) {
        return Cluster(latLngs, this.props.clusterOptions)
      }

      return Cluster(latLngs)
    }

    getBounds (map) {
      if (!map) {
        return {
          bounds: {
            nw: { lat: 85, lng: -180 },
            se: { lat: -85, lng: 180 }
          },
          zoom: 2
        }
      }

      let boundsJson = map.getBounds().toJSON()

      return {
        bounds: {
          nw: {
            lat: boundsJson.north,
            lng: boundsJson.west
          },
          se: {
            lat: boundsJson.south,
            lng: boundsJson.east
          }
        },
        zoom: map.getZoom()
      }
    }

    getClusters (props) {
      if (!this.state || !this.state.map) {
        return null
      }

      let bounds = this.getBounds(this.state.map)
      let filteredMarkers = this.getMarkersIncludedInCluster(props)
      let clusterer = this.getClusterer(this.getLatLngs(filteredMarkers))

      if (!clusterer || !this.state.map) {
        return null
      } else {
        return clusterer(this.props.locationProps || bounds)
      }
    }

    getFlattenChildren (props) {
      return props.children
    }

    getMarkersNotInCluster (props) {
      if (!this.getFlattenChildren(props)) {
        return []
      }

      let notIncluster = this.getFlattenChildren(props).filter(child => {
        if (!child) return true

        return child.props.shouldFilterFromCluster
      })

      return notIncluster
    }

    getMarkersIncludedInCluster (props) {
      if (!this.getFlattenChildren(props)) {
        return []
      }

      return this.getFlattenChildren(props).filter(child => {
        if (!child) return false

        return !child.props.shouldFilterFromCluster
      })
    }

    _distanceToMouse (markerPos, mousePos, markerProps) {
      let x = markerPos.x

      // because of marker non symmetric,
      // we transform it central point to measure distance from marker circle center
      // you can change distance function to any other distance measure
      let y = markerPos.y

      // and i want that hover probability on markers with text ==== 'A' be greater than others
      // so i tweak distance function (for example it's more likely to me that user click on 'A' marker)
      // another way is to decrease distance for 'A' marker
      // this is really visible on small zoom values or if there are a lot of markers on the map
      let distanceKoef = 1
      if (markerProps.isSelected || markerProps.shouldFilterFromCluster) {
        distanceKoef = 0.9
      }

      // it's just a simple example, you can tweak distance function as you wish
      return (
        distanceKoef *
        Math.sqrt(
          (x - mousePos.x) * (x - mousePos.x) +
            (y - mousePos.y) * (y - mousePos.y)
        )
      )
    }

    onGoogleApiLoaded ({ map, maps }) {
      // console.log(map, 'HA')
      this.setState({ map: map })

      if (this.props.onGoogleApiLoaded) {
        this.props.onGoogleApiLoaded({ map, maps })
      }
    }

    render () {
      let {
        enableClustering, // eslint-disable-line no-unused-vars
        ...other
      } = this.props

      let { map } = this.state
      let clusteredMarkers = []

      if (map) {
        let zoomLevel = map.getZoom()
        let clusters = this.getClusters(this.props)

        if (zoomLevel >= this.SPIDERYFY_ZOOM_LEVEL) {
          clusteredMarkers = this.getSpiderMarkers()
        } else {
          clusteredMarkers = clusters ? this.getClusteredMarkers(clusters) : []
        }
      }

      return (
        <GMap
          {...other}
          distanceToMouse={this._distanceToMouse}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={this.onGoogleApiLoaded}
        >
          {clusteredMarkers}
          {this.getMarkersNotInCluster(this.props)}
        </GMap>
      )
    }
  }

  return MapWithClustering
}

export default MapWithClusteringFactory
