import React from 'react'

import get from 'lodash/get'

import { Link, Router } from '@reach/router'

import parseQueryString from 'utils/parse-querystring'

import styled from 'styled-components'

import colors from 'styles/colors'

import Dropdown from 'components/SimpleDropdown'

import StaysList from './components/StaysList'
import StayDetails from './components/StayDetails'

const isUpcomingActive = ({ location }) => {
  const isCurrent = !location.pathname.match(/past/)
  return isCurrent ? { active: 1 } : null
}

const isPastActive = ({ location }) => {
  const isCurrent = location.pathname.match(/past/)
  return isCurrent ? { active: 1 } : null
}

class StaysIndex extends React.Component {
  state = {
    filter: 'All'
  }

  changeFilter = filter => {
    this.setState({ filter })
  }

  render () {
    const { filter } = this.state

    const { location, navigate } = this.props

    const queryString = get(location, 'search', '')
    const query = parseQueryString(queryString)

    return (
      <Container>
        <div className='flex flex-column items-center justify-center center w-100 mw7 mb4'>
          <h1 className='w-100 tc mb4'>Stays</h1>

          <div className='w-100 bb b--black-10 pb3 mb4 tc'>
            <TabButton to='./' getProps={isUpcomingActive}>
              Upcoming
            </TabButton>
            <TabButton to='past' getProps={isPastActive}>
              Past
            </TabButton>
          </div>

          <Dropdown
            className='mb4'
            renderButton={onClick => (
              <LinkButton
                className='tl flex flex-row items-center'
                onClick={onClick}
                title='Filter stays'
              >
                Showing: {filter} Stays <Caret />
              </LinkButton>
            )}
            renderMenu={(_, toggle) => (
              <ul>
                <li>
                  <RawButton
                    onClick={() => {
                      this.changeFilter('All')
                      toggle()
                    }}
                  >
                    <span>All Stays</span>
                  </RawButton>
                </li>
                <li>
                  <RawButton
                    onClick={() => {
                      this.changeFilter('Hosting')
                      toggle()
                    }}
                  >
                    <span>Hosting Stays</span>
                  </RawButton>
                </li>
                <li>
                  <RawButton
                    onClick={() => {
                      this.changeFilter('Traveling')
                      toggle()
                    }}
                  >
                    <span>Traveling Stays</span>
                  </RawButton>
                </li>
              </ul>
            )}
          />

          <Router className='w-100'>
            <StaysList
              path='upcoming/*'
              default
              type='upcoming'
              filter={filter}
            />
            <StaysList path='past/*' type='past' filter={filter} />
          </Router>

          {query.stay && (
            <StayDetails
              itemId={query.stay}
              convoId={query.convo}
              navigate={navigate}
              location={location}
            />
          )}
        </div>
      </Container>
    )
  }
}

const RawButton = props => (
  <button
    type='button'
    className='bg-transparent pa0 ma0 bn pointer link black-30 hover-black-60 flex flex-row items-center'
    {...props}
  />
)

const Container = styled.div`
  background-color: ${colors.ice};
  padding-top: 4rem;
  width: 100%;
  overflow: auto;
`

export const TabButton = styled(Link)`
  border: none;
  background-color: transparent;
  text-decoration: none;
  cursor: pointer;
  border-bottom: 2px solid transparent;
  padding: 0.5rem 0.25rem;
  margin-right: 2rem;
  font-size: 1rem;
  color: ${colors.blackRGBA(0.5)};
  transition: all 0.25s ease;

  &[active] {
    color: ${colors.blackRGBA(0.75)};
    border-bottom-color: ${colors.darkBlue};
  }

  &:hover,
  &:focus {
    color: ${colors.blackRGBA(0.75)};
    opacity: 0.9;
    border-bottom-color: ${colors.darkBlue};
  }

  &:active {
    opacity: 0.7;
  }
`

export const LinkButton = styled.button.attrs({ type: 'button' })`
  border: none;
  background-color: transparent;
  color: ${colors.blackRGBA(0.6)};
  cursor: pointer;
  padding: 0 0 2px;
  border-bottom: 2px solid transparent;
  transition: all 0.25s ease;

  &:hover,
  &:focus {
    opacity: 0.9;
    border-bottom-color: ${colors.blackRGBA(0.6)};
  }

  &:active {
    opacity: 0.7;
  }
`

export const Caret = styled.div`
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid ${colors.blackRGBA(0.6)};
  margin-left: 0.25rem;
`

export default StaysIndex
