import React from 'react'

import Modal from 'components/Modal'

class DeleteSuccesModal extends React.Component {
  render () {
    const { show } = this.props

    return (
      <Modal isOpen={show} contentLabel='DeleteSuccesModal'>
        <div className='center flex flex-column items-center w-100 w-90-ns mb0'>
          <h3 className='mt0 mb3'>We're sorry to see you go :(</h3>

          <div className='mv4'>
            <p className='tc mb4'>
              Hopefully our paths will cross again in the future
            </p>
            <p className='tc'>You're being logged out now...</p>
          </div>
        </div>
      </Modal>
    )
  }
}

export default DeleteSuccesModal
