import React from 'react'

import styled from 'styled-components'

import { RenderOnAction } from 'utils/StateMachine'

import colors from 'styles/colors'

import RequestsSent from './RequestsSent'
import RequestsIgnored from './RequestsIgnored'

import withStore from './store'

const Wrapper = styled.div`
  position: relative;

  padding-bottom: 16px;
  margin-bottom: 16px;

  text-align: center;

  @media (min-width: 992px) {
    padding-bottom: 32px;
    margin-bottom: 32px;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 10%;
    right: 10%;

    width: 80%;
    height: 1px;
    background-color: ${colors.silver};
  }
`

const GoogleWrapper = styled.div`
  padding: 0 16px;

  font-size: 18px;
  font-weight: 300;
  text-align: center;

  background-color: #fff;
`

class RequestSentIgnored extends React.Component {
  loadIgnored = () => {
    this.props.machineStore.transition({
      type: 'LOAD_IGNORED'
    })
  }

  loadSent = () => {
    this.props.machineStore.transition({
      type: 'LOAD_SENT'
    })
  }

  toggleModal = modalToShow => {
    this.props.machineStore.transition({
      type: 'TOGGLE_MODAL',
      modalToShow
    })
  }

  sendRequest = item => {
    this.props.machineStore.transition({
      type: 'SEND_REQUEST',
      ...item
    })
  }

  render () {
    const {
      modalToShow,
      ignored,
      sent,
      hasMore,
      inProgress
    } = this.props.machineStore.state

    return (
      <RenderOnAction value='showLoading'>
        {loading => (
          <Wrapper>
            <GoogleWrapper className='center w-90-ns flex justify-around'>
              <button
                className='bg-white blue bn pointer'
                onClick={() => this.toggleModal('sent')}
              >
                See invites sent
              </button>
              <button
                className='bg-white blue bn pointer'
                onClick={() => this.toggleModal('ignored')}
              >
                See invites ignored
              </button>
            </GoogleWrapper>

            <RequestsSent
              {...{
                show: modalToShow === 'sent',
                toggle: this.toggleModal,
                contacts: sent,
                loading,
                inProgress,
                hasMore,
                loadMore: this.loadSent
              }}
            />

            <RequestsIgnored
              {...{
                show: modalToShow === 'ignored',
                toggle: this.toggleModal,
                sendRequest: this.sendRequest,
                contacts: ignored,
                loading,
                inProgress,
                hasMore,
                loadMore: this.loadIgnored
              }}
            />
          </Wrapper>
        )}
      </RenderOnAction>
    )
  }
}

export default withStore(RequestSentIgnored)
