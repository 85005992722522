import React from 'react'

import { Form } from 'react-final-form'
import { FORM_ERROR } from 'final-form'

import navigateBack from 'utils/navigateBack'

import colors from 'styles/colors'

import Modal from 'components/Modal'
import Input, { ErrorText } from 'components/Input'
import ButtonPill from 'components/ButtonPill'

import StayPalsList from './StayPalsList'

const onSubmitEmails = ({ emails }, form, store, callb) => {
  const callback = err => {
    if (err) {
      callb({ [FORM_ERROR]: err })
      return
    }

    setTimeout(() => {
      form.reset()
    }, 3000)
    callb()
  }

  store.transition({ type: 'SEND_INVITES', emails, callback })
}

const AddHousemateModal = ({ store }) => {
  const loading = store.matchesActions('showLoading')
  return (
    <Modal isOpen onRequestClose={navigateBack} contentLabel='Add housemate(s)'>
      <div className='center flex flex-column items-center w-100 w-90-ns mv3'>
        <h3 className='f3 bb b--black-05 mb4 pb4 w-100'>Add housemate(s)</h3>

        <div className='w-100 mb4'>
          <h4 className='fw5 mb2 black-90'>Add housemate by email</h4>

          <Form
            onSubmit={(values, form, callback) => {
              onSubmitEmails(values, form, store, callback)
            }}
            render={({
              handleSubmit,
              submitting,
              invalid,
              submitError,
              submitSucceeded,
              dirtySinceLastSubmit
            }) => (
              <form onSubmit={handleSubmit}>
                <div className='flex flex-row justify-between items-center mb2 flex-wrap flex-nowrap-ns'>
                  <div style={{ flex: 1 }} className='pr3 mb2 mb0-ns'>
                    <Input
                      name='emails'
                      label='Separate emails by comma'
                      style={{ marginBottom: 0 }}
                    />
                  </div>

                  <div className='tc'>
                    <ButtonPill
                      type='submit'
                      children={submitting ? 'Sending...' : 'Send'}
                      background={colors.blue}
                      hoverBackground={colors.darkBlue}
                      color={colors.white}
                      border={colors.whiteRGBA(0.1)}
                      disabled={submitting}
                    />
                  </div>
                </div>

                {!dirtySinceLastSubmit && invalid && submitError && (
                  <ErrorText>{submitError}</ErrorText>
                )}

                {submitSucceeded && (
                  <p className='f5 green b lh-copy'>Invites sent!</p>
                )}
              </form>
            )}
          />
        </div>

        <div className='w-100 mb4'>
          <h4 className='fw5 mb2 black-90'>
            Add friends or friends-of-friends as housemate
          </h4>

          <StayPalsList store={store} />
        </div>

        <div className='w-100 flex flex-row justify-center bt b--black-05 mt3 pt4'>
          <ButtonPill onClick={navigateBack} disabled={loading}>
            Close
          </ButtonPill>
        </div>
      </div>
    </Modal>
  )
}

export default AddHousemateModal
