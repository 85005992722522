import React from 'react'

import VirtualList from 'react-tiny-virtual-list'
import styled from 'styled-components'

import get from 'lodash/get'

import ButtonPill from 'components/ButtonPill'
import RoomItem from 'app/components/RoomItem'
import StayPalsCarousel, { Avatar } from '../components/StayPalsCarousel'
import Price from '../components/Price'
import Payment from '../components/Payment'
import Success from '../components/Success'

import { getPlaceAddress } from 'utils/profile-info'
import colors from 'styles/colors'
import Error from '../components/Error'
import MediaQuery from 'react-responsive'

class FinalizeStay extends React.Component {
  state = {
    roomRowWidth: 0
  }

  get data () {
    return get(this.props, `proposalStore.state.data`, {})
  }

  selectRoom = async room => {
    let rooms = this.data.rooms || []

    if (this.isSelected(room.id)) {
      rooms = rooms.filter(({ id }) => id !== room.id)
    } else {
      rooms = rooms.concat(room)
    }

    if (this.activeState !== 'chooseRoom') {
      await this.props.proposalStore.transition({
        type: 'RESET',
        from: rooms.length > 0 ? 'inputPrice' : 'chooseRoom'
      })
    }

    this.props.proposalStore.transition({ type: 'NEXT', data: { rooms } })
  }

  selectPayment = async card => {
    const {
      activeState,
      props: { proposalStore }
    } = this

    if (activeState !== 'selectPayment') {
      await proposalStore.transition({
        type: 'RESET',
        from: 'selectPayment',
        next: () => proposalStore.transition({ type: 'NEXT', data: { card } })
      })
    } else {
      await proposalStore.transition({ type: 'NEXT', data: { card } })
    }
  }

  isSelected (rid) {
    return (this.data.rooms || []).some(({ id }) => id === rid)
  }

  setRoomRow = roomRow => {
    if (!roomRow) return

    this.roomRow = roomRow

    this.setState({
      roomRowWidth: roomRow.clientWidth
    })
  }

  renderRoomItem = ({ index, style }) => {
    if (!this.roomRow) {
      return null
    }

    const rooms = get(this.data, 'host.place.rooms', [])
    const room = rooms[index]

    return (
      <div key={index} style={style}>
        <RoomItem
          {...room}
          onPress={() => this.selectRoom(room)}
          selected={this.isSelected(room.id)}
        />
      </div>
    )
  }

  submitProposal = () => {
    this.props.proposalStore.transition({
      type: 'SEND'
    })
  }

  get housemates () {
    const host = get(this.data, 'host', {})
    const houseMembers = get(this.data, 'host.place.houseMembers', [])

    return houseMembers.filter(({ id }) => id !== host.id)
  }

  get activeState () {
    return get(this.props, 'proposalStore.state.machineState.value')
  }

  getRowStatus = rowKey => {
    // Use STEPS to get the rowKey
    const { steps } = this.props
    const activeIndex = steps.findIndex(({ key }) => key === this.activeState)
    const rowIndex = steps.findIndex(({ key }) => key === rowKey)

    if (rowIndex === activeIndex) {
      return 'active'
    }

    return rowIndex > activeIndex ? 'disabled' : 'done'
  }

  render () {
    const { roomRowWidth } = this.state
    const { data, housemates, activeState } = this
    const { user, proposalStore, bookingType } = this.props
    const host = get(data, 'host') || {} // incase it is null
    const place = get(host, 'place') || {}
    const address = getPlaceAddress(place)
    const totalRooms = get(data, 'host.place.rooms.length', 0)
    const businessBooking = bookingType === 'business'

    return (
      <Container className='black-70 w-100'>
        <h3 className='f3-l f4 fw4 mt4-l mt3 mb3 mb4-l tc'>Stay Proposal</h3>

        <table className='w-100 finalizeTable'>
          <tbody>
            <Row status='done'>
              <MediaQuery maxWidth={991}>
                <th>Place Details</th>
              </MediaQuery>
              <MediaQuery minWidth={992}>
                <th>
                  Place
                  <br />
                  Details
                </th>
              </MediaQuery>

              <td>
                <div
                  className='flex flex-column tc items-center'
                  style={{ minWidth: '5rem' }}
                >
                  <p className='mb2'>Host</p>
                  <Avatar size='4.7rem' {...host} />
                  <p className='mb0 mt2 f5'>{host.name}</p>
                  <p className={`mb0 f6 ${host.friend ? 'red' : 'blue'}`}>
                    Friends
                    {!host.friend && '-of-Friends'}
                  </p>
                </div>
              </td>
              {host.mutualStayPals && host.mutualStayPals.length > 0 && (
                <td className='v-mid'>
                  <p className='mb3 tc'>Mutual Friends</p>
                  <div className='center' style={{ width: 170 }}>
                    <StayPalsCarousel stayPals={host.mutualStayPals} />
                  </div>
                </td>
              )}
              <td className='placeFinalRow'>
                <p className='mb1'>{place.name}</p>
                <p className='mb2 black-50 lh-title'>
                  {address.firstLine}
                  <br />
                  {address.secondLine}
                </p>
                <p className='black-50 f6 lh-title mb0'>
                  Cats: {place.cat ? 'Yes' : 'No'}
                  <br />
                  Dogs: {place.dog ? 'Yes' : 'No'}
                  <br />
                  Children: {place.children ? 'Yes' : 'No'}
                  <br />
                </p>
              </td>
              {housemates.length > 0 && (
                <td>
                  <p className='mb2 tc'>Place Housemates</p>
                  <div className='center' style={{ width: 170 }}>
                    <StayPalsCarousel stayPals={housemates} />
                  </div>
                </td>
              )}
            </Row>

            <Row status={this.getRowStatus('chooseRoom')}>
              <th>
                Choose
                <br />
                Room
              </th>
              <td colSpan={4} className='overflow-hidden'>
                <div
                  className='w-100 mt3'
                  style={{ height: 0 }}
                  ref={this.setRoomRow}
                />

                <VirtualList
                  className='scrollbar'
                  scrollDirection='horizontal'
                  width={roomRowWidth}
                  height={186}
                  itemCount={totalRooms}
                  itemSize={350}
                  renderItem={this.renderRoomItem}
                />
              </td>
            </Row>

            {businessBooking && (
              <React.Fragment>
                <Row status={this.getRowStatus('inputPrice')}>
                  <th>
                    Input
                    <br />
                    Comparable
                    <br />
                    rate <span className='i fw3'>(USD)</span>
                  </th>
                  <td colSpan={4}>
                    <Price
                      data={data}
                      user={user}
                      proposalStore={proposalStore}
                      activeState={activeState}
                    />
                  </td>
                </Row>

                <Row status={this.getRowStatus('selectPayment')}>
                  <MediaQuery maxWidth={991}>
                    <th>Select Payment Method</th>
                  </MediaQuery>
                  <MediaQuery minWidth={992}>
                    <th>
                      Select
                      <br />
                      Payment
                      <br />
                      Method
                    </th>
                  </MediaQuery>
                  <Payment
                    selectPayment={this.selectPayment}
                    card={data.card}
                  />
                </Row>
              </React.Fragment>
            )}

            <Row status={this.getRowStatus('reviewProposal')}>
              <MediaQuery maxWidth={991}>
                <th>Send Stay Proposal</th>
              </MediaQuery>
              <MediaQuery minWidth={992}>
                <th>
                  Send
                  <br />
                  Stay
                  <br />
                  Proposal
                </th>
              </MediaQuery>
              <td colSpan={4}>
                <ButtonPill
                  background={colors.blue}
                  className='w-100 w-auto-ns'
                  hoverBackground={colors.darkBlue}
                  color={colors.white}
                  border={colors.whiteRGBA(0.1)}
                  disabled={this.getRowStatus('reviewProposal') !== 'active'}
                  type='submit'
                  onClick={this.submitProposal}
                >
                  Send
                </ButtonPill>
              </td>
            </Row>
          </tbody>
        </table>

        <Success
          show={activeState === 'success'}
          proposalStore={proposalStore}
        />
        <Error show={activeState === 'error'} proposalStore={proposalStore} />
      </Container>
    )
  }
}

const Container = styled.div`
  width: 100%;

  th {
    width: 10rem;
    padding-left: 3rem;
  }

  td:last-child {
    padding-right: 3rem;
  }
  @media (max-width: 991px) {
    .finalizeTable {
      display: flex;
      flex: 1;
      flex-direction: column;
      tbody {
        display: flex;
        flex: 1;
        flex-direction: column;
        tr {
          display: flex;
          flex-direction: column;
          flex: 1;
          th {
            width: 100%;
            text-align: center;
            padding-left: 0;
            padding-right: 0;
          }
          td:last-child {
            padding-right: 1rem;
          }
        }
      }
      .placeFinalRow {
        display: flex;
        flex-direction: row;
        flex: 1;
        flex-wrap: nowrap;
        padding-right: 1rem;
        gap: 4px;
      }
    }
  }
`

const Row = styled.tr`
  background: ${props =>
    props.status === 'active'
      ? colors.activeYellow
      : props.status === 'done'
        ? colors.ice
        : colors.white};

  ${props =>
    props.status === 'disabled' &&
    `pointer-events: none;
  opacity: 0.5;`};
`

export default FinalizeStay
