import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const TYPES = {
  ECONOMY: 'ECONOMY',
  BUSINESS: 'BUSINESS',
  FIRST_CLASS: 'FIRST_CLASS'
}

const TYPE_MAPPING = {
  [TYPES.ECONOMY]: {
    LIGHT_COLOR: '#EBFAF2',
    DARK_COLOR: '#43CA82',
    CHILDREN: 'Economy'
  },
  [TYPES.BUSINESS]: {
    LIGHT_COLOR: '#FEF0ED',
    DARK_COLOR: '#F66D59',
    CHILDREN: 'Business'
  },
  [TYPES.FIRST_CLASS]: {
    LIGHT_COLOR: '#E6EFFC',
    DARK_COLOR: '#1861E6',
    CHILDREN: 'First Class'
  }
}

const TripType = ({ dealType = '' }) => {
  const typeConfig = TYPE_MAPPING[dealType.toUpperCase()] || {}

  return (
    <Type
      lightColor={typeConfig.LIGHT_COLOR}
      darkColor={typeConfig.DARK_COLOR}
      children={typeConfig.CHILDREN}
    />
  )
}

const Type = styled.div`
  position: absolute;
  top: 30px;
  right: 40px;
  outline: none;
  padding: 2px 6px;
  font-weight: bold;
  font-size: 12px;
  border-radius: 999px;
  background: ${props => props.lightColor};
  color: ${props => props.darkColor};
`

TripType.propTypes = {
  dealType: PropTypes.string.isRequired
}

export default TripType
