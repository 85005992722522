import { get, findKey, omit } from 'lodash'

import { navigate } from 'gatsby'

import { getCurrentUser } from 'stores/auth'

import * as routes from 'config/routes'
import { withStateMachine, StateMachineContainer } from 'utils/StateMachine'
import logger from 'utils/logger'

import { getReadableError } from 'utils/api'
import * as api from './api'

const statechart = {
  initial: 'idle',
  states: {
    idle: {
      on: {
        DESTROY_LOOKUP: { loading: { actions: ['destroyLookup'] } },
        DESTROY_ACCOUNT: { loading: { actions: ['destroyAccount'] } }
      },
      onEntry: 'hideLoading'
    },
    loading: {
      onEntry: 'showLoading',
      on: {
        SUCCESS: 'idle',
        FAILURE: 'idle',
        LOAD: { loading: { actions: ['getStayPals'] } }
      }
    }
  }
}

export const defaultDestroyModal = {
  show: false,
  reason: ''
}

class DeleteAccountContainer extends StateMachineContainer {
  requestQueue = []

  request = async (apiName, data, event = {}) => {
    logger.captureBreadcrumb({
      message: 'MaintenanceContainer.' + apiName,
      category: 'stayPals',
      data
    })

    try {
      const [error, response] = await api[apiName](data)

      if (error) {
        throw new Error(error)
      } else {
        return response
      }
    } catch (error) {
      logger.captureException(error)

      let err = getReadableError(error)

      if (apiName === 'destroyAccount' && /invalid/i.test(err)) {
        err = 'Invalid Password'
      }

      this.transition({
        ...event,
        type: 'FAILURE',
        error: {
          [apiName]: err
        }
      })
      return false
    }
  }

  destroyLookup = async event => {
    const data = await this.request('destroyLookup')

    if (data) {
      const lookup = omit(get(data, 'count', {}), ['ownedPlaces'])
      const reason = findKey(lookup, v => v > 0)

      this.transition({
        ...event,
        type: 'SUCCESS',
        destroyModal: {
          show: true,
          canDelete: !!reason,
          reason
        }
      })

      return true
    }
  }

  destroyAccount = async ({ password, passwordSet, ...event }) => {
    const data = await this.request('destroyLookup')
    if (data) {
      const lookup = omit(get(data, 'count', {}), ['ownedPlaces'])
      const reason = findKey(lookup, v => v > 0)
      if (reason) {
        this.transition({
          ...event,
          type: 'SUCCESS',
          destroyModal: {
            show: true,
            canDelete: !!reason,
            reason
          }
        })
        return true
      }
      const ok = await this.request(
        'destroyAccount',
        {
          email: getCurrentUser().email,
          password,
          passwordSet
        },
        event
      )

      if (ok) {
        this.transition({
          ...event,
          type: 'SUCCESS',
          destroyModal: {
            destroyed: true
          }
        })

        setTimeout(() => {
          navigate(`${routes.LOGOUT}`)
        }, 3000)

        return true
      }
    }
  }
}

const withDeleteAccountStore = withStateMachine(
  null,
  new DeleteAccountContainer({ statechart })
)

export default withDeleteAccountStore
