/*
Used in separate file to deal with messy subscriptions
*/
import React, { Fragment, useEffect, useState } from 'react'

import get from 'lodash/get'
import root from 'utils/windowOrGlobal'

import styled from 'styled-components'

import colors from 'styles/colors'

import Button from 'components/Button'
import { View } from 'components/Native'
import getAssetUrl from 'utils/getAssetUrl'

import Icon from 'components/Icon'
import { FullPageLoader } from '../../../../../../components/Loader'
import { Text, TouchableOpacity } from '../../../../../../components/Native'

const perc = { width: 240, height: 240 }
const red100 = getAssetUrl('assets/mobile/red100', perc)
const red75 = getAssetUrl('assets/mobile/red50', perc)
const blue100 = getAssetUrl('assets/mobile/blue100', perc)
const blue75 = getAssetUrl('assets/mobile/blue50', perc)

const planOrder = { free: 1, prime: 2, prime_first: 3, primeFirst: 3 }
const firstClass = planObj => {
  if (/first/i.test(get(planObj, 'planDetails.subscriptionClass', ''))) {
    return true
  }
  if (/jetsetter/i.test(get(planObj, 'planDetails.name', ''))) {
    return true
  }
  return false
}

const getCurrentPlan = planObj => {
  if (firstClass(planObj)) {
    return 'primeFirst'
  } else if (planObj) {
    return 'prime'
  }
  return 'free'
}

const daysIntervalToMonths = singlePackage => {
  const durationType = 'month'
  let duration
  let monthlyAmount
  if (!singlePackage) {
    return { durationType, duration, monthlyAmount }
  }
  if (singlePackage.interval === 'day') {
    switch (singlePackage.intervalCount) {
      case 1:
        duration = 1
        monthlyAmount = singlePackage.amount
        break

      case 2:
        duration = 6
        monthlyAmount = singlePackage.amount / 6
        break

      case 3:
        duration = 12
        monthlyAmount = singlePackage.amount / 12
        break

      default:
        break
    }
  }
  if (singlePackage.interval === 'month') {
    duration = +singlePackage.intervalCount
    monthlyAmount = +singlePackage.amount / +singlePackage.intervalCount
  }
  if (singlePackage.interval === 'year') {
    duration = +singlePackage.intervalCount * 12
    monthlyAmount = +singlePackage.amount / (+singlePackage.intervalCount * 12)
  }
  return { durationType, duration, monthlyAmount }
}

const inAppPurchaseInstructionIOS = `You had brought your subscription through iOS inapp purchase. To manage or cancel your subscription, open the Settings app on your iOS device and follow these steps:
    1. Tap [your name] > iTunes & App Store.
    2. Tap your Apple ID at the top of the screen, then tap View Apple ID. You might need to sign in with your Apple ID.
    3. Scroll to Subscriptions, then tap it. Find and select your StayCircles Subscription
    `

const SubscriptionPlansRenderer = ({
  store,
  canManage,
  getMobileManageInstructions,
  openStripeManageSubscription,
  subscription,
  stripeSubscriptionPlans,
  openStripeCheckoutPlan
}) => {
  const [packages, setPackages] = useState(null)
  const [activePlan, setActivePlan] = useState(null)
  const [loading] = useState(false)
  const storeLoading = store.matchesActions('showLoading')
  const [activePlanPackage, setActivePlanPackage] = useState(null)

  const getActiveCurrentPlanReadble = () => {
    const { subscription } = store.state
    const currentPlan = getCurrentPlan(subscription)
    const currentPlanPackage = get(subscription, 'planDetails', null)
    if (!currentPlan || !currentPlanPackage) {
      return ''
    }
    const activePlan = Object.values(packages[currentPlan]).find(
      singlePackage => singlePackage.id === currentPlanPackage.id
    )
    return `billed every \n ${activePlan?.duration ||
      '-'}-${activePlan?.durationType || '-'}${
      activePlan?.duration > 1 ? 's' : ''
    }`
  }

  const togglePlanPackages = plan => {
    if (plan === 'free') {
      return
    }
    setActivePlan(activePlan === plan ? null : plan)
    setActivePlanPackage(
      activePlan === plan ? null : Object.values(packages[plan])[1]?.id
    )
  }

  const isAppleIAP = () => {
    return ['apple_in_app_purchase', 'revenuecat_ios'].includes(
      get(store, 'state.subscription.paymentType', '')
    )
  }

  const subscribe = async (upgrading, subscribePlan, store) => {
    const { subscription } = store.state
    const currentPlan = getCurrentPlan(subscription)

    // Check if user brought through inapp and show instruction to manage inapp
    if (subscription && isAppleIAP()) {
      root.alert(inAppPurchaseInstructionIOS)
      return
    }

    if (subscribePlan === 'free' && currentPlan !== 'free') {
      // check if already cancelled
      if (subscription && subscription.autoRenewAt && !subscription.expired) {
        openStripeManageSubscription(subscribePlan, subscription)
        return
      } else {
        root.alert(
          `You have already cancelled your subscription. Your subscription is valid until ${subscription.expiredAt}`
        )
        return
      }
    }
    if (activePlan !== subscribePlan || !activePlanPackage) {
      togglePlanPackages(subscribePlan)
      return
    }

    const activePlanPackageDetails = Object.values(
      packages[subscribePlan]
    ).find(singlePackage => singlePackage.id === activePlanPackage)
    if (!activePlanPackageDetails) {
      return
    }
    if (currentPlan !== 'free') {
      if (subscription && subscription.autoRenewAt && !subscription.expired) {
        openStripeManageSubscription(
          subscribePlan,
          subscription,
          activePlanPackageDetails
        )
      } else {
        openStripeManageSubscription(
          subscribePlan,
          subscription,
          activePlanPackageDetails,
          true
        )
      }
    } else {
      openStripeCheckoutPlan(activePlan, activePlanPackage)
    }
  }

  const currentOrUpgrade = (store, plan) => {
    const loading = store.matchesActions('showLoading')
    if (loading) {
      return null
    }

    const { subscription } = store.state
    const currentPlan = getCurrentPlan(subscription)
    const currentPlanPackage = get(subscription, 'planDetails.id', null)
    //   const showReenable = plan !== 'free' && !get(subscription, 'autoRenewAt')
    if (currentPlan === plan && plan === 'free') {
      return null
    }

    if (currentPlan === plan && currentPlanPackage === activePlanPackage) {
      return (
        <Fragment>
          <SubscribeButton disabled>YOUR SUBSCRIPTION</SubscribeButton>
        </Fragment>
      )
    } else {
      const upgrading = planOrder[currentPlan] < planOrder[plan]
      return (
        <SubscribeButton
          onClick={() => {
            subscribe(upgrading, plan, store)
          }}
        >
          {plan === 'free' ? 'Change Plan' : 'Purchase'}
          {plan !== 'free' && (
            <>
              <Divider name='button-divider' />
              <Logo name='stripe-lean' />
            </>
          )}
        </SubscribeButton>
      )
    }
  }

  const renderPackages = plan => {
    return (
      <Row noPadding>
        <PlanPackagesWrapper>
          {packages &&
            packages[plan] &&
            Object.values(packages[plan]).map((singlePackage, index) => {
              const isActivePlanPackage =
                singlePackage.id === activePlanPackage && plan === activePlan
              return (
                <PlanPackage
                  key={singlePackage.id || index}
                  active={isActivePlanPackage}
                  onClick={() => {
                    setActivePlan(plan)
                    setActivePlanPackage(singlePackage.id)
                  }}
                >
                  {singlePackage.mostPopular && (
                    <PlanPackagePopularTabWrapper>
                      <PlanPackagePopularTab>
                        <PlanPackagePopularTabText>
                          {'MOST POPULAR'}
                        </PlanPackagePopularTabText>
                      </PlanPackagePopularTab>
                    </PlanPackagePopularTabWrapper>
                  )}
                  <PlanPackageIntervalText active={isActivePlanPackage}>
                    {singlePackage.duration} {singlePackage.durationType}
                  </PlanPackageIntervalText>
                  <PlanPackagePriceText active={isActivePlanPackage}>
                    {singlePackage.price}
                  </PlanPackagePriceText>

                  <PlanPackageSaveNoteText active={isActivePlanPackage}>
                    {singlePackage.saveText || <>&nbsp;</>}
                  </PlanPackageSaveNoteText>
                </PlanPackage>
              )
            })}
        </PlanPackagesWrapper>
      </Row>
    )
  }
  const setActivePlanAndPackage = (_activePlan, _activePlanPackage) => {
    setActivePlan(_activePlan)
    setActivePlanPackage(_activePlanPackage)
  }

  useEffect(() => {
    if (subscription) {
      const currentPlan = getCurrentPlan(subscription)
      const currentPlanPackage = get(subscription, 'planDetails.id', null)
      setActivePlanAndPackage(currentPlan, currentPlanPackage)
    }
  }, [subscription])

  useEffect(() => {
    if (stripeSubscriptionPlans && stripeSubscriptionPlans.length > 0) {
      const prime = {}
      const primeFirst = {}
      let rawPlans = stripeSubscriptionPlans
      rawPlans = rawPlans.sort(function (a, b) {
        return +b.amount - +a.amount
      })
      rawPlans.map(singlePlan => {
        const { durationType, duration, monthlyAmount } = daysIntervalToMonths(
          singlePlan
        )
        const formattedSinglePackage = {
          ...singlePlan,
          durationType,
          duration,
          monthlyAmount,
          price: `$${monthlyAmount}/mo`
        }
        if (
          singlePlan.name.includes('Jetsetter') ||
          singlePlan.subscriptionClass === 'prime_first'
        ) {
          primeFirst[`i-${formattedSinglePackage.id}`] = formattedSinglePackage
        } else {
          prime[`i-${formattedSinglePackage.id}`] = formattedSinglePackage
        }
      })
      setPackages({ prime, primeFirst })
    }
  }, [stripeSubscriptionPlans])

  const currentPlan = getCurrentPlan(subscription)
  if (loading || storeLoading) {
    return <FullPageLoader />
  }
  const isPrimeActive = activePlan === 'prime'
  const isPrimeFirstActive = activePlan === 'primeFirst'
  return canManage ? (
    <Fragment>
      <Info className='f6 center lh-copy tc '>
        We don’t sell advertising, your data, or profit if you book a flight.
        Our priority is pure - you.
        <br />
        Thank you for supporting us.
      </Info>
      <WhatYouGet className='w-50-ns'>
        <MainRow active={currentPlan === 'free'}>
          {currentPlan === 'free' && <Checkmark />}
          <Row className='ph2 tc w-100 mb3 lh-copy'>
            <PlanColumn>
              <PlanName>Dreamer</PlanName>
              <Person
                publicID='assets/mobile/am_subscription_dreamer'
                alt='Dreamer'
              />
            </PlanColumn>

            <PlanColumn>
              <PlanText>
                Regular &<br />
                Economy Class
                <br />
                Deals
              </PlanText>
              <DealsPercent src={blue75} />
            </PlanColumn>
            <PlanColumn>
              <PlanText>
                First &<br />
                Business Class
                <br />
                Deals
              </PlanText>
              <DealsPercent src={red75} />
            </PlanColumn>
            <PlanPrice>
              <span className='f4'>
                {' '}
                free <br /> forever
              </span>
            </PlanPrice>
          </Row>
          <Row noPadding>{currentOrUpgrade(store, 'free')}</Row>
        </MainRow>
        <MainRow active={currentPlan === 'prime'}>
          {currentPlan === 'prime' && <Checkmark />}
          <Row
            className='ph2 tc w-100 mb3 lh-copy'
            active={isPrimeActive}
            onClick={() => {
              togglePlanPackages('prime')
            }}
          >
            <PlanColumn>
              <PlanName>Adventurer</PlanName>
              <Person
                publicID='assets/mobile/am_subscription_adventure_machine'
                alt='Adventurer'
              />
            </PlanColumn>

            <PlanColumn>
              <PlanText>
                Regular &<br />
                Economy Class
                <br />
                Deals
              </PlanText>
              <DealsPercent src={blue100} />
            </PlanColumn>
            <PlanColumn>
              <PlanText>
                First &<br />
                Business Class
                <br />
                Deals
              </PlanText>
              <DealsPercent src={red75} />
            </PlanColumn>
            {/* {activePlan !== 'prime' && ( */}
            <PlanPrice>
              <span>
                {currentPlan === 'prime' ? (
                  <p>
                    <span className='f4'>
                      {`$${subscription &&
                        daysIntervalToMonths(
                          get(subscription, 'planDetails', null)
                        )?.monthlyAmount}`}
                      <span className='f6'> / mo</span>
                    </span>
                    <p className='f4 fw4 mv1'>
                      {getActiveCurrentPlanReadble()}
                    </p>
                  </p>
                ) : (
                  <p>
                    <span className='f4'>
                      {packages && Object.values(packages.prime)[0]?.price}
                    </span>
                  </p>
                )}
              </span>
            </PlanPrice>
            {/* )} */}
          </Row>
          {renderPackages('prime')}
          <Row noPadding>{currentOrUpgrade(store, 'prime')}</Row>
        </MainRow>
        <MainRow active={currentPlan === 'primeFirst'}>
          {currentPlan === 'primeFirst' && <Checkmark />}
          <Row
            className='ph2 tc w-100 mb3 lh-copy'
            active={isPrimeFirstActive}
            onClick={() => {
              togglePlanPackages('primeFirst')
            }}
          >
            <PlanColumn>
              <PlanName>Jetsetter</PlanName>

              <Person
                publicID='assets/mobile/am_subscription_fc_flyer'
                alt='Jetsetter'
              />
            </PlanColumn>

            <PlanColumn>
              <PlanText>
                Regular &<br />
                Economy Class
                <br />
                Deals
              </PlanText>
              <DealsPercent src={blue100} />
            </PlanColumn>
            <PlanColumn>
              <PlanText>
                First &<br />
                Business Class
                <br />
                Deals
              </PlanText>
              <DealsPercent src={red100} />
            </PlanColumn>
            {/* {activePlan !== 'primeFirst' && ( */}
            <PlanPrice>
              <span>
                {currentPlan === 'primeFirst' ? (
                  <p>
                    <span className='f4'>
                      {`$${subscription &&
                        daysIntervalToMonths(
                          get(subscription, 'planDetails', null)
                        )?.monthlyAmount}`}
                      <span className='f6'> / mo</span>
                    </span>
                    <p className='f4 fw4 mv1'>
                      {getActiveCurrentPlanReadble()}
                    </p>
                  </p>
                ) : (
                  <p>
                    <span className='f4'>
                      {packages && Object.values(packages.primeFirst)[0]?.price}
                    </span>
                  </p>
                )}
              </span>
            </PlanPrice>
            {/* )} */}
          </Row>
          {renderPackages('primeFirst')}
          <Row noPadding>{currentOrUpgrade(store, 'primeFirst')}</Row>
        </MainRow>
      </WhatYouGet>
      <p className='f5 center lh-solid mb5 tc black fw7'>
        Recurring billing, cancel anytime...
        <Info className='black-50 fw3'>
          By tapping Purchase, your payment will be charged to your payment
          <br />
          method, and your subscription will automatically renew for the <br />
          same length at the same price until you cancel in your <br />
          settings on the StayCircles website or the Adventure Machine <br />
          mobile app. By tapping Purchase, you agree to our Terms.
        </Info>
      </p>
    </Fragment>
  ) : (
    <div className='mw6 mt4 center'>{getMobileManageInstructions(store)}</div>
  )
}

const Row = styled.div`
  width: 100%;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  padding: ${props => (props.noPadding ? '0px' : '0 14px')};
  justify-content: space-between;
  @media (max-width: 480px) {
    flex-direction: column;
  }
`

const WhatYouGet = styled.div`
  /* max-width: 1280px; */
  margin: 0 auto;
  /* width: 50%; */
  /* box-shadow: 0px 2px 6px 0 rgba(0, 0, 0, 0.2); */

  p {
    font-size: 1.15rem;
  }

  @media (min-width: 1600px) {
    p {
      font-size: 1.25rem;
    }
  }
`

const Person = styled.img.attrs({
  src: props =>
    `https://res.cloudinary.com/staycircles/image/upload/f_auto,q_auto,c_scale,h_400/${props.publicID}`
})`
  height: 160px;
  width: auto;
  /* margin-bottom: 48px; */
  /* margin-top: 48px; */
`

const LearnMoreButton = styled(Button)`
  padding: 8px 16px;
  margin-bottom: 24px;
  margin-top: 16px;
`

LearnMoreButton.defaultProps = {
  link: true,
  children: 'Learn more'
}

const PlanName = styled.h3`
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 8px;
  color: #153c6b;
  /* text-transform: uppercase; */
  letter-spacing: 0.06em;
`

const PlanText = styled.h5`
  text-align: center;
  font-size: 14px;
  margin-bottom: 20px;
  color: #2867b2;
  font-weight: 400;
`

const DealsPercent = styled.img`
  width: 100px;
  height: 100px;
`

const SubscribeButton = styled(Button).attrs({
  color: colors.white,
  colorHover: colors.offwhite,
  bgColor: colors.buttonBlue,
  bgColorHover: colors.blue
})`
  font-weight: 600;
  /* letter-spacing: 0.06em; */
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  height: 45px;
  :disabled {
    background: #c7e1ff;
    color: #29558b;
  }
`

// custom

const PlanColumn = styled(View)`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  /* padding: 0 14px; */
`

const MainRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  box-shadow: 0px 2px 6px 0 rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  margin-bottom: 20px;

  /* background: white; */
  background: ${props => (props.active ? '#E6F1FF' : '#fff')};
  @media (max-width: 480px) {
    flex-direction: column;
  }
`

const PlanPrice = styled.h5`
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-family: 600;
  font-size: ${props => (props.small ? '11px' : '14px')};
  color: #153c6b;
  margin-top: 24px;
  p {
    white-space: pre-line;
    margin: 0;
  }
`

const Logo = styled(Icon)`
  color: #333;
  height: 16px;

  /* @media (min-width: 1921px) {
    height: 36px;
  } */
`

const Divider = styled(Logo)`
  padding-left: 10px;
  padding-right: 10px;
  height: 24px;
`

const PlanPackagesWrapper = styled(View)`
  flex-direction: row;
  width: 100%;
  background-color: white};
`

const PlanPackage = styled(TouchableOpacity)`
  flex: 1;
  position: relative;
  /* background-color: #f5f7fa; */
  justify-content: flex-start;
  align-items: center;
  padding-top: 25px;
  padding-bottom: 5px;
  border: 2px solid #dadee6;
  border-color: ${props => (props.active ? '#1C81FF' : '#DADEE6')};
  background: ${props => (props.active ? '#ffffff' : '#f5f7fa')};
`
const PlanPackageIntervalText = styled(Text)`
  font-weight: 600;
  font-size: 14px !important;
  line-height: 14.4px;
  color: ${props => (props.active ? '#000000' : '#B7B7B7')};
`
const PlanPackagePopularTabWrapper = styled(View)`
  display: block;
  position: absolute;
  width: 100%;
  top: -20px;
  padding: 8px;
`

const PlanPackagePopularTab = styled.div.attrs({})`
  border-radius: 25px;
  display: inline-block;
  background-color: ${colors.buttonBlue};
  /* background-color: #1c81ff; */
  border-radius: 25px;
  padding: 5px 10px;
`
const Checkmark = styled(Logo).attrs({
  name: 'checkmark-solid',
  height: 25
})`
  position: absolute;
  right: 10px;
  top: 10px;
`
const PlanPackagePriceText = styled(Text)`
  font-weight: 600;
  font-size: 14px !important;
  line-height: 14.4px;
  margin-top: 6px;
  margin-bottom: 2px;
  color: ${props => (props.active ? '#1C81FF' : '#B7B7B7')};
`
const PlanPackageSaveNoteText = styled(Text)`
  font-size: 12px !important;
  color: ${props => (props.active ? '#000000' : '#B7B7B7')};
`

const PlanPackagePopularTabText = styled(Text)`
  font-family: 600;
  color: ${colors.white};
  font-size: 8px !important;
  text-align: center;
  line-height: 10.8px;
`
const Info = styled.p`
  color: #607a99;
`
export default SubscriptionPlansRenderer
