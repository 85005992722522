import React from 'react'

import styled from 'styled-components'

import Loader from 'components/Loader'

class Spinner extends React.Component {
  shouldComponentUpdate ({ show }) {
    if (show !== this.props.show) {
      return true
    }
    return false
  }

  render () {
    return (
      <Container show={this.props.show}>
        <Loader width='30px' height='45px' />
      </Container>
    )
  }
}

const Container = styled.div`
  position: absolute;
  z-index: 1;
  display: ${props => (props.show ? 'block' : 'none')};
  right: 10px;
  bottom: 5px;
`

export default Spinner
