import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import sortBy from 'lodash/sortBy'
import get from 'lodash/get'

import { isYourself, makeAnonymous } from './utils'

import Pin from './Pin'
import MultiUserHousehold from './MultiUserHousehold'

class StayPalMarker extends PureComponent {
  render () {
    const { item, currentUser, searchFor, hideHouseMembers } = this.props

    const isCommunity = searchFor === 'community'

    if (get(item, 'houseMembers') && item.houseMembers.length > 1) {
      item.houseMembers.map(
        sp =>
          sp.is3rdDegreeSp && !isYourself(sp, currentUser)
            ? makeAnonymous(sp)
            : sp
      )
      const houseMembers = sortBy(
        item.houseMembers,
        sp => (sp.isOwner ? 0 : 1)
      ).filter(stayPal => {
        if (!stayPal || !stayPal.id || (hideHouseMembers && !stayPal.isOwner)) {
          return
        }
        return true
      })
      return (
        <MultiUserHousehold
          {...this.props}
          {...{ isCommunity, houseMembers }}
        />
      )
    } else {
      let stayPal = item.owner
      if (!stayPal && get(item, 'houseMembers[0]')) {
        stayPal = item.houseMembers.find(sp => sp.isOwner)
      }

      const isCurrentUser = isYourself(stayPal, currentUser)

      return (
        <Pin {...this.props} {...{ stayPal, isCommunity, isCurrentUser }} />
      )
    }
  }
}

StayPalMarker.propTypes = {
  item: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  searchFor: PropTypes.string,
  active: PropTypes.bool,
  unfocused: PropTypes.bool,
  onClick: PropTypes.func,
  params: PropTypes.object,
  paymentMethods: PropTypes.array
}

export default StayPalMarker
