import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import cn from 'classnames'

import { getFullName } from 'utils/profile-info'

import StayPalPicture from './StayPalPicture'

const thisIsYou =
  'This is you! Browse the map and click on the faces you see to send messages, propose stays, etc.'

class Pin extends PureComponent {
  constructor (props) {
    super(props)

    this.onLoadImage = this.onLoadImage.bind(this)
    this.onErrorLoadImage = this.onErrorLoadImage.bind(this)

    this.isDragging = false
    this.isMoving = false

    this.state = {
      pictureLoaded: false,
      pictureInvalid: false
    }
  }

  componentDidMount () {
    this.checkPictureLoaded()
    this._isMounted = true
  }

  componentWillUnmount () {
    this._isMounted = false
    if (this.picture) {
      this.picture.removeEventListener('load', this.onLoadImage)
      this.picture.removeEventListener('error', this.onErrorLoadImage)
    }
  }

  checkPictureLoaded () {
    let pictureLoaded = this.picture ? this.picture.complete : true
    if (this._isMounted) {
      this.setState({ pictureLoaded })
    }

    if (!pictureLoaded && this.picture) {
      this.picture.addEventListener('load', this.onLoadImage)
      this.picture.addEventListener('error', this.onErrorLoadImage)
    }
  }

  onLoadImage () {
    if (this._isMounted) {
      this.setState({
        pictureLoaded: true
      })
    }
  }

  onErrorLoadImage () {
    if (this._isMounted) {
      this.setState({
        pictureLoaded: true,
        pictureInvalid: true
      })
    }
  }

  isFirstDegree () {
    const { stayPal, isCurrentUser } = this.props

    return (stayPal.friend && stayPal.is_address_public) || isCurrentUser
  }

  getCircleRadius () {
    if (this.isFirstDegree()) return {}

    let radius = 112

    const { zoom } = this.props

    if (zoom < 14) {
      radius = Math.max(16, radius / Math.max(1, 14 - zoom - 1))
    } else if (zoom > 14) {
      radius = Math.max(112, radius * (zoom - 14 + 1))
    }

    return { width: radius, height: radius }
  }

  _onClick = (e, zoomIn = false) => {
    // Prevent selecting pin when map is being dragged
    if (this.isDragging) return

    const { active, item, stayPal, isCurrentUser, onClick } = this.props

    if ((zoomIn || !active) && !stayPal.is3rdDegreeSp && !isCurrentUser) {
      onClick(item, stayPal.id, 15, zoomIn)
    }
  }

  _onMouseEnter = () => {
    this.props.onMouseEnter && this.props.onMouseEnter()
  }

  __onMouseLeave = () => {
    this.props.onMouseLeave && this.props.onMouseLeave()
  }

  __onMouseDown = () => {
    this.isMoving = false
    this.props.onMouseDown && this.props.onMouseDown()
  }

  __onMouseMove = () => {
    this.isMoving = true
    this.props.onMouseMove && this.props.onMouseMove()
  }

  __onMouseUp = () => {
    this.isDragging = this.isMoving
    this.props.onMouseUp && this.props.onMouseUp()
  }

  render () {
    const {
      stayPal,
      index,
      activeHousemateIndex,
      isCurrentUser,
      ...props
    } = this.props

    const name = getFullName(stayPal)
    const [firstName, lastName] = name.split(' ')
    const lastNameInitial = lastName ? lastName[0] + '.' : ''
    const tooltip = !props.onJoyride && isCurrentUser ? thisIsYou : undefined

    return (
      <div
        className={cn('sc-pin SC-ExploreMap-Pin', {
          'SC-ExploreMap-Pin--first': this.isFirstDegree(),
          'SC-ExploreMap-Pin--private': !this.isFirstDegree() && !isCurrentUser,
          'SC-ExploreMap-Pin--second': !stayPal.friend && !isCurrentUser,
          'SC-ExploreMap-Pin--multiple': props.multiple,
          'SC-ExploreMap-Pin--alone': !props.multiple,
          // 'SC-ExploreMap-Pin--unfocused': props.unfocused,
          // 'SC-ExploreMap-Pin--blurred': !this.state.pictureLoaded,
          'SC-ExploreMap-Pin--detached': props.detached,
          'SC-ExploreMap-Pin--active': props.active,
          'SC-ExploreMap-Pin--housemate-active':
            props.multiple && activeHousemateIndex !== -1
        })}
        onClick={this._onClick}
        onDoubleClick={e => this._onClick(e, true)}
        onMouseEnter={this._onMouseEnter}
        onMouseLeave={this._onMouseLeave}
        onMouseDown={this._onMouseDown}
        onMouseMove={this._onMouseMove}
        onMouseUp={this._onMouseUp}
        title={name}
      >
        {stayPal.cloudinaryImgUrl ? (
          <StayPalPicture
            className='SC-ExploreMap-Pin__avatar'
            stayPal={{ ...stayPal, friend: this.isFirstDegree() }}
            alt={name}
            getImgRef={r => {
              this.picture = r
            }}
            isCurrentUser={isCurrentUser}
            isPictureInvalid={this.state.pictureInvalid}
            width='67'
            height='67'
          >
            <div
              className='overlay flex items-center justify-center tc'
              data-rh={tooltip}
            >
              <span className='overlay-scrolling-text nowrap absolute dib f4 b'>
                {name}
              </span>
            </div>
            {this.props.multiple &&
              !this.props.last && (
              <div
                className={cn('SC-ExploreMap-Pin__link', {
                  'SC-ExploreMap-Pin__link-active':
                      activeHousemateIndex !== -1 &&
                      activeHousemateIndex > index
                })}
              />
            )}
          </StayPalPicture>
        ) : (
          <div className='SC-ExploreMap-Pin__nameBadge' data-rh={tooltip}>
            {firstName} {lastNameInitial}
          </div>
        )}

        <div className='SC-ExploreMap-Pin__tail'>
          <div className='SC-ExploreMap-Pin__tail-ring' />
          <div
            className='SC-ExploreMap-Pin__tail-circle'
            style={this.getCircleRadius()}
          />
        </div>
      </div>
    )
  }
}

Pin.propTypes = {
  active: PropTypes.bool,
  index: PropTypes.number,
  activeHousemateIndex: PropTypes.number,
  unfocused: PropTypes.bool,
  isCommunity: PropTypes.bool,
  isCurrentUser: PropTypes.bool,
  multiple: PropTypes.bool,
  first: PropTypes.bool,
  last: PropTypes.bool,
  onClick: PropTypes.func,
  handleSendMessage: PropTypes.func,
  item: PropTypes.object,
  user: PropTypes.object,
  stayPal: PropTypes.object,
  params: PropTypes.object,
  paymentMethods: PropTypes.array
}

export default Pin
