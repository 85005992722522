import React from 'react'

import get from 'lodash/get'
import formatDate from 'utils/formatDate'

import { cloudinary, getUserPicture, getFullName } from 'utils/profile-info'
import root from 'utils/windowOrGlobal'

import { Link } from '@reach/router'

import styled, { css } from 'styled-components'

import colors from 'styles/colors'

import Dropdown from 'components/SimpleDropdown'
import Icon from 'components/Icon'

class StayItem extends React.Component {
  state = {
    loadingReceipt: false,
    receiptError: null
  }

  shouldComponentUpdate (nextProps, nextState) {
    const status = ['statusName', 'paymentStatusName']
    const isEqual = (a, b) => status.every(stat => a[stat] === b[stat])

    const stateChanged = nextState === this.state

    if (isEqual(this.props.item, nextProps.item) && !stateChanged) {
      return false
    }

    return true
  }

  renderPicture () {
    const { item } = this.props

    let picture
    let size = { width: 226, height: 184 }

    const rooms = get(item, 'roomsDetails')
    const room = rooms.find(room => !!get(room, 'images[0]'))

    picture = get(room, 'images[0]')
    if (get(picture, 'url') && !get(picture, 'cloudinaryPublicId')) {
      picture = picture.url
    }

    const publicId = get(picture, 'cloudinaryPublicId')
    if (publicId) {
      picture = cloudinary(publicId, {
        ...size,
        crop: 'fill'
      })
    }

    if (picture) {
      return (
        <div className='mr3'>
          <div className='overflow-hidden br3' style={size}>
            <img
              src={picture}
              alt='Stay room picture'
              className='img br3'
              style={size}
            />
          </div>
        </div>
      )
    }

    return (
      <div className='mr3'>
        <div
          className='br3 flex justify-center items-center'
          style={{ ...size, backgroundColor: '#eef3f7' }}
        >
          <Icon name='better-bed' height={64} color='#a5b2bd' />
        </div>
      </div>
    )
  }

  get placeName () {
    const { item } = this.props
    // There is a typo on the API results here
    // It will be fixed soon but to be sure we check for it too
    const place = get(item, 'placeDatails') || get(item, 'placeDetails')

    if (!place || !get(place, 'city') || !get(place, 'country')) {
      return item.name
    }

    return `${place.city}, ${place.country}`
  }

  get shouldShowReceipt () {
    const { item } = this.props

    return (
      item.stayTypeName === 'Business' &&
      item.paymentStatusName !== 'Awaiting' &&
      ['Pending', 'Approved', 'Completed'].includes(item.statusName)
    )
  }

  openReceipt = () => {
    if (this.shouldShowReceipt && !this.state.loadingReceipt) {
      const { item, getReceiptUrl } = this.props
      this.setState({ loadingReceipt: true })
      getReceiptUrl(item.id, url => {
        if (this.state.loadingReceipt) {
          if (url) {
            this.setState({ loadingReceipt: false })
            root.open(url)
          } else {
            this.setState({ receiptError: true })
          }
        }
      })
    }
  }

  get shouldShowCancel () {
    const { item } = this.props
    return ['Pending', 'Approved'].includes(item.statusName)
  }

  get dates () {
    const { item } = this.props
    const start = formatDate(item.startDate, 'E d')
    const end = formatDate(item.endDate, 'E d, yyyy')
    return `${start} - ${end}`
  }

  get price () {
    const { item } = this.props
    if (!item.pricePerNight) return
    return Math.round(Number(item.pricePerNight))
  }

  render () {
    const { item } = this.props

    const link = `./?stay=${item.id}&convo=${item.conversationId}`

    return (
      <Link to={link} className='link'>
        <StayItemContainer className='w-100 bg-white pa3 br3 flex flex-row mb4'>
          <div className='dn db-ns'>{this.renderPicture()}</div>

          <div className='w-100 flex flex-column'>
            <div className='flex flex-column flex-row-l justify-between w-100 mb3'>
              <span
                style={{ fontSize: 24, maxWidth: 320 }}
                className='fw6 black-80 truncate'
              >
                {this.placeName}
              </span>

              <div className='flex flex-row items-center'>
                <Badge status={item.statusName}>
                  {item.statusName === 'Denied' ? 'Declined' : item.statusName}
                </Badge>

                {(this.shouldShowReceipt || this.shouldShowCancel) && (
                  <Dropdown
                    className='ml3'
                    renderButton={onClick => (
                      <Button
                        onClick={e => {
                          e.preventDefault()
                          onClick()
                        }}
                        style={{ padding: '6px' }}
                      >
                        <Icon name='ellipsis' height={6} />
                      </Button>
                    )}
                    renderMenu={() => (
                      <ul>
                        {this.shouldShowReceipt && (
                          <li>
                            <Button
                              onClick={e => {
                                e.preventDefault()
                                this.openReceipt()
                              }}
                              style={{ minWidth: 230 }}
                            >
                              {this.state.loadingReceipt ? (
                                <span>Loading...</span>
                              ) : this.state.receiptError ? (
                                <span>Error</span>
                              ) : (
                                <span>Download Receipt (PDF)</span>
                              )}
                            </Button>
                          </li>
                        )}
                        {this.shouldShowCancel && (
                          <li>
                            <Button
                              onClick={e => {
                                e.preventDefault()
                                this.props.cancelStay()
                              }}
                              style={{ minWidth: 230 }}
                            >
                              <span>Cancel Stay</span>
                            </Button>
                          </li>
                        )}
                      </ul>
                    )}
                  />
                )}
              </div>
            </div>

            <div className='flex flex-row mb3 black-50'>
              <Icon
                name='calendar'
                height={20}
                color={colors.blackRGBA(0.3)}
                className='mr2'
              />
              <span>{this.dates}</span>
            </div>

            <div className='flex flex-column flex-row-l black-60 mb4'>
              <div className='flex flex-row items-center mr4'>
                <Picture
                  src={getUserPicture(item.host, { width: 45 })}
                  alt='Host picture'
                  className='mr2'
                />
                <div>
                  <span className='fw6 db'>{getFullName(item.host)}</span>
                  <span>Host</span>
                </div>
              </div>
              <div className='flex flex-row items-center'>
                <Picture
                  src={getUserPicture(item.traveller, { width: 45 })}
                  alt='Traveler picture'
                  className='mr2'
                />
                <div>
                  <span className='fw6 db'>{getFullName(item.traveller)}</span>
                  <span>
                    {item.stayTypeName === 'Business' ? 'Business ' : ''}
                    Traveler
                  </span>
                </div>
              </div>
            </div>

            <div className='flex flex-row justify-between items-baseline w-100'>
              <span className='fw6 black-60'>
                {item.type === 'Staying' ? 'Traveling' : 'Hosted'}{' '}
                {item.stayTypeName} Stay
                {this.price ? ` - $${this.price} per night` : ''}
              </span>

              <span className='black-50 view-link flex flex-row items-center'>
                View Details <CaretIcon />
              </span>
            </div>
          </div>
        </StayItemContainer>
      </Link>
    )
  }
}

const Button = props => (
  <button
    type='button'
    className='bg-transparent pa0 ma0 bn pointer link black-30 hover-black-60 flex flex-row items-center'
    {...props}
  />
)

const Picture = styled.img`
  box-shadow: inset 0px 0px 2px 0 rgba(0, 0, 0, 0.2);
  border-radius: 45px;
`

const StayItemContainer = styled.div`
  box-shadow: 0px 2px 6px 0 rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.5s ease-in-out;

  .view-link,
  .view-link svg {
    transition: color 0.25s ease-in-out;
  }

  &:hover,
  &:focus {
    box-shadow: 0px 4px 10px 0 rgba(0, 0, 0, 0.3);

    .view-link,
    .view-link svg {
      color: ${colors.blue};
    }
  }
`

const CaretIcon = styled(Icon).attrs({ name: 'caret' })`
  height: 6px;
  color: ${colors.blackRGBA(0.5)};
  margin-left: 10px;

  transform: rotate(-90deg);
`

const getStatusColors = props => {
  switch (props.status) {
    case 'Pending':
      return css`
        background-color: #fff2de;
        color: #99661a;
      `
    case 'Completed':
      return css`
        background-color: #e4f0e9;
        color: #52a374;
      `
    case 'Denied':
    case 'Cancelled':
    case 'Autodeclined':
      return css`
        background-color: ${colors.blackRGBA(0.1)};
        color: ${colors.blackRGBA(0.5)};
      `
    case 'Confirmed':
    case 'Approved':
    default:
      return css`
        background-color: #deedff;
        color: #1e78e5;
      `
  }
}

const Badge = styled.div`
  padding: 0px 16px;
  border-radius: 28px;
  height: 28px;
  font-size: 14px;
  line-height: 1;
  font-weight: 600;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  ${getStatusColors};
`

export default StayItem
