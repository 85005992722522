import React, { useState, useEffect } from 'react'
import { navigate } from 'gatsby'
import { useLocation, Link } from '@reach/router'
import { ADVENTURE_DEALS } from 'config/routes'
import { get } from 'lodash'
import styled from 'styled-components'
import colors from 'styles/colors'
import Icon from 'components/Icon'
import root from 'utils/windowOrGlobal'
import { formatFlightDeal } from 'utils/deals'
import DealDateCard from '../../components/DealDateCard'
import { Container } from '../../components/CommonStyles'
import { Calendar } from 'react-date-range'
import { format, parseISO } from 'date-fns'
import PotentialHosts from '../../components/PotentialHosts'
import ButtonPill from 'components/ButtonPill'
// import withDealsState, { DealData } from '../store'
import LoaderAM from '../../components/LoaderAM'
import { getDealById } from '../api'

const DealInformation = props => {
  const location = useLocation()
  const [dealInfo, setDealInfo] = useState(false)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const { state: paramState } = location
  // eslint-disable-next-line react/prop-types
  const { dealId } = props
  useEffect(() => {
    if (paramState && paramState.id) {
      setDealInfo(paramState)
      setLoading(false)
    } else {
      fetchDealInfo()
    }
  }, [])

  const fetchDealInfo = async () => {
    const [error, data] = await getDealById(dealId)
    if (error) {
      setError(true)
      setLoading(false)
    }
    if (data) {
      setDealInfo(formatFlightDeal(data.flightDeal))
      setLoading(false)
    }
  }

  const _handleShareClick = () => {
    navigator.clipboard.writeText(window.location.href)
    root.alert('Yay, Link copied to your clipboard')
  }
  // Formatting //
  const getRange = alternativeDates => {
    if (alternativeDates.length === 0) {
      return { firstDate: new Date(), totalMonths: 1 }
    }

    const firstDate = alternativeDates[0].departDate
    const lastDate = alternativeDates[alternativeDates.length - 1].departDate

    return { firstDate, lastDate }
  }
  // end of formartting

  const destinationNameText =
    dealInfo.destinationName ||
    dealInfo.toAirport?.regionalCity?.name ||
    'New York'
  const [showDealDates, setShowDealDates] = useState(false)
  const initialDeals = get(dealInfo, 'alternativeDates', [])

  const alternativeDepartDates = initialDeals.map(
    singleDate => singleDate.departDate
  )
  const [deals, setDeals] = useState(initialDeals)
  const price = String(dealInfo.subjectPrice || '').split(' ')[0]
  const normalPrice = String(dealInfo.normalPrice || '').split(' ')[0]

  const isFirstClass = dealInfo.dealType === 'first_class'
  const hasDates = !!get(dealInfo, 'alternativeDates[0].departDate')
  const hasHosts = !!get(dealInfo, 'potentialHosts[0]')
  const { firstDate, lastDate } = getRange(initialDeals || [])

  // Alternative day content render
  const handleDateClick = day => {
    const dayString = format(day, 'yyyy-MM-dd')
    if (alternativeDepartDates.indexOf(dayString) === -1) {
      return
    }
    const deals = dealInfo.alternativeDates.filter(
      ({ departDate }) => departDate === dayString
    )
    setDeals(deals)
    setShowDealDates(true)
  }

  const customDayContent = day => {
    const isMarkedDate = alternativeDepartDates.indexOf(
      format(day, 'yyyy-MM-dd')
    )
    if (isMarkedDate !== -1) {
      return (
        <div style={{}}>
          <div
            style={{
              top: 0,
              left: 0,
              position: 'absolute',
              width: '95%',
              height: '100%',
              zIndex: 1,
              backgroundColor: colors.darkerBlue,
              borderRadius: 4
            }}
          />
          <span
            style={{ position: 'relative', zIndex: 2, color: colors.white }}
          >
            {format(day, 'd')}
          </span>
        </div>
      )
    }
  }

  // End of alternative day content

  if (error) {
    return null
  }

  return loading ? (
    <LoaderAM centered size={32} />
  ) : (
    <DealInformationContainer>
      <DestinationImageWrapper url={dealInfo.toAirportPictures[0].url}>
        {!showDealDates && (
          <ShareIconWrapper onClick={_handleShareClick}>
            <Icon name='share' height={16} color={colors.white} />
          </ShareIconWrapper>
        )}
        {showDealDates && (
          <BackIconWrapper
            onClick={() => {
              setDeals([])
              setShowDealDates(false)
            }}
          >
            <Icon name='arrow' height={16} color={colors.white} />
          </BackIconWrapper>
        )}
        <CloseIconWrapper onClick={() => navigate(`/${ADVENTURE_DEALS}`)}>
          <Icon name='close' height={16} color={colors.white} />
        </CloseIconWrapper>
        {/* <DestinationImage src={state.toAirportPictures[0].url} /> */}
      </DestinationImageWrapper>
      <DealInfoContentWrapper>
        <Row>
          <TravelChart>
            <TravelRow>
              <Column>
                <Text mv='0px' bold size={14} color={colors.blackRGBA(0.4)}>
                  FROM
                </Text>
                <Text mv='0px' big bold color={colors.blackRGBA(0.8)}>
                  {dealInfo.fromAirport.iataCode}
                </Text>
              </Column>

              <Column middle style={{ alignItems: 'center', marginTop: 24 }}>
                <IconWrapper rotate center mb='5px'>
                  <Icon name='arrow' height={16} color={colors.black} />
                </IconWrapper>
                <IconWrapper center>
                  <Icon name='arrow' height={16} color={colors.black} />
                </IconWrapper>
              </Column>

              <Column>
                <Text mv='0px' bold size={14} color={colors.blackRGBA(0.4)}>
                  TO
                </Text>
                <Text mv='0px' big bold color={colors.blackRGBA(0.8)}>
                  {dealInfo.toAirport.iataCode}
                </Text>
              </Column>
            </TravelRow>

            <TravelRow>
              <Column>
                <Text mv='0px' size={18} bold color={colors.blackRGBA(0.5)}>
                  {dealInfo.fromShort}
                </Text>
              </Column>
              <Column middle />
              <Column>
                <Text mv='0px' size={18} bold color={colors.blackRGBA(0.5)}>
                  {dealInfo.toShort}
                </Text>
              </Column>
            </TravelRow>
          </TravelChart>
        </Row>
        {!showDealDates && (
          <div>
            <Row
              style={{ marginTop: 0, width: '100%', justifyContent: 'center' }}
            >
              <Icon
                name='flight-tickets'
                height={36}
                color={colors.blackRGBA(0.2)}
              />

              <Text color={colors.blue} size={33} bold mh='10px'>
                {price}
              </Text>

              <Text
                size={17}
                style={{
                  textDecorationLine: 'line-through',
                  textDecorationStyle: 'solid',
                  color: colors.moonGray
                }}
              >
                ({normalPrice})
              </Text>
            </Row>

            <Badge green={isFirstClass || dealInfo.isBusinessDeal}>
              <BadgeText
                green={isFirstClass || dealInfo.isBusinessDeal}
                mv='4px'
                mh='27px'
                bold
              >
                {dealInfo.isBusinessDeal
                  ? 'Business'
                  : isFirstClass
                    ? 'First Class'
                    : 'Economy'}{' '}
                - Round trip Airline(s): {dealInfo.airlines.join(', ')}
              </BadgeText>
            </Badge>

            {/* {(isPrime || isPFCDeal) && <PrimeLogo source={{ uri: primeLogo }} />} */}

            {hasDates && dealInfo.active && (
              <div
                style={{ display: 'flex', flexDirection: 'column', flex: 1 }}
              >
                <Row style={{ marginBottom: 12 }}>
                  <IconWrapper style={{ marginRight: 8 }}>
                    <Icon
                      name='calendar'
                      height={20}
                      color={colors.blackRGBA(0.3)}
                    />
                  </IconWrapper>
                  <Text size={16}>Choose a departure date to book flights</Text>
                </Row>
                <Row
                  noPadding
                  style={{ marginBottom: 16, justifyContent: 'center' }}
                >
                  <CalendarWrapper>
                    <Calendar
                      className='flightsCalendar'
                      onChange={handleDateClick}
                      showPreview={false}
                      dayContentRenderer={customDayContent}
                      minDate={parseISO(firstDate)}
                      maxDate={parseISO(lastDate)}
                      shownDate={parseISO(firstDate)}
                    />
                  </CalendarWrapper>
                </Row>
              </div>
            )}

            <Row style={{ justifyContent: 'center' }}>
              {dealInfo.active ? (
                <div>
                  <Text color={colors.silver}>
                    Deal sent at {dealInfo.updatedAt}
                    {'\n'}
                  </Text>
                  <Text color={colors.silver} style={{ fontStyle: 'italic' }}>
                    (deals usually expire within 48 hrs.)
                  </Text>
                </div>
              ) : (
                <Text color={colors.silver}>This deal has expired.</Text>
              )}
            </Row>

            {/* {item.active && !hasDates && (
        <View>
          <Row>
            <Button
              label="Google Flights Search"
              onPress={() => {
                this.handleFlightSearch()
              }}
            />
          </Row>

          <Row style={{ justifyContent: 'center' }}>
            <DestinationImage
              source={{ uri: item.dealImage }}
              style={{ width: 180, height: 225 }}
            />
          </Row>
        </View>
      )} */}

            {!hasHosts && (
              <Row style={{ marginBottom: 32, width: '361px' }}>
                <InviteCardLink to={`/app/adventure/deals/learnmore`}>
                  <InviteCard>
                    <InviteRow style={{ marginBottom: '20px' }}>
                      <IconWrapper style={{ marginRight: '8px' }}>
                        <Icon
                          name='people'
                          height={20}
                          color={colors.blue}
                          style={{ marginRight: 8 }}
                        />
                      </IconWrapper>
                      <Text bold color={colors.blue} size={20}>
                        Invite Friends
                      </Text>
                    </InviteRow>

                    <Text medium left mh='0px' mv='4px'>
                      You might have friends-of-friends in {dealInfo.toShort}{' '}
                      and not know it!
                    </Text>
                    <Text
                      medium
                      left
                      color={colors.silver}
                      style={{ lineHeight: '21px' }}
                      mh='0px'
                      mv='0px'
                    >
                      Connect with friends on StayCircles to find out & grow
                      your map.
                    </Text>
                    <Text
                      medium
                      left
                      style={{ color: colors.blue, marginTop: '26px' }}
                      mh='0px'
                      mv='0px'
                    >
                      Learn more →
                    </Text>
                  </InviteCard>
                </InviteCardLink>
              </Row>
            )}

            {hasHosts && (
              <Row style={{ width: '100%', flexDirection: 'column' }}>
                <Row style={{ justifyContent: 'center', flex: 1 }}>
                  <Icon
                    name='people'
                    width={22}
                    color={colors.blackRGBA(0.3)}
                    style={{ marginRight: 8 }}
                  />
                  <Text alpha={0.7} bold size={20} mh='10px'>
                    Potential Hosts
                  </Text>
                </Row>

                <Row style={{ marginTop: 0, paddingHorizontal: 16 }}>
                  <Text alpha={0.5}>
                    Your friends and friends-of-friends in {dealInfo.toShort}
                  </Text>
                </Row>

                <Row
                  style={{
                    justifyContent: 'center',
                    marginTop: 0,
                    paddingHorizontal: 16
                  }}
                >
                  <InviteCardLink to={`/app/adventure/deals/learnmore`}>
                    <Text bold style={{ color: colors.blue }}>
                      Learn more →
                    </Text>
                  </InviteCardLink>
                </Row>

                <Row noPadding>
                  <div className='potential-hosts-wrapper'>
                    <PotentialHosts
                      potentialHosts={dealInfo.potentialHosts}
                      destination={destinationNameText}
                      type={dealInfo.type}
                    />
                  </div>
                </Row>

                <Row
                  style={{
                    marginBottom: 32,
                    justifyContent: 'center',
                    flex: 1,
                    display: 'flex'
                  }}
                >
                  <ButtonPill
                    style={{ display: 'flex', flexDirection: 'row', flex: 1 }}
                    onClick={() => navigate('/app')}
                  >
                    <Icon
                      name='calendar'
                      width={22}
                      color={colors.blackRGBA(0.3)}
                      style={{ marginRight: 10 }}
                    />
                    <Text
                      bold
                      color={colors.blue}
                      style={{ letterSpacing: 0.2 }}
                      mh='8px'
                    >
                      OPEN FRIENDS MAP
                    </Text>
                  </ButtonPill>
                </Row>
              </Row>
            )}
          </div>
        )}
        {showDealDates && (
          <DealDatesWrapper>
            <Row>
              <Text mv='14px' medium style={{ lineHeight: '19.6px' }}>
                Choose dates below to be redirected to Google Flights. We
                recommend booking directly with the airline from here by making
                a final flight selection.
              </Text>
            </Row>
            <Row style={{ flexDirection: 'column', padding: '0px' }}>
              {deals &&
                deals.map(singleDeal => (
                  <DealDateCard
                    flightDeal={dealInfo}
                    item={singleDeal}
                    key={singleDeal.id}
                  />
                ))}
            </Row>
          </DealDatesWrapper>
        )}
      </DealInfoContentWrapper>
    </DealInformationContainer>
  )
}

const DealInformationContainer = styled(Container)`
  height: auto;
  margin-top: 40px;
  width: 750px;
  background-color: ${colors.white};
  position: relative;
`
const DealInfoContentWrapper = styled.div`
  width: 361px;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .potential-hosts-wrapper {
    .list {
      justify-content: center;
    }
  }
`
const DestinationImageWrapper = styled.div`
  height: 262px;
  width: 100%;
  overflow: hidden;
  background-image: url(${({ url }) => url});
  background-size: cover;
  background-position: center;
`
// const DestinationImage = styled.img`
//   width: 100%;
//   height: 262px;
// `

const TravelChart = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: -48px 0 10px;
  background-color: white;
  elevation: 2;
  shadow-color: ${colors.nobel};
  shadow-offset: 0 0;
  shadow-opacity: 1;
  shadow-radius: 2;
  border-radius: 4;
  padding: 18px;
  width: 361px;
`

const TravelRow = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 18px;
  padding: ${props => (props.noPadding ? '0' : '0 20px')};
`

const Column = styled.div`
  width: ${props => (props.middle ? '28%' : ' 38%')};
`
const Text = styled.p`
  font-weight: ${props => (props.bold ? 700 : props.medium ? 500 : 400)};
  text-align: ${props =>
    props.left ? 'left' : props.right ? 'right' : 'center'};
  font-size: ${props => (props.big ? 36 : props.size || 16)}px;
  color: ${props =>
    props.alpha
      ? colors.blackRGBA(props.alpha)
      : props.color
        ? props.color
        : colors.black};
  margin: ${props => props.mv || '4px'} ${props => props.mh || '0px'};
`
const IconWrapper = styled.div`
  transform: ${props => (props.rotate ? 'rotate(180deg)' : 'rotate(0deg)')};
  margin-bottom: ${props => (props.mb ? props.mb : '0px')};
  text-align: ${props => (props.center ? 'center' : 'inherit')};
`

const Badge = styled.div`
  background: ${props => (props.green ? '#E4F0E9' : '#deedff')};
  padding: 3px 12px;
  border-radius: 12px;
  display: flex;
  flex: 1,
  flex-direction: row;
  justify-content: center;
  margin: 16px 36px;
`

const BadgeText = styled.p`
  font-weight: ${props => (props.bold ? 700 : 400)};
  font-size: 14px;
  color: ${props => (props.green ? '#52A374' : '#1e78e5')};
  text-align: center;
  margin: ${props => props.mv || '4px'} ${props => props.mh || '8px'};
`

const InviteCard = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  padding: 20px;
  background: #fff2de;
  border: 1px solid rgba(153, 102, 26, 0.05);
  shadow-color: ${colors.nobel};
  shadow-offset: 0px 1px;
  shadow-opacity: 1;
  shadow-radius: 2;
`

const InviteRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const InviteCardLink = styled(Link)`
  text-decoration: none;
  transition: all 0.25s ease;
`

const DealDatesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: white;
  padding: 18px;
  width: 361px;
`

const CalendarWrapper = styled.div`
  border: 1px solid;
  border-radius: 10px;
  border-color: ${colors.darkerBlue};
  .flightsCalendar {
    display: block;
    background: transparent;
    .rdrMonthAndYearWrapper {
      font-family: 'Lato', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
      font-weight: 300;
      font-size: 14px;
      line-height: 17px;
      color: #222223;
      border-bottom: 1px solid;
      margin: 0px 20px;
      border-color: ${colors.darkerBlue};
      justify-content: center;
      .rdrMonthAndYearPickers {
        flex: 0;
        pointer-events: none;
        font-family: 'Lato', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
        font-weight: 300;
        font-size: 14px;
        line-height: 17px;
        color: #222223;
        .rdrMonthPicker,
        .rdrYearPicker {
          margin-right: 0px;
          margin-left: 0px;
        }
        select {
          appearance: none;
          background: none;
          padding-right: 0px;
          padding-left: 0px;
          margin-right: 0px;
          margin-left: 0px;
          font-family: 'Lato', 'Helvetica Neue', 'Helvetica', 'Arial',
            sans-serif;
          font-weight: 300;
          font-size: 14px;
          line-height: 17px;
          color: #222223;
        }
      }

      .rdrNextPrevButton {
        background: none;
      }
      .rdrPprevButton {
        i {
          border-color: transparent #2f80ed transparent transparent;
        }
      }
      .rdrNextButton {
        i {
          border-color: transparent transparent transparent #2f80ed;
        }
      }
    }
    .rdrDayDisabled {
      background: none;
    }
    .rdrDayHovered:after {
      border: none;
    }
  }
`
const NavIconWrapper = styled.div`
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 2rem;
  width: 40px;
  height: 40px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`
const ShareIconWrapper = styled(NavIconWrapper)`
  left: 1rem;
`
const CloseIconWrapper = styled(NavIconWrapper)`
  right: 1rem;
`
const BackIconWrapper = styled(NavIconWrapper)`
  left: 1rem;
`

export default DealInformation
