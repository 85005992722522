import React, { PureComponent } from 'react'

import { Router } from '@reach/router'

import styled from 'styled-components'

import colors from 'styles/colors'

import Profile from './screens/profile'
import Password from './screens/password'
import Address from './screens/address'
import Notifications from './screens/notifications'
import Transactions from './screens/transactions'
import Maintenance from './screens/maintenance'
import DeleteAccount from './screens/deleteaccount'

import Sidebar from './components/Sidebar'

class Settings extends PureComponent {
  render () {
    const {
      user: { passwordSet = true }
    } = this.props
    return (
      <Container className='flex flex-column flex-row-l w-100 justify-center-l overflow-auto scrollbar'>
        <Sidebar passwordSet={passwordSet} />

        <Content>
          <Profile default path='profile/*' />
          {passwordSet && <Password path='password' />}
          <Address path='address' />
          <Notifications path='notifications' />
          <Transactions path='transactions/*' />
          <Maintenance path='maintenance' />
          <DeleteAccount path='delete-account' passwordSet={passwordSet} />
        </Content>
      </Container>
    )
  }
}

const Container = styled.div`
  background-color: ${colors.ice};
  padding-top: 4rem;
`

const Content = styled(Router)`
  width: 100%;
  max-width: 32rem;
  margin: 0 auto;

  @media (min-width: 992px) {
    margin: 0 2rem;
  }

  @media (min-width: 1200px) {
    margin-right: 22rem; /* Centralize Content by compensating Sidebar width */
  }
`

export default Settings
