import React, { Component } from 'react'

import get from 'lodash/get'
import debounce from 'lodash/debounce'

import styled from 'styled-components'

import { TextInput } from 'components/Input'
import ConversationsList from '../components/ConversationsList'

import ConversationsStoreProvider from '../store/conversations'

import * as routes from 'config/routes'
import { push } from 'utils/parse-querystring'

class Inbox extends Component {
  static defaultProps = { activeList: 'inbox' }

  componentDidMount () {
    this.checkConversationId()
  }

  componentDidUpdate (prevProps) {
    if (prevProps.list.length === 0 && this.props.list.length > 0) {
      this.checkConversationId()
    }

    if (prevProps.query.conversation !== this.props.query.conversation) {
      this.checkConversationId()
    }

    if (
      prevProps.list[0] !== this.props.list[0] &&
      get(this.props.list, '0.id')
    ) {
      this.setConversationQuery(get(this.props.list, '0.id'))
    }
  }

  checkConversationId = () => {
    if (!this.props.list[0]) {
      return
    }

    const conversationId = Number(get(this.props, 'query.conversation'))
    let convo

    if (conversationId) {
      convo = this.props.list.find(convo => convo.id === conversationId)
    }

    if (convo) {
      this.openConversation(convo)
    }
  }

  setConversationQuery = id => {
    const pathname =
      this.props.activeList === 'archive'
        ? routes.MESSAGES_ARCHIVE
        : routes.MESSAGES

    push({
      pathname,
      query: {
        ...this.props.query,
        conversation: id
      }
    })
  }

  handleEdit = ({ id, conversationData, title }) => {
    const { setConversationData, store } = this.props

    if (id !== conversationData.id) {
      return true
    }

    setConversationData({
      ...conversationData,
      title,
      editName: (id, title) =>
        this.handleEdit({
          conversationData,
          id,
          title
        })
    })

    return store.editConversationName(conversationData.id, title)
  }

  openConversation = ({ id, name }) => {
    const { setConversationData, activeList, store } = this.props

    store.markRead(id)

    const conversationData = {
      id,
      title: name,
      activeList,
      archive: store.archiveConversation
    }

    setConversationData({
      ...conversationData,
      editName: (id, title) =>
        this.handleEdit({
          conversationData,
          id,
          title
        })
    })
  }

  handleSearch = debounce(query => {
    const archives = this.props.activeList === 'archive'

    this.props.store.transition({
      type: 'SEARCH',
      query,
      archives
    })
  }, 500)

  render () {
    const archives = this.props.activeList === 'archive'
    const { store, list, height } = this.props
    const conversationId = Number(get(this.props, 'query.conversation'))

    return (
      <Container>
        <div id='Messages__Sidebar__Searchbox' className='pt3'>
          <SearchBar
            small
            placeholder='Search subject...'
            className='w-90'
            onChange={e => this.handleSearch(e.target.value)}
          />
        </div>

        <div style={{ height }}>
          <ConversationsList
            onPressItem={data => {
              this.setConversationQuery(data.id)
            }}
            list={list}
            lastUpdated={store.state.data.lastUpdated}
            conversationId={conversationId}
            load={() => store.transition({ type: 'LOAD', archives })}
            unload={() => store.transition('EXIT')}
            retry={() => store.transition('RETRY')}
            refresh={() => store.transition({ type: 'REFRESH', archives })}
            loadNextPage={() => store.transition('NEXT')}
            loading={store.matchesActions('loadNext')}
            hasMore={store.state.hasMore}
            height={height}
          />
        </div>
      </Container>
    )
  }
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  background-color: white;
`

const SearchBar = styled(TextInput)`
  border-radius: 24px;
  margin: 0 15px 20px;
`

export default props => (
  <ConversationsStoreProvider>
    {store => (
      <Inbox {...props} store={store} list={store.state.data.conversations} />
    )}
  </ConversationsStoreProvider>
)
