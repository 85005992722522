import React, { PureComponent } from 'react'

import styled, { css } from 'styled-components'
import Carousel from 'nuka-carousel'

import Icon from 'components/Icon'

import { getUserPicture } from 'utils/profile-info'

import colors from 'styles/colors'

class StayPalsCarousel extends PureComponent {
  static defaultProps = {
    numToShow: 2,
    stayPals: []
  }

  render () {
    const { numToShow, stayPals } = this.props

    return (
      <Carousel
        slidesToShow={numToShow}
        initialSlideWidth={80}
        cellSpacing={8}
        edgeEasing='easeLinear'
        renderCenterLeftControls={({ previousSlide, currentSlide }) => (
          <NavButton left onClick={previousSlide} disabled={currentSlide === 0}>
            <PlusIcon />
          </NavButton>
        )}
        renderCenterRightControls={({
          nextSlide,
          currentSlide,
          slideCount
        }) => {
          const total = Math.ceil(Math.max(slideCount, numToShow) / numToShow)

          return (
            <NavButton
              right
              onClick={nextSlide}
              disabled={currentSlide >= total - 1}
            >
              <PlusIcon />
            </NavButton>
          )
        }}
        renderBottomCenterControls={() => null}
      >
        {stayPals.map(sp => (
          <div key={sp.id} className='tc' style={{ width: 80, height: 125 }}>
            <Avatar {...sp} size='48px' />
            <p className='mb0 mt2 f6 black-60 lh-title'>{sp.name}</p>
          </div>
        ))}
      </Carousel>
    )
  }
}

export const Avatar = styled.img.attrs({
  src: props => getUserPicture(props),
  alt: props => props.name
})`
  border-radius: 100%;
  border: 2px solid
    ${props => (props.friend ? colors.redRGBA(0.5) : colors.blueRGBA(0.5))};
  ${props =>
    props.size &&
    `
    width: ${props.size};
    height: ${props.size};
    `};
`

const NavButton = styled.button.attrs({ type: 'button' })`
  background: transparent;
  border: none;
  outline: none;
  padding: 0;
  cursor: pointer;
  transition: opacity 0.25s ease;

  &:hover,
  &:focus {
    opacity: 0.7;
  }
  &[disabled] {
    opacity: 0.3;
  }
  ${props =>
    props.left &&
    css`
      margin-left: -1.25rem;
    `} ${props =>
  props.right &&
    css`
      margin-right: -1.25rem;
    `};
`

const PlusIcon = styled(Icon).attrs({ name: 'plus' })`
  width: 1.25rem;
  height: 1.25rem;
  color: ${colors.blackRGBA(0.5)};
  border-radius: 50%;
  border: 1px solid ${colors.blackRGBA(0.5)};
  padding: 2px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
`

export default StayPalsCarousel
