import React from 'react'
import PropTypes from 'prop-types'

import get from 'lodash/get'

import { FORM_ERROR } from 'final-form'

import logger from 'utils/logger'

import * as api from './_api'

import StateMachineProvider, { StateMachineContainer } from 'utils/StateMachine'

import root from 'utils/windowOrGlobal'

const statechart = {
  key: 'prime',
  initial: 'idle',
  states: {
    idle: {
      on: {
        LOAD_SUBSCRIPTION: {
          loading: { actions: ['showLoading', 'loadSubscription'] }
        },
        ACTIVATE_SUBSCRIPTION: {
          idle: { actions: ['showLoading', 'activateSubscription'] }
        },
        CANCEL_SUBSCRIPTION: {
          idle: { actions: ['showLoading', 'cancelSubscriptionPlan'] }
        },
        REACTIVATE_SUBSCRIPTION_PLAN: {
          idle: { actions: ['showLoading', 'reactivateSubscriptionPlan'] }
        },
        LOAD_PAYMENTS: { loading: { actions: ['loadPayments'] } },
        CREATE_PAYMENT: { loading: { actions: ['createPayment'] } },
        SET_DEFAULT_PAYMENT: {
          idle: { actions: ['showLoading', 'setDefaultPayment'] }
        }
      }
    },
    loading: {
      onEntry: ['showLoading'],
      on: {
        LOAD_SUCCESS: 'idle',
        LOAD_FAILURE: 'error'
      }
    },
    error: {
      onEntry: 'showError',
      on: { RECOVER: 'idle' }
    }
  }
}

class PrimeContainer extends StateMachineContainer {
  constructor (props) {
    super(props)

    // Make sure to keep the state machine engine
    const stateMachine = this.state

    this.state = {
      ...stateMachine,
      subscription: undefined,
      plans: [],
      subscribePlan: 'prime',
      paymentMethods: [],
      error: null
    }
  }

  componentDidTransition (prevStateMachine, event) {
    if (event && event.type) {
      switch (event.type) {
        case 'LOAD_SUCCESS':
          this.setData(event)
          break
        case 'LOAD_FAILURE':
          this.setError(event)
          break
      }
    }
  }

  setError = ({ error, callback }) => {
    let errorStr = Array.isArray(error) ? error[0] : error

    if (get(errorStr, 'message')) {
      errorStr = errorStr.message
    } else if (
      typeof errorStr !== 'string' &&
      typeof get(errorStr, 'toString') === 'function'
    ) {
      errorStr = errorStr.toString()
    }

    if (errorStr && errorStr.match(/token/i)) {
      this.transition('RECOVER')
      callback && callback()
      return
    }

    if (callback) {
      const formError = { [FORM_ERROR]: errorStr }
      callback(formError)
    } else {
      this.setState({ error: errorStr })
    }
  }

  setData = event => {
    if (event.hasOwnProperty('subscription')) {
      this.setSubscription(event.subscription, event.callback)
    } else if (event.hasOwnProperty('paymentMethods')) {
      this.setPayMethods(event.paymentMethods, event.callback)
    }
  }

  setSubscription = (data, callback) => {
    this.setState({ subscription: data }, callback)
  }

  setPayMethods = async (data, callback) => {
    if (Array.isArray(data)) {
      await this.setState({ paymentMethods: data }, callback)
    } else {
      const paymentMethods = this.state.paymentMethods.concat(data)
      await this.setState({ paymentMethods }, callback)
    }
  }

  request = async (apiName, data, callback) => {
    logger.captureBreadcrumb({
      message: 'PrimeContainer.' + apiName,
      category: 'payments',
      data
    })

    try {
      const [error, response] = await api[apiName](data)

      if (error) {
        throw new Error(error)
      } else {
        return response
      }
    } catch (error) {
      logger.captureException(error)

      await this.transition({ type: 'LOAD_FAILURE', error, callback })

      return false
    }
  }

  loadSubscription = async ({ subscribePlan = 'prime', callback }) => {
    const subsData = await this.request('getSubscriptionPlan')
    const plansData = await this.request('getAllSubscriptionPlans')

    await this.setState({
      plans: get(plansData, 'subscriptionPlans', []),
      subscribePlan
    })

    await this.transition({
      type: 'LOAD_SUCCESS',
      subscription: get(subsData, 'subscription'),
      callback
    })

    return true
  }

  loadPayments = async ({ callback }) => {
    const data = await this.request('getPaymentMethods')

    await this.transition({
      type: 'LOAD_SUCCESS',
      paymentMethods: get(data, 'paymentMethods', []),
      callback
    })

    return true
  }

  createPayment = async ({ card, callback }) => {
    const data = await this.request('createPaymentMethod', card, callback)

    if (data) {
      const paymentMethod = get(data, 'paymentMethod')

      await this.transition({
        type: 'LOAD_SUCCESS',
        paymentMethods: paymentMethod
      })

      await this.transition({ type: 'ACTIVATE_SUBSCRIPTION', callback })
    }
  }

  setDefaultPayment = async ({ id, callback }) => {
    if (!id) return

    const ok = await this.request('setDefaultMethod', id, callback)

    if (ok) {
      await this.transition({ type: 'LOAD_PAYMENTS', callback })

      return true
    }
  }

  activateSubscription = async ({
    callback,
    subscribePlan = this.state.subscribePlan
  }) => {
    const { subscription, plans } = this.state

    // prime | prime_first
    const primePlan = plans.find(
      plan => plan.subscriptionClass === subscribePlan
    )

    const id = get(primePlan, 'id', 1)

    if (subscription && subscription.id !== id) {
      // Upgrading/downgrading to another plan
      await this.request('upgradeSubscriptionPlan', {
        currentPlan: subscription.id,
        id
      })
    } else if (subscription && subscription.autoRenewAt) {
      // Plan is currently subscribed so unsubscribing
      await this.request('cancelSubscriptionPlan', subscription.id, callback)
    } else if (subscription && subscription.daysLeft > 0) {
      // Plans is deactivated but not expired
      // Reactivating
      await this.request(
        'reactivateSubscriptionPlan',
        subscription.id,
        callback
      )
    } else {
      await this.request('activateSubscriptionPlan', id)
    }

    await this.transition({
      type: 'LOAD_SUBSCRIPTION',
      subscribePlan,
      callback
    })
  }

  cancelSubscriptionPlan = async ({
    callback,
    subscription = this.state.subscription
  }) => {
    if (!subscription) return false

    await this.request('cancelSubscriptionPlan', subscription.id, callback)

    await this.transition({
      type: 'LOAD_SUBSCRIPTION',
      callback
    })

    return true
  }

  reactivateSubscriptionPlan = async ({
    callback,
    subscription = this.state.subscription
  }) => {
    if (!subscription) return false

    const ok = await this.request(
      'reactivateSubscriptionPlan',
      subscription.id,
      callback
    )

    if (!ok) return false

    await this.transition({
      type: 'LOAD_SUBSCRIPTION',
      callback
    })

    root.alert('Success!\n' + 'Your plan will be auto-renewed on expiry!')

    return true
  }
}

export default class PrimeStoreProvider extends React.Component {
  static propTypes = {
    children: PropTypes.func.isRequired
  }

  constructor (props) {
    super(props)
    this.container = new PrimeContainer({ statechart })
  }

  componentDidMount () {
    const { isLoggedIn } = this.props
    if (isLoggedIn) {
      this.loadData()
    }
  }

  componentDidUpdate ({ isLoggedIn, subscribePlan }) {
    const planChanged = isLoggedIn && subscribePlan !== this.props.subscribePlan
    const userJustLoggedIn = !isLoggedIn && this.props.isLoggedIn
    if (planChanged || userJustLoggedIn) {
      this.loadData()
    }
  }

  loadData = async () => {
    const { subscribePlan } = this.props

    this.container.transition({
      type: 'LOAD_SUBSCRIPTION',
      subscribePlan,
      callback: () => this.container.transition('LOAD_PAYMENTS')
    })
  }

  render () {
    return (
      <StateMachineProvider container={this.container}>
        {machineStore => this.props.children(machineStore)}
      </StateMachineProvider>
    )
  }
}
