import api from 'utils/api'

export const getConversations = ({ page, query: keyword, archives }) => {
  const params = {
    page,
    keyword,
    archives
  }

  return api.get('/conversations', params)
}

// action = 'archive' || 'unarchive'
export const archiveConversation = (id, action = 'archive') => {
  return api.put(`/conversations/${id}/${action}`)
}

export const editConversationName = (id, value) => {
  const params = { name: value }

  return api.put(`/conversations/${id}`, params)
}

export const createConversation = ({ stayPalPid, subject, message }) => {
  const data = {
    name: subject,
    message,
    attributes: { user_ids: [stayPalPid] }
  }

  return api.post('/conversations/', data)
}
