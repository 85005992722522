import React from 'react'

import logger from 'utils/logger'

import uniqBy from 'lodash/uniqBy'
import get from 'lodash/get'

import * as api from '../api/conversations'
import { authenticatePusher } from '../api/messages'

import StateMachineProvider, { StateMachineContainer } from 'utils/StateMachine'

import { getCurrentUser } from 'stores/auth'

const statechart = {
  key: 'conversations',
  initial: 'start',
  states: {
    start: {
      on: { LOAD: 'loading' }
    },
    loading: {
      onEntry: ['loadConversations', 'authenticatePusher', 'showLoading'],
      on: {
        LOAD_SUCCESS: 'list',
        LOAD_FAILURE: 'error'
      }
    },
    error: {
      onEntry: 'showError',
      on: { RETRY: 'loading' }
    },
    list: {
      onEntry: 'showList',
      on: {
        REFRESH: 'loading',
        RELOAD: { list: { actions: ['loadConversations'] } },
        SEARCH: 'loading',
        NEXT: { list: { actions: ['loadNext'] } },
        NEXT_CANCEL: 'list',
        LOAD_SUCCESS: 'list',
        LOAD_FAILURE: 'list',
        EXIT: { start: { actions: ['unauthenticatePusher'] } }
      }
    }
  }
}

class ConversationsContainer extends StateMachineContainer {
  constructor (props) {
    super(props)

    // Make sure to keep the state machine engine
    const stateMachine = this.state

    this.state = {
      ...stateMachine,
      currentUser: getCurrentUser(),
      data: { conversations: [] },
      hasMore: true,
      params: {
        query: '',
        page: 1,
        archives: false
      }
    }
  }

  componentDidTransition (prevStateMachine, event) {
    if (event && event.type && event.type === 'LOAD_SUCCESS') {
      this.setConversations(event)
    }
  }

  setCurrentUserToConversations = list => {
    const userID = this.state.currentUser.pid
    return list
      .filter(({ users }) => get(users, 'length', 0) > 1)
      .map(conversation => {
        const users = conversation.users.map(u => {
          if (u.id !== userID) return u
          return { ...u, current: true }
        })

        return { ...conversation, users }
      })
  }

  setConversations = ({ data: { conversations, ...data } }) => {
    conversations = this.setCurrentUserToConversations(conversations)

    if (data.currentPage > 1) {
      conversations = uniqBy(
        this.state.data.conversations.concat(conversations),
        'id'
      )
    }

    const totalPages = Math.ceil(
      data.totalConversations / data.conversationsPerPage
    )

    const hasMore = data.page < totalPages

    data = { ...data, conversations, lastUpdated: Date.now() }

    this.setState({ data, hasMore })
  }

  loadConversations = async (event = {}) => {
    logger.captureBreadcrumb({
      message: 'MessagesScreen.loadConversations',
      category: 'messages',
      data: event
    })

    let params = {
      page: event.page || 1,
      query: event.query,
      archives: event.archives
    }

    if (['RETRY', 'REFRESH', 'RELOAD'].includes(event.type)) {
      params = this.state.params
      params.page = 1
    }

    try {
      await this.setState({ params })

      const [error, data] = await api.getConversations(params)

      if (error) {
        throw new Error(error)
      } else {
        this.transition({ type: 'LOAD_SUCCESS', data })
      }
    } catch (error) {
      logger.captureException(error)
      this.transition({ type: 'LOAD_FAILURE', error })
    }
  }

  loadNext = async () => {
    const { hasMore, params } = this.state
    const nextPage = Number.isNaN(params.page) ? 1 : params.page + 1

    if (!hasMore) {
      this.transition('NEXT_CANCEL')
      return
    }

    this.loadConversations({
      ...params,
      page: nextPage
    })
  }

  archiveConversation = async (id, action) => {
    try {
      const [error] = await api.archiveConversation(id, action)

      if (error) {
        throw new Error(error)
      } else {
        await this.transition({ type: 'RELOAD' })
        return true
      }
    } catch (error) {
      logger.captureException(error)
    }
  }

  editConversationName = async (id, value) => {
    try {
      const [error] = await api.editConversationName(id, value)

      if (error) {
        throw new Error(error)
      } else {
        const data = this.state.data
        const conversations = data.conversations.map(c => {
          if (c.id === id) {
            return {
              ...c,
              name: value
            }
          }

          return c
        })

        this.setState({
          data: {
            ...data,
            conversations,
            lastUpdated: Date.now()
          }
        })
        return true
      }
    } catch (error) {
      logger.captureException(error)
    }
  }

  setNewLastMessage = () => {
    this.transition({ type: 'RELOAD' })
  }

  markRead = id => {
    const data = this.state.data

    const conversations = data.conversations.map(c => {
      if (c.id === id) {
        return {
          ...c,
          lastSeenAt: new Date().toISOString()
        }
      }

      return c
    })

    this.setState({
      data: {
        ...data,
        conversations,
        lastUpdated: Date.now()
      }
    })
  }

  authenticatePusher = () => {
    const { currentUser } = this.state

    try {
      this.channel = authenticatePusher({
        userPID: currentUser.pid,
        token: currentUser.jwtToken,
        newMessageHandler: this.setNewLastMessage
      })
    } catch (error) {
      logger.captureException(error)
    }
  }

  unauthenticatePusher = () => {
    try {
      if (this.channel) {
        this.channel.close()
      }
    } catch (error) {
      logger.captureException(error)
    }
  }
}

export default class ConversationsStoreProvider extends React.Component {
  constructor (props) {
    super(props)
    this.container = new ConversationsContainer({
      statechart
    })
  }

  render () {
    return (
      <StateMachineProvider container={this.container}>
        {machineStore => this.props.children(machineStore)}
      </StateMachineProvider>
    )
  }
}
