import React from 'react'

import get from 'lodash/get'

import colors from 'styles/colors'

import ButtonPill from 'components/ButtonPill'

const statusMap = {
  pendingHost: {
    label: 'Accept',
    test: data => data.status === 'Pending' && data.isViewerHost,
    getOnClick: ({ accept }) => accept,
    buttonColor: colors.darkBlue,
    buttonHoverColor: colors.darkerBlue,
    labelColor: colors.white,
    cancelButton: {
      label: 'Decline',
      getOnClick: ({ cancel }) => cancel
    }
  },
  pendingTraveler: {
    label: 'Pending',
    test: data => data.status === 'Pending' && !data.isViewerHost,
    disabled: true,
    buttonColor: '#fff2de',
    labelColor: '#99661a',
    cancelButton: {
      label: 'Cancel',
      getOnClick: ({ cancel }) => cancel
    }
  },
  accepted: {
    label: 'Approved',
    test: data => data.status === 'Approved' && data.isViewerHost,
    disabled: true,
    buttonColor: '#deedff',
    labelColor: '#1e78e5',
    cancelButton: {
      label: 'Cancel',
      getOnClick: ({ cancel }) => cancel
    }
  },
  confirmed: {
    label: 'Confirmed',
    test: data =>
      data.status === 'Approved' &&
      data.paymentStatus === 'Succeeded' &&
      !data.isViewerHost,
    disabled: true,
    buttonColor: '#deedff',
    labelColor: '#1e78e5',
    cancelButton: {
      label: 'Cancel',
      getOnClick: ({ cancel }) => cancel
    }
  },
  // @TODO: Add 'Pay Now' functionality
  awaitingPayment: {
    label: 'Retry Payment',
    test: data =>
      data.status === 'Approved' &&
      data.paymentStatus === 'Awaiting' &&
      !data.isViewerHost,
    getOnClick: ({ showPaymentMethods }) => showPaymentMethods,
    buttonColor: '#fff2de', // Pay now: colors.red
    buttonHoverColor: '#fff2de', // Pay now: colors.darkRed
    labelColor: '#99661a', // Pay now: colors.white
    cancelButton: {
      label: 'Cancel',
      getOnClick: ({ cancel }) => cancel
    }
  },
  declined: {
    label: 'Declined',
    test: data => data.status === 'Denied' || data.status === 'Declined',
    disabled: true,
    buttonColor: colors.blackRGBA(0.1),
    labelColor: colors.blackRGBA(0.5)
  },
  autoDeclined: {
    label: 'Auto-declined',
    test: data => data.status === 'Autodeclined',
    disabled: true,
    buttonColor: colors.blackRGBA(0.1),
    labelColor: colors.blackRGBA(0.5)
  },
  cancelled: {
    label: 'Cancelled',
    test: data => data.status === 'Cancelled',
    disabled: true,
    buttonColor: colors.blackRGBA(0.1),
    labelColor: colors.blackRGBA(0.5)
  },
  completed: {
    label: 'Completed',
    test: data => data.status === 'Completed',
    disabled: true,
    buttonColor: '#e4f0e9',
    labelColor: '#52a374'
  }
}

class ActionButtons extends React.Component {
  get status () {
    const { data, ...handlers } = this.props

    const statusKey = Object.keys(statusMap).find(key =>
      statusMap[key].test(data)
    )

    const status = statusMap[statusKey]

    const getOnClick = get(status, 'getOnClick', () => {})
    const getOnCancelClick = get(status, 'cancelButton.getOnClick', () => {})

    return {
      key: statusKey,
      ...status,
      onClick: getOnClick(handlers),
      onCancelClick: getOnCancelClick(handlers)
    }
  }

  renderButton = () => {
    const { loading } = this.props
    const {
      onClick,
      label,
      disabled,
      buttonColor,
      buttonHoverColor,
      labelColor
    } = this.status

    return (
      <ButtonPill
        onClick={onClick}
        disabled={disabled || loading}
        background={buttonColor}
        hoverBackground={buttonHoverColor || buttonColor}
        color={labelColor}
        border={colors.whiteRGBA(0.1)}
        style={{ opacity: 1 }}
      >
        {loading ? 'Loading...' : label}
      </ButtonPill>
    )
  }

  renderCancelButton = () => {
    const { loading } = this.props
    const { cancelButton, onCancelClick } = this.status

    if (loading || !cancelButton) return null

    return (
      <ButtonPill
        onClick={onCancelClick}
        color={colors.blackRGBA(0.5)}
        hoverColor={colors.blackRGBA(0.7)}
        hoverBackground='transparent'
        border='transparent'
        style={{ opacity: 1 }}
      >
        {cancelButton.label}
      </ButtonPill>
    )
  }

  render () {
    return (
      <div className='flex flex-column items-center'>
        {this.renderButton()}
        {this.renderCancelButton()}
      </div>
    )
  }
}

export default ActionButtons
