import React from 'react'

import styled from 'styled-components'

import colors from 'styles/colors'

import getAssetUrl from 'utils/getAssetUrl'

import ButtonPill from 'components/ButtonPill'
import { View, Text } from 'components/Native'
import Modal from 'components/Modal'

const coverUri = getAssetUrl('assets/hangout_i3gbth.png', {
  height: 600,
  opacity: 90,
  effect: 'make_transparent:10'
})

class Error extends React.Component {
  navigateHome = async () => {
    const { proposalStore } = this.props

    await proposalStore.transition('RECOVER')
  }

  render () {
    const {
      show,
      proposalStore: {
        state: { errorMsg }
      }
    } = this.props
    return (
      <Modal
        isOpen={show}
        onRequestClose={this.navigateHome}
        contentLabel='New Stay Proposed!'
      >
        <Container>
          <img src={coverUri} />

          <Title>Error!</Title>

          <Message>
            {errorMsg || 'Stay already proposed for this date/place'}.
            <br />
            {'Please choose any other date/place.'}
          </Message>

          <ButtonPill className='mt3' onClick={this.navigateHome}>
            Close
          </ButtonPill>
        </Container>
      </Modal>
    )
  }
}

const Container = styled(View)`
  flex: 1;
  padding: 16px;
  align-items: center;
`

const Title = styled(Text)`
  font-weight: bold;
  font-size: 24px;
  color: ${colors.red};
  margin-bottom: 8px;
`

const Message = styled(View)`
  flex: 1;
`

export default Error
