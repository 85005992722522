import primeSrc from '../assets/prime.svg'
import primeFirstSrc from '../assets/prime_first.svg'

export const PAGE_CONFIG = {
  DEALS: 'DEALS',
  DESTINATIONS_WITH_FRIENDS: 'DESTINATIONS_WITH_FRIENDS',
  RECOMMEND_VISIT: 'RECOMMEND_VISIT',
  MANAGE_AIRPORTS: 'MANAGE_AIRPORTS'
}

export const ROUTE_CONFIG = {
  [PAGE_CONFIG.DEALS]: `/app/adventure/deals`,
  [PAGE_CONFIG.DESTINATIONS_WITH_FRIENDS]: `/app/adventure/destination_with_friends`,
  [PAGE_CONFIG.RECOMMEND_VISIT]: `/app/adventure/recommend_visit`,
  [PAGE_CONFIG.MANAGE_AIRPORTS]: `/app/adventure/manage_airports`
}

export const STEPS = [1, 2, 3, 4, 5]

export const HEADER_CONFIG = [
  // { label: 'Recommend Visit', route: ROUTE_CONFIG.RECOMMEND_VISIT },
  // {
  //   label: 'Destinations with Friends',
  //   route: ROUTE_CONFIG.DESTINATIONS_WITH_FRIENDS
  // },
  { label: 'Deals', route: ROUTE_CONFIG.DEALS },
  { label: 'Manage Airports', route: ROUTE_CONFIG.MANAGE_AIRPORTS }
]

export const PRIME_DEAL_TYPES = {
  PRIME_DEAL: 'prime_deal',
  PRIME_FIRST_DEAL: 'prime_first_deal',
  FREE_DEAL: 'free_deal'
}

export const PRIME_LOGOS = {
  [PRIME_DEAL_TYPES.PRIME_DEAL]: primeSrc,
  [PRIME_DEAL_TYPES.PRIME_FIRST_DEAL]: primeFirstSrc
}
