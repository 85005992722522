import React from 'react'

import get from 'lodash/get'

import { Location, navigate } from '@reach/router'

import { FORM_ERROR } from 'final-form'

import { getUrlParameter } from 'utils/parse-querystring'

import colors from 'styles/colors'

import Modal from 'components/Modal'
import ButtonPill from 'components/ButtonPill'
import Loader from 'components/Loader'

import ProfileStoreProvider from '../store'

const EmailVerificationModal = () => (
  <Location>
    {({ location }) => {
      const close = () => navigate(location.pathname)

      return (
        <Modal isOpen onRequestClose={close} contentLabel='Email Verification'>
          <ProfileStoreProvider location={location}>
            {({ store }) => {
              const code = getUrlParameter(location, 'confirmation_code')

              const loading = store.matchesActions('showLoading')

              return (
                <ConfirmEmail
                  confirm={callback => {
                    store.transition({
                      type: 'CONFIRM_EMAIL',
                      code,
                      callback
                    })
                  }}
                >
                  {(email, error) => {
                    if (loading) {
                      return (
                        <div className='center flex flex-column items-center w-100 w-90-ns mv4'>
                          <Loader />
                        </div>
                      )
                    }

                    if (error) {
                      return (
                        <div className='center flex flex-column items-center w-100 w-90-ns mv4'>
                          <p className='f3 tc mb4 lh-copy'>
                            The email <b>{email}</b> could not be verified for
                            the following reason(s):
                          </p>

                          <p className='i pl4'>• {error}</p>
                        </div>
                      )
                    }

                    return (
                      <div className='center flex flex-column items-center w-100 w-90-ns mv4'>
                        <p>
                          The email <b>{email}</b> was successfully verified.
                        </p>

                        <ButtonPill
                          onClick={close}
                          background={colors.blue}
                          hoverBackground={colors.darkBlue}
                          color={colors.white}
                          border={colors.whiteRGBA(0.1)}
                        >
                          Okay
                        </ButtonPill>
                      </div>
                    )
                  }}
                </ConfirmEmail>
              )
            }}
          </ProfileStoreProvider>
        </Modal>
      )
    }}
  </Location>
)

class ConfirmEmail extends React.Component {
  state = {
    email: null,
    error: null
  }

  componentDidMount () {
    const cb = this.callback
    this.props.confirm(cb)
  }

  callback = response => {
    const error = response[FORM_ERROR]
    if (error) {
      this.setState({ error })
    }

    const email = get(response, 'email', '')
    this.setState({ email })
  }

  render () {
    const { email, error } = this.state

    return this.props.children(email, error)
  }
}

export default EmailVerificationModal
