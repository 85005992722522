import React from 'react'

import get from 'lodash/get'

import { getPlaceAddress } from 'utils/profile-info'

import styled from 'styled-components'

import colors from 'styles/colors'

import { Checkbox } from 'components/Checkbox'

import Button from '../../../components/SmallButton'

import UserPinMap from './UserPinMap'

import { LinkButton } from '../index'

const placeAttributes = [
  { name: 'primary', label: 'Primary Residence' },
  { name: 'cat', label: 'Cats' },
  { name: 'dog', label: 'Dogs' },
  { name: 'children', label: 'Children' }
]

const PlaceDetails = ({
  loading,
  place,
  toggleAttribute,
  togglePrivacy,
  onSubmitData,
  openModal,
  onDeletePlace
}) => (
  <PlaceDetailsWrapper>
    <div className='flex flex-row items-center mb4 flex-wrap flex-nowrap-ns placeAttributes-row'>
      {placeAttributes.map(attr => (
        <CheckboxInput
          key={attr.name}
          name={attr.name}
          value={get(place, attr.name, false)}
          onChange={e => {
            toggleAttribute(place, attr.name, e.target.checked)
          }}
          label={attr.label}
          disabled={loading}
          className='mr4-ns mr2'
        />
      ))}
    </div>

    <div className='mb4'>
      <h4 className='fw5 mb2 black-90'>Address</h4>

      <div
        className='pl3 flex flex-row items-center flex-wrap flex-nowrap-ns'
        onClick={openModal('edit_address')}
      >
        <div>
          <span className='db mb1'>{getPlaceAddress(place).fullAddress}</span>
          <span className='i black-50'>
            This address is only shown to your Friends.
          </span>
        </div>

        <Button
          blueBorder
          onClick={openModal('edit_address')}
          className='f6 ml2'
        >
          Edit
        </Button>
      </div>
    </div>

    <div className='mb4'>
      <h4 className='fw5 mb2 black-90'>Review location</h4>

      <p className='lh-copy pl3 black-70'>
        You can drag the pin to edit your place location. You can choose how
        your
        <br />
        location is shown to your{' '}
        <span style={{ color: colors.red }}>Friends</span> and{' '}
        <span style={{ color: colors.blue }}>Friends-of-Friends</span> below.
      </p>

      <UserPinMap place={place} onSubmitData={onSubmitData} />
    </div>

    <div className='mb5'>
      <h4 className='fw5 mb3 black-90'>
        Choose how location is shown on the map
      </h4>

      <LocationVisibility
        isAddressPublic={get(place, 'isAddressPublic', true)}
        togglePrivacy={togglePrivacy}
        loading={loading}
      />
    </div>

    <div className='bt b--black-10 pt4 mb5'>
      <LinkButton
        className='f6'
        onClick={onDeletePlace}
        style={{ color: colors.blackRGBA(0.6) }}
      >
        Delete this place
      </LinkButton>
    </div>
  </PlaceDetailsWrapper>
)

const LocationVisibility = ({ isAddressPublic, togglePrivacy, loading }) => (
  <div className='pl3'>
    <h5 className='mb3' style={{ color: colors.blue }}>
      Friends-of-Friends
    </h5>
    {/* <p className="lh-copy i black-50">
      StayPals2 are your friends-of-friends. They are the StayPals of your
      StayPals.
    </p> */}

    <div className='flex flex-row items-center mb4'>
      <CheckboxInput checked disabled readOnly />
      <PinViewImage
        src='https://res.cloudinary.com/staycircles/image/upload/f_auto,q_auto,w_75,c_scale/v1538751480/assets/staypals2-pin-cloud_wfdfly.png'
        alt='StayPals 2nd degree pin with general location'
        className='mh3'
      />
      <div>
        <p className='mb1'>General location</p>
        <p className='black-50 lh-copy mb0'>
          Your Friends-of-Friends only see the general location of your place.
          <br />
          This is the standard setting and cannot be changed.
        </p>
      </div>
    </div>

    <h5 className='mb1' style={{ color: colors.red }}>
      Friends
    </h5>
    <p className='lh-copy i black-50'>
      Friends are those who you choose to confirm on the StayCircles
      <br />
      platform, one by one.
    </p>

    <div
      className='flex flex-row items-center mb3 pointer'
      onClick={() => togglePrivacy(true)}
    >
      <CheckboxInput
        value={isAddressPublic}
        onChange={() => togglePrivacy(true)}
        disabled={loading}
      />
      <PinViewImage
        src='https://res.cloudinary.com/staycircles/image/upload/f_auto,q_auto,w_75,c_scale/v1538751481/assets/staypals-pin-dot_nlfnog.png'
        alt='Friends pin with specific location'
        className='mh3'
      />
      <div>
        <p className='mb1'>Specific location</p>
        <p className='black-50 lh-copy mb0'>
          Show your Friends your specific location of your place.
          <br />
          This is the default setting.
        </p>
      </div>
    </div>

    <div
      className='flex flex-row items-center mb3 pointer'
      onClick={() => togglePrivacy(false)}
    >
      <CheckboxInput
        value={!isAddressPublic}
        onChange={() => togglePrivacy(false)}
        disabled={loading}
      />
      <PinViewImage
        src='https://res.cloudinary.com/staycircles/image/upload/f_auto,q_auto,w_75,c_scale/v1538751480/assets/staypal-pin-cloud_aozdb5.png'
        alt='Friends pin with general location'
        className='mh3'
      />
      <div>
        <p className='mb1'>General location</p>
        <p className='black-50 lh-copy mb0'>
          Show your Friends only your general location of your place.
        </p>
      </div>
    </div>
  </div>
)

const PinViewImage = styled.img`
  width: 75px;
  height: 75px;
`

export const CheckboxInput = styled(Checkbox)`
  [class~='icon'] {
    width: 1.15rem;
    height: 1.15rem;
    padding: 0.1rem;
  }
`

const PlaceDetailsWrapper = styled.div`
  .placeAttributes-row {
    gap: 0.5rem;
  }
`

export default PlaceDetails
