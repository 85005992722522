import React from 'react'
import PropTypes from 'prop-types'

import { get, debounce, merge, isEmpty } from 'lodash'

import root from 'utils/windowOrGlobal'
import logger from 'utils/logger'

import * as api from '../api/places'

import StateMachineProvider, { StateMachineContainer } from 'utils/StateMachine'

const statechart = {
  key: 'hosting-places',
  initial: 'idle',
  states: {
    idle: {
      onEntry: ['showLoading'],
      on: { LOAD: 'loading' }
    },
    loading: {
      onEntry: ['load', 'showLoading'],
      on: {
        LOAD_SUCCESS: 'list',
        LOAD_FAILURE: 'error'
      }
    },
    list: {
      onEntry: 'showList',
      on: {
        LOAD: 'loading',
        UPDATE: { list: { actions: ['update'] } },
        TOGGLE_ATTRIBUTE: {
          list: { actions: ['toggleAttribute'] }
        },
        DELETE: { list: { actions: ['deletePlace', 'showLoading'] } },
        SUCCESS: 'list',
        ERROR: 'error'
      }
    },
    error: {
      onEntry: 'showError',
      on: { RECOVER: 'loading' }
    }
  }
}

class PlacesContainer extends StateMachineContainer {
  constructor (props) {
    super(props)

    // Make sure to keep the state machine engine
    const stateMachine = this.state

    this.state = {
      ...stateMachine,
      data: {
        primaryPlaceId: null,
        places: []
      },
      updating: false
    }

    this.updateRequest = debounce(this.updateRequest, 1000)
  }

  componentDidTransition (prevStateMachine, event) {
    if (event && event.type) {
      switch (event.type) {
        case 'LOAD_SUCCESS':
          this.setData(event.data)
          break
        case 'LOAD_FAILURE':
          this.setError(event.error)
          break
      }
    }
  }

  setData = async (data, shouldMerge = false) => {
    if (shouldMerge) {
      if (get(data, 'id')) {
        await this.setPlaceData(data)
      } else if (get(data, '[0]')) {
        await this.setPlacesListData(data)
      } else {
        await this.setState({
          data: merge({}, this.state.data, data)
        })
      }
    } else {
      await this.setState({ data })
    }
  }

  setPlacesListData = async places => {
    await this.setState({
      data: {
        ...this.state.data,
        places: [...this.state.data.places, ...places]
      }
    })
  }

  setPlaceData = async place => {
    await this.setState({
      data: {
        ...this.state.data,
        places: this.state.data.places.map(otherPlace => {
          if (otherPlace.id === place.id) {
            return merge({}, otherPlace, place)
          }

          return otherPlace
        })
      }
    })
  }

  getPlaceById = id => {
    const places = get(this.state.data, 'places', [])
    return places.find(p => p.id === id)
  }

  setError = async error => {
    const errorStr = Array.isArray(error) ? error[0] : error
    await this.setState({ error: errorStr })
  }

  load = async ({ callback } = {}) => {
    logger.captureBreadcrumb({
      message: 'PlacesContainer.load',
      category: 'hosting'
    })

    try {
      const [error, data] = await api.getPlaces()

      if (error) {
        throw new Error(error)
      } else {
        const primaryPlaceId = data.primaryPlaceId

        const places = data.places.map(place => {
          if (place.id === primaryPlaceId) {
            return {
              ...place,
              primary: true
            }
          }

          return place
        })

        this.transition(
          {
            type: 'LOAD_SUCCESS',
            data: { primaryPlaceId, places }
          },
          { hasPlaces: !!places[0] }
        )

        if (typeof callback === 'function') {
          callback()
        }

        return data
      }
    } catch (error) {
      logger.captureException(error)

      this.transition({ type: 'LOAD_FAILURE', error })

      if (typeof callback === 'function') {
        callback(error)
      }

      return false
    }
  }

  request = async (name, apiCall, data) => {
    logger.captureBreadcrumb({
      message: 'PlacesContainer.' + name,
      category: 'hosting',
      data
    })

    try {
      const [error, response] = await apiCall(data)

      if (error) {
        throw new Error(error)
      } else {
        return response
      }
    } catch (error) {
      logger.captureException(error)

      this.transition({ type: 'ERROR', error })
      return false
    }
  }

  toggleAttribute = async ({ place, key, value }) => {
    if (key === 'primary') {
      await this.togglePrimary(place)

      return
    }

    await this.update({
      place: {
        id: place.id,
        [key]: value
      }
    })
  }

  update = async ({ place: newData, callback }) => {
    // Cache initial data for recovery in case request goes wrong
    // and then optimistically update state before making request
    if (!this.state.cachedData) {
      const previousPlace = this.getPlaceById(newData.id)
      await this.setState({ cachedData: previousPlace })
    }

    // Optimistically update
    await this.setData(newData, true)

    // The request here is extracted out because we need
    // to debounce it separately as optimistic update
    // always need to happen so that UI can be responsive
    await this.updateRequest(newData, callback)
  }

  updateRequest = async (newData, callback) => {
    await this.setState({ updating: true })

    if (this.state.cachedData) {
      const data = this.getPlaceById(newData.id)
      newData = mergeDifference(this.state.cachedData, data)
      newData.id = data.id
    }

    if (!isEmpty(newData)) {
      const res = await this.request('updateRequest', api.updatePlace, newData)

      if (res) {
        const data = get(res, 'place', {})
        this.transition({ type: 'SUCCESS', data })
      } else {
        // Recover pre-update cached data if update went wrong
        const cachedData = get(this.state, 'cachedData', {})
        await this.setData(cachedData)
      }
    } else {
      // If newData is empty (ie. when updates matches original data)
      // make SUCCESS transition anyway because
      // 1) it didn't fail and 2) cleanup from UPDATE transition
      this.transition({ type: 'SUCCESS', data: {} })
    }

    await this.setState({ cachedData: undefined, updating: false })

    if (typeof callback === 'function') {
      callback()
    }
  }

  togglePrimary = async place => {
    const onlyPlace = this.state.data.places.length === 1

    if (place.primary) {
      if (onlyPlace) {
        root.alert(
          `Oops, this is your only place.\nYou need to add another place and mark it as primary first.`
        )
      } else {
        // Optimistically update
        await this.setData({ id: place.id, primary: false }, true)

        let res = await this.request('getPlaces', api.getPlaces)

        if (res) {
          const places = get(res, 'places', [])
          const otherPlace = places.find(p => p.id !== place.id)

          res = await this.request(
            'togglePrimary',
            api.setPrimaryPlace,
            otherPlace.id
          )
        }

        if (!res) {
          // Recover pre-update data if update went wrong
          await this.setData({ id: place.id, primary: true }, true)
        }
      }
    } else {
      // Optimistically update
      await this.setData({ id: place.id, primary: true }, true)

      const res = await this.request(
        'togglePrimary',
        api.setPrimaryPlace,
        place.id
      )

      if (!res) {
        // Recover pre-update data if update went wrong
        await this.setData({ id: place.id, primary: false }, true)
      }
    }
  }

  deletePlace = async ({ place, callback }) => {
    const res = await this.request('deletePlace', api.deletePlace, place.id)

    if (res) {
      await this.transition({ type: 'LOAD', callback })
    } else {
      if (typeof callback === 'function') {
        const errored = true
        callback(errored)
      }
    }
  }
}

const container = new PlacesContainer({ statechart })

export default class PlacesStoreProvider extends React.PureComponent {
  static propTypes = {
    children: PropTypes.func.isRequired
  }

  componentDidMount () {
    this.loadData()
  }

  loadData = async () => {
    await container.transition('LOAD')
  }

  render () {
    return (
      <StateMachineProvider container={container}>
        {machineStore => this.props.children(machineStore)}
      </StateMachineProvider>
    )
  }
}

/**
 * Helpers
 */

function mergeDifference (prevObj, newObj) {
  return Object.keys(newObj).reduce((obj, key) => {
    const value = get(prevObj, key)
    const newValue = get(newObj, key)

    // Ignore objects
    if (typeof value !== 'object' && value !== newValue) {
      obj = { ...obj, [key]: newValue }
    }

    return obj
  }, {})
}
