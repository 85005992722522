import api from 'utils/api'

export const acceptRequest = (id, status = 'approve') => {
  return api.put(`/connections/${id}`, { status })
}

export const ignoreRequest = id => {
  return acceptRequest(id, 'deny')
}

export const getRequests = (page = 1) => {
  const params = {
    page,
    filter: 'incoming'
  }

  return api.get('/connections', params)
}

export const loadUnreadRequests = () => {
  return api.get('/notifications/unread_count')
}
