import React from 'react'

import formatDate from 'utils/formatDate'
import isAfter from 'date-fns/isAfter'

import styled from 'styled-components'

import { View, Image, Text, TouchableOpacity } from 'components/Native'

import colors from 'styles/colors'

import { getUserPicture } from 'utils/profile-info'

export const ITEM_HEIGHT = 90

class ConversationItem extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      unread: this.getUnread(props.data)
    }
  }

  shouldComponentUpdate (nextProps) {
    if (nextProps.data !== this.props.data) {
      return true
    }

    return false
  }

  getUnread = data => {
    if (!data.lastMessage) return false

    const user = data.users.find(u => u.current)
    const seenMessage = isAfter(data.lastSeenAt, data.lastMessage.createdAt)
    const lastMessageFromCurrentUser =
      data.lastMessage.user && data.lastMessage.user.id === user.id

    return !seenMessage && !lastMessageFromCurrentUser
  }

  get friend () {
    const { data } = this.props
    return data.users.find(u => !u.current) || {}
  }

  get participantsName () {
    const { data } = this.props
    return data.users
      .map(singleUser => `${singleUser.firstName} ${singleUser.lastName}`)
      .join(',')
  }

  get pictureURI () {
    return getUserPicture(this.friend, { width: 50 }, true)
  }

  get lastMessageDate () {
    const { data } = this.props

    const format = 'd E yy'
    if (data.lastMessage) {
      return formatDate(data.lastMessage.createdAt, format)
    }

    return formatDate(data.createdAt, format)
  }

  get unread () {
    const { data } = this.props

    if (!data.lastMessage) return false

    const user = data.users.find(u => u.current)
    const seenMessage = isAfter(data.lastSeenAt, data.lastMessage.createdAt)
    const lastMessageFromCurrentUser =
      data.lastMessage.user && data.lastMessage.user.id === user.id

    return !seenMessage && !lastMessageFromCurrentUser
  }

  get lastMessageBody () {
    const { data } = this.props

    if (data.lastMessage) {
      return data.lastMessage.body
    }
  }

  get isGroup () {
    const { data: { users = [], conversationType } = {} } = this.props
    return (
      conversationType === 'shared_destination' ||
      (conversationType === 'general' && users.length > 2)
    )
  }

  get conversationTitle () {
    const { data: { users = [], conversationType, name } = {} } = this.props
    if (this.isGroup) {
      return `Group: ${users.length} participants`
    } else if (conversationType === 'potential_hosting') {
      return name || 'Recommended visit'
    } else if (conversationType === 'stay') {
      return name || 'Stay'
    }
  }

  get chatName () {
    if (this.isGroup) {
      const { data: { users = [], name } = {} } = this.props
      const userNames = users.map(
        ({ firstName, lastName }) => `${firstName} ${lastName}`
      )
      return name && name !== '' ? name : userNames.join(', ')
    } else {
      const { name, firstName, lastName } = this.friend || {}
      return name || `${firstName} ${lastName}`
    }
  }

  handlePress = () => {
    this.setState({ unread: false })
    this.props.onPress()
  }

  render () {
    // const { data } = this.props
    const { unread } = this.state

    return (
      <Wrapper onPress={this.handlePress}>
        <Picture source={{ uri: this.pictureURI }} />

        <Info>
          <NameDateRow>
            <Title unread={unread} numberOfLines={1}>
              {this.chatName}
            </Title>

            <LastMessageDate>{this.lastMessageDate}</LastMessageDate>
          </NameDateRow>

          <Name unread={unread}>{this.conversationTitle}</Name>

          {this.lastMessageBody && (
            <LastMessageExcerpt unread={unread}>
              {this.lastMessageBody}
            </LastMessageExcerpt>
          )}
        </Info>
      </Wrapper>
    )
  }
}

const Wrapper = styled(TouchableOpacity)`
  position: relative;
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
`

const Picture = styled(Image)`
  width: 50px;
  height: 50px;
  border-radius: 25px;
`

const Info = styled(View)`
  flex: 1;
  padding-left: 16px;
  overflow: hidden;
`

const NameDateRow = styled(View)`
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  // margin-bottom: 4px;
`

const Name = styled(Text)`
  font-size: 14px;
  color: ${colors.blackRGBA(0.7)};
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 90%;
  overflow: hidden;
  font-weight: ${props => (props.unread ? 'bold' : 'normal')};
`

const LastMessageDate = styled(Text)`
  font-size: 12px;
  color: ${colors.blackRGBA(0.4)};
`

const Title = styled(Text)`
  font-weight: ${props => (props.unread ? 'bold' : 'normal')};
  font-size: 16px;
  color: ${props =>
    props.unread ? colors.blackRGBA(0.9) : colors.blackRGBA(0.7)};
  margin-bottom: 4px;
  text-align: left;
  height: 18px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const LastMessageExcerpt = styled(Text)`
  font-weight: ${props => (props.unread ? 'bold' : 'normal')};
  font-size: 14px;
  color: ${props =>
    props.unread ? colors.blackRGBA(0.7) : colors.blackRGBA(0.6)};
  text-align: left;
  height: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export default ConversationItem
