import React from 'react'

import styled from 'styled-components'
import formatDate from 'utils/formatDate'
import pluralize from 'pluralize'
import { get, debounce } from 'lodash'

import { RenderOnAction } from 'utils/StateMachine'

import Icon from 'components/Icon'
import Loader from 'components/Loader'
import { TouchableOpacity } from 'components/Native'

import colors from 'styles/colors'

import NextStepsList from '../components/NextStepsList'

import root from 'utils/windowOrGlobal'

class Menu extends React.Component {
  constructor (props) {
    super(props)

    this.transitionNext = debounce(this.transitionNext, 250)
    this.containerHeight = root.window.offsetHeight
  }

  componentDidMount () {
    const { bookingType } = this.props

    const stayFor = bookingType === 'business' ? 'business' : 'fun'
    this.store.setData({ stayFor })

    this.store.transition({ type: 'NEXT' })
  }

  get store () {
    return get(this.props, 'proposalStore', {})
  }

  get data () {
    return get(this.store, 'state.data', {})
  }

  get activeState () {
    return get(this.store, 'state.machineState.value', {})
  }

  get activeIndex () {
    if (this.activeState === 'start') {
      return -1
    }

    const activeIndex = this.props.steps.findIndex(
      step => step.key === this.activeState
    )
    return activeIndex
  }

  get datesAndGuests () {
    const { startDate, endDate, guests } = this.data

    if (!(startDate && endDate)) return null

    console.log({ startDate, endDate })
    const format = 'E, MMM dd'
    const start = formatDate(startDate, format)
    const end = formatDate(endDate, format)

    return `${start} - ${end} | ${guests} ${pluralize('Guest', guests)}`
  }

  get hostInfo () {
    const { host } = this.data

    if (!host) return null

    return `${host.name} - ${host.place.city}`
  }

  get roomInfo () {
    const { rooms } = this.data

    if (!rooms || rooms.length === 0) return null

    const first = rooms[0]
    const other =
      rooms.length > 1
        ? `+ ${rooms.length - 1} other ${pluralize('room', rooms.length - 1)}`
        : ''

    return `${first.name}, ${first.peopleSize} ${pluralize(
      'sleep',
      first.peopleSize
    )} ${other}`
  }

  get priceInfo () {
    const { price } = this.data

    if (!price) return null

    return `$${price.night} - Total: $${price.total}`
  }

  get payInfo () {
    const { card } = this.data

    if (!card) return null

    return `${card.brand} - ${card.last4}`
  }

  getStepResult (key) {
    const results = {
      selectDates: 'datesAndGuests',
      selectHost: 'hostInfo',
      chooseRoom: 'roomInfo',
      inputPrice: 'priceInfo',
      selectPayment: 'payInfo'
    }

    return this[results[key]]
  }

  transitionNext = (params = {}) => {
    this.store.transition({ type: 'NEXT', ...params })
  }

  navigatePrevious = step => {
    step = step || this.props.steps[this.activeIndex - 1]

    if (!step) {
      return
    }

    return () => {
      this.transitionNext({
        type: 'RESET',
        from: step.key
      })
    }
  }

  renderSteps () {
    const { bookingType, steps } = this.props
    const isReview = this.activeState === 'reviewProposal'
    const isFun = bookingType !== 'business'

    return steps.map((step, index) => {
      const active = step.key === this.activeState
      const past = index < this.activeIndex
      const isLast =
        step.key ===
        (isReview ? (isFun ? 'chooseRoom' : 'selectPayment') : 'reviewProposal')

      const onPress = past ? this.navigatePrevious(step) : undefined

      if (isReview && active) {
        return null
      }

      return (
        <StepRow
          key={step.icon}
          active={active}
          past={past}
          onPress={onPress}
          activeOpacity={past || active ? 0.7 : 1}
        >
          <StepRowInfo>
            <StepIcon name={step.icon} active={active ? 1 : undefined} />
            <StepRowLabel>
              <StepRowLabelTop>
                {past ? step.labelBottom : step.labelTop}
              </StepRowLabelTop>

              <StepRowLabelBottom active={active} past={past}>
                {past
                  ? this.getStepResult(step.key)
                  : step.labelBottom.toLowerCase()}
              </StepRowLabelBottom>
            </StepRowLabel>
          </StepRowInfo>

          <StepRowCheckmark>
            {index !== 0 && (
              <StepRowCheckmarkLine top active={active} past={past} />
            )}

            {!isLast && (
              <StepRowCheckmarkLine bottom active={active} past={past} />
            )}

            <StepRowCheckmarkCircle active={active} past={past}>
              {past && (
                <Icon
                  color={colors.white}
                  name='checkmark-i'
                  width='1rem'
                  height='1rem'
                />
              )}
              {active && <StepRowCheckmarkDot />}
            </StepRowCheckmarkCircle>
          </StepRowCheckmark>
        </StepRow>
      )
    })
  }

  render () {
    const isReview = this.activeState === 'reviewProposal'
    const { bookingType } = this.props

    return (
      <RenderOnAction value='showSending'>
        {loading =>
          loading ? (
            <Wrapper>
              <Container center containerHeight={this.containerHeight}>
                <Loader centered size={32} />
              </Container>
            </Wrapper>
          ) : (
            <Wrapper>
              <div>
                <Container containerHeight={this.containerHeight}>
                  {this.renderSteps()}
                </Container>
                <NextStepsList isActive={isReview} bookingType={bookingType} />
              </div>
            </Wrapper>
          )
        }
      </RenderOnAction>
    )
  }
}

const Wrapper = styled.div`
  height: 100%;
`

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100%;
  height: 100%;
  min-height: ${props => props.containerHeight};
  background-color: ${colors.white};

  ${props =>
    props.center &&
    `
    justify-content: center;
    align-items: center;
  `};

  @media (min-width: 992px) {
    flex-direction: column;
  }
`

const StepRow = styled(TouchableOpacity)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 0.5rem;
  align-items: center;
  background-color: ${props =>
    props.past
      ? colors.ice
      : props.active
        ? colors.activeYellow
        : colors.white};
  width: 100%;
  padding: 16px;
  overflow: hidden;
  @media (min-width: 992px) {
    flex-direction: row;
    justify-content: space-between;
    padding: ${props => (props.active ? 24 : 16)}px 16px;
  }
`

const StepRowInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  @media (min-width: 992px) {
    flex-direction: row;
    gap: initial;
  }
`

const StepIcon = styled(Icon)`
  height: 1.75rem;
  width: 1.75rem;
  margin: 0 8px 0 8px;
  color: ${props => (props.active ? colors.darkBlue : colors.blackRGBA(0.24))};
  @media (min-width: 992px) {
    margin: 0 18px 0 8px;
  }
`

const StepRowLabel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width: 992px) {
    align-items: start;
  }
`

const StepRowLabelTop = styled.p`
  color: ${props =>
    props.past ? colors.blackRGBA(0.5) : colors.blackRGBA(0.4)};
  font-size: 0.825rem;
  margin-bottom: 4px;
  @media (min-width: 992px) {
    font-size: 1.125rem;
  }
`

const StepRowLabelBottom = styled.p`
  color: ${props =>
    props.active
      ? colors.blue
      : props.past
        ? colors.blackRGBA(0.6)
        : colors.blackRGBA(0.7)};
  font-size: ${props => (props.past ? 0.5 : 0.825)}rem;
  margin-bottom: 0;
  @media (min-width: 992px) {
    font-size: ${props => (props.past ? 1 : 1.125)}rem;
  }
`

const StepRowCheckmark = styled.div`
  display: none;
  flex-direction: column;
  justify-content: center;
  position: relative;
  align-items: center;
  @media (min-width: 992px) {
    display: flex;
  }
`

const StepRowCheckmarkCircle = styled.div`
  display: flex;
  width: 2rem;
  height: 2rem;
  border-radius: 1rem;
  background-color: ${props => (props.past ? colors.blue : colors.white)};
  border-width: 3px;
  border-style: solid;
  border-color: ${props =>
    props.active ? colors.white : props.past ? colors.blue : colors.darkIce};
  justify-content: center;
  align-items: center;

  ${props =>
    (props.past || props.active) &&
    `
  box-shadow: 0 1px 4px ${colors.blackRGBA(0.1)};
  `};
`

const StepRowCheckmarkDot = styled.div`
  width: 0.625rem;
  height: 0.625rem;
  border-radius: ${0.625 / 2}rem;
  background-color: ${colors.blue};
`

const StepRowCheckmarkLine = styled.div`
  width: 3px;
  height: 100%;
  background-color: ${props =>
    (props.active && props.top) || props.past ? colors.blue : colors.darkIce};
  position: absolute;
  left: 50%;
  top: ${props => (props.top ? 'auto' : '100%')};
  bottom: ${props => (props.bottom ? 'auto' : '100%')};
  margin-left: -1.5px;
`

export default Menu
