import React from 'react'

import styled from 'styled-components'

import { View, Text, TouchableOpacity } from 'components/Native'

import colors from 'styles/colors'

const EmptyMessageView = ({ name, onRefresh }) => {
  return (
    <Wrapper>
      <Message>Looks like you have no {name}</Message>

      {onRefresh && (
        <TouchableOpacity onClick={onRefresh}>
          <Label>Refresh</Label>
        </TouchableOpacity>
      )}
    </Wrapper>
  )
}

const Wrapper = styled(View)`
  flex: 1;
  justify-content: center;
  align-items: center;
`

const Message = styled(Text)`
  font-size: 18px;
  color: ${colors.darkGray};
  margin-bottom: 32px;
`

const Label = styled(Text)`
  font-size: 18px;
  color: ${colors.blue};
`

export default EmptyMessageView
