import React from 'react'

import styled from 'styled-components'

import colors from 'styles/colors'

import { getFullName, getUserPicture } from 'utils/profile-info'

class MutualStayPals extends React.PureComponent {
  static defaultProps = {
    stayPals: []
  }

  get tooltip () {
    const { stayPals } = this.props
    let tooltip

    if (stayPals.length === 1) {
      tooltip = getFullName(stayPals[0]) + ' is a mutual Friend'
    } else if (stayPals.length > 1) {
      tooltip =
        'Mutual Friends include ' +
        stayPals
          .slice(0, 5)
          .map(getFullName)
          .join(', \n')

      if (stayPals.length > 5) {
        tooltip += `\n and ${stayPals.length - 5} more`
      }
    }

    return tooltip
  }

  render () {
    const { stayPals } = this.props

    if (stayPals.length === 0) {
      return null
    }

    let sp1, sp2

    sp1 = {
      src: getUserPicture({
        ...stayPals[0],
        friend: true
      }),
      name: getFullName(stayPals[0])
    }

    if (stayPals[1]) {
      sp2 = {
        src: getUserPicture({
          ...stayPals[1],
          friend: true
        }),
        name: getFullName(stayPals[1])
      }
    }

    return (
      <div
        className='flex flex-row justify-center items-center'
        data-rh={this.tooltip}
        data-rh-at={stayPals.length > 2 ? 'top' : 'left'}
      >
        {stayPals.length > 2 && (
          <p className='mb0 f7 black-50'>+ {stayPals.length - 2} Mutual</p>
        )}
        <Avatar className='ml1' src={sp1.src} alt={sp1.name} />
        {!!sp2 && <Avatar small src={sp2.src} alt={sp2.name} />}
      </div>
    )
  }
}

const Avatar = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px solid ${colors.redRGBA(0.5)};
  background: white;

  ${props =>
    props.small &&
    `
    position: relative;
    transform: scale(0.8);
    left: -10px;
    margin-right: -10px;
    z-index: -1;
    `};
`

export default MutualStayPals
