import api from 'utils/api'

export const unStayPal = stayPalID => {
  return api.delete(`/connections/${stayPalID}`)
}

export const getStayPals = params => {
  return api.get('/stay_pals', params)
}

export const destroyLookup = () => {
  return api.get('/account/security/destroy_lookup')
}

export const destroyAccount = async ({ email, password }) => {
  const loginResponse = await api.post('/auth/sign_in/', { email, password })

  if (loginResponse[0]) return loginResponse

  return api.delete('/account/security/destroy')
}
