import React from 'react'

import Markdown from 'markdown-to-jsx'

import formatDate from 'utils/formatDate'

import { getUserPicture } from 'utils/profile-info'

import styled from 'styled-components'
import { Link } from '@reach/router'

import { View, Text, Image } from 'components/Native'
import { parseEmoji, EmojiComponent } from 'components/emoji.js'

import colors from 'styles/colors'

class ChatMessage extends React.Component {
  shouldComponentUpdate ({ message, conversationId }) {
    return (
      message.id !== this.props.message.id ||
      conversationId !== this.props.conversationId
    )
  }

  get isCurrentUser () {
    const { message } = this.props
    return message.user && message.user.current
  }

  get isFriend () {
    const { message } = this.props
    return message.user && message.user.friend
  }

  get isBySystem () {
    return this.props.message.bySystem
  }

  get pictureURI () {
    const { message } = this.props

    if (this.isBySystem) {
      const systemUser = {
        cloudinary_public_id: 'assets/branding/system_avatar'
      }
      return getUserPicture(systemUser, { width: 50 }, true)
    }

    return getUserPicture(message.user, { width: 50 }, true)
  }

  get body () {
    const { message, isViewerHost, detailsLink } = this.props

    if (/must update credit card here/.test(message.body) && !isViewerHost) {
      return (
        <React.Fragment>
          There was a problem processing your payment.{' '}
          <Link
            to={detailsLink}
            className='link dim'
            style={{ color: colors.darkerBlue }}
          >
            Click to view details and update payment information.
          </Link>
        </React.Fragment>
      )
    }

    return (
      <Markdown
        options={{
          overrides: {
            Emoji: EmojiComponent
          }
        }}
      >
        {parseEmoji(message.body)}
      </Markdown>
    )
  }

  get date () {
    const { message } = this.props

    return formatDate(message.createdAt, 'E d, h:mm a')
  }

  get isheader () {
    return this.props.message.isheader
  }
  get isfooter () {
    return this.props.message.isfooter
  }

  render () {
    const { conversationType } = this.props
    const styleProps = {
      user: this.isCurrentUser,
      system: this.isBySystem,
      friend: this.isFriend
    }

    if (this.isBySystem && conversationType === 'shared_destination') {
      return <SystemMessage>{this.body}</SystemMessage>
    }

    if (this.isheader && conversationType === 'potential_hosting') {
      return <SystemMessage isheader>{this.props.message.body}</SystemMessage>
    }

    if (this.isfooter && conversationType === 'potential_hosting') {
      return <SystemMessage isheader>{this.props.message.body}</SystemMessage>
    }

    return (
      <Container {...styleProps}>
        {!this.isCurrentUser && (
          <Picture {...styleProps} source={{ uri: this.pictureURI }} />
        )}

        <MsgBlock>
          <Block {...styleProps}>
            <Msg {...styleProps}>{this.body}</Msg>
          </Block>
          <Time {...styleProps}>{this.date}</Time>
        </MsgBlock>
      </Container>
    )
  }
}

const Container = styled(View)`
  padding: 0 16px;
  flex-direction: row;
  justify-content: ${props => (props.user ? 'flex-end' : 'flex-start')};
  margin-top: 12px;
`

export const Picture = styled(Image)`
  width: 40px;
  height: 40px;
  border-radius: 20px;
  margin-right: 1rem;
  border: 2px solid ${props => (props.friend ? colors.red : colors.blue)};
`

const MsgBlock = styled(View)`
  max-width: 72%;
`

const Block = styled(View)`
  padding: 16px;
  border-radius: 6px;
  background-color: ${props =>
    props.user
      ? colors.blue
      : props.system
        ? colors.lightYellow
        : colors.blackRGBA(0.05)};
`

const Msg = styled(Text)`
  font-size: 16px;
  color: ${props => (props.user ? colors.white : colors.blackRGBA(0.8))};
  word-break: break-word;
  line-height: 1.5;
`

const Time = styled(Text)`
  font-size: 12px;
  color: ${colors.blackRGBA(0.5)};
  margin-top: 8px;
  text-align: ${props => (props.user ? 'right' : 'left')};
`

const SystemMessage = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 16px;
  text-align: center;
  ${({ isheader }) => (isheader ? `margin: 10px 30px` : '')};
  ${({ isheader }) => (isheader ? `padding: 30px 10px` : 'padding: 30px')};
  ${({ isheader }) => (isheader ? `border: 1px solid ${colors.red}` : '')};
  border-radius: 6px;
  color: ${({ isheader }) => (isheader ? colors.red : colors.darkerBlue)};
`

export default ChatMessage
