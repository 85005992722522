import React from 'react'

import styled from 'styled-components'
import { get } from 'lodash'

import colors from 'styles/colors'

import { CheckmarkIcon, LIST } from 'app/components/Sidebar'
import IntegratedStayPalsList from './IntegratedStayPalsList'

const bullets = get(
  LIST.find(({ key }) => key === 'explore'),
  'bullets',
  []
)

const Container = styled.div.attrs({
  id: 'OpenSidebarContainer'
})`
  box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  display: block;

  @media (min-width: 992px) {
    height: 100%;
  }

  @media (min-width: 1025px) {
    width: 37vw;
    display: block;
  }

  @media (min-width: 1025px) and (max-width: 1399px) {
    transform: scale(0.9) translate(-5%, -5%);
    height: 110%;
    margin-right: -4%;
  }

  @media (min-width: 1300px) {
    width: 35vw;
  }

  @media (min-width: 1400px) {
    width: 33vw;
  }

  @media (min-width: 1600px) {
    width: 25vw;
  }

  @media (min-width: 2000px) {
    width: 500px;
  }
`

const BulletList = styled.ul`
  margin: 0 auto;
  padding: 0.7rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;

  li {
    display: flex;
    flex-direction: row;

    span {
      color: ${colors.blackRGBA(0.5)};
      font-size: calc(12px + 4 * ((100vw - 320px) / 1580));
      line-height: 1.5;
      white-space: pre;
    }
  }

  @media (min-width: 992px) {
    padding: 1.5rem;
    display: block;
  }

  @media (min-width: 1025px) and (max-width: 1900px) {
    li {
      margin: 0 0 4px;
    }
  }
`

const HintContainer = styled.div.attrs({
  id: 'HintContainer'
})`
  // min-height: 15.56vh;
  background: ${colors.ice};
  display: flex;
  flex-direction: row;
  align-items: center;
  @media (min-width: 992px) {
    min-height: 15.56vh;
  }
`

class OpenSidebar extends React.PureComponent {
  render () {
    const { query, mapStore } = this.props

    return (
      <Container>
        <HintContainer>
          <BulletList className='list'>
            {bullets.map(bullet => (
              <li key={bullet}>
                <i>
                  <CheckmarkIcon />
                </i>{' '}
                <span>{bullet}</span>
              </li>
            ))}
          </BulletList>
        </HintContainer>
        <IntegratedStayPalsList query={query} mapStore={mapStore} />
      </Container>
    )
  }
}

export default OpenSidebar
