import React from 'react'

import styled from 'styled-components'

import InfiniteList, { SmallLoader } from 'components/InfiniteList'

import { RenderOnAction } from 'utils/StateMachine'

import ErrorMessageView from 'components/ErrorMessageView'

import ConversationItem, { ITEM_HEIGHT } from './ConversationItem'

class ConversationsList extends React.PureComponent {
  componentDidMount () {
    this.props.load()
  }

  componentWillUnmount () {
    this.props.unload()
  }

  componentDidUpdate ({ list, onPressItem }) {
    if (
      list.length === 0 &&
      this.props.list.length > 0 &&
      isNaN(this.props.conversationId)
    ) {
      onPressItem(this.props.list[0])
    }
  }

  _keyExtractor = item => `${item.id}`

  _renderItem = ({ item }) => {
    const { onPressItem } = this.props

    return (
      <ConversationItem
        id={item.id}
        data={item}
        onPress={() => onPressItem(item)}
      />
    )
  }

  render () {
    const {
      list,
      retry,
      loadNextPage,
      loading,
      hasMore,
      height,
      lastUpdated
    } = this.props

    return (
      <React.Fragment>
        <RenderOnAction value='showLoading'>
          <SmallLoader />
        </RenderOnAction>

        <RenderOnAction value='showError'>
          <ErrorMessageView retry={retry} />
        </RenderOnAction>

        <RenderOnAction value='showList'>
          <Wrapper style={{ height }}>
            <InfiniteList
              height={height}
              width={400}
              itemHeight={ITEM_HEIGHT}
              renderItem={this._renderItem}
              data={list}
              loadMore={loadNextPage}
              loading={loading}
              hasMore={hasMore}
              extraData={lastUpdated}
            />
          </Wrapper>
        </RenderOnAction>
      </React.Fragment>
    )
  }
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
`

export default ConversationsList
