import React from 'react'
import styled from 'styled-components'
import Icon from 'components/Icon'
import { Link, navigate } from 'gatsby'
import { useLocation } from '@reach/router'
import { HEADER_CONFIG } from '../config'

const AMHeaderContainer = styled.div`
  background-color: #fff;
  padding: 10px 0 10px 20px;
  display: flex;
  align-items: center;
  padding-top: 1rem;
  .right-side {
    display: flex;
    margin-left: auto;
    padding-right: 20px;
    .am-menu-active {
      &::after {
        content: '';
        width: 100%;
        height: 1px;
        position: absolute;
        bottom: -2px;
        left: 0;
        background-color: #737373;
      }
    }
    @media screen and (max-width: 991px) {
      display: none;
    }
  }
`

const CustomLink = styled(Link)`
  text-decoration: none;
  margin-left: 50px;
  color: #737373;
  position: relative;
  &::active,
  &::visited {
    text-decoration: none;
    color: #737373;
  }
  &:hover {
    color: #000;
  }
`

const PageSelector = styled.select`
  width: 100%;
  max-width: 10rem;
  padding: 16px;
  background: white;
  border: 1px solid #c9c9ca;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 14px;
  margin: 0 2rem;
  margin-left: auto;

  @media screen and (min-width: 992px) {
    display: none;
  }
`
const Header = () => {
  const location = useLocation()
  const { search, pathname } = location
  return (
    <AMHeaderContainer>
      <Icon name='am-logo' width={200} height={40} />
      <div className='right-side'>
        {HEADER_CONFIG.map(conf => {
          const isActive = `${pathname}${search}` === conf.route
          return (
            <CustomLink
              to={conf.route}
              className={isActive ? 'am-menu-active' : ''}
              key={conf.route}
              partiallyActive
              activeClassName='am-menu-active'
            >
              {conf.label}
            </CustomLink>
          )
        })}
      </div>
      <PageSelector onChange={e => navigate(e.target.value)} value={pathname}>
        {HEADER_CONFIG.map(s => (
          <option key={s.route} value={s.route} children={s.label} />
        ))}
      </PageSelector>
    </AMHeaderContainer>
  )
}

export default Header
