import React, { Fragment } from 'react'

import pick from 'lodash/pick'

import { required } from 'utils/field-validators'

import Input from 'components/Input'
import PromptModal from '../../../components/PromptModal'
import AddressSearchField from '../../../components/AddressSearchField'

const addressFields = [
  'address1',
  'address2',
  'city',
  'state',
  'country',
  'zipCode',
  'latitude',
  'longitude'
]

const EditAddressModal = ({ place, onSubmit }) => {
  return (
    <PromptModal
      title='Edit Address'
      onSubmit={onSubmit}
      getInitialValues={() => pick(place, addressFields)}
    >
      {({ submitting, form }) => (
        <Fragment>
          <AddressSearchField
            name='address1'
            validate={required}
            disabled={submitting}
            form={form}
          />

          <Input
            name='city'
            label='City'
            validate={required}
            disabled={submitting}
          />

          <div className='w-100 flex flex-row justify-between'>
            <div style={{ width: '48%' }}>
              <Input
                name='state'
                label='State'
                validate={required}
                disabled={submitting}
              />
            </div>

            <div style={{ width: '48%' }}>
              <Input
                name='zipCode'
                label='Zip/Postal'
                validate={required}
                disabled={submitting}
              />
            </div>
          </div>

          <Input
            name='country'
            label='Country'
            validate={required}
            disabled={submitting}
          />

          <Input
            name='address2'
            label='Apartment number / unit / suite'
            disabled={submitting}
          />
        </Fragment>
      )}
    </PromptModal>
  )
}

export default EditAddressModal
