import React from 'react'

import get from 'lodash/get'
import pluralize from 'pluralize'

import { cloudinary } from 'utils/profile-info'

import styled, { css } from 'styled-components'

import Icon from 'components/Icon'

import PhotoShowcaseModal from 'app/screens/hosting/screens/Rooms/components/PhotoShowcaseModal'

const privateOrShared = priv => (priv ? 'Private' : 'Shared')

class RoomItem extends React.Component {
  state = {
    openPicturesShowcase: false
  }

  togglePicturesShowcase = () => {
    if (!get(this.props, 'images[0]')) return

    this.setState({
      openPicturesShowcase: !this.state.openPicturesShowcase
    })
  }

  render () {
    const {
      name,
      peopleSize,
      privateRoom,
      privateBathroom,
      images
    } = this.props

    const image = get(images, '[0]')

    return (
      <div className='mb3'>
        <Container
          shouldHover={!!image}
          className='bg-white bn pa0 br3 flex flex-row justify-between items-center overflow-hidden'
          style={{ width: 380 }}
          onClick={this.togglePicturesShowcase}
        >
          <div style={{ padding: 24 }} className='tl'>
            <p className=' black-80 mb2 truncate' style={{ fontSize: 18 }}>
              {name}
            </p>

            <p className=' black-60 mb1'>
              <span className='fw6 black-80'>{peopleSize}</span>{' '}
              {pluralize('Sleep', peopleSize)}
            </p>

            <p className=' black-60 mb1'>{privateOrShared(privateRoom)} room</p>

            <p className=' black-60 mb1'>
              {privateOrShared(privateBathroom)} bathroom
            </p>
          </div>

          <Picture item={image} />
        </Container>

        {this.state.openPicturesShowcase && (
          <PhotoShowcaseModal
            pictures={images}
            onClose={this.togglePicturesShowcase}
          />
        )}
      </div>
    )
  }
}

const Picture = ({ item }) => {
  let picture
  let size = { width: 148, height: 150 }

  if (get(item, 'url') && !get(item, 'cloudinaryPublicId')) {
    picture = item.url
  }

  const publicId = get(item, 'cloudinaryPublicId')
  if (publicId) {
    picture = cloudinary(publicId, {
      ...size,
      crop: 'fill'
    })
  }

  if (picture) {
    return (
      <img src={picture} alt='Stay room picture' className='img' style={size} />
    )
  }

  return (
    <div
      className='flex justify-center items-center'
      style={{ ...size, backgroundColor: '#eef3f7' }}
    >
      <Icon name='better-bed' height={48} color='#a5b2bd' />
    </div>
  )
}

const Container = styled.button`
  box-shadow: 0px 2px 6px 0 rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.5s ease-in-out;
  cursor: normal;

  ${props =>
    props.shouldHover &&
    css`
      &:hover,
      &:focus {
        outline: none;
        cursor: pointer;
        box-shadow: 0px 4px 10px 0 rgba(0, 0, 0, 0.3);
      }
    `};
`

export default RoomItem
