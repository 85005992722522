import api from 'utils/api'
import uploadImage from 'utils/upload-image'
import root from 'utils/windowOrGlobal'

/**
 * data = {
 *  firstName,
 *  lastName,
 *  dob,
 *  cloudinaryImgUrl,
 *  cloudinaryPublicId
 * }
 */
export const updateProfileDetails = async data => {
  return api.put('/account/update_profile_info', { user: data })
}

export const uploadPhoto = async ({ file, userID, onUploadProgress }) => {
  const formData = new root.FormData()

  formData.append('upload_preset', 'uploads')
  formData.append('folder', '/users/' + userID)
  formData.append('file', file, 'avatar')

  return uploadImage(formData, onUploadProgress)
  // cloudinaryImgUrl = data.secureUrl
  // cloudinaryPublicId = data.publicId
}

export const deletePhoto = () => {
  return api.delete('/account/delete_picture')
}

export const addNewEmail = async email => {
  return api.put('/account/add_email', { email })
}

export const resendEmailConfirmation = async email => {
  return api.put('/account/resend_confirmation_email', { email })
}

export const setPrimaryEmail = async email => {
  return api.put('/account/set_primary_email', { email })
}

export const deleteEmail = async email => {
  return api.delete('/account/remove_email', { email })
}

export const addNewPhone = async phone => {
  return api.put('/account/add_phone_number', { phoneNumber: phone })
}

export const setPrimaryPhone = async phone => {
  return api.put('/account/set_primary_number', { phoneNumber: phone })
}

export const deletePhone = async phone => {
  return api.delete('/account/remove_phone_number', { phoneNumber: phone })
}

export const confirmEmail = async code => {
  return api.put('/account/confirm_email', { confirmation_code: code })
}
