import React from 'react'

import styled from 'styled-components'

import { debounce, get } from 'lodash'

import { TextInput } from 'components/Input'
import InfiniteList from 'components/InfiniteList'
import MutualStayPals from 'components/MutualStayPals'
import AddStayPalButton from '../AddStayPalButton'
import InvitePanel from '../InvitePanel'
import InviteSuccessModal from '../InviteSuccessModal'
import PendingRequestsButton from '../PendingStayPalRequests/PendingRequestsButton'

import {
  getFullName,
  getUserPicture,
  getStayPalRequestStatus
} from 'utils/profile-info'
import { RenderOnAction } from 'utils/StateMachine'
import root from 'utils/windowOrGlobal'
import getHeight from 'utils/getHeight'

import colors from 'styles/colors'
import MediaQuery from 'react-responsive'

import withStore, { defaultStayPals, defaultStayPals2 } from './store'

const ITEM_HEIGHT = 60

export const TABS = [
  {
    title: 'Friends-of-Friends',
    // subTitle: 'friends-of-friends',
    tooltip: 'Friends of your friends',
    key: 'stayPals2',
    routeKey: 'spsOfSps'
  },
  {
    title: 'Friends',
    tooltip: 'Your friends',
    key: 'stayPals',
    routeKey: 'myStayPals'
  },
  {
    title: 'Invite Friends',
    tooltip: 'Invite friends by email',
    key: 'invite',
    routeKey: 'inviteSps'
  },
  {
    routeKey: 'spRequests'
  }
]

class IntegratedStayPalsList extends React.Component {
  state = {
    showModal: '',
    showPendingRequests: false,
    emails: [],
    contentHeight: 600,
    bodyHeight: 150,
    listHeight: 600
  }

  shouldComponentUpdate (nextProps, nextState) {
    if (
      nextProps !== this.props ||
      nextProps.machineStore.state !== this.props.machineStore.state ||
      nextProps.mapStore.state !== this.props.mapStore.state ||
      nextState !== this.state
    ) {
      return true
    }

    return false
  }

  get activeTab () {
    return get(this.props.machineStore, 'state.activeTab', 'stayPals2')
  }

  get error () {
    return get(this.props.machineStore, 'state.event.error', {})
  }

  get inProgress () {
    return get(this.props.machineStore, 'state.inProgress', [])
  }

  get stayPals () {
    return get(this.props.machineStore, 'state.stayPals', defaultStayPals)
  }

  get stayPals2 () {
    return get(this.props.machineStore, 'state.stayPals2', defaultStayPals2)
  }

  makeSP = sp => {
    const { machineStore, mapStore } = this.props
    const stayPalRequestStatus = getStayPalRequestStatus(sp)

    const type =
      stayPalRequestStatus === 'received'
        ? 'ACCEPT_SP_REQUEST'
        : 'SEND_SP_REQUEST'

    const cb = () =>
      mapStore.transition({
        type: 'SET_SP_REQUEST_SUCCESS',
        id: sp.id,
        stayPalRequestStatus
      })

    machineStore.transition({
      type,
      sp,
      cb
    })
  }

  sendInvite = emails => {
    this.props.machineStore.transition({
      type: 'SEND_SP_REQUEST',
      sp: { id: 'invite', email: emails },
      cb: () =>
        this.setState({
          showModal: 'inviteSuccess',
          emails
        })
    })
  }

  closeInviteSuccessModal = () => {
    this.setState({
      showModal: '',
      emails: []
    })
  }

  loadMore = () => {
    const { activeTab } = this

    if (!['stayPals', 'stayPals2'].includes(activeTab)) {
      return
    }

    this.props.machineStore.transition({
      type: 'LOAD',
      spType: activeTab
    })
  }

  searchStayPals = (term, spType) => {
    if (!['stayPals', 'stayPals2'].includes(spType)) {
      return
    }

    this.props.machineStore.transition({
      type: 'SEARCH_STAYPAL',
      spType,
      term
    })
  }

  delayedSearch = debounce(this.searchStayPals, 500, {
    trailing: true,
    leading: false
  })

  renderItem = ({ item }) => {
    const { activeTab, inProgress, props } = this
    const friend = activeTab === 'stayPals'
    const name = getFullName(item)
    const picture = getUserPicture({ ...item, friend })
    let address = ''
    let onClick

    if (item.primaryPlace) {
      const { city, country } = item.primaryPlace
      address = `${city}, ${country}`

      onClick = () => {
        if (props.mapStore.state.activeDetails.loading) return

        if (
          Math.abs(
            props.mapStore.state.location.center.lat - item.primaryPlace.lat
          ) < 1e-6 ||
          Math.abs(
            props.mapStore.state.location.center.lng - item.primaryPlace.lng
          ) < 1e-6
        ) {
          return
        }

        props.mapStore.transition({
          type: 'SET_LOCATION',
          location: {
            zoom: Math.max(props.query.zoom, 15),
            center: {
              lat: item.primaryPlace.lat,
              lng: item.primaryPlace.lng
            }
          }
        })
      }
    }

    const tooltip = address ? `${name} from ${address}` : name

    return (
      <div className='pv2 pr3 flex flex-row items-center' key={item.id}>
        <div
          className='flex flex-row items-center pr3 o-80 glow'
          style={{ flex: 1, cursor: item.primaryPlace ? 'pointer' : 'default' }}
          onClick={onClick}
        >
          <Avatar
            friend={friend}
            src={picture}
            alt={`${name}'s picture`}
            data-rh={tooltip}
            data-rh-at='right'
          />

          <div
            className='ph2 h-100'
            style={{
              width: item.mutualStayPals.length > 2 ? '165px' : '200px'
            }}
          >
            <p className='f6 black-80 mb0 truncate'>{name}</p>
            {!!address && (
              <p className='f7 black-60 mt1 mb0 truncate'>{address}</p>
            )}
          </div>
        </div>

        <MutualStayPals stayPals={item.mutualStayPals} />

        {activeTab === 'stayPals2' && (
          <AddStayPalButton
            makeSP={() => this.makeSP(item)}
            stayPalRequestStatus={getStayPalRequestStatus(item)}
            loading={inProgress.includes(item.id)}
          />
        )}
      </div>
    )
  }

  setTerm = e => {
    const term = e.target.value
    const spType = this.activeTab

    this.props.machineStore.transition({
      type: 'SET_TERM',
      term,
      spType
    })

    this.delayedSearch(term, spType)
  }

  setTab = (activeTab, loading) => {
    if (loading || this.activeTab === activeTab) return

    this.props.machineStore.transition({
      type: 'SET_TAB',
      activeTab
    })
  }

  updateHeights = () => {
    let contentHeight = getHeight('BodyWrapper') - getHeight('HintContainer')

    let bodyHeight =
      contentHeight - getHeight('IntegratedStayPalsList__Tab-Header')

    let listHeight = bodyHeight - getHeight('IntegratedStayPalsList__Search')

    // Adjust based on the scaling we do on certain resolutions
    // to fit the sidebar on the screen better
    const multiply = n => n + n * 0.1
    contentHeight = multiply(contentHeight)
    bodyHeight = multiply(bodyHeight)
    listHeight = multiply(listHeight)

    this.mounted &&
      this.setState({
        contentHeight,
        bodyHeight,
        listHeight
      })
  }

  setTabFromQuery = (query = this.props.query) => {
    const { activePanel } = query

    if (!activePanel) {
      return
    }

    const activeTab = TABS.find(({ routeKey }) => routeKey === activePanel)

    if (activeTab && activeTab.key) {
      this.setTab(activeTab.key)
    }
  }

  _renderContent = (loading, isDesktopOrLaptop) => {
    const { activeTab } = this
    const { contentHeight, bodyHeight, listHeight } = this.state
    return (
      <div
        className='overflow-hidden b--black-10 bt bg-white-80 w-100'
        style={{
          cursor: loading ? 'progress' : 'default',
          ...(isDesktopOrLaptop && { height: contentHeight })
        }}
        id='IntegratedStayPalsList'
      >
        <div id='IntegratedStayPalsList__Tab-Header'>
          <PendingRequestsButton />

          <div className='dt dt--fixed bb b--black-05'>
            {TABS.filter(tab => tab.key).map(tab => (
              <a
                onClick={() => this.setTab(tab.key, loading)}
                style={{ borderColor: colors.darkBlue }}
                className={`dtc tc pv2 bg-transparent v-mid ${
                  tab.key === activeTab ? 'bb bw1' : 'pointer black-40 bn'
                }`}
                key={tab.key}
                data-rh={tab.tooltip}
              >
                {tab.title}
                {!!tab.subTitle && (
                  <span className='f7 black-40'>
                    <br />
                    {tab.subTitle}
                  </span>
                )}
              </a>
            ))}
          </div>
        </div>

        {activeTab === 'invite' && (
          <InvitePanel
            height={bodyHeight}
            sendInvite={this.sendInvite}
            loading={loading}
          />
        )}

        {activeTab !== 'invite' && (
          <React.Fragment>
            <div id='IntegratedStayPalsList__Search' className='ph3 pv4'>
              <TextInput
                small
                value={this[activeTab].term}
                className='w-100'
                placeholder='Search by name...'
                onChange={this.setTerm}
              />
            </div>

            <div className='ph3'>
              <InfiniteList
                height={listHeight}
                width='100%'
                itemHeight={ITEM_HEIGHT}
                renderItem={this.renderItem}
                data={this[activeTab].list}
                loadMore={() => this.loadMore()}
                loading={loading}
                hasMore={this[activeTab].hasMore}
                extraData={this.props.machineStore.state}
              />
            </div>
          </React.Fragment>
        )}
      </div>
    )
  }

  componentDidMount () {
    this.mounted = true

    root.setTimeout(() => {
      this.updateHeights()
      this.setTabFromQuery()
    }, 3000)
  }

  componentWillUnmount () {
    this.mounted = false
  }

  handleMediaQueryChange = matches => {
    matches
      ? this.updateHeights()
      : this.mounted &&
        this.setState({
          listHeight: 120
        })
  }

  render () {
    const {
      showModal,
      emails
      // showPendingRequests TODO: show pending request panel
    } = this.state

    return (
      <RenderOnAction value='showLoading'>
        {loading => (
          <div className='flex flex-column items-center justify-center center w-100'>
            <div className='w-100' />
            <MediaQuery minWidth={992} onChange={this.handleMediaQueryChange}>
              {matches => this._renderContent(loading, matches)}
            </MediaQuery>
            <InviteSuccessModal
              show={showModal === 'inviteSuccess'}
              emails={emails}
              onClose={this.closeInviteSuccessModal}
            />
          </div>
        )}
      </RenderOnAction>
    )
  }
}

export const Avatar = styled.img`
  width: 44px;
  height: 44px;
  border-radius: 50%;
  border: 2px solid
    ${props => (props.friend ? colors.redRGBA(0.5) : colors.blueRGBA(0.5))};
`

export default withStore(IntegratedStayPalsList)
