import React from 'react'

import get from 'lodash/get'

import { Link, Router } from '@reach/router'

import parseQueryString from 'utils/parse-querystring'

import styled from 'styled-components'

import colors from 'styles/colors'

import Inbox from './screens/Inbox'
import Archive from './screens/Archive'
import Conversation from './screens/Conversation'
import StayDetails from 'app/screens/stays/components/StayDetails'

import getHeight from 'utils/getHeight'
import root from 'utils/windowOrGlobal'

const isInboxActive = ({ location }) => {
  const isCurrent = !location.pathname.match(/archive/)
  return isCurrent ? { active: 1 } : null
}

const isArchiveActive = ({ location }) => {
  const isCurrent = location.pathname.match(/archive/)
  return isCurrent ? { active: 1 } : null
}

class MesssagesIndex extends React.Component {
  state = {
    conversationData: {},
    bodyHeight: 1000,
    sidebarHeight: 1000
  }

  setConversationData = conversationData => this.setState({ conversationData })

  componentDidMount () {
    this.mounted = true

    root.setTimeout(this.updateHeights, 250)
  }

  componentWillUnmount () {
    this.mounted = false
  }

  updateHeights = () => {
    let bodyHeight = getHeight('BodyWrapper') - 144

    let sidebarHeight =
      bodyHeight -
      getHeight('Messages__Sidebar-Header') -
      getHeight('Messages__Sidebar__Searchbox')

    let contentHeight =
      bodyHeight -
      getHeight('SC__Message__ConversationHeader') -
      getHeight('SC__MessageInput')

    this.mounted &&
      this.setState({
        contentHeight,
        bodyHeight,
        sidebarHeight
      })
  }

  render () {
    const { conversationData, sidebarHeight, contentHeight } = this.state

    const { location, navigate } = this.props

    const queryString = get(location, 'search', '')
    const query = parseQueryString(queryString)

    return (
      <Container>
        <div className='bg-white flex flex-row center w-100 mw8 mv-5 bordered'>
          <Sidebar>
            <div id='Messages__Sidebar-Header'>
              <p className='black-70 b b--black-20 bb f4 mb0 pa4 tc'>
                Messages
              </p>
              <div className='b b--black-10 bb flex items-center tc w-100'>
                <TabButton to='./' getProps={isInboxActive}>
                  Inbox
                </TabButton>
                <TabButton to='archive' getProps={isArchiveActive}>
                  Archive
                </TabButton>
              </div>
            </div>
            <Router className='w-100'>
              <Inbox
                path='inbox/*'
                default
                query={query}
                setConversationData={this.setConversationData}
                height={sidebarHeight}
              />
              <Archive
                path='archive/*'
                query={query}
                setConversationData={this.setConversationData}
                height={sidebarHeight}
              />
            </Router>
          </Sidebar>

          <Conversation
            {...conversationData}
            contentHeight={contentHeight}
            query={query}
          />

          {query.stay && (
            <StayDetails
              itemId={query.stay}
              convoId={query.conversation}
              navigate={navigate}
              location={location}
              skipReload
            />
          )}
        </div>
      </Container>
    )
  }
}

const Container = styled.div`
  background-color: ${colors.ice};
  width: 100%;
  overflow: auto;
  padding-top: 3rem;
  padding-bottom: 6rem;
  .bordered {
    border: 1px solid rgba(0, 0, 0, 0.09);
  }
`

export const TabButton = styled(Link)`
  border: none;
  background-color: transparent;
  text-decoration: none;
  cursor: pointer;
  width: 50%;
  padding: 1rem 0;
  font-size: 1rem;
  color: ${colors.blackRGBA(0.5)};
  box-shadow: 0px 4px 4px rgba(50, 50, 50, 0.05);
  transition: all 0.25s ease;

  &[active] {
    color: ${colors.darkBlue};
  }

  &:hover,
  &:focus {
    color: ${colors.darkerBlue};
    opacity: 0.9;
  }

  &:active {
    opacity: 0.7;
  }
`

export const LinkButton = styled.button.attrs({ type: 'button' })`
  border: none;
  background-color: transparent;
  color: ${colors.blackRGBA(0.6)};
  cursor: pointer;
  padding: 0 0 2px;
  border-bottom: 2px solid transparent;
  transition: all 0.25s ease;

  &:hover,
  &:focus {
    opacity: 0.9;
    border-bottom-color: ${colors.blackRGBA(0.6)};
  }

  &:active {
    opacity: 0.7;
  }
`

export const Caret = styled.div`
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid ${colors.blackRGBA(0.6)};
  margin-left: 0.25rem;
`

const Sidebar = styled.div`
  width: 400px;
`

export default MesssagesIndex
