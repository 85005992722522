import React from 'react'

import styled from 'styled-components'
import { get } from 'lodash'

import colors from 'styles/colors'

import { CheckmarkIcon, LIST } from 'app/components/Sidebar'

import getHeight from 'utils/getHeight'
import root from 'utils/windowOrGlobal'

const Container = styled.div.attrs({
  id: 'OpenSidebarContainer'
})`
  height: 100%;
  box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.05);
  // width: 500px;
  overflow: hidden;
  .content-container {
    height: initial !important;
  }
  @media (min-width: 992px) {
    width: 500px;
    .content-container {
      height: inherit !important;
    }
  }
`

const BulletList = styled.ul`
  margin: 0 auto;
  padding: 0.5rem;
  @media (min-width: 992px) {
    padding: 1.5rem;
  }

  li {
    display: flex;
    flex-direction: row;

    span {
      color: ${colors.blackRGBA(0.5)};
      font-size: calc(12px + 4 * ((100vw - 320px) / 1580));
      line-height: 1.5;
      white-space: pre;
    }
  }

  @media (min-width: 1025px) and (max-width: 1900px) {
    li {
      margin: 0 0 4px;
    }
  }
`

const HintContainer = styled.div.attrs({
  id: 'HintContainer'
})`
  background: ${colors.ice};
  display: flex;
  flex-direction: row;
  align-items: center;
  @media (min-width: 992px) {
    min-height: 15.67vh;
  }
`

class OpenSidebar extends React.PureComponent {
  state = {
    contentHeight: 5000
  }

  componentDidMount () {
    root.setTimeout(() => {
      const contentHeight =
        getHeight('BodyWrapper') - getHeight('HintContainer')

      this.setState({ contentHeight })
    }, 500)
  }

  get bullets () {
    return get(
      LIST.find(({ key }) => key === this.props.listKey),
      'bullets',
      []
    )
  }

  render () {
    return (
      <Container>
        <HintContainer>
          <BulletList className='list'>
            {this.bullets.map(bullet => (
              <li key={bullet}>
                <i>
                  <CheckmarkIcon />
                </i>{' '}
                <span>{bullet}</span>
              </li>
            ))}
          </BulletList>
        </HintContainer>
        <div
          className='scrollbar overflow-auto content-container'
          style={{ height: this.state.contentHeight }}
        >
          {this.props.children}
        </div>
      </Container>
    )
  }
}

export default OpenSidebar
