import React from 'react'
import { Link } from '@reach/router'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import colors from 'styles/colors'
import getAssetUrl from 'utils/getAssetUrl'
import config from 'config'
import { NavigateButton } from './CommonStyles'

const perc = { width: 240, height: 240 }
const red100 = getAssetUrl('assets/mobile/red100', perc)
const red50 = getAssetUrl('assets/mobile/red50', perc)
const blue100 = getAssetUrl('assets/mobile/blue100', perc)
const blue50 = getAssetUrl('assets/mobile/blue50', perc)
const plans = {
  prime: {
    name: 'Prime',
    id: 'prime',
    price: config.PRIME_PRICE,
    // logo: primeLogo,
    title: 'Are you an Adventurer?',
    hiddenFor: 'Dreamer (free plan)',
    columnOne: {
      title: 'Dreamer',
      deals: blue50
    },
    columnTwo: {
      title: 'Adventurer',
      deals: blue100
    }
  },
  firstClass: {
    name: 'Prime First',
    id: 'primeFirst',
    price: config.PRIME_FIRST_PRICE,
    // logo: primeFirstLogo,
    title: 'Are you a Jetsetter?',
    hiddenFor: 'Dreamer / Adventurer plans',
    columnOne: {
      title: 'Dreamer / Adventurer',
      deals: red50
    },
    columnTwo: {
      title: 'Jetsetter',
      deals: red100
    }
  }
}

const HiddenDeal = ({ dealType, primeType }) => {
  // const primaryImage = toAirportPictures.find(apPic => apPic.isPrimary)

  const isFirst = /first_class/i.test(primeType) || dealType === 'first_class'

  const plan = isFirst ? plans.firstClass : plans.prime
  return (
    <HiddenCard>
      <HiddenCardButton activeOpacity={0.9}>
        <GetPrimeButton>
          <HiddenTop>
            <Text center small color='#2D62A2'>
              <Font>This deal is hidden for</Font>
              <Font family={900} fontStyle='italic'>
                {' '}
                {plan.hiddenFor}
              </Font>
            </Text>
          </HiddenTop>

          <GetPrimeContainer>
            <Text big color='#153C6B' style={{ marginBottom: 10 }}>
              {plan.title}
            </Text>

            <Row
              style={{
                width: '100%',
                paddingHorizontal: 10,
                justifyContent: 'space-around'
              }}
            >
              <Column
                style={{
                  height: '100%',
                  justifyContent: 'center',
                  alignItems: 'flex-start'
                }}
              >
                <Text
                  color='#0B4A96'
                  weight={900}
                  size='12px'
                  style={{ marginBottom: 10 }}
                >
                  Cheap Flight <br />
                  Deal Alerts
                </Text>
                <Text color='#0B4A96' size='12px'>
                  Regular &<br />
                  Economy
                  <br />
                  Class
                </Text>
              </Column>

              <Column>
                <Text
                  size={isFirst ? '10px' : '12px'}
                  color='#153C6B'
                  weight={900}
                  center
                >
                  {plan.columnOne.title}
                </Text>

                <Row>
                  <Person
                    publicID='assets/mobile/am_subscription_dreamer'
                    alt='Dreamer'
                  />
                </Row>

                <DealsPercent src={plan.columnOne.deals} />
                <Text color='#153C6B' weight={900} small center>
                  free forever
                </Text>
              </Column>

              <Column>
                <Text
                  size={isFirst ? '10px' : '12px'}
                  color='#153C6B'
                  weight={900}
                  center
                >
                  {plan.columnTwo.title}
                </Text>

                <Row>
                  <Person
                    publicID='assets/mobile/am_subscription_adventure_machine'
                    alt='Adventurer'
                  />
                </Row>

                <DealsPercent src={plan.columnTwo.deals} />
                <Text color='#153C6B' weight={900} small center>
                  <Font>${plan.price} / mo</Font>
                </Text>
              </Column>
            </Row>

            <Row style={{ marginTop: 10, width: '100%' }}>
              <UpgradeLink to='/app/settings/prime/'>
                <UpgradeButton
                  background={colors.bluesh}
                  color={colors.white}
                  flat
                >
                  UPGRADE
                </UpgradeButton>
              </UpgradeLink>
            </Row>
          </GetPrimeContainer>
        </GetPrimeButton>
      </HiddenCardButton>
    </HiddenCard>
  )
}

const HiddenCard = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  flex: 1;
  align-items: center;
  justify-content: center;
  z-index: 99;
  display: flex;
  background: linear-gradient(
    270deg,
    rgba(0, 0, 0, 0.67) 0%,
    rgba(0, 0, 0, 0.38) 100%
  );
`

const HiddenCardButton = styled.div`
  cursor: pointer;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  //   height: 100%;
`

const GetPrimeButton = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.white};
  width: ${props => (props.isFirst ? '80%' : '78%')};
  shadow-color: ${colors.blackRGBA(0.2)};
  shadow-offset: 0px 4px;
  shadow-opacity: 1;
  shadow-radius: 7;
  flex: 1;
  elevation: 5;
  border-radius: 5px;
`
const GetPrimeContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  justify-content: center;
  align-items: center;
`

const HiddenTop = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #c7e1ff;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 4px;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const PrimeLogo = styled.img`
  width: 81;
  height: 30;
  margin-right: 10px;
`

export const PrimeLogoNew = styled.img`
  width: 70px;
  height: 28px;
  margin-left: 8px;
`

const DealsPercent = styled.img`
  width: 46px;
  height: 46px;
  margin-bottom: 5px;
`

export const Button = styled.button`
  flex-direction: row;
  width: 100%;
`

export const Text = styled.p`
  margin: 0;
  padding: 0;
  font-weight: ${props =>
    props.weight || (props.big ? 900 : props.light ? 300 : 400)};
  font-size: ${props =>
    props.size || (props.big ? '20px' : props.small ? '12px' : '14px')};
  color: ${props => props.color || colors.black};
  ${props => props.spaced && `margin: 8px 0;`};
  ${props => props.strike && `text-decoration: line-through;`};
  ${props => props.center && `text-align: center;`};
`

const Font = styled.span`
  margin: 0;
  padding: 0;
  font-weight: ${props => props.weight || 900};
  font-style: ${props => props.fontStyle || 'regular'};
`

const Person = styled.img.attrs({
  src: props =>
    `https://res.cloudinary.com/staycircles/image/upload/f_auto,q_auto,c_scale,h_400/${props.publicID}`
})`
  height: 72px;
  width: auto;
  margin-top: 5px;
  margin-bottom: 10px;
  margin-left: ${props => (props.last ? '-20px' : 0)};
`

const UpgradeLink = styled(Link)`
  width: 100%;
  text-decoration: none;
`
const UpgradeButton = styled(NavigateButton)`
  display: flex;
  justify-content: center;
  text-aligh: center;
`

HiddenDeal.propTypes = {
  dealType: PropTypes.string,
  primeType: PropTypes.string
}

export default HiddenDeal
